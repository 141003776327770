import { IDashboardResultPoint, IDashboardResultPointGroupAccelerants, IDashboardTooltip } from './dashboardTypes';

export enum BarValue {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface IAccelerant {
  id: string;
  title: string;
  value: BarValue;
  tooltip: IDashboardTooltip;
  points: IDashboardResultPoint[] | IDashboardResultPointGroupAccelerants[];
  subAccelerants?: {
    id: string;
    title: string;
    value: BarValue;
    points: IDashboardResultPoint[] | IDashboardResultPointGroupAccelerants[];
    tooltip: IDashboardTooltip;
  }[];
}
