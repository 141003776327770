import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { wrapCreateBrowserRouter } from '@sentry/react';
import AssessmentCompare from 'pages/Assessments/AssessmentCompare/AssessmentCompare';
import AssessmentCompletion from 'pages/Assessments/AssessmentCompletion/AssessmentCompletion';
import AssessmentCreate from 'pages/Assessments/AssessmentCreate/AssessmentCreate';
import AssessmentDetails from 'pages/Assessments/AssessmentDetails/AssessmentDetails';
import AssessmentList from 'pages/Assessments/AssessmentList/AssessmentList';
import AssessmentTake from 'pages/Assessments/AssessmentTake/AssessmentTake';
import ChangePassword from 'pages/Auth/ChangePassword/ChangePassword';
import ForgotPassword from 'pages/Auth/ForgotPassword/ForgotPassword';
import Profile from 'pages/Auth/Onboarding/Profile/Profile';
import SetupPassword from 'pages/Auth/SetupPassword/SetupPassword';
import SignIn from 'pages/Auth/SignIn/SignIn';
import SignUp from 'pages/Auth/SignUp/SignUp';
import DashboardNavigational from 'pages/Dashboards/DashboardNavigational/DashboardNavigational';
import DashboardRegular from 'pages/Dashboards/DashboardRegular/DashboardRegular';
import GroupAdd from 'pages/Groups/GroupAdd/GroupAdd';
import GroupHierarchyPage from 'pages/Groups/GroupHierarchyPage/GroupHierarchyPage';
import GroupList from 'pages/Groups/GroupList/GroupList';
import GroupManage from 'pages/Groups/GroupManage/GroupManage';
import RoleAdd from 'pages/Roles/RoleAdd/RoleAdd';
import RoleList from 'pages/Roles/RoleList/RoleList';
import RolePermissions from 'pages/Roles/RolePermissions/RolePermissions';
import UserAdd from 'pages/Users/UserAdd/UserAdd';
import UserAddCsv from 'pages/Users/UserAddCsv/UserAddCsv';
import UserList from 'pages/Users/UserList/UserList';

import Layout from 'components/Layout/Layout';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';

import AssessmentListOutlet from 'pages/Assessments/AssessmentList/components/Outlet/components/AssessmentList/AssessmentList';
import AssessmentResultsOutlet from 'pages/Assessments/AssessmentList/components/Outlet/components/AssessmentResults/AssessmentResults';

import { PermissionsAction, permissionsEntity } from 'types/permissionsTypes';

import ROUTES from './routes';

const getRoutes = () => {
  const routesFromElements = createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path={ROUTES.LOGIN} element={<SignIn />} />
      <Route path={ROUTES.SIGNUP} element={<SignUp />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<SetupPassword />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.ONBOARDING_PROFILE} element={<Profile />} />

      {/* Private routes */}
      <Route path={ROUTES.DASHBOARD_NAVIGATIONAL} element={<PrivateRoute component={<DashboardNavigational />} />} />
      <Route path={ROUTES.DASHBOARD_REGULAR} element={<PrivateRoute component={<DashboardRegular />} />} />
      <Route path={ROUTES.PERSONAL_PROFILE_SETTINGS} element={<PrivateRoute component={<Profile />} />} />
      <Route path={ROUTES.CHANGE_PASSWORD} element={<PrivateRoute component={<ChangePassword />} />} />

      {/* Role */}
      <Route
        path={ROUTES.ROLE_LIST}
        element={
          <PrivateRoute
            component={<RoleList />}
            entities={[permissionsEntity.ROLE]}
            permissions={[PermissionsAction.READ]}
          />
        }
      />

      <Route
        path={ROUTES.ROLE_ADD}
        element={
          <PrivateRoute
            component={<RoleAdd />}
            entities={[permissionsEntity.ROLE]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      <Route
        path={ROUTES.ROLE_EDIT()}
        element={
          <PrivateRoute
            component={<RoleAdd edit />}
            entities={[permissionsEntity.ROLE]}
            permissions={[PermissionsAction.READ, PermissionsAction.UPDATE]}
          />
        }
      />

      <Route
        path={ROUTES.ROLE_PERMISSIONS}
        element={
          <PrivateRoute
            component={<RolePermissions />}
            entities={[permissionsEntity.ROLE]}
            permissions={[PermissionsAction.READ, PermissionsAction.UPDATE]}
          />
        }
      />

      {/* User */}
      <Route
        path={ROUTES.USER_LIST}
        element={
          <PrivateRoute
            component={<UserList />}
            entities={[permissionsEntity.USER]}
            permissions={[PermissionsAction.READ]}
          />
        }
      />

      <Route
        path={ROUTES.USER_ADD}
        element={
          <PrivateRoute
            component={<UserAdd />}
            entities={[permissionsEntity.USER]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      <Route
        path={ROUTES.USER_EDIT()}
        element={
          <PrivateRoute
            component={<UserAdd edit />}
            entities={[permissionsEntity.USER]}
            permissions={[PermissionsAction.READ, PermissionsAction.UPDATE]}
          />
        }
      />

      <Route
        path={ROUTES.USER_ADD_CSV}
        element={
          <PrivateRoute
            component={<UserAddCsv />}
            entities={[permissionsEntity.USER]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      {/* Group */}
      <Route
        path={ROUTES.GROUP_LIST}
        element={
          <PrivateRoute
            component={<GroupList />}
            entities={[permissionsEntity.GROUP]}
            permissions={[PermissionsAction.READ]}
          />
        }
      />

      <Route
        path={ROUTES.GROUP_HIERARCHY}
        element={
          <PrivateRoute
            component={<GroupHierarchyPage />}
            entities={[permissionsEntity.GROUP]}
            permissions={[PermissionsAction.READ]}
          />
        }
      />

      <Route
        path={ROUTES.GROUP_ADD}
        element={
          <PrivateRoute
            component={<GroupAdd />}
            entities={[permissionsEntity.GROUP]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      <Route
        path={ROUTES.GROUP_EDIT()}
        element={
          <PrivateRoute
            component={<GroupAdd />}
            entities={[permissionsEntity.GROUP]}
            permissions={[PermissionsAction.READ, PermissionsAction.UPDATE]}
          />
        }
      />

      <Route
        path={ROUTES.GROUP_MANAGE()}
        element={
          <PrivateRoute
            component={<GroupManage />}
            entities={[permissionsEntity.GROUP]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      {/* Assessment */}
      <Route
        element={
          <PrivateRoute
            component={<AssessmentList />}
            entities={[permissionsEntity.ASSESSMENT]}
            permissions={[PermissionsAction.READ]}
          />
        }
      >
        <Route path={ROUTES.DIAGNOSTIC_LIST} element={<AssessmentListOutlet />} />
        <Route path={ROUTES.DIAGNOSTIC_RESULTS} element={<AssessmentResultsOutlet />} />
      </Route>

      <Route
        path={ROUTES.DIAGNOSTIC_CREATE}
        element={
          <PrivateRoute
            component={<AssessmentCreate />}
            entities={[permissionsEntity.ASSESSMENT]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      <Route
        path={ROUTES.DIAGNOSTIC_COMPARE()}
        element={
          <PrivateRoute
            component={<AssessmentCompare />}
            entities={[permissionsEntity.ASSESSMENT]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      <Route
        path={ROUTES.DIAGNOSTIC_DETAILS()}
        element={
          <PrivateRoute
            component={<AssessmentDetails />}
            entities={[permissionsEntity.ASSESSMENT]}
            permissions={[PermissionsAction.READ, PermissionsAction.CREATE]}
          />
        }
      />

      <Route
        path={ROUTES.DIAGNOSTIC_TAKE()}
        element={
          <PrivateRoute
            component={<AssessmentTake />}
            entities={[permissionsEntity.ASSESSMENT]}
            permissions={[PermissionsAction.READ]}
          />
        }
      />

      <Route
        path={ROUTES.DIAGNOSTIC_COMPLETION()}
        element={
          <PrivateRoute
            component={<AssessmentCompletion />}
            entities={[permissionsEntity.OWN_RESULTS, permissionsEntity.OTHER_USERS_DASHBOARD_COMPARISON]}
            permissions={[PermissionsAction.READ]}
          />
        }
      />

      <Route path={ROUTES.DIAGNOSTIC_EDIT()} element={<PrivateRoute component={<AssessmentCreate />} />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>,
  );

  if (process.env.REACT_APP_SENTRY_TOKEN) {
    const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
    return sentryCreateBrowserRouter(routesFromElements);
  }

  return createBrowserRouter(routesFromElements);
};

export const routes = getRoutes();
