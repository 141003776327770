import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'hooks/redux';

import { useDeleteSectionMutation } from 'store/api/assessmentApi/assessmentApi';
import { removeBoardElement } from 'store/features/createAssessmentSlice';

import { DeleteModalState } from 'types/createAssessmentTypes';

import ModalWrapper from '../ModalWrapper';

import styles from './DeleteModuleModal.module.scss';

type Props = {
  state: DeleteModalState;
  handleClose: () => void;
};

const DeleteModuleModal = ({ state, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteModule, { isLoading, isSuccess, reset }] = useDeleteSectionMutation();

  const closeModalHandler = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  useEffect(() => {
    if (isSuccess) {
      closeModalHandler();
    }
  }, [closeModalHandler, isSuccess]);

  const handleDeleteModule = () => {
    deleteModule(state.id)
      .unwrap()
      .then(() => {
        dispatch(removeBoardElement({ elementId: state.id, isRecursive: true }));
      });
  };

  return (
    <ModalWrapper
      open={state.open}
      close={handleClose}
      cancelTitle={t('general.cancel')}
      action={handleDeleteModule}
      actionTitle={t('general.confirm')}
      status={isLoading}
      attention
    >
      <div className={styles.DeletionModal}>
        <p>
          {t('assessment.delete_module_message.part_1')} <span>'{t('general.confirm')}'</span>{' '}
          {t('assessment.delete_module_message.part_2')}
        </p>
        <p>{t('assessment.delete_module_message.part_3')}</p>
      </div>
    </ModalWrapper>
  );
};

export default DeleteModuleModal;
