import { ChangeEvent, FormEvent, MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck, MdFormatColorText } from 'react-icons/md';

import classNames from 'classnames';
import { Location, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import usePopup from '../../utils/hooks/usePopup';

import { CustomEditor } from 'types/richTextEditorTypes';

import { activeMark, addMarkData } from '../../utils/SlateUtilityFunctions';
import { colors } from './defaultColors';

import styles from './ColorPicker.module.scss';

const logo = {
  color: <MdFormatColorText size={18} />,
};

type Props = {
  editor: CustomEditor;
};

const ColorPicker = ({ editor }: Props) => {
  const format = 'color';
  const { t } = useTranslation();
  const [selection, setSelection] = useState<Location | null>(null);
  const [hexValue, setHexValue] = useState<string>('');
  const [validHex, setValidHex] = useState<boolean>(false);
  const colorPickerRef = useRef(null);
  const [showOptions, setShowOptions] = usePopup(colorPickerRef);

  const isValideHexSix = new RegExp('^#[0-9A-Za-z]{6}');
  const isValideHexThree = new RegExp('^#[0-9A-Za-z]{3}');

  const changeColor = (e: MouseEvent<HTMLButtonElement>) => {
    const clickedColor = (e.target as HTMLDivElement).getAttribute('data-value');
    selection && Transforms.select(editor, selection);
    selection && ReactEditor.focus(editor);

    addMarkData(editor, { format, value: clickedColor });
    setShowOptions(false);
  };

  const toggleOption = () => {
    setSelection(editor.selection);
    selection && ReactEditor.focus(editor);

    setShowOptions((prev) => !prev);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validHex) return;
    selection && Transforms.select(editor, selection);

    addMarkData(editor, { format, value: hexValue });
    setShowOptions(false);
    setValidHex(false);
    setHexValue('');
    selection && ReactEditor.focus(editor);
  };

  const handleHexChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newHex = e.target.value;
    setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
    setHexValue(newHex);
  };

  return (
    <div className={classNames(styles.colorPicker, styles.popupWrapper)} ref={colorPickerRef}>
      <button style={{ color: showOptions ? 'var(--black)' : activeMark(editor, format) }} onClick={toggleOption}>
        {logo[format]}
      </button>
      {showOptions && (
        <div className={styles.popup}>
          <div className={styles.colorOptions}>
            {colors.map((color, index) => {
              return (
                <button
                  key={index}
                  data-value={color}
                  onClick={changeColor}
                  className={styles.option}
                  style={{ background: color }}
                />
              );
            })}
          </div>
          <p>{t('general.or').toUpperCase()}</p>
          <form onSubmit={handleFormSubmit}>
            <div className={styles.hexPreview} style={{ background: validHex ? hexValue : 'var(--black)' }}></div>
            <input
              type="text"
              placeholder="#000000"
              value={hexValue}
              onChange={handleHexChange}
              style={{ border: validHex === false ? '1px solid red' : '1px solid var(--alto)' }}
            />
            <button style={{ color: validHex ? 'green' : '' }} type={'submit'}>
              <MdCheck size={20} />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
