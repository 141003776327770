import classNames from 'classnames';

import { CardVariants } from 'types/assessmentTypes';

import styles from './Line.module.scss';

type Props = {
  variant?: CardVariants;
};

const SelectManyCard = ({ variant }: Props) => (
  <div
    className={classNames(styles.Line, {
      [styles.Yellow]: variant === CardVariants.Yellow,
    })}
  />
);

export default SelectManyCard;
