import classNames from 'classnames';

import styles from './NothingFoundText.module.scss';

type Props = {
  text: string;
  center?: boolean;
};

const NothingFoundText = ({ text, center }: Props) => (
  <div className={classNames(styles.NothingFoundContainer, center && styles.Center)}>
    <span>{text}</span>
  </div>
);

export default NothingFoundText;
