import { Dispatch, SetStateAction } from 'react';

import Loader from 'components/Loader/Loader';

import { CreateMappingForm, MappingData, Step, Steps } from './components';

import { useFormBuilder } from './hooks';

import { useGetVisualisationsQuery } from 'store/api/visualisationsApi/visualisationsApi';

import { IMappingsFormData } from 'types/createAssessmentTypes';
import { ResultsMappingStep } from 'types/enums';

import styles from './ResultsMapping.module.scss';

type Props = {
  mappingsFormData: IMappingsFormData;
  setMappingsFormData: Dispatch<SetStateAction<IMappingsFormData>>;
  isMappingsCreated: boolean;
  setIsMappingsCreated: Dispatch<SetStateAction<boolean>>;
  mappingsSteps: {
    steps: ResultsMappingStep[];
    currentStep: number;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
  };
};
export const ResultsMapping = ({
  mappingsFormData,
  setMappingsFormData,
  isMappingsCreated,
  setIsMappingsCreated,
  mappingsSteps,
}: Props) => {
  const { data, isLoading } = useGetVisualisationsQuery();

  const { scurveFormData, accelerantsFormData, ecosystemFormData } = useFormBuilder(data);
  const { steps, currentStep, handlePreviousStep, handleNextStep } = mappingsSteps;

  if (isLoading) return <Loader flexCenter size="md" />;

  if (!isMappingsCreated) {
    return (
      <MappingData
        scurveFormData={scurveFormData}
        accelerantsFormData={accelerantsFormData}
        ecosystemFormData={ecosystemFormData}
      />
    );
  }

  return (
    <div className={styles.Container}>
      <div className={styles.StepsWrapper}>
        <Steps>
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step}
              isPathCompleted={index < currentStep - 1}
              isStepCompleted={index < currentStep}
              isActive={currentStep === index}
            />
          ))}
        </Steps>
      </div>

      {currentStep === 0 && (
        <CreateMappingForm
          formData={scurveFormData}
          handleNextStep={handleNextStep}
          currentStep={currentStep}
          mappingsFormData={mappingsFormData}
          setMappingsFormData={setMappingsFormData}
        />
      )}

      {currentStep === 1 && (
        <CreateMappingForm
          formData={accelerantsFormData}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          currentStep={currentStep}
          mappingsFormData={mappingsFormData}
          setMappingsFormData={setMappingsFormData}
        />
      )}

      {currentStep === 2 && (
        <CreateMappingForm
          formData={ecosystemFormData}
          handlePreviousStep={handlePreviousStep}
          setIsCreating={setIsMappingsCreated}
          currentStep={currentStep}
          mappingsFormData={mappingsFormData}
          setMappingsFormData={setMappingsFormData}
        />
      )}
    </div>
  );
};
