import { Slider as SliderMUI, SliderProps } from '@mui/material';

const Slider = (props: SliderProps) => {
  return (
    <SliderMUI
      {...props}
      sx={{
        height: 4,
        '& .MuiSlider-track': {
          backgroundColor: 'var(--refined-teal)',
          height: 8,
          border: 'none',
        },
        '& .MuiSlider-rail': {
          backgroundColor: 'var(--lines)',
          height: 8,
        },
        '& .MuiSlider-thumb': {
          width: 24,
          height: 24,
          backgroundColor: 'var(--refined-teal)',
          transition: '0.2s',
          '&.Mui-active': {
            width: 28,
            height: 28,
          },
        },
      }}
    />
  );
};

export default Slider;
