import { Stack } from '@mui/material';

import { useAppSelector } from 'hooks/redux';

import { GetGroupByIdResponse } from 'store/api/groupsApi/types';
import { GetUserByIdResponse } from 'store/api/usersApi/types';

import { ComparisonType, CompletionDetails } from 'types/assessmentTypes';
import { DashboardType } from 'types/dashboardTypes';
import { CardColor } from 'types/enums';

import { ReactComponent as Compare } from 'assets/images/compare.svg';

import DashboardCardInfo from '../../../DashboardCardInfo/DashboardCardInfo';

type Props = {
  dashboardType: DashboardType;
  groupLeft: GetGroupByIdResponse | undefined;
  groupRight: GetGroupByIdResponse | undefined;
  isSameGroup: boolean;
  userLeft: GetUserByIdResponse | undefined;
  userRight: GetUserByIdResponse | undefined;
  isSameUser: boolean;
  completionLeft: CompletionDetails | undefined;
  completionRight: CompletionDetails | undefined;
  isSameAssessment: boolean;
};

const ComparisonInfo = ({
  dashboardType,
  groupLeft,
  groupRight,
  isSameGroup,
  userLeft,
  userRight,
  isSameUser,
  completionLeft,
  completionRight,
  isSameAssessment,
}: Props) => {
  const { comparisonType } = useAppSelector((state) => state.permissions);

  const isOwnDashboard = comparisonType === ComparisonType.Own;

  const renderComparisonInfoLabel = () => {
    if (dashboardType === DashboardType.GROUP) {
      return (
        <>
          <DashboardCardInfo
            group={groupLeft}
            groupColor={CardColor.Blue}
            assessmentName={completionLeft?.assessment_name}
            assessmentNameColor={CardColor.Blue}
          />

          <Compare />

          <DashboardCardInfo
            group={isSameGroup ? undefined : groupRight}
            groupColor={CardColor.Yellow}
            assessmentName={completionRight?.assessment_name}
            assessmentNameColor={CardColor.Yellow}
          />
        </>
      );
    }

    if (dashboardType === DashboardType.USER) {
      if (isOwnDashboard) {
        return (
          <>
            <DashboardCardInfo assessmentName={completionLeft?.assessment_name} assessmentNameColor={CardColor.Blue} />

            <Compare />

            <DashboardCardInfo
              assessmentName={completionRight?.assessment_name}
              assessmentNameColor={CardColor.Yellow}
            />
          </>
        );
      }

      return (
        <>
          <DashboardCardInfo
            user={userLeft}
            userColor={CardColor.Blue}
            assessmentName={completionLeft?.assessment_name}
            assessmentNameColor={CardColor.Blue}
          />

          <Compare />

          <DashboardCardInfo
            user={isSameUser ? undefined : userRight}
            userColor={CardColor.Yellow}
            assessmentName={completionRight?.assessment_name}
            assessmentNameColor={CardColor.Yellow}
          />
        </>
      );
    }

    return null;
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1.25} minWidth={0}>
      {renderComparisonInfoLabel()}
    </Stack>
  );
};

export default ComparisonInfo;
