import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  AssessmentAssigneesOverwritePayload,
  AssessmentAssigneesOverwriteResponse,
  AssessmentComparisonPayload,
  AssessmentComparisonResponse,
  CompletionValidatePayload,
  CompletionValidateResponse,
  CreateAssessmentPayload,
  CreateAssessmentResponse,
  CreateSectionPayload,
  CreateSectionResponse,
  DeleteSectionPayload,
  DeleteSectionResponse,
  GetAssessmentByIdPayload,
  GetAssessmentByIdResponse,
  GetAssessmentElementsByIdPayload,
  GetAssessmentElementsByIdResponse,
  GetAssessmentHierarchyPayload,
  GetAssessmentHierarchyResponse,
  GetAssessmentsPayload,
  GetAssessmentsResponse,
  GetComparisonListGroupPayload,
  GetComparisonListGroupResponse,
  GetComparisonListPayload,
  GetComparisonListResponse,
  GetComparisonListUsersAllPayload,
  GetComparisonListUsersPayload,
  GetComparisonUsersAllResponse,
  GetComparisonUsersResponse,
  GetCompletionByIdPayload,
  GetCompletionByIdResponse,
  GetCompletionsPayload,
  GetCompletionsResponse,
  GetDashboardGroupsPayload,
  GetDashboardGroupsResponse,
  GetIncompleteAssessmentsPayload,
  GetIncompleteAssessmentsResponse,
  GetLastCompletionGroupPayload,
  GetLastCompletionGroupResponse,
  GetLastCompletionPayload,
  GetLastCompletionResponse,
  GetSectionsPayload,
  GetSectionsResponse,
  MakeAnswerChoicePayload,
  MakeAnswerChoiceResponse,
  MakeAnswerNumericPayload,
  MakeAnswerNumericResponse,
  MakeAnswerTextPayload,
  MakeAnswerTextResponse,
  SendVisualizationPdfPayload,
  SendVisualizationPdfResponse,
  StartAssessmentPayload,
  StartAssessmentResponse,
  UpdateAssessmentPayload,
  UpdateAssessmentResponse,
  UpdateSectionPayload,
  UpdateSectionResponse,
  UploadImagePayload,
  UploadImageResponse,
} from 'store/api/assessmentApi/types';

import { baseAssessmentQueryWithReauth } from '../../helpers';

import { GetSections } from 'types/createAssessmentTypes';
import { SortTypes } from 'types/enums';

export const assessmentApi = createApi({
  tagTypes: [
    'Assessment',
    'Sections',
    'Completion',
    'AssessmentDetail',
    'AssessmentHierarchy',
    'Units',
    'CompletionLatest',
  ],
  reducerPath: 'assessmentsApi',
  baseQuery: baseAssessmentQueryWithReauth,
  endpoints: (builder) => ({
    getAssessments: builder.query<GetAssessmentsResponse, GetAssessmentsPayload>({
      query: (data) => {
        let params = {};

        if (data) {
          const {
            page,
            size,
            order_by: { sortBy, sortType },
            search,
          } = data;

          params = { page, size, search, order_by: (sortType === SortTypes.ASC ? '+' : '-') + sortBy };
        }

        return {
          url: '/assessments/',
          params: { ...params },
        };
      },
      providesTags: () => ['Assessment'],
    }),

    getAssessmentById: builder.query<GetAssessmentByIdResponse, GetAssessmentByIdPayload>({
      query: ({ id }) => `/assessments/${id}/`,
      providesTags: ['AssessmentDetail'],
    }),

    getAssessmentHierarchy: builder.query<GetAssessmentHierarchyResponse, GetAssessmentHierarchyPayload>({
      query: (id) => `/assessments/${id}/hierarchy/`,
      providesTags: ['AssessmentHierarchy'],
    }),

    getAssessmentElementsById: builder.query<GetAssessmentElementsByIdResponse, GetAssessmentElementsByIdPayload>({
      query: (id) => `/assessments/${id}/elements/`,
    }),

    startAssessment: builder.mutation<StartAssessmentResponse, StartAssessmentPayload>({
      query: (assessment) => ({
        method: 'POST',
        url: '/completion/',
        body: assessment,
      }),
    }),

    getCompletions: builder.query<GetCompletionsResponse, GetCompletionsPayload>({
      query: (data) => {
        let params = {};

        if (data) {
          const {
            page,
            size,
            order_by: { sortBy, sortType },
            search,
          } = data;

          params = { page, size, search, order_by: (sortType === SortTypes.ASC ? '+' : '-') + sortBy };
        }

        return {
          url: '/completion/',
          params: { ...params },
        };
      },
      providesTags: ['Completion'],
    }),

    getCompletionById: builder.query<GetCompletionByIdResponse, GetCompletionByIdPayload>({
      query: ({ id }) => ({
        url: `/completion/${id}/`,
      }),
    }),

    makeAnswerNumeric: builder.mutation<MakeAnswerNumericResponse, MakeAnswerNumericPayload>({
      query: (assessment) => ({
        method: 'POST',
        url: '/answers/numeric/',
        body: assessment,
      }),
    }),

    makeAnswerText: builder.mutation<MakeAnswerTextResponse, MakeAnswerTextPayload>({
      query: (assessment) => ({
        method: 'POST',
        url: '/answers/text/',
        body: assessment,
      }),
    }),

    makeAnswerChoice: builder.mutation<MakeAnswerChoiceResponse, MakeAnswerChoicePayload>({
      query: (assessment) => ({
        method: 'POST',
        url: '/answers/choice/',
        body: assessment,
      }),
    }),

    updateSection: builder.mutation<UpdateSectionResponse, UpdateSectionPayload>({
      invalidatesTags: ['Sections'],
      query: ({ id, section_type, title }) => ({
        url: `/sections/${id}/`,
        method: 'PUT',
        body: {
          title,
          section_type,
        },
      }),
    }),

    deleteSection: builder.mutation<DeleteSectionResponse, DeleteSectionPayload>({
      invalidatesTags: ['Sections'],
      query: (id) => ({
        url: `/sections/${id}/`,
        method: 'DELETE',
        body: '',
      }),
    }),

    createSection: builder.mutation<CreateSectionResponse, CreateSectionPayload>({
      invalidatesTags: ['Sections'],
      query: (payload) => ({
        url: '/sections/',
        method: 'POST',
        body: payload,
      }),
    }),

    getSections: builder.query<GetSectionsResponse, GetSectionsPayload>({
      query: ({ type, page = 1, size = 100 }) => ({
        url: '/sections/',
        params: { section_type: type, is_hidden: false, page, size },
      }),
      transformResponse: (response: GetSections) => {
        return response.items;
      },
      providesTags: ['Sections'],
    }),

    createAssessment: builder.mutation<CreateAssessmentResponse, CreateAssessmentPayload>({
      query: (payload) => ({
        url: '/assessments/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Assessment'],
    }),

    updateAssessment: builder.mutation<UpdateAssessmentResponse, UpdateAssessmentPayload>({
      query: ({ payload, id }) => ({
        url: `/assessments/${id}/`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['AssessmentHierarchy', 'Units'],
    }),

    assessmentAssigneesOverwrite: builder.mutation<
      AssessmentAssigneesOverwriteResponse,
      AssessmentAssigneesOverwritePayload
    >({
      query: ({ payload, id }) => ({
        url: `/assessments/${id}/assignees/overwrite/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['AssessmentDetail'],
    }),

    deleteAssessment: builder.mutation<void, string>({
      query: (id) => ({
        url: `/assessments/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Assessment', 'Units'],
    }),

    assessmentComparison: builder.query<AssessmentComparisonResponse, AssessmentComparisonPayload>({
      query: ({ completedAssessmentIdFirst, completedAssessmentIdSecond, comparisonType }) => ({
        url: '/comparison/',
        params: {
          completed_assessment_id_first: completedAssessmentIdFirst,
          completed_assessment_id_second: completedAssessmentIdSecond,
          comparison_type: comparisonType,
        },
      }),
    }),

    getComparisonList: builder.query<GetComparisonListResponse, GetComparisonListPayload>({
      query: ({ userId, comparisonType, comparedAssessmentId, dateFrom, dateTo }) => ({
        url: '/comparison/list/',
        params: {
          user_id: userId,
          comparison_type: comparisonType,
          date_from: dateFrom,
          date_to: dateTo,
          ...(comparedAssessmentId && { compared_completed_assessment_id: comparedAssessmentId }),
        },
      }),
    }),

    getLastCompletionGroup: builder.query<GetLastCompletionGroupResponse, GetLastCompletionGroupPayload>({
      query: ({ groupId }) => `/completion/latest/group/${groupId}/`,
    }),

    getComparisonListGroup: builder.query<GetComparisonListGroupResponse, GetComparisonListGroupPayload>({
      query: ({ groupId, dateFrom, dateTo }) => ({
        url: `/comparison/list/group/${groupId}/`,
        params: { date_from: dateFrom, date_to: dateTo },
      }),
    }),

    getComparisonUsers: builder.query<GetComparisonUsersResponse, GetComparisonListUsersPayload>({
      query: ({ assessmentTakeId, groupId }) => ({
        url: '/comparison/users/',
        params: {
          compared_completed_assessment_id: assessmentTakeId,
        },
        headers: {
          group_id: groupId,
        },
      }),
    }),

    getComparisonUsersAll: builder.query<GetComparisonUsersAllResponse, GetComparisonListUsersAllPayload>({
      query: ({ assessmentTakeId }) => ({
        url: '/comparison/users/all/',
        params: {
          compared_completed_assessment_id: assessmentTakeId,
        },
      }),
    }),

    getLastCompletion: builder.query<GetLastCompletionResponse, GetLastCompletionPayload>({
      query: ({ userId }) => ({
        url: '/completion/latest/',
        params: {
          user_id: userId,
        },
      }),
      providesTags: ['CompletionLatest'],
    }),

    // We trigger request, on selectedGroup change, but adding it in headers in file src/store/api/authService.ts
    getDashboardGroups: builder.query<GetDashboardGroupsResponse, GetDashboardGroupsPayload>({
      query: () => '/comparison/groups/',
    }),

    sendVisualizationPdf: builder.mutation<SendVisualizationPdfResponse, SendVisualizationPdfPayload>({
      query: (payload) => ({
        method: 'POST',
        url: '/visualisations/pdf/',
        body: payload,
      }),
    }),

    completionValidate: builder.mutation<CompletionValidateResponse, CompletionValidatePayload>({
      query: ({ payload, completionId }) => ({
        method: 'POST',
        url: `/completion/${completionId}/validate/`,
        body: payload,
      }),
    }),

    uploadImage: builder.mutation<UploadImageResponse, UploadImagePayload>({
      query: (payload) => ({
        method: 'POST',
        url: '/assessments/images/',
        body: payload,
      }),
    }),

    getIncompleteAssessments: builder.query<GetIncompleteAssessmentsResponse, GetIncompleteAssessmentsPayload>({
      query: () => '/assessments/incomplete/',
    }),
  }),
});

export const {
  useGetAssessmentsQuery,
  useLazyGetAssessmentsQuery,
  useGetAssessmentByIdQuery,
  useGetAssessmentElementsByIdQuery,
  useStartAssessmentMutation,
  useGetCompletionsQuery,
  useGetCompletionByIdQuery,
  useMakeAnswerChoiceMutation,
  useMakeAnswerNumericMutation,
  useMakeAnswerTextMutation,
  useCreateAssessmentMutation,
  useGetSectionsQuery,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
  useDeleteAssessmentMutation,
  useUpdateAssessmentMutation,
  useGetAssessmentHierarchyQuery,
  useAssessmentAssigneesOverwriteMutation,
  useGetComparisonListQuery,
  useGetComparisonUsersQuery,
  useGetComparisonUsersAllQuery,
  useGetLastCompletionQuery,
  useGetLastCompletionGroupQuery,
  useGetComparisonListGroupQuery,
  useGetDashboardGroupsQuery,
  useSendVisualizationPdfMutation,
  useUploadImageMutation,
  useGetIncompleteAssessmentsQuery,
  useLazyGetIncompleteAssessmentsQuery,
  useCompletionValidateMutation,
} = assessmentApi;
