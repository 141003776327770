import { useState } from 'react';

import { ResultsMappingStep } from 'types/enums';

const steps = Object.values(ResultsMappingStep);

export const useSteps = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const handlePreviousStep = () => {
    if (currentStep === 0) return;
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };

  const handleNextStep = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  return { steps, currentStep, handlePreviousStep, handleNextStep };
};
