import React from 'react';

export const AttentionSVG = () => (
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3280_139557)">
      <path
        d="M18.4999 27.3905C19.2345 27.3905 19.8299 26.795 19.8299 26.0605C19.8299 25.3259 19.2345 24.7305 18.4999 24.7305C17.7654 24.7305 17.1699 25.3259 17.1699 26.0605C17.1699 26.795 17.7654 27.3905 18.4999 27.3905Z"
        fill="#DC001C"
      />
      <path
        d="M18.5 22.6094C18.2348 22.6094 17.9804 22.504 17.7929 22.3165C17.6054 22.1289 17.5 21.8746 17.5 21.6094V9.60938C17.5 9.34416 17.6054 9.0898 17.7929 8.90227C17.9804 8.71473 18.2348 8.60938 18.5 8.60938C18.7652 8.60938 19.0196 8.71473 19.2071 8.90227C19.3946 9.0898 19.5 9.34416 19.5 9.60938V21.6094C19.5 21.8746 19.3946 22.1289 19.2071 22.3165C19.0196 22.504 18.7652 22.6094 18.5 22.6094Z"
        fill="#DC001C"
      />
      <path
        d="M15.5625 1.68166C15.8404 1.17582 16.2492 0.753946 16.746 0.460172C17.2428 0.166399 17.8094 0.0115145 18.3866 0.011719C18.9638 0.0119234 19.5303 0.167209 20.0269 0.461334C20.5235 0.75546 20.9319 1.17762 21.2095 1.68366L35.0995 27.2437C35.3659 27.7341 35.5003 28.2853 35.4894 28.8433C35.4785 29.4013 35.3229 29.9469 35.0376 30.4265C34.7523 30.9062 34.3473 31.3035 33.8622 31.5795C33.3771 31.8554 32.8286 32.0006 32.2705 32.0007H4.5225C3.95813 32.0133 3.40036 31.8773 2.90511 31.6064C2.40985 31.3355 1.99453 30.9391 1.70078 30.457C1.40702 29.975 1.24516 29.4242 1.23142 28.8598C1.21768 28.2955 1.35255 27.7374 1.6225 27.2417L15.5625 1.68166ZM3.3805 28.1987C3.27747 28.3881 3.22648 28.6014 3.23272 28.8169C3.23897 29.0325 3.30222 29.2425 3.41604 29.4256C3.52986 29.6088 3.69019 29.7585 3.88069 29.8595C4.07118 29.9605 4.28505 30.0092 4.5005 30.0007H32.2705C32.482 30.0005 32.6898 29.9455 32.8735 29.8408C33.0573 29.7362 33.2107 29.5855 33.3187 29.4037C33.4267 29.2219 33.4856 29.0151 33.4896 28.8037C33.4936 28.5923 33.4426 28.3834 33.3415 28.1977L19.4545 2.64266C19.3492 2.45144 19.1946 2.29196 19.0067 2.18085C18.8188 2.06974 18.6046 2.01108 18.3863 2.01098C18.168 2.01087 17.9537 2.06934 17.7657 2.18027C17.5777 2.2912 17.4229 2.45053 17.3175 2.64166L3.3795 28.1987H3.3805Z"
        fill="#DC001C"
      />
    </g>
    <defs>
      <clipPath id="clip0_3280_139557">
        <rect width="36" height="36" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default AttentionSVG;
