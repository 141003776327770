import { ReactNode } from 'react';

import classNames from 'classnames';

import { ReactComponent as ArrowBack } from 'assets/images/arrow-back.svg';

import styles from './MobileBack.module.scss';

type Props = {
  title: string;
  handleBack: () => void;
  children?: ReactNode;
  column?: boolean;
};

const MobileBack = ({ title, handleBack, children, column }: Props) => {
  return (
    <div className={classNames(styles.Container, column && styles.Column)} onClick={handleBack}>
      <div className={styles.Left}>
        <ArrowBack className={styles.Icon} />
        <span className={styles.Title}>{title}</span>
      </div>
      {children && <div className={styles.Right}>{children}</div>}
    </div>
  );
};

export default MobileBack;
