import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Pagination } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import orderBy from 'lodash/orderBy';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Loader from 'components/Loader/Loader';
import EditUnitModal from 'components/Modals/EditUnitModal/EditUnitModal';
import ModalWrapper from 'components/Modals/ModalWrapper';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';

import AssignedToCell from '../Table/components/AssignedToCell/AssignedToCell';
import AccordionCustom from './components/AccordionCustom';
import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';
import useTableControl from 'hooks/useTableControl';

import { useDeleteAssessmentMutation } from 'store/api/assessmentApi/assessmentApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { getCurrentPageItems } from 'helpers/pagination';
import { getFieldId } from 'helpers/table';

import { MOBILE_BREAKPOINT } from 'constants/';

import { IAssessment, ITableProps } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize, SortTypes } from 'types/enums';
import { MobileCardType, MobileCardsData } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import Table from '../Table/Table';

import styles from './AssessmentListItem.module.scss';

type Props = {
  unitTitle?: string;
  unitId?: string;
  assessments: IAssessment[];
  tableProps: ITableProps;
  total?: number;
};

type DataTable = {
  id: string;
  assessmentId: string;
  fieldId: number;
  name: string;
  description: string;
  creator: string;
  assignedTo: string[];
  attemptDate: Date;
  isAssignedToCurrentUser: boolean;
};

const AssessmentListItem = ({ unitTitle, unitId, tableProps, assessments, total }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assessment, unit } = useAppSelector((state) => state.permissions.permissions);
  const [deleteAssessment, { isLoading: isLoadingDeleteAssessment }] = useDeleteAssessmentMutation();
  const [openEditUnitModal, toggleEditUnitModal] = useState(false);
  const [openDeleteAssessmentModal, toggleDeleteAssessmentModal] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState<{ assessmentId: string } | null>(null);
  const { page, onPageChange } = useTableControl('title');
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const pageTableProps = {
    ...tableProps,
    page,
    onPageChange,
  };

  const onRowClick = ({ row: { assessmentId } }: GridRowParams) => {
    if (
      checkPermission(assessment, [PermissionsAction.READ]) ||
      checkPermission(assessment, [PermissionsAction.CREATE])
    ) {
      navigate(ROUTES.DIAGNOSTIC_DETAILS(assessmentId));
    }
  };

  const handleCloseEditUnitModal = () => toggleEditUnitModal(false);
  const handleCloseDeleteAssessmentModal = () => toggleDeleteAssessmentModal(false);

  const handleDeleteTable = (row: { assessmentId: string }) => {
    setAssessmentToDelete(row);
    toggleDeleteAssessmentModal(true);
  };

  const handleTakeAssessment = useCallback(
    (id: string) => {
      navigate(ROUTES.DIAGNOSTIC_TAKE(id));
    },
    [navigate],
  );

  const handleDeleteAssessment = () => {
    if (assessmentToDelete) {
      deleteAssessment(assessmentToDelete.assessmentId)
        .unwrap()
        .then(() => {
          handleCloseDeleteAssessmentModal();
        })
        .catch(() => {
          toast.error(t('general.something_went_wrong'));
        });
    }
  };

  const getActions = useCallback(
    (row: DataTable) => {
      const actions: ReactNode[] = [];

      if (
        isMobile &&
        (checkPermission(assessment, [PermissionsAction.READ]) ||
          checkPermission(assessment, [PermissionsAction.CREATE]))
      ) {
        actions.push(
          <ButtonStyled
            key="open"
            fill={ButtonFill.Transparent}
            size={ButtonSize.Link}
            onClick={() => navigate(ROUTES.DIAGNOSTIC_DETAILS(row.assessmentId))}
          >
            {t('general.open')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(assessment, [PermissionsAction.READ]) && row.isAssignedToCurrentUser) {
        actions.push(
          <ButtonStyled
            key="take"
            onClick={(event) => {
              event.stopPropagation();
              handleTakeAssessment(row.assessmentId.toString());
            }}
            fill={ButtonFill.Contained}
            size={isMobile ? ButtonSize.Small : ButtonSize.Long}
          >
            {t('general.take_diagnostic')}
          </ButtonStyled>,
        );
        return actions;
      }

      if (!isMobile && checkPermission(assessment, [PermissionsAction.UPDATE])) {
        actions.push(
          <ButtonStyled
            key="edit"
            onClick={(event) => {
              event.stopPropagation();
              navigate(ROUTES.DIAGNOSTIC_EDIT(row.assessmentId));
            }}
            fill={ButtonFill.Transparent}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            icon={<Edit />}
          >
            {t('general.edit')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(assessment, [PermissionsAction.DELETE])) {
        actions.push(
          <ButtonStyled
            key="delete"
            fill={ButtonFill.TransparentRed}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteTable(row);
            }}
            icon={<Delete />}
          >
            {t('general.delete')}
          </ButtonStyled>,
        );
      }

      return actions;
    },
    [assessment, handleTakeAssessment, isMobile, navigate, t],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'fieldId', headerName: '#', sortable: false, width: 30 },
      {
        field: 'name',
        headerName: t('assessment.table.name'),
        sortable: false,
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('assessment.table.description'),
        sortable: false,
        valueFormatter: (data) => data.value || ' - ',
        flex: 1,
      },
      {
        field: 'creator',
        headerName: t('assessment.table.creator'),
        sortable: false,
        valueFormatter: (data) => data.value || ' - ',
        flex: 1,
      },
      {
        field: 'assignedTo',
        headerName: t('assessment.table.assigned_to'),
        sortable: false,
        valueFormatter: (data) => data.value[0] || t('general.not_assigned'),
        renderCell: (params) => <AssignedToCell value={params.value} formattedValue={params.formattedValue} />,
        minWidth: 146,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: t('assessment.table.actions'),
        headerAlign: 'left',
        align: 'left',
        flex: 1.5,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, DataTable>) => getActions(params.row),
      },
    ],
    [getActions, t],
  );

  const dataTable = useMemo(
    () =>
      assessments.map((item, index) => {
        const { id, created_at, title, description, creator_full_name, users, groups, is_assigned_to_current_user } =
          item;

        const assignedToUsers = users.map((item) => item.first_name + ' ' + item.last_name);
        const assignedToGroups = groups.map((item) => item.name);
        const assignedTo = [...assignedToUsers, ...assignedToGroups];

        return {
          id: String(id + index),
          assessmentId: id,
          name: title,
          description: description,
          creator: creator_full_name || '-',
          assignedTo: assignedTo,
          attemptDate: new Date(created_at),
          isAssignedToCurrentUser: is_assigned_to_current_user,
        };
      }),
    [assessments],
  );

  const mobileCardsData = useMemo<MobileCardsData>(
    () =>
      isMobile && dataTable
        ? orderBy(
            dataTable,
            ['name'],
            [tableProps.sort.sortType === SortTypes.ASC ? SortTypes.ASC : SortTypes.DESC],
          ).map((item, index) => ({
            row: [
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.name'),
                    value: item.name,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Ordinal,
                    label: '#',
                    value: !!tableProps.search
                      ? getFieldId({ page: tableProps.page, size: 12, index, total: dataTable.length })
                      : index + 1,
                    key: item.id + 2,
                  },
                ],
                key: item.id + 1,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.creator'),
                    value: item.creator,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.assigned_to'),
                    value: (
                      <AssignedToCell
                        value={item.assignedTo}
                        formattedValue={item.assignedTo[0] || t('general.not_assigned')}
                      />
                    ),
                    key: item.id + 2,
                  },
                ],
                key: item.id + 2,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.description'),
                    value: item.description,
                    key: item.id + 1,
                  },
                ],
                key: item.id + 3,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Actions,
                    actions: getActions(item as DataTable),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 4,
              },
            ],
            key: item.id,
          }))
        : [],
    [dataTable, getActions, isMobile, t, tableProps.page, tableProps.search, tableProps.sort.sortType],
  );

  return (
    <Fragment>
      {unitTitle && (
        <EditUnitModal
          enableDelete
          open={openEditUnitModal}
          handleClose={handleCloseEditUnitModal}
          unitName={unitTitle}
          unitId={unitId || ''}
        />
      )}
      <ModalWrapper
        title={t('delete_diagnostic.delete_diagnostic_title')}
        subtitle={t('delete_diagnostic.delete_diagnostic_subtitle')}
        open={openDeleteAssessmentModal}
        status={isLoadingDeleteAssessment}
        close={handleCloseDeleteAssessmentModal}
        action={handleDeleteAssessment}
      />

      {Boolean(tableProps.search) ? (
        <>
          {isMobile ? (
            <div className={styles.MobileContainer}>
              {tableProps.isLoading || tableProps.isFetching ? (
                <Loader flexCenter size="md" />
              ) : (
                <>
                  {mobileCardsData?.length && total ? (
                    <>
                      <MobileCards data={mobileCardsData} />
                      <div className={styles.PaginationContainer}>
                        <Pagination
                          color="primary"
                          shape="rounded"
                          page={tableProps.page}
                          count={Math.ceil(total / tableProps.size)}
                          onChange={(_, value) => tableProps.onPageChange(value)}
                        />
                      </div>
                    </>
                  ) : (
                    <NothingFoundText text={t('general.no_items')} />
                  )}
                </>
              )}
            </div>
          ) : (
            <Table assessments={dataTable} columns={columns} onRowClick={onRowClick} total={total} {...tableProps} />
          )}
        </>
      ) : (
        <AccordionCustom
          accordionContent={
            isMobile ? (
              <>
                <MobileCards data={getCurrentPageItems(mobileCardsData, page, pageTableProps.size)} />
                {mobileCardsData.length ? (
                  <div className={styles.PaginationContainer}>
                    <Pagination
                      color="primary"
                      shape="rounded"
                      page={page}
                      count={Math.ceil(dataTable.length / pageTableProps.size)}
                      onChange={(_, value) => onPageChange(value)}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <Table assessments={dataTable} columns={columns} onRowClick={onRowClick} {...pageTableProps} />
            )
          }
          accordionSummaryChildren={
            <Fragment>
              <span className={styles.AccordionSummaryChildrenTitleContainer}>
                <span className={styles.AccordionSummaryChildrenTitle}>{unitTitle}</span>
                <span className={styles.AccordionSummaryChildrenCount}>({assessments.length})</span>
              </span>
              {checkPermission(unit, [PermissionsAction.UPDATE]) && (
                <ButtonStyled
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleEditUnitModal(true);
                  }}
                  fill={ButtonFill.Transparent}
                  size={isMobile ? ButtonSize.Link : ButtonSize.Small}
                  icon={<Edit />}
                >
                  {t('assessment.edit_unit')}
                </ButtonStyled>
              )}
            </Fragment>
          }
        />
      )}
    </Fragment>
  );
};

export default AssessmentListItem;
