import { Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { Loader } from 'rsuite';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import Select from 'components/Inputs/Select/Select';
import AddSingleUser from 'components/Modals/AddSingleUser/AddSingleUser';
import BulkCopyUsersModal from 'components/Modals/BulkCopyUsersModal/BulkCopyUsersModal';
import DeleteUserModal from 'components/Modals/DeleteUserModal/DeleteUserModal';
import SetRoles from 'components/Modals/SetRoles/SetRoles';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useTableControl from 'hooks/useTableControl';
import { useToggle } from 'hooks/useToggle';

import { useGetGroupHierarchyQuery } from 'store/api/groupsApi/groupsApi';
import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';
import {
  useCopyUsersBulkMutation,
  useDeactivateUsersBulkMutation,
  useDeleteUsersBulkMutation,
  useGetUsersQuery,
  useUpdateUsersBulkMutation,
} from 'store/api/usersApi/usersApi';
import { clearUsersCsv, setCSVUsers, setUserToEdit, updateCSVUsers } from 'store/features/usersSlice';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { copyObjectWithoutMethods } from 'helpers/copyObjectWithoutMethods';

import { BulkAction, ButtonFill, ButtonSize, DeleteType, SortingMode } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';
import { Accesses, CSVUser, ExtendedParsedUser, GetUsersResponse, ParsedCSVUsers } from 'types/userTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from '../GroupManage.module.scss';

type Props = {
  isUsersFromCSV: boolean;
  setIsUsersFromCSV: Dispatch<SetStateAction<boolean>>;
  setIsUsersChanged: Dispatch<SetStateAction<boolean>>;
  isUsersChanged: boolean;
  onOpenUploadCSVModal: () => void;
  isClearCSVUsersRef: MutableRefObject<boolean>;
};

const UserManage = ({
  isUsersFromCSV,
  setIsUsersFromCSV,
  onOpenUploadCSVModal,
  setIsUsersChanged,
  isUsersChanged,
  isClearCSVUsersRef,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id: groupId = '' } = useParams();
  const { page, size, sort, onPageChange, onSorting } = useTableControl('first_name');
  const usersFromCSV = useAppSelector((state) => state.users.usersFromCsv);
  const loaded = useAppSelector((state) => state.users.loaded);
  const selectedGroup = useAppSelector((state) => state.navigation.selectedGroupId);
  const user = useAppSelector((state) => state.permissions.permissions.user);

  const [isSetRolesOpen, toggleSetRolesOpen] = useToggle(false);
  const [isCreateUserModalOpen, toggleCreateUserModalOpen] = useToggle(false);
  const [isBulkCopyUsersModalOpen, toggleBulkCopyUsersModalOpen] = useToggle(false);
  const [bulkAction, changeBulkAction] = useState<BulkAction | null>(null);
  const [deleteType, selectDeleteType] = useState<DeleteType>(DeleteType.Soft);
  const [selectedCSVUser, setSelectedCSVUser] = useState<CSVUser | null>(null);
  const [selectedRole, selectRole] = useState('');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [initUsers, setInitUsers] = useState<null | GetUsersResponse>(null);

  const BULK_DATA = [
    { value: BulkAction.Role, label: t('general.set_role') },
    { value: BulkAction.Deactivate, label: t('general.deactivate_users') },
    { value: BulkAction.Delete, label: t('general.delete_users') },
    ...(loaded ? [] : [{ value: BulkAction.Copy, label: t('general.copy_users') }]),
  ];

  const {
    data: users,
    isLoading: usersLoading,
    isFetching: usersFetching,
    isSuccess: usersSuccess,
  } = useGetUsersQuery({ page, size, order_by: sort.sortBy, search: '', groupId });
  const { currentData: roles, isLoading: rolesLoading } = useGetRolesQuery({ groupId: selectedGroup });
  const { currentData: hierarchy, isLoading: groupLoading } = useGetGroupHierarchyQuery({
    search: '',
    orderBy: 'name',
    selectedGroup,
  });
  const [deleteUsersBulk] = useDeleteUsersBulkMutation();
  const [deactivateUsersBulk] = useDeactivateUsersBulkMutation();
  const [updateUsersBulk] = useUpdateUsersBulkMutation();
  const [copyUsersBulk] = useCopyUsersBulkMutation();

  useEffect(() => {
    if (usersSuccess && !initUsers) {
      setInitUsers(users);
    }

    if (initUsers && !isEqual(users, initUsers) && !isUsersChanged) {
      setIsUsersChanged(true);
    }
  }, [initUsers, usersSuccess, users, setIsUsersChanged, isUsersChanged]);

  useEffect(() => {
    if (loaded) {
      const usersCSVTransform =
        usersFromCSV?.items.map((el) => ({
          ...el,
          accesses: [
            {
              ...el.accesses[0],
              group_id: groupId,
              group_name: '',
            },
          ],
        })) || [];

      dispatch(setCSVUsers({ items: usersCSVTransform }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (usersFromCSV?.items.length === 0) setIsUsersFromCSV(false);
  }, [setIsUsersFromCSV, usersFromCSV]);

  const handleCloseSetRoles = () => {
    toggleSetRolesOpen();
    selectRole('');
  };

  const handleBulkRoleSet = () => {
    if (users) {
      updateUsersBulk({
        userIds: selectionModel as string[],
        accesses: [{ group_id: groupId, role_id: selectedRole }],
      });
    }
    setSelectionModel([]);
    handleCloseSetRoles();
  };

  const handleDeleteCSVUser = (user: CSVUser) => {
    const copiedUsers: ParsedCSVUsers = copyObjectWithoutMethods(usersFromCSV);
    copiedUsers.items = copiedUsers.items.filter((el: ExtendedParsedUser) => el.itemId !== user.itemId);
    dispatch(updateCSVUsers(copiedUsers));
  };

  const handleBulkCopy = (accesses: Accesses[]) => {
    if (selectionModel.length) {
      copyUsersBulk({
        userIds: selectionModel as string[],
        accesses,
      })
        .unwrap()
        .then(() => toast.success(t('groups.bulk_copy_success')))
        .catch(() => toast.error(t('groups.bulk_copy_error')));
      setSelectionModel([]);
    }
    toggleBulkCopyUsersModalOpen();
  };

  const handleBulkDeleteUsersCSV = () => {
    const copiedData: ParsedCSVUsers = copyObjectWithoutMethods(usersFromCSV);
    copiedData.items = copiedData.items.filter((item) => !selectionModel.includes(item.itemId));
    dispatch(updateCSVUsers(copiedData));
    if (!copiedData.items.length) {
      dispatch(clearUsersCsv());
    }
    setSelectionModel([]);
  };

  const handleBulkDeactivateUsersCSV = () => {
    const copiedUsers: ParsedCSVUsers = copyObjectWithoutMethods(usersFromCSV);
    copiedUsers.items = copiedUsers.items.map((row) =>
      selectionModel.includes(row.itemId) ? { ...row, is_active: false } : row,
    );
    dispatch(updateCSVUsers(copiedUsers));
    setSelectionModel([]);
  };

  const handleBulkSetRoleCSV = () => {
    const copiedUsers: ParsedCSVUsers = copyObjectWithoutMethods(usersFromCSV);
    copiedUsers.items = copiedUsers.items.map((row) =>
      selectionModel.includes(row.itemId)
        ? {
            ...row,
            accesses: [
              {
                ...row.accesses[0],
                role_id: selectedRole,
                role_name: roles?.items.find((role) => role.id === selectedRole)?.name || '',
              },
            ],
          }
        : row,
    );
    dispatch(updateCSVUsers(copiedUsers));
    handleCloseSetRoles();
    setSelectionModel([]);
  };

  const bulkActions: {
    [key in BulkAction]: () => void;
  } = {
    [BulkAction.Delete]: () => {
      if (isUsersFromCSV) {
        handleBulkDeleteUsersCSV();
        return;
      }

      deleteUsersBulk({ groupId, usersIds: selectionModel as string[] });
    },
    [BulkAction.Role]: () => {
      toggleSetRolesOpen();
    },
    [BulkAction.Deactivate]: () => {
      if (isUsersFromCSV) {
        handleBulkDeactivateUsersCSV();
        return;
      }

      if (selectionModel.length) {
        deactivateUsersBulk({ groupId, usersIds: selectionModel as string[] })
          .unwrap()
          .then(() => toast.success(t('groups.bulk_deactivate_success')))
          .catch(() => toast.error(t('groups.bulk_deactivate_error')));
        setSelectionModel([]);
      }
    },
    [BulkAction.Copy]: () => toggleBulkCopyUsersModalOpen(),
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'fieldId', headerName: '#', width: 90, sortable: false },
      {
        field: 'fullName',
        headerName: t('inputs.full_name'),
        sortable: true,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => `${params.row.first_name || ''} ${params.row.last_name || ''}`,
      },
      {
        field: isUsersFromCSV ? 'role_name' : BulkAction.Role,
        headerName: t('inputs.role'),
        sortable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          isUsersFromCSV ? `${params.row.accesses?.[0].role_name || ''}` : params.row.role,
      },
      {
        headerName: t('general.actions'),
        field: 'actions',
        headerAlign: isUsersFromCSV ? 'center' : 'left',
        width: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const actions: ReactNode[] = [];

          if (checkPermission(user, [PermissionsAction.UPDATE])) {
            actions.push(
              <ButtonStyled
                key="edit"
                onClick={() => {
                  if (isUsersFromCSV) {
                    isClearCSVUsersRef.current = false;
                    dispatch(setUserToEdit(params.row));
                    navigate(ROUTES.USER_ADD, { state: { isCSV: true, goBackOnCancel: true, manageGroupId: groupId } });
                    return;
                  }

                  navigate(ROUTES.USER_EDIT(params.row.id), { state: { goBackOnCancel: true } });
                }}
                fill={ButtonFill.Transparent}
                size={ButtonSize.Small}
                icon={<Edit />}
              >
                {t('general.edit')}
              </ButtonStyled>,
            );
          }

          if (checkPermission(user, [PermissionsAction.DELETE])) {
            actions.push(
              <ButtonStyled
                key="delete"
                fill={ButtonFill.TransparentRed}
                size={ButtonSize.Small}
                onClick={(e) => {
                  e.stopPropagation();
                  isUsersFromCSV ? handleDeleteCSVUser(params.row) : setSelectedCSVUser(params.row);
                }}
                icon={<Delete />}
              >
                {t('general.delete')}
              </ButtonStyled>,
            );
          }

          return actions;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, t, isUsersFromCSV, usersFromCSV],
  );

  if (usersLoading || rolesLoading || groupLoading) return <Loader center size="lg" />;

  if (!users?.items.length && !isUsersFromCSV) {
    return (
      <div className={styles.EmptyState}>
        <AddSingleUser
          open={isCreateUserModalOpen}
          group_id={groupId}
          groups={hierarchy}
          close={toggleCreateUserModalOpen}
        />

        <div className={styles.Text}>{t('groups.no_users')}</div>
        <div className={styles.Controls}>
          {checkPermission(user, [PermissionsAction.CREATE]) && (
            <>
              <ButtonStyled
                fill={ButtonFill.Outlined}
                size={ButtonSize.Small}
                onClick={onOpenUploadCSVModal}
                icon={<AddIcon />}
              >
                {t('general.add_csv')}
              </ButtonStyled>

              <ButtonStyled
                fill={ButtonFill.Contained}
                size={ButtonSize.Small}
                onClick={toggleCreateUserModalOpen}
                icon={<AddIcon />}
              >
                {t('users.add_single_user')}
              </ButtonStyled>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.UserManage}>
      <AddSingleUser
        open={isCreateUserModalOpen}
        group_id={groupId}
        groups={hierarchy}
        close={toggleCreateUserModalOpen}
      />

      <DeleteUserModal
        change={selectDeleteType}
        open={!!selectedCSVUser}
        close={() => {
          setSelectedCSVUser(null);
          selectDeleteType(DeleteType.Soft);
        }}
        selectedUserId={selectedCSVUser?.id}
        deleteType={deleteType}
      />

      <SetRoles
        open={isSetRolesOpen}
        roles={roles?.items.map((el) => ({ value: el.id, label: el.name }))}
        role={selectedRole}
        selectRole={selectRole}
        onConfirm={isUsersFromCSV ? handleBulkSetRoleCSV : handleBulkRoleSet}
        close={handleCloseSetRoles}
      />

      <BulkCopyUsersModal
        open={isBulkCopyUsersModalOpen}
        onConfirm={handleBulkCopy}
        close={toggleBulkCopyUsersModalOpen}
      />

      <div className={styles.TableHeader}>
        <div className={styles.BulkEdit}>
          <div className={styles.Select}>
            <Select
              size="sm"
              label={t('inputs.bulk_edit')}
              placeholder={t('general.select')}
              data={BULK_DATA}
              value={bulkAction?.toString()}
              onSelect={(value) => changeBulkAction(value as BulkAction)}
            />
          </div>

          <ButtonStyled
            fill={ButtonFill.Outlined}
            size={ButtonSize.Small}
            onClick={() => bulkAction && bulkActions[bulkAction]()}
          >
            {t('general.apply')}
          </ButtonStyled>
        </div>

        <div className={styles.Controls}>
          {!isUsersFromCSV && checkPermission(user, [PermissionsAction.CREATE]) && (
            <ButtonStyled
              fill={ButtonFill.Contained}
              size={ButtonSize.Small}
              onClick={toggleCreateUserModalOpen}
              icon={<AddIcon />}
            >
              {t('users.add_single_user')}
            </ButtonStyled>
          )}
        </div>
      </div>

      <DataTable
        enableSelectionOnClick
        checkboxSelection
        columns={columns}
        data={
          isUsersFromCSV
            ? {
                items: sortBy(
                  usersFromCSV.items.map((item) => ({
                    ...item,
                    errorMessages: item.errors.map((item) =>
                      item.error && item.name
                        ? t(item.error, {
                            name: item.name[0].toUpperCase() + item.name.slice(1).toLowerCase().replace('_', ' '),
                            ...(item.items?.length ? { value: item.items[0] } : {}),
                          })
                        : '',
                    ),
                  })),
                  [(item) => !item.errors.length],
                ),
                total: usersFromCSV.items.length,
              }
            : users
        }
        isLoading={usersLoading || usersFetching}
        page={page}
        sortingMode={isUsersFromCSV ? SortingMode.Client : SortingMode.Server}
        changePage={onPageChange}
        getRowId={isUsersFromCSV ? (row) => row.fieldId : undefined}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        onSortModelChange={isUsersFromCSV ? undefined : onSorting}
        selectionModel={selectionModel}
      />
    </div>
  );
};

export default UserManage;
