import { GetRolesResponse } from 'store/api/rolesApi/types';

import { IGroupHierarchyItem } from 'types/groupHierarchyTypes';

import Level from '../../Level';

type Props = { data: IGroupHierarchyItem[]; roles: GetRolesResponse | undefined; index: number };

const LevelGroups = ({ data, roles, index }: Props) => {
  return (
    <>
      {data.map((item, key) => (
        <Level key={key} data={item} roles={roles} index={index + 1} />
      ))}
    </>
  );
};

export default LevelGroups;
