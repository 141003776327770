import { IVisualisationFormMarkup } from 'types/createAssessmentTypes';

import { VisualisationItem } from '../VisualisationItem/VisualisationItem';

import styles from './VisualisationData.module.scss';

type Props = {
  markup: IVisualisationFormMarkup[];
};

export const VisualisationData = ({ markup }: Props) => {
  return (
    <>
      {markup.map((visualisation) => (
        <div key={visualisation.id} className={styles.VisualisationData}>
          <VisualisationItem type="large" visualisation={visualisation} />
          {visualisation.children?.map((visualisationChild) => (
            <VisualisationItem key={visualisationChild.id} type="medium" visualisation={visualisationChild} />
          ))}
        </div>
      ))}
    </>
  );
};
