import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import classNames from 'classnames';

import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import Text from 'components/Inputs/Text/Text';
import TextArea from 'components/Inputs/Text/components/TextArea/TextArea';
import Tabs from 'components/Tabs/Tabs';

import AssigneeLists from './components/AssigneeLists/AssigneeLists';
import FileUpload from './components/FileUpload/FileUpload';
import { QuestionMapping } from './components/QuestionMapping/QuestionMapping';
import { ResultsMapping } from './components/ResultsMapping/ResultsMapping';
import { useSteps } from './components/ResultsMapping/hooks';
import UnitList from './components/UnitList/UnitList';

import { useAppSelector } from 'hooks/redux';

import { useValidateAssessmentMappings } from '../../hooks';

import {
  CreateAssessmentPayload,
  CreateAssessmentResponse,
  UpdateAssessmentPayload,
} from 'store/api/assessmentApi/types';

import ROUTES from 'router/routes';

import { convertFlatIntoHierarchy } from 'helpers/assessments/elements';
import { assessmentSettingValidation } from 'helpers/validations';

import { AssessmentSettings } from 'types/createAssessmentTypes';
import { isServerErrorWithTranslation, isValidationServerError } from 'types/errorTypes';

import styles from './AssessmentSettingTab.module.scss';

type Props = {
  createAssessment: MutationTrigger<
    MutationDefinition<
      CreateAssessmentPayload,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      | 'Assessment'
      | 'Sections'
      | 'Completion'
      | 'AssessmentDetail'
      | 'AssessmentHierarchy'
      | 'Units'
      | 'CompletionLatest',
      CreateAssessmentResponse,
      'assessmentsApi'
    >
  >;
  updateAssessment: MutationTrigger<
    MutationDefinition<
      UpdateAssessmentPayload,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      | 'Assessment'
      | 'Sections'
      | 'Completion'
      | 'AssessmentDetail'
      | 'AssessmentHierarchy'
      | 'Units'
      | 'CompletionLatest',
      CreateAssessmentResponse,
      'assessmentsApi'
    >
  >;
  isRendered: boolean;
  cloneAssessmentId: string;
};

enum Tab {
  AssignTo,
  UnitSet,
  ResultsMapping,
  QuestionMapping,
}

const AssessmentSettingTab = ({ isRendered, createAssessment, updateAssessment, cloneAssessmentId }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const boardElements = useAppSelector((state) => state.createAssessment.boardElements);
  const assessmentSettings = useAppSelector((state) => state.createAssessment.assessmentSettings);
  const mappings = useAppSelector((state) => state.createAssessment.mappings);
  const questionsMappings = useAppSelector((state) => state.createAssessment.questionMappings);
  const isResultsMappingsVerified = useAppSelector((state) => state.createAssessment.isResultsMappingsVerified);
  const isAdminGroup = useAppSelector((state) => state.navigation.isAdminGroup);

  const [mappingsFormData, setMappingsFormData] = useState({});
  const [isMappingsCreated, setIsMappingsCreated] = useState(!id);
  const mappingsSteps = useSteps();
  const [activeTab, setActiveTab] = useState(Tab.AssignTo);

  const { isQuestionsMappingsUnique } = useValidateAssessmentMappings();

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    getFieldState,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,
    control,
  } = useForm<AssessmentSettings>({
    mode: 'onChange',
    resolver: yupResolver(assessmentSettingValidation(t)),
    defaultValues: {
      assessment_name: '',
      assessment_description: '',
      assessment_image: '',
      is_default: false,
      show_context: false,
      unit_ids: [],
      user_ids: [],
      group_ids: [],
    },
  });

  useEffect(() => {
    if ((id || cloneAssessmentId) && assessmentSettings) {
      reset({ ...assessmentSettings });
    }
  }, [assessmentSettings, id, cloneAssessmentId, reset]);

  const clearErrorTimeout = (field: keyof AssessmentSettings) => {
    setTimeout(() => {
      clearErrors(field);
    }, 2000);
  };

  const onSubmit = async (data: AssessmentSettings) => {
    if (!data.is_default && !getValues('user_ids')?.length && !getValues('group_ids')?.length) {
      setError('user_ids', {
        type: 'empty assignee',
        message: t('assessment.validation.assignee_required'),
      });
      setActiveTab(Tab.AssignTo);
      clearErrorTimeout('user_ids');
      return;
    }

    if (!getValues('unit_ids')?.length) {
      setError('unit_ids', {
        type: 'empty assignee',
        message: t('assessment.validation.unit_required'),
      });
      setActiveTab(Tab.UnitSet);
      clearErrorTimeout('unit_ids');
      return;
    }

    if (!isResultsMappingsVerified) {
      toast.error(t('assessment.validation.result_mappings_all_mapped'));
      setActiveTab(Tab.ResultsMapping);
      return;
    }

    if (!isQuestionsMappingsUnique()) {
      toast.error(t('assessment.validation.questions_graphs_unique'));
      setActiveTab(Tab.QuestionMapping);
      return;
    }

    const payload: CreateAssessmentPayload = {
      ...data,
      mappings: Object.entries(mappings).map(([visualisationId, sectionsIds]) => ({
        visualisation_id: visualisationId,
        section_definition_ids: sectionsIds,
      })),
      responsibility_mappings: Object.entries(questionsMappings).map(([questionId, mappingData]) => ({
        visualisation_id: mappingData?.visualisationId,
        question_id: questionId,
      })),
      children: convertFlatIntoHierarchy(boardElements),
      type: 'assessment',
      is_global: isAdminGroup,
    };

    try {
      if (id) {
        await updateAssessment({ id, payload }).unwrap();
        await navigate(ROUTES.DIAGNOSTIC_LIST);
        toast.success<string>(t('assessment.success_updated'));
      } else {
        await createAssessment(payload).unwrap();
        await navigate(ROUTES.DIAGNOSTIC_LIST);
        toast.success<string>(t('assessment.success_created'));
      }
    } catch (error) {
      if (isValidationServerError(error)) {
        toast.error(error.data.detail[0].msg);
        return;
      }

      if (isServerErrorWithTranslation(error) && error.data.detail.error === 'server.already_exists') {
        toast.error(
          t('assessment.validation.default_already_exists', {
            name: error.data.detail.items.title,
          }),
        );
        return;
      }

      toast.error(t('general.something_went_wrong'));
    }
  };

  return (
    <>
      {isRendered && (
        <form onSubmit={handleSubmit(onSubmit)} id={'hook-form'} className={styles.SettingsContainer}>
          <div className={styles.SidebarContainer}>
            <FileUpload
              preview={watch('assessment_image')}
              setValue={setValue}
              error={getFieldState('assessment_image').error?.message as string}
            />

            <div>
              <Box mb={3}>
                <Stack>
                  <Controller
                    control={control}
                    name="is_default"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={() => onChange(!value)}>
                        <span className={classNames(styles.Checkbox, { [styles.Active]: value })}>
                          {t('assessment.default')}
                        </span>
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="show_context"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={() => onChange(!value)}>
                        <span className={classNames(styles.Checkbox, { [styles.Active]: value })}>
                          {t('assessment.enable_context')}
                        </span>
                      </Checkbox>
                    )}
                  />
                </Stack>
              </Box>

              <Text
                label={t('assessment.diagnostic_name')}
                placeholder={t('assessment.diagnostic_name')}
                register={register('assessment_name')}
                error={getFieldState('assessment_name', formState)}
                error_message={formState.errors?.assessment_name?.message}
              />

              <TextArea
                label={t('assessment.diagnostic_description')}
                placeholder={t('assessment.diagnostic_description')}
                register={register('assessment_description')}
                rows={5}
                error_message={formState.errors?.assessment_description?.message}
                error={getFieldState('assessment_description', formState)}
              />
            </div>
          </div>

          <div className={styles.MainContainer}>
            <Tabs
              tabs={[
                { title: t('assessment.assign_to') },
                { title: t('assessment.unit_set') },
                { title: t('assessment.results_mapping') },
                { title: t('assessment.questions_mapping') },
              ]}
              activeTab={activeTab}
              changeTab={setActiveTab}
            />

            {activeTab === Tab.AssignTo && (
              <AssigneeLists
                userList={watch('user_ids')}
                groupList={watch('group_ids')}
                setValue={setValue}
                error={formState.errors.user_ids?.message as string}
              />
            )}

            {activeTab === Tab.UnitSet && (
              <UnitList
                unitList={watch('unit_ids')}
                error={getFieldState('unit_ids').error?.message as string}
                setValue={setValue}
              />
            )}

            {activeTab === Tab.ResultsMapping && (
              <ResultsMapping
                mappingsFormData={mappingsFormData}
                setMappingsFormData={setMappingsFormData}
                isMappingsCreated={isMappingsCreated}
                setIsMappingsCreated={setIsMappingsCreated}
                mappingsSteps={mappingsSteps}
              />
            )}

            {activeTab === Tab.QuestionMapping && <QuestionMapping />}
          </div>
        </form>
      )}
    </>
  );
};

export default AssessmentSettingTab;
