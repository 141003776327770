import { ChangeEvent } from 'react';

import classNames from 'classnames';
import { Avatar, Checkbox } from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';

import { Size } from 'types/enums';
import { IGroupsHierarchyUser } from 'types/groupHierarchyTypes';

import styles from './LevelUsers.module.scss';

type data = {
  data: IGroupsHierarchyUser;
  list: { groups: string[]; users: string[] };
  onSelectEntity: (value: string, entity: string) => void;
  size: Size;
};

const LevelUsers = ({ data, list, onSelectEntity, size }: data) => {
  const itemChecked = list.users.includes(data.id);

  const onSelect = (value: ValueType = '', checked: boolean, event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSelectEntity(value.toString(), 'user');
  };

  return (
    <li>
      <div className={classNames(styles.Element, { [styles.Checked]: itemChecked }, size && styles[size])}>
        <div className="GlobalCheckbox">
          <Checkbox checked={itemChecked} value={data.id} onChange={onSelect} />
        </div>

        <div className={styles.Avatar}>
          <Avatar size="xs" circle src={data.photo || '/broken-image.jpg'} alt={data.first_name[0]} />
        </div>

        <div className={styles.Name}>
          <span>
            {data.first_name} {data.last_name}
          </span>
        </div>
      </div>
    </li>
  );
};

export default LevelUsers;
