import { NormalizedGroupsHierarchy } from 'types/groupHierarchyTypes';
import { IGroupNavigationData } from 'types/navigationTypes';

export const mapToGroupNavigationData = (item: NormalizedGroupsHierarchy): IGroupNavigationData => {
  return {
    value: item.id,
    label: item.name,
    type: item.type,
    children: item.children,
  };
};
