import { Fragment } from 'react';
import { useDrag } from 'react-dnd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useCardManipulate } from '../../hooks';

import { setDraggedElements } from 'store/features/createAssessmentSlice';

import { findChildElements, findSiblingElements } from 'helpers/assessments/elements';

import { cardTypesAcceptedByModule, cardTypesAcceptedBySection } from '../../constants';

import { Element, ModuleBoardElement, SectionBoardElement } from 'types/boardElements';
import { CARD_TYPES, CalculationTypeChange, ContainerType } from 'types/createAssessmentTypes';
import { TitleFieldsChange } from 'types/createAssessmentTypes';

import Accordion from '../Accordion/Accordion';
import WeightBlock from '../BoardElements/WeightBlock/WeightBlock';
import DropZone from '../DropZone/DropZone';

type Props = {
  element: SectionBoardElement | ModuleBoardElement;
  onElementDelete: () => void;
  onTitleFieldsChange: TitleFieldsChange;
  onCalculationTypeChange: CalculationTypeChange;
  type: ContainerType;
};

const Section = ({ element, type, onTitleFieldsChange, onCalculationTypeChange, onElementDelete }: Props) => {
  const dispatch = useAppDispatch();
  const { renderCards } = useCardManipulate();

  const boardElements = useAppSelector((state) => state.createAssessment.boardElements);
  const draggedElements = useAppSelector((state) => state.createAssessment.draggedElements);

  const childElements: Element[] = findSiblingElements(boardElements, element.id) || [];

  const dropZoneAccept = {
    [CARD_TYPES.section]: cardTypesAcceptedBySection,
    [CARD_TYPES.module]: cardTypesAcceptedByModule,
  }[type];

  const [{ opacity }, drag] = useDrag(
    () => ({
      type,

      item: () => {
        dispatch(setDraggedElements([element.id, ...findChildElements(boardElements, element.id).map(({ id }) => id)]));

        return element;
      },

      end: () => {
        dispatch(setDraggedElements([]));
      },

      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.2 : 1,
      }),
    }),
    [element, boardElements],
  );

  const isDropZoneEnabled = !draggedElements.includes(element.id);

  return (
    <Accordion dragRef={drag} container={element} onDelete={onElementDelete} opacity={opacity}>
      <WeightBlock
        element={element}
        calculationTypeChange={onCalculationTypeChange}
        onWeightChange={onTitleFieldsChange}
        calculationType={element.calculation_type}
      />

      {childElements.map((childElement, index) => (
        <Fragment key={childElement.id}>
          {isDropZoneEnabled && <DropZone parentNode={element} dropIndex={index} accept={dropZoneAccept} />}

          {renderCards(childElement)}
        </Fragment>
      ))}

      {isDropZoneEnabled && (
        <DropZone parentNode={element} dropIndex={childElements.length || 0} accept={dropZoneAccept} />
      )}
    </Accordion>
  );
};

export default Section;
