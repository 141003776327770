import {
  CreateUnitPayload,
  CreateUnitResponse,
  DeleteUnitPayload,
  DeleteUnitResponse,
  GetUnitPayload,
  GetUnitResponse,
  GetUnitsPayload,
  GetUnitsResponse,
  UpdateUnitPayload,
  UpdateUnitResponse,
} from './types';

import { assessmentApi } from '../assessmentApi/assessmentApi';

export const unitsApi = assessmentApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query<GetUnitsResponse, GetUnitsPayload>({
      query: (payload) => ({
        url: '/units/',
        params: payload,
      }),
      providesTags: ['Units'],
    }),

    getUnit: builder.query<GetUnitResponse, GetUnitPayload>({
      query: (id) => `/units/${id}/`,
      providesTags: ['Units'],
    }),

    createUnit: builder.mutation<CreateUnitResponse, CreateUnitPayload>({
      query: (title) => ({
        url: '/units/',
        method: 'POST',
        body: {
          title,
        },
      }),
      invalidatesTags: ['Units'],
    }),

    updateUnit: builder.mutation<UpdateUnitResponse, UpdateUnitPayload>({
      query: ({ id, title }) => ({
        url: `/units/${id}/`,
        method: 'PUT',
        body: {
          title,
        },
      }),
      invalidatesTags: ['Assessment', 'Completion', 'Units'],
    }),

    deleteUnit: builder.mutation<DeleteUnitResponse, DeleteUnitPayload>({
      query: (id) => ({
        url: `/units/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Assessment', 'Completion', 'Units'],
    }),
  }),
});

export const { useGetUnitsQuery, useGetUnitQuery } = unitsApi;

export const { useCreateUnitMutation, useUpdateUnitMutation, useDeleteUnitMutation } = unitsApi;
