import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { Tab, TabsProps } from 'types/tabsTypes';

import styles from './Tabs.module.scss';

const Tabs = ({
  children,
  tabs,
  activeTab,
  changeTab,
  tabStyles,
  underline,
  fullWidth,
  spaceBetween,
  spaceBetweenChildren,
  center,
  fontLarge,
}: TabsProps) => {
  const navigate = useNavigate();

  const handleClick = async (e: MouseEvent<HTMLButtonElement>, item: Tab, index: number) => {
    if (item.route) {
      navigate(item.route);
    }

    if (item?.shouldChangeTab) {
      if (await item?.shouldChangeTab()) {
        changeTab(index);
      }
      return;
    }

    changeTab(index);
  };

  return (
    <div
      className={classNames(styles.TabsContainer, {
        [styles.Underline]: underline,
        [styles.FullWidth]: fullWidth,
        [styles.SpaceBetween]: spaceBetween,
        [styles.SpaceBetweenChildren]: spaceBetweenChildren,
        [styles.Center]: center,
        [styles.FontLarge]: fontLarge,
      })}
    >
      <div className={styles.Tabs}>
        {tabs?.map((item, index) => (
          <button
            key={index}
            type="button"
            style={tabStyles}
            className={classNames(styles.Tab, {
              [styles.Active]: activeTab === index,
            })}
            onClick={(e) => handleClick(e, item, index)}
          >
            {item.title}
          </button>
        ))}
      </div>
      {children && <div className={styles.Controls}>{children}</div>}
    </div>
  );
};

export default Tabs;
