export const AscendingSortIcon = () => {
  return (
    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.640134 3.56251L2.62332 1.39893L2.62332 8.59091C2.62332 8.81684 2.79121 9 2.9983 9C3.2054 9 3.37328 8.81684 3.37328 8.59091L3.37328 1.39524L5.35987 3.56252C5.50631 3.72228 5.74373 3.72228 5.89017 3.56252C6.03661 3.40276 6.03661 3.14374 5.89017 2.98398L3.27274 0.128466L3.26525 0.120067C3.23691 0.0891427 3.20515 0.0642026 3.17131 0.0452504C3.11758 0.0150645 3.05856 -1.51475e-05 2.99955 3.57406e-06L2.9983 4.52839e-07C2.88294 4.42753e-07 2.77974 0.0568375 2.71095 0.146243L0.109828 2.98397C-0.0366107 3.14373 -0.0366108 3.40275 0.10983 3.56251C0.256268 3.72227 0.493694 3.72227 0.640134 3.56251Z"
        fill="#A6B4C1"
      />
    </svg>
  );
};
