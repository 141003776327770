import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import GroupedAvatars from 'components/GroupedAvatars/GroupedAvatars';
import Loader from 'components/Loader/Loader';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import Scrollable from 'components/Scrollable/Scrollable';

import { useAppSelector } from 'hooks/redux';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { SelectorItem, SelectorVariant } from 'types/dashboardTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import styles from './Selector.module.scss';

type SelectorProps = {
  items: SelectorItem[];
  selectedValue?: string;
  isLoading?: boolean;
  variant?: SelectorVariant;
  onChange: (value: string) => void;
};

const Selector = ({ items, selectedValue, onChange, isLoading, variant = SelectorVariant.User }: SelectorProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const { other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);
  const [actionsWidth, setActionsWidth] = useState<string>('auto');

  const isGroupVariant = variant === SelectorVariant.Group;
  const isUserVariant = variant === SelectorVariant.User;

  const isViewResults =
    isGroupVariant || (isUserVariant && checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ]));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  const actionsRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setActionsWidth(node.offsetWidth + 'px');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const renderContent = isLoading ? (
    <Loader flexCenter size="md" />
  ) : items.length ? (
    <FormControl className={styles.Form}>
      <RadioGroup name="radio-buttons-group" value={selectedValue} onChange={handleChange}>
        {items.map((item) => (
          <FormControlLabel
            sx={FormControlLabelSx}
            key={item.value}
            value={item.value}
            disabled={!isViewResults}
            control={<Radio className={styles.Radio} />}
            label={
              <Grid container wrap="nowrap" direction="row" alignItems="center" rowSpacing={0} columnSpacing={2}>
                <Grid item xs zeroMinWidth>
                  <Grid container wrap="nowrap" direction="row" alignItems="center" rowSpacing={0} columnSpacing={2}>
                    {isUserVariant && (
                      <Grid item xs="auto">
                        <Avatar src={item.img}>{item.title}</Avatar>
                      </Grid>
                    )}
                    <Grid item xs className={styles.TitleContainer}>
                      <span className={styles.Title}>{item.title}</span>
                    </Grid>
                  </Grid>
                </Grid>

                {isGroupVariant && (
                  <Grid item xs>
                    {item.avatars && item.avatars.length && (
                      <GroupedAvatars isChecked={item.value === selectedValue} avatars={item.avatars} />
                    )}
                  </Grid>
                )}

                {item.actions && (
                  <Grid item xs="auto">
                    <Grid
                      ref={actionsRef}
                      container
                      direction="row"
                      alignItems="center"
                      rowSpacing={0}
                      columnSpacing={0.5}
                    >
                      {item.actions.map((action, index) => (
                        <Grid item xs="auto" key={index}>
                          {action}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            }
            className={classNames(styles.Label, { [styles.Checked]: item.value === selectedValue })}
            slotProps={{ typography: { className: styles.Typography } }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  ) : (
    <NothingFoundText text={t('assessment.nothing_found')} center />
  );

  return (
    <div className={styles.Container}>
      {Boolean(items.length && !isLoading) && (
        <Grid
          container
          direction="row"
          alignItems="center"
          rowSpacing={0}
          columnSpacing={2}
          className={styles.CardContentHeader}
        >
          {isGroupVariant && (
            <Grid item xs>
              <span>{t('dashboard.group_name')}</span>
            </Grid>
          )}
          <Grid item xs>
            <span>{isGroupVariant ? t('general.users') : t('general.user')}</span>
          </Grid>
          <Grid item xs="auto">
            <span style={{ display: 'block', width: actionsWidth }}>{t('general.actions')}</span>
          </Grid>
        </Grid>
      )}

      {isMobile ? (
        <div className={styles.MobileContentContainer}>{renderContent}</div>
      ) : (
        <Scrollable paddingX={16} maxHeight={265}>
          {renderContent}
        </Scrollable>
      )}
    </div>
  );
};

const FormControlLabelSx = { maxWidth: '100%' };

export default Selector;
