import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { SortTypes } from 'types/enums';

import { ReactComponent as Arrow } from 'assets/images/arrow-sort.svg';

import styles from './SortButton.module.scss';

type Props = {
  sortType: string;
  onSort: () => void;
};

const SortButton = ({ sortType, onSort }: Props) => {
  const { t } = useTranslation();

  return (
    <button className={styles.SortButton} onClick={onSort}>
      <Arrow
        className={classNames(styles.Arrow, {
          [styles.Sort]: sortType === SortTypes.DESC,
        })}
      />
      {`${t('general.sort_by')} ${sortType === SortTypes.DESC ? 'Z-A' : 'A-Z'}`}
    </button>
  );
};

export default SortButton;
