import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Loader from 'components/Loader/Loader';

import { useComparisonInfo } from 'hooks/useComparisonInfo';

import { DashboardSearchParams, DashboardType } from 'types/dashboardTypes';
import { ButtonFill, ButtonSize } from 'types/enums';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';

import ComparisonInfo from '../ComparisonInfo/ComparisonInfo';

type Props = {
  isHideButton?: boolean;
  dashboardType: DashboardType;
};

const ComparisonHeaderInfo = ({ isHideButton, dashboardType }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const groupIdLeft = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const groupIdRight = searchParams.get(DashboardSearchParams.GROUP_2) || '';

  const userIdLeft = searchParams.get(DashboardSearchParams.USER_1) || '';
  const userIdRight = searchParams.get(DashboardSearchParams.USER_2) || '';

  const completionIdLeft = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
  const completionIdRight = searchParams.get(DashboardSearchParams.COMPLETION_2) || '';

  const {
    isLoading,
    groupLeft,
    groupRight,
    isSameGroup,
    userLeft,
    userRight,
    isSameUser,
    completionLeft,
    completionRight,
    isSameAssessment,
  } = useComparisonInfo({
    dashboardType,
    groupIdLeft,
    groupIdRight,
    userIdLeft,
    userIdRight,
    completionIdLeft,
    completionIdRight,
  });

  const handleClear = () => {
    searchParams.delete(DashboardSearchParams.GROUP_2);
    searchParams.delete(DashboardSearchParams.COMPLETION_2);
    searchParams.delete(DashboardSearchParams.USER_1);
    searchParams.delete(DashboardSearchParams.USER_2);
    searchParams.delete(DashboardSearchParams.ASSESSMENT_1);
    searchParams.delete(DashboardSearchParams.ASSESSMENT_2);
    searchParams.delete(DashboardSearchParams.DATE_FROM);
    searchParams.delete(DashboardSearchParams.DATE_TO);
    setSearchParams(searchParams);
  };

  if (!completionLeft) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1} minWidth={0}>
      <ComparisonInfo
        dashboardType={dashboardType}
        groupLeft={groupLeft}
        groupRight={groupRight}
        isSameGroup={isSameGroup}
        userLeft={userLeft}
        userRight={userRight}
        isSameUser={isSameUser}
        completionLeft={completionLeft}
        completionRight={completionRight}
        isSameAssessment={isSameAssessment}
      />

      {!isHideButton && (
        <ButtonStyled fill={ButtonFill.TransparentRed} size={ButtonSize.Small} onClick={handleClear} icon={<Delete />}>
          {t('general.clear')}
        </ButtonStyled>
      )}
    </Stack>
  );
};

export default ComparisonHeaderInfo;
