import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import classNames from 'classnames';
import { Slider } from 'rsuite';

import { QuestionSliderBoardElement } from 'types/boardElements';
import { OnElementDelete, TitleFieldsChange } from 'types/createAssessmentTypes';

import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';

import styles from '../BoardElements.module.scss';

type Props = {
  element: QuestionSliderBoardElement;
  onElementDelete: OnElementDelete;
  onTitleFieldsChange: TitleFieldsChange;
};
const QuestionSlider = ({ onElementDelete, onTitleFieldsChange, element }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.CardContainer, styles.ShortTextEntry)}>
      <div className={styles.InnerBody}>
        <CardTitleBlock onDelete={onElementDelete} element={element} onTitleChange={onTitleFieldsChange} />

        <div className={classNames(styles.QuestionSliderInput)}>
          <input disabled className={styles.SliderInput} type="number" />
        </div>

        <div className={styles.FromToRow}>
          <NumericFormat
            placeholder={t('general.from')}
            value={element.min_value}
            className={classNames(styles.Input, {
              [styles.BorderError]:
                element.min_value < 0 || element.min_value === undefined || element.min_value >= element.max_value,
            })}
            onValueChange={({ floatValue }) => {
              onTitleFieldsChange(element.id, 'min_value', floatValue ?? 0);
            }}
          />

          <div className={styles.Separator} />

          <NumericFormat
            placeholder={t('general.to')}
            value={element.max_value}
            className={classNames(styles.Input, {
              [styles.BorderError]: element.max_value === undefined || element.max_value <= element.min_value,
            })}
            onValueChange={({ floatValue }) => {
              onTitleFieldsChange(element.id, 'max_value', floatValue ?? 0);
            }}
          />
        </div>

        <div className={styles.SliderWrapper}>
          <Slider
            progress
            disabled
            tooltip={false}
            barClassName={styles.SliderBar}
            handleClassName={styles.HandleClass}
          />
        </div>
      </div>

      <BottomCardNavPanel
        weight={element.weight}
        onChange={(weight) => onTitleFieldsChange(element.id, 'weight', weight)}
      />
    </div>
  );
};

export default QuestionSlider;
