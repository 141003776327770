import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import ModalWrapper from 'components/Modals/ModalWrapper';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';

import MobileCards from './components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import { useDeleteRoleMutation, useGetRolesQuery } from 'store/api/rolesApi/rolesApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { getFieldId } from 'helpers/table';

import { MIN_SEARCH_LENGTH, MOBILE_BREAKPOINT } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';
import { MobileCardType, MobileCardsData } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from './RoleList.module.scss';

type Row = {
  id: string;
};

const RoleList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const role = useAppSelector((state) => state.permissions.permissions.role);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const { page, size, sort, search, onPageChange, onSearching, onSorting } = useTableControl('name');

  const debouncedSearch = useDebounce(search, 500);

  const [open, setOpen] = useState(false);
  const [selectedRole, selectRole] = useState('');

  const { data, isLoading, isFetching } = useGetRolesQuery({
    page,
    size,
    order_by: sort.sortBy,
    search: debouncedSearch.length < MIN_SEARCH_LENGTH ? '' : debouncedSearch,
    groupId: selectedGroupId,
  });
  const [deleteRole, { isLoading: deletingRole }] = useDeleteRoleMutation();

  const handleOpen = (id: string) => {
    setOpen(true);
    selectRole(id);
  };

  const handleClose = () => {
    setOpen(false);
    selectRole('');
  };

  const onDeleteRole = () => {
    deleteRole(selectedRole)
      .unwrap()
      .then((resp) => handleClose())
      .catch((err) => toast.error('Wrong'));
  };

  const getActions = useCallback(
    (id: string) => {
      const actions: ReactNode[] = [];

      if (checkPermission(role, [PermissionsAction.UPDATE])) {
        actions.push(
          <ButtonStyled
            key="edit"
            onClick={() => navigate(ROUTES.ROLE_EDIT(id))}
            fill={ButtonFill.Transparent}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            icon={<Edit />}
          >
            {t('general.edit')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(role, [PermissionsAction.DELETE])) {
        actions.push(
          <ButtonStyled
            key="delete"
            fill={ButtonFill.TransparentRed}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            onClick={() => handleOpen(id)}
            icon={<Delete />}
          >
            {t('general.delete')}
          </ButtonStyled>,
        );
      }

      return actions;
    },
    [isMobile, navigate, role, t],
  );

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      { field: 'fieldId', headerName: '#', sortable: false, width: 30 },
      {
        field: 'name',
        headerName: t('roles.table.name'),
        width: 150,
        sortable: false,
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('general.description'),
        width: 150,
        sortable: false,
        flex: 1,
        valueFormatter: (data) => data.value || ' - ',
      },
      {
        field: 'creator',
        headerName: t('roles.table.creator'),
        valueFormatter: (data) => (data.value ? `${data.value.first_name} ${data.value.last_name}` : ' - '),
        width: 150,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: t('general.actions'),
        headerAlign: 'left',
        width: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, Row>) => getActions(params.row.id),
      },
    ],

    [t, getActions],
  );

  const mobileCardsData = useMemo<MobileCardsData>(
    () =>
      isMobile && data?.items
        ? data.items.map((item, index) => ({
            row: [
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('roles.table.name'),
                    value: item.name,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Ordinal,
                    label: '#',
                    value: getFieldId({ size: size, page, index: index, total: data?.items.length }),
                    key: item.id + 2,
                  },
                ],
                key: item.id + 1,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('roles.table.creator'),
                    value: `${item.creator?.first_name || ''} ${item.creator?.last_name || ''}`.trim(),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 2,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('general.description'),
                    value: item.description || '',
                    key: item.id + 1,
                  },
                ],
                key: item.id + 3,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Actions,
                    actions: getActions(item.id),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 4,
              },
            ],
            key: item.id,
          }))
        : [],
    [data?.items, getActions, isMobile, page, size, t],
  );

  const getPageTitleAction = () => {
    const actions = [];

    if (!isMobile && checkPermission(role, [PermissionsAction.UPDATE])) {
      actions.push(
        <ButtonStyled
          key="edit"
          size={ButtonSize.Small}
          fill={ButtonFill.Outlined}
          onClick={() => navigate(ROUTES.ROLE_PERMISSIONS)}
        >
          {t('roles.edit_permissions')}
        </ButtonStyled>,
      );
    }

    if (checkPermission(role, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="add_role"
          size={ButtonSize.Small}
          fill={ButtonFill.Contained}
          onClick={() => navigate(ROUTES.ROLE_ADD)}
          icon={<AddIcon />}
        >
          {t('roles.add_role')}
        </ButtonStyled>,
      );
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  useDocumentTitle([t('page_titles.role_list')]);

  return (
    <div className={styles.RolePage}>
      <ModalWrapper
        open={open}
        close={handleClose}
        action={onDeleteRole}
        status={deletingRole}
        title={t('roles.modal.title')}
        subtitle={t('roles.modal.subtitle')}
      />

      <PageTitle
        controls
        title={t('roles.role_list')}
        searchPlaceholder={t('roles.search_placeholder')}
        search={search}
        sort={sort.sortType}
        onSort={onSorting}
        onSearch={onSearching}
      >
        {pageTitleActions.length ? pageTitleActions : null}
      </PageTitle>

      {!isMobile ? (
        <DataTable
          data={data}
          columns={columns}
          isLoading={isLoading || isFetching}
          page={page}
          changePage={onPageChange}
          emptyResultText={t('roles.table.empty_result')}
        />
      ) : (
        <div className={styles.MobileContainer}>
          {isLoading || isFetching ? (
            <Loader flexCenter size="md" />
          ) : (
            <>
              {mobileCardsData?.length ? (
                <MobileCards data={mobileCardsData} />
              ) : (
                <NothingFoundText text={t('roles.table.empty_result')} />
              )}
            </>
          )}
          {data?.items.length ? (
            <div className={styles.PaginationContainer}>
              <Pagination
                color="primary"
                shape="rounded"
                page={page}
                count={Math.ceil((data?.total || 0) / (data?.size || 0))}
                onChange={(_, value) => onPageChange(value)}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default RoleList;
