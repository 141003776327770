import { MouseEvent, useEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';

import { Tag } from 'components/Tag/Tag';

import { ISelectOption } from 'types/createAssessmentTypes';

import styles from './TagValue.module.scss';

const GAP_WIDTH = 4;
const LABEL_WIDTH = 50;

type Props = {
  checkedItems: ISelectOption[];
  onTagRemove: (event: MouseEvent, item: ISelectOption) => void;
};

export const TagValue = ({ checkedItems, onTagRemove }: Props) => {
  const calculationHelperRef = useRef<HTMLDivElement>(null);
  const tagsContainerRef = useRef<HTMLDivElement>(null);

  const [renderedItemsCount, setRenderedItemsCount] = useState(0);

  const wrapperRect = calculationHelperRef.current?.getBoundingClientRect();
  const tagsContainerRect = tagsContainerRef.current?.getBoundingClientRect();

  useEffect(() => {
    const tags = tagsContainerRef?.current?.children || [];
    const tagsWidth = tagsContainerRect?.width || 0;
    const availableWidth = wrapperRect?.width || 0;

    if (!tags.length || !tagsWidth) return;

    let renderedItemsWidth = tags[0].getBoundingClientRect().width + GAP_WIDTH;
    let renderedItemsCount = 1;

    if (tags.length > 1 && tagsWidth > availableWidth) {
      for (let i = 1; i < tags.length; i++) {
        renderedItemsWidth += tags[i].getBoundingClientRect().width + GAP_WIDTH;

        if (renderedItemsWidth + LABEL_WIDTH > availableWidth) {
          break;
        }

        renderedItemsCount += 1;
      }
    } else {
      renderedItemsCount = tags.length;
    }

    setRenderedItemsCount(renderedItemsCount);
  }, [wrapperRect?.width, tagsContainerRect?.width, checkedItems]);

  return (
    <div className={styles.Container}>
      <div>
        <div className={styles.TagsContainer}>
          <Stack direction="row" gap={0.5}>
            {[...checkedItems].splice(0, renderedItemsCount).map((item) => (
              <Tag
                key={item.value}
                closable
                className="custom-tag"
                onClose={(event: MouseEvent) => onTagRemove(event, item)}
              >
                {item.label}
              </Tag>
            ))}
          </Stack>
        </div>

        {renderedItemsCount < checkedItems.length && (
          <span className={styles.OverflowLabel}>+{checkedItems.length - renderedItemsCount} more</span>
        )}
      </div>

      {/* This block duplicates the "real" tags rendering exclusively for proper calculation */}
      <div className={styles.CalculationHelper} ref={calculationHelperRef}>
        <div className={styles.TagsContainer}>
          <Stack direction="row" gap={0.5} ref={tagsContainerRef}>
            {checkedItems.map((item) => (
              <Tag key={item.value} closable className="custom-tag">
                {item.label}
              </Tag>
            ))}
          </Stack>
        </div>
      </div>
    </div>
  );
};
