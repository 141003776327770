import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/Inputs/Text/Text';

import { MAX_SHORT_TEXT_ENTRY_LENGTH } from 'constants/';

import { ShortTextEntryTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

type Props = {
  element: ShortTextEntryTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  onChangeAnswer: (value: string) => void;
};

const ShortTextEntry = ({ element, loading, onNext, onPrev, onChangeAnswer }: Props) => {
  const { i18n, t } = useTranslation();
  const [showError, setShowError] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > MAX_SHORT_TEXT_ENTRY_LENGTH) {
      setShowError(true);
      return false;
    }

    setShowError(false);
    onChangeAnswer(e.target.value);
  };

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{
        loading,
        onNext,
        onPrev,
        disabled: !element.answer || !element.answer.length,
      }}
    >
      <Text
        required
        value={element.answer || ''}
        onChange={handleChange}
        error_message={showError ? t('validation.short_text_max_input') : undefined}
      />
    </ElementWrapper>
  );
};

export default ShortTextEntry;
