import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from 'rsuite';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Select from 'components/Inputs/Select/Select';
import TextInput from 'components/Inputs/Text/Text';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Title from 'components/Title/Title';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import {
  useCreateGroupMutation,
  useGetGroupByIdQuery,
  useGetGroupHierarchyQuery,
  useUpdateGroupMutation,
} from 'store/api/groupsApi/groupsApi';
import { setSecondGroupId } from 'store/features/navigationSlice';

import ROUTES from 'router/routes';

import { handleNoPermissionError } from 'helpers/handleNoPermissionError';
import { getErrorEntity } from 'helpers/i18n';
import { createGroupForm, editGroupForm } from 'helpers/validations';

import { ButtonFill } from 'types/enums';
import { IServerErrorWithTranslation } from 'types/errorTypes';
import { IGroupHierarchyItem } from 'types/groupHierarchyTypes';

import styles from './GroupAdd.module.scss';

type FormValues = {
  parent_id: string;
  name: string;
  description: string;
  user_limit: number;
};

const GroupAdd = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedGroup = useAppSelector((state) => state.navigation.selectedGroupId);
  const [searchParams] = useSearchParams();
  const groupSearchParam = searchParams.get('group');

  const { data, isLoading } = useGetGroupHierarchyQuery({ search: '', orderBy: 'name', selectedGroup });

  const {
    data: groupData,
    isLoading: groupDataLoading,
    isSuccess,
    isError,
  } = useGetGroupByIdQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  const [createGroup, { isLoading: creatingGroup }] = useCreateGroupMutation();
  const [updateGroup, { isLoading: updatingGroup }] = useUpdateGroupMutation();

  const { register, control, handleSubmit, reset, formState, setValue, getFieldState, setError } = useForm<FormValues>({
    resolver: yupResolver(id ? editGroupForm : createGroupForm),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isError) navigate(ROUTES.GROUP_LIST);
  }, [isError, navigate]);

  useEffect(() => {
    if (isSuccess && typeof groupData === 'object') reset({ ...groupData } as FormValues);
  }, [groupData, isSuccess, reset]);

  useEffect(() => {
    if (groupSearchParam) setValue('parent_id', groupSearchParam);
  }, [groupSearchParam, setValue]);

  const transformData = (data: IGroupHierarchyItem) => {
    const obj: { value: string; label: string; children?: any[] } = { value: data.id, label: data.name };
    if (data?.children && data?.children.length > 0) obj.children = data?.children.map((item) => transformData(item));
    return obj;
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const newGroup = {
      name: data.name.trim(),
      description: data.description,
      user_limit: parseInt(String(data.user_limit)),
      parent_id: data.parent_id,
      type: 'group',
    };

    if (!formState.isDirty && id) {
      dispatch(setSecondGroupId(id));
      navigate(ROUTES.GROUP_MANAGE(id), { state: { edit: true } });
      return;
    }

    const setGroupError = (error: IServerErrorWithTranslation, field: keyof FormValues) => {
      const { status } = error;
      const { detail } = error.data;

      if (status === 400) {
        const { entity, value } = getErrorEntity(error);

        setError(field, {
          type: 'server',
          message: t(detail.error, {
            entity,
            value,
            field: t(`server.field.${field}`).toLowerCase(),
            ...detail.items,
          }),
        });
      }
    };

    id
      ? updateGroup({ id, group: newGroup })
          .unwrap()
          .then(() => {
            dispatch(setSecondGroupId(id));
            navigate(ROUTES.GROUP_MANAGE(id), { state: { edit: true } });
          })
          .catch((error) => {
            handleNoPermissionError(error.status);
            setGroupError(error, 'name');
          })
      : createGroup(newGroup)
          .unwrap()
          .then((resp) => {
            dispatch(setSecondGroupId(resp.id));
            navigate(ROUTES.GROUP_MANAGE(resp.id), { state: { edit: false } });
          })
          .catch((error) => {
            handleNoPermissionError(error.status);
            setGroupError(error, 'name');
          });
  };

  useDocumentTitle(id ? [t('page_titles.group_edit')] : [t('page_titles.group_add')]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <ShadowBlock>
          <Breadcrumb
            items={[
              { name: t('groups.group_list_short'), href: ROUTES.GROUP_LIST },
              id
                ? {
                    name: t('groups.edit_group'),
                    href: ROUTES.GROUP_EDIT(id),
                    active: true,
                  }
                : {
                    name: t('groups.add_group'),
                    href: ROUTES.GROUP_ADD,
                    active: true,
                  },
            ]}
          />

          <div>
            {(groupDataLoading || isLoading) && <Loader backdrop vertical size="lg" />}
            <Title
              title={id ? t('groups.edit_group') : t('groups.add_group')}
              description={id ? t('groups.edit_group_description') : t('groups.add_group_description')}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="parent_id"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    cascade
                    disabled={Boolean(id)}
                    isLoading={isLoading}
                    value={value}
                    onSelect={onChange}
                    data={data?.items.map((el) => transformData(el))}
                    label={t('inputs.parental_group')}
                    placeholder={t('inputs.parental_group')}
                    error={getFieldState('parent_id', formState)}
                    error_message={t(formState?.errors.parent_id?.message || '')}
                  />
                )}
              />

              <TextInput
                required
                label={t('inputs.name')}
                placeholder={t('inputs.name_placeholder')}
                register={register('name')}
                error={getFieldState('name', formState)}
                error_message={t(formState?.errors.name?.message || '')}
              />

              <TextInput
                label={t('general.description')}
                placeholder={t('inputs.description_placeholder')}
                register={register('description')}
                error={getFieldState('description', formState)}
                error_message={t(formState?.errors.description?.message || '')}
              />

              <TextInput
                required
                type="number"
                label={t('inputs.user_limit')}
                placeholder={t('inputs.user_limit_placeholder')}
                register={register('user_limit')}
                error={getFieldState('user_limit', formState)}
                error_message={t(formState?.errors.user_limit?.message || '')}
              />

              <Stack direction="row" width="100%" justifyContent="space-between" spacing={1}>
                <ButtonStyled
                  fullWidth
                  fill={ButtonFill.Outlined}
                  onClick={() =>
                    state?.isGroupHierarchy ? navigate(ROUTES.GROUP_HIERARCHY) : navigate(ROUTES.GROUP_LIST)
                  }
                >
                  {t('general.cancel')}
                </ButtonStyled>

                <ButtonStyled
                  fullWidth
                  fill={ButtonFill.Contained}
                  clickType="submit"
                  loading={creatingGroup || updatingGroup}
                >
                  {!id
                    ? t('groups.create_group_btn')
                    : id && formState.isDirty
                    ? t('groups.update_group')
                    : t('groups.manage_group')}
                </ButtonStyled>
              </Stack>
            </form>
          </div>
        </ShadowBlock>
      </div>
    </div>
  );
};

export default GroupAdd;
