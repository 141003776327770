import { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';

import { useHideScroll } from 'hooks/useHideScroll';

import ArrowSVG from 'assets/svg/create-assesment/ArrowSVG';

import styles from './MobileDropdown.module.scss';

type Props = {
  openMobileMenu: boolean;
  setOpenMobileMenu: Dispatch<SetStateAction<boolean>>;
};

const MobileDropdown = ({ openMobileMenu, setOpenMobileMenu }: Props) => {
  useHideScroll();

  return (
    <div
      className={classNames(styles.MobileDropDown, { [styles.Active]: openMobileMenu })}
      onClick={() => {
        if (!openMobileMenu) {
          setOpenMobileMenu(true);
        } else {
          setOpenMobileMenu(false);
        }
      }}
    >
      <ArrowSVG />
    </div>
  );
};

export default MobileDropdown;
