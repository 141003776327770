import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import TextInput from 'components/Inputs/Text/Text';
import AvatarSelectModal from 'components/Modals/AvatarSelectModal/AvatarSelectModal';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Status from 'components/Status/Status';
import Title from 'components/Title/Title';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { usePrevious } from 'hooks/usePrevious';
import { useToggle } from 'hooks/useToggle';

import { useLazyGetIncompleteAssessmentsQuery } from 'store/api/assessmentApi/assessmentApi';
import {
  useGetMyProfileQuery,
  useUpdateProfileWithDataInvalidationMutation,
  useUpdateProfileWithoutDataInvalidationMutation,
} from 'store/api/profileApi/profileApi';

import ROUTES from 'router/routes';

import { getIsIndividualUser } from 'helpers/checkUserPermission';
import { ProfileSettingsResolver } from 'helpers/validations';

import { ButtonFill } from 'types/enums';

import { ReactComponent as EditIcon } from 'assets/images/pencil-edit.svg';
import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';
import { ReactComponent as User } from 'assets/images/user-avatar.svg';

import styles from './Profile.module.scss';

type FormValues = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  organization: string;
  subGroup: string;
  role: string;
};

type AvailableRoute = 'onboarding' | 'personal-profile-settings';

const getCrumbs = (t: TFunction, route: AvailableRoute, dashboard: string) => [
  {
    name: t(`${route === 'onboarding' ? 'general.sign_up' : 'dashboard.page_title'}`),
    href: route === 'onboarding' ? ROUTES.SIGNUP : dashboard,
  },
  {
    name: t('profile.personal_profile_settings'),
    href: route === 'personal-profile-settings' ? ROUTES.PERSONAL_PROFILE_SETTINGS : ROUTES.ONBOARDING_PROFILE,
    active: true,
  },
];

const Profile = () => {
  const navigate = useNavigate();
  const currentRoute = useMemo<AvailableRoute>(() => window.location.pathname.split('/')[1] as AvailableRoute, []);

  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const dashboard = useAppSelector((state) => state.navigation.dashboard);
  const userPermissions = useAppSelector((state) => state.permissions.permissions);
  const [isEmailDisabled, toggleIsEmailDisabled] = useToggle(true);
  const [getIncompleteAssessments] = useLazyGetIncompleteAssessmentsQuery();

  const [open, toggle] = useState(false);
  const [avatar, setAvatar] = useState(user?.photo ? { preview: user?.photo, file: '' } : { preview: '', file: null });

  const prevAvatarValue = usePrevious(avatar);

  const { data } = useGetMyProfileQuery();
  const [deleteAvatar] = useUpdateProfileWithoutDataInvalidationMutation();
  const [updateUser, { isLoading: updating }] = useUpdateProfileWithDataInvalidationMutation();

  const { register, handleSubmit, watch, formState, getFieldState } = useForm<FormValues>({
    defaultValues: {
      ...user,
      organization: user?.groups[0].group_name,
      role: user?.groups[0].role_name,
    },
    mode: 'onChange',
    resolver: yupResolver(ProfileSettingsResolver),
  });

  const isFormEdited =
    !!Object.keys(formState.dirtyFields).length || (prevAvatarValue && prevAvatarValue?.file !== avatar.file);
  const isOnboarding = currentRoute === 'onboarding';

  const onDeleteAvatar = () => {
    if (data?.photo) {
      deleteAvatar({
        id: data.id,
        user: { ...data, photo: '' },
        groupId: user?.groups[0].group_id,
      }).then(() => setAvatar({ preview: '', file: '' }));
    } else {
      setAvatar({ preview: '', file: '' });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateUser({
      id: data.id,
      user: {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        photo: avatar.file || null, // Return null passes API validation if we won't update photo
      },
      groupId: user?.groups[0].group_id,
    })
      .unwrap()
      .then(async () => {
        if (isOnboarding && getIsIndividualUser(userPermissions)) {
          const incompleteAssessments = await getIncompleteAssessments().unwrap();

          if (incompleteAssessments.length === 1) {
            navigate(ROUTES.DIAGNOSTIC_TAKE(incompleteAssessments[0]));
          } else {
            navigate(ROUTES.DIAGNOSTIC_LIST);
          }

          return;
        }

        navigate(dashboard);
      })
      .catch((error) => {
        toast.error<string>(error?.data?.detail || t('general.error'));
      });
  };
  const fullName = watch(['first_name', 'last_name']);

  const changePasswordHandler = () => {
    navigate(ROUTES.CHANGE_PASSWORD);
  };

  useDocumentTitle([t('page_titles.profile')]);

  return (
    <div className={styles.Container}>
      <ShadowBlock>
        <Breadcrumb items={getCrumbs(t, currentRoute, dashboard)} />

        <AvatarSelectModal
          avatar={avatar}
          open={open}
          handleClose={() => toggle(false)}
          setAvatar={setAvatar}
          deleteAvatar={onDeleteAvatar}
        />

        <div className={styles.OnboardingProfile}>
          <form className={styles.OnboardingForm} onSubmit={handleSubmit(onSubmit)}>
            <button className={styles.AvatarWrapper} onClick={() => toggle(true)} type="button">
              <div className={styles.AvatarLabel}>
                {avatar?.preview ? <img src={avatar.preview} alt="avatar" className={styles.Image} /> : <User />}
                <div className={styles.EditIcon}>
                  <PencilIcon />
                </div>
              </div>
            </button>

            <div className={styles.TitleContainer}>
              <Title title={fullName[0] || fullName[1] ? `${fullName[0]} ${fullName[1]}` : t('general.your_name')} />
            </div>

            <Status isActive={!!user?.is_active} isRow />

            <TextInput
              required
              register={register('first_name')}
              label={t('inputs.first_name')}
              placeholder={t('inputs.first_name_placeholder')}
              error={getFieldState('first_name', formState)}
              error_message={t(formState.errors.first_name?.message || '')}
            />

            <TextInput
              required
              register={register('last_name')}
              label={t('inputs.last_name')}
              placeholder={t('inputs.last_name_placeholder')}
              error={getFieldState('last_name', formState)}
              error_message={t(formState.errors.last_name?.message || '')}
            />

            <div className={styles.EmailContainer}>
              <div className={styles.InputContainer}>
                <TextInput
                  required
                  register={register('email')}
                  disabled={isEmailDisabled}
                  label={t('inputs.email')}
                  placeholder={t('inputs.email_placeholder')}
                  error={getFieldState('email', formState)}
                  error_message={t(formState.errors.email?.message || '')}
                />
              </div>
              {isEmailDisabled && (
                <button type="button" onClick={() => toggleIsEmailDisabled()}>
                  <EditIcon />
                </button>
              )}
            </div>

            <TextInput
              disabled
              register={register('organization')}
              label={t('inputs.organization')}
              placeholder={t('inputs.organization_placeholder')}
            />

            <TextInput
              disabled
              register={register('role')}
              label={t('inputs.role')}
              placeholder={t('inputs.role_placeholder')}
            />

            <div className={styles.Buttons}>
              <div className={styles.Save}>
                <ButtonStyled
                  disabled={isOnboarding ? false : !isFormEdited}
                  fill={ButtonFill.Contained}
                  clickType="submit"
                  loading={updating}
                  fullWidth
                >
                  {t('general.save')}
                </ButtonStyled>
              </div>

              <div className={styles.ChangePassBtn}>
                <ButtonStyled onClick={changePasswordHandler} fill={ButtonFill.Transparent} fullWidth>
                  {t('general.change_password')}
                </ButtonStyled>
              </div>
            </div>
          </form>
        </div>
      </ShadowBlock>
    </div>
  );
};

export default Profile;
