export const SortIcon = (): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.35987 8.43749L5.37668 10.6011L5.37668 3.40909C5.37668 3.18316 5.20879 3 5.0017 3C4.7946 3 4.62671 3.18316 4.62671 3.40909L4.62672 10.6048L2.64014 8.43748C2.49369 8.27772 2.25627 8.27772 2.10983 8.43748C1.96339 8.59724 1.96339 8.85626 2.10983 9.01602L4.72726 11.8715L4.73475 11.8799C4.76309 11.9109 4.79485 11.9358 4.82869 11.9548C4.88242 11.9849 4.94144 12 5.00045 12L5.0017 12C5.11706 12 5.22026 11.9432 5.28905 11.8538L7.89017 9.01603C8.03661 8.85627 8.03661 8.59725 7.89017 8.43749C7.74373 8.27773 7.50631 8.27773 7.35987 8.43749Z"
        fill="#A6B4C1"
      />
      <path
        d="M8.64013 6.56251L10.6233 4.39893L10.6233 11.5909C10.6233 11.8168 10.7912 12 10.9983 12C11.2054 12 11.3733 11.8168 11.3733 11.5909L11.3733 4.39524L13.3599 6.56252C13.5063 6.72228 13.7437 6.72228 13.8902 6.56252C14.0366 6.40276 14.0366 6.14374 13.8902 5.98398L11.2727 3.12847L11.2653 3.12007C11.2369 3.08914 11.2052 3.0642 11.1713 3.04525C11.1176 3.01506 11.0586 2.99998 10.9996 3L10.9983 3C10.8829 3 10.7797 3.05684 10.711 3.14624L8.10983 5.98397C7.96339 6.14373 7.96339 6.40275 8.10983 6.56251C8.25627 6.72227 8.49369 6.72227 8.64013 6.56251Z"
        fill="#A6B4C1"
      />
    </svg>
  );
};
