import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';

import { useUploadUsersCsvMutation } from 'store/api/usersApi/usersApi';

import ROUTES from 'router/routes';

import { MAX_UPLOAD_FILE_SIZE } from 'constants/index';

import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';

import ModalWrapper from '../ModalWrapper';

import styles from './UploadCSV.module.scss';

type Props = {
  open: boolean;
  fromGroup?: boolean;
  handleClose: () => void;
  onUserUploaded?: (value: boolean) => void;
};

type FormValues = {
  file: null | FileType[];
};

const UploadCSV = ({ open, fromGroup, onUserUploaded, handleClose }: Props) => {
  const uploader = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [uploadUsers, { isLoading }] = useUploadUsersCsvMutation();

  const { handleSubmit, control, formState, setError, clearErrors, reset, watch } = useForm<FormValues>({
    defaultValues: { file: null },
    mode: 'onSubmit',
  });

  const file = watch('file');

  const handleCancel = () => {
    handleClose();
    reset({ file: null });
  };

  const onSubmit = (data: FormValues) => {
    const formData = new FormData();

    if (!data.file?.length) return;

    const blob = data.file[0].blobFile;

    if (!blob) return;

    if (data.file.length > 1) {
      setError('file', { type: 'filetype', message: t('csv.validation_message.files_length') });
      return;
    }

    if (blob.type !== 'text/csv') {
      setError('file', { type: 'filetype', message: t('csv.validation_message.file_type') });
      return;
    }

    if (blob.size > MAX_UPLOAD_FILE_SIZE) {
      setError('file', { type: 'filetype', message: t('csv.validation_message.file_size') });
      return;
    }

    formData.append('data', blob!);

    uploadUsers(formData)
      .unwrap()
      .then((data) => {
        if (fromGroup && onUserUploaded) {
          handleCancel();
          onUserUploaded(true);
        } else navigate(ROUTES.USER_ADD_CSV, { state: { access: true } });
      })
      .catch((error) => {
        let value = '';

        if (error.data.detail.error === 'server.csv.validation.invalid_columns_names') {
          value = error.data.detail.items.join(', ');
        }

        setError('file', {
          type: 'filetype',
          message: t(error.data.detail.error, value ? { value } : {}),
        });
      });
  };

  return (
    <ModalWrapper
      open={open}
      title={t('general.add_csv')}
      subtitle={t('csv.subtitle')}
      close={handleCancel}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.upload')}
      status={isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {formState.errors?.file && (
          <div className={styles.Error}>
            <p>{formState.errors?.file?.message}</p>
          </div>
        )}

        <Controller
          control={control}
          name="file"
          rules={{
            required: {
              value: true,
              message: t('csv.validation_message.file_required'),
            },
          }}
          render={({ field: { onChange } }) => (
            <Uploader
              onChange={onChange}
              action=""
              ref={uploader}
              accept=".csv"
              autoUpload={false}
              multiple={false}
              draggable
              onRemove={() => clearErrors('file')}
              fileListVisible={!!file?.length}
              renderFileInfo={(file: FileType) =>
                uploader && (
                  <div className={styles.FileInfo}>
                    <FileIcon />
                    <div className={styles.Name}>{file.name}</div>
                  </div>
                )
              }
            >
              <div>
                <UploadIcon className={styles.DragImage} />
                <div className={styles.DragText}>{t('csv.drag_text')}</div>
                <div className={styles.DragSelectText}>
                  <div className={styles.ActiveText}>{t('csv.drag_select_text_1')} </div> {t('csv.drag_select_text_2')}
                </div>
              </div>
            </Uploader>
          )}
        />
      </form>
    </ModalWrapper>
  );
};

export default UploadCSV;
