import { Node } from 'slate';

import { CustomElement } from 'types/richTextEditorTypes';

export const serializeToChars = (nodes: CustomElement[]) => {
  return nodes.map((n) => Node.string(n)).join('');
};

export const serializeToText = (nodes: CustomElement[]) => {
  return nodes.map((n) => Node.string(n)).join('\n');
};

export const getSerializedText = (answer: string) => {
  try {
    return serializeToText(JSON.parse(answer));
  } catch (error) {
    return answer;
  }
};
