import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import PasswordInput from 'components/Inputs/Password/Password';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Title from 'components/Title/Title';

import PasswordValidationCheckbox from '../components/PasswordValidationCheckbox/PasswordValidationCheckbox';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useActivateUserMutation } from 'store/api/authApi/authApi';

import ROUTES from 'router/routes';

import { SetupPassResolver } from 'helpers/validations';

import { ButtonFill } from 'types/enums';

import styles from './SetupPassword.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const SetupPassword = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const [activateUser, { isLoading }] = useActivateUserMutation();
  const navigate = useNavigate();
  const { register, getFieldState, formState, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(SetupPassResolver),
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const urlData = new URLSearchParams(search);
    const resetPasswordData = {
      token: urlData.get('token'),
      uid: urlData.get('uid'),
      password: data.password,
    };

    activateUser(resetPasswordData)
      .unwrap()
      .then((resp) => {
        if ('detail' in resp) {
          toast.success(resp.detail);
          navigate(ROUTES.LOGIN);
        }
      })
      .catch((error) => {
        toast.error(t(error.data.detail.error));
      });
  };

  useDocumentTitle([t('page_titles.setup_password')]);

  return (
    <div className={styles.Container}>
      <ShadowBlock>
        <Breadcrumb
          items={[
            { name: t('general.login'), href: ROUTES.LOGIN },
            {
              name: t('general.setup_pass'),
              href: ROUTES.RESET_PASSWORD,
              active: true,
            },
          ]}
        />

        <div className={styles.PasswordSetup}>
          <Title title={t('general.setup_pass')} description={t('general.setup_pass_description')} />

          <form className={styles.PasswordSetup_Form} onSubmit={handleSubmit(onSubmit)}>
            <PasswordInput
              label={t('inputs.setup_password')}
              placeholder={t('inputs.password_placeholder')}
              register={register('password')}
              error={getFieldState('password', formState)}
              error_message={t(formState.errors.password?.message ? t('sign_up.stronger_message') : '')}
            />

            <div className={styles.SignUpValidation}>
              <div className={styles.ValidationTitle}>{t('sign_up.validation_title')}</div>

              <div
                style={{
                  opacity: formState.errors?.password?.message ? '1' : '0',
                  transition: '300ms ease-in-out all',
                }}
              ></div>

              <div className={styles.ValidationBlock}>
                <div className={styles.ValidationEl}>
                  <PasswordValidationCheckbox type="validation.length" formState={formState} />
                  {t('sign_up.password_length')}
                </div>
                <div className={styles.ValidationEl}>
                  <PasswordValidationCheckbox type="validation.capital" formState={formState} />
                  {t('sign_up.password_capital')}
                </div>
                <div className={styles.ValidationEl}>
                  <PasswordValidationCheckbox type="validation.number" formState={formState} />
                  {t('sign_up.password_number')}
                </div>
                <div className={styles.ValidationEl}>
                  <PasswordValidationCheckbox type="validation.symbol" formState={formState} />
                  {t('sign_up.password_symbol')}
                </div>
              </div>
            </div>

            <PasswordInput
              label={t('inputs.setup_confirm')}
              placeholder={t('inputs.confirm_password')}
              register={register('confirmPassword')}
              error={getFieldState('confirmPassword', formState)}
              error_message={t(formState.errors?.confirmPassword?.message || '')}
            />

            <div className={styles.ButtonContainer}>
              <ButtonStyled
                fill={ButtonFill.Contained}
                clickType="submit"
                fullWidth
                loading={isLoading}
                disabled={!formState.isValid}
              >
                {t('general.confirm')}
              </ButtonStyled>
            </div>
          </form>
        </div>
      </ShadowBlock>
    </div>
  );
};

export default SetupPassword;
