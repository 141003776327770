import { TFunction } from 'i18next';
import * as yup from 'yup';

interface TestContextExtended {
  from: {
    value: {
      option1: {
        user: string;
      };
    };
  }[];
}

const EMAIL_REGX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const EMAIL_USERNAME_MAX_LENGTH = 64;

const validateEmailUsernameLength = (value: string | undefined): boolean => {
  if (value?.indexOf('@')) {
    const email = value.substring(0, value.indexOf('@'));
    return email.length <= EMAIL_USERNAME_MAX_LENGTH;
  }
  return true;
};

export const OnboardingResolver = yup.object().shape({
  group: yup.string().required(),
  role: yup.string(),
});

export const SignInResolver = yup.object().shape({
  email: yup.string().required('validation.email_required').matches(EMAIL_REGX, 'validation.email_invalid'),
  password: yup.string().required('validation.password_required'),
});

const passwordValidation = yup
  .string()
  .required('validation.password_required')
  .matches(/[A-Z]/, 'validation.capital')
  .matches(/[^\w]/, 'validation.symbol')
  .matches(/[0-9]/, 'validation.number')
  .matches(/^(.{8,16})$/, 'validation.length');

const confirmPasswordValidation = yup
  .string()
  .oneOf([yup.ref('password'), undefined], 'validation.password_confirmation');

const passwordsValidationObject = {
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
};

export const SignUpResolver = yup.object().shape({
  email: yup.string().required('validation.email_required').matches(EMAIL_REGX, 'validation.email_invalid'),
  ...passwordsValidationObject,
});

export const SetupPassResolver = yup.object().shape({
  ...passwordsValidationObject,
});

export const ForgotResolver = yup.object().shape({
  email: yup.string().required('validation.email_required').matches(EMAIL_REGX, 'validation.email_invalid'),
});

export const ProfileSettingsResolver = yup.object().shape({
  email: yup.string().required('validation.email_required').matches(EMAIL_REGX, 'validation.email_invalid'),
  first_name: yup.string().required('validation.first_name_required').max(64, 'validation.name_max_length'),
  last_name: yup.string().required('validation.last_name_required').max(64, 'validation.name_max_length'),
});

const groupFormValidation = {
  name: yup.string().max(64, 'validation.max_group_name').required('validation.group_name_required'),
  description: yup.string().max(160, 'validation.max_group_description'),
  user_limit: yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .required('validation.user_limit_required')
    .min(1, 'validation.user_limit_count')
    .typeError(''),
};

export const createGroupForm = yup.object().shape({
  parent_id: yup.string().required('validation.no_empty_parental_group'),
  ...groupFormValidation,
});

export const editGroupForm = yup.object().shape({
  ...groupFormValidation,
});

export const editSection = yup.object().shape({
  name: yup.string().max(64, 'validation.section_length').required('validation.section_required'),
});

export const unitResolver = yup.object().shape({
  name: yup.string().min(3, 'validation.unit_length').max(24, 'validation.unit_length'),
});

const groupValidation = yup.string().required('validation.group_required');
const userValidation = yup.string().required('validation.user_required');
const assessmentValidation = yup.string().required('validation.diagnostic_required');

const individualUsersComparisonValidationObject = yup.object({
  user: userValidation,
  assessment: assessmentValidation,
});

const ownComparisonValidationObject = yup.object({
  assessment: assessmentValidation,
});

export const compareIndividualUsersAssessmentModalResolver = yup.object({
  option1: individualUsersComparisonValidationObject,
  option2: individualUsersComparisonValidationObject,
});

export const compareInGroupUsersAssessmentModalResolver = (t: TFunction) =>
  yup.object({
    option1: yup.object({
      group: groupValidation,
      user: yup.string(),
      assessment: assessmentValidation,
    }),
    option2: yup.object({
      group: groupValidation,
      user: yup.string().test('is option1.user selected', t('validation.user_required'), (value, testContext) => {
        const context = testContext as yup.TestContext & TestContextExtended;

        try {
          const user1 = context.from?.[context.from.length - 1].value.option1.user;

          if (user1) {
            return !!value;
          }
        } catch (e) {
          return false;
        }

        return true;
      }),
      assessment: assessmentValidation,
    }),
  });

export const compareOwnAssessmentModalResolver = yup.object({
  option1: ownComparisonValidationObject,
  option2: ownComparisonValidationObject,
});

export const assessmentSettingValidation = (t: TFunction) =>
  yup.object({
    assessment_name: yup
      .string()
      .required(t('assessment.validation.name_required'))
      .min(3, t('assessment.validation.min_input'))
      .max(32, t('assessment.validation.max_32')),
    assessment_description: yup
      .string()
      .required(t('assessment.validation.description_required'))
      .min(3, t('assessment.validation.min_input'))
      .max(140, t('assessment.validation.max_140')),
    user_ids: yup.array().of(yup.string().required(t('assessment.validation.assignee_required'))),
  });

export const addUserResolver = (t: TFunction) =>
  yup.object().shape({
    subgroup: yup.string().required(t('validation.subgroup_required')),
    role: yup.string().required(t('validation.role_required')),
    status: yup.string().required(t('validation.status_required')),
    first_name: yup
      .string()
      .required(t('validation.first_name_required'))
      .max(64, 'validation.name_max_length')
      .nullable(),
    last_name: yup
      .string()
      .required(t('validation.last_name_required'))
      .max(64, 'validation.name_max_length')
      .nullable(),
    email: yup
      .string()
      .required('validation.email_required')
      .test('maxLength', 'validation.email_username_length', validateEmailUsernameLength)
      .matches(EMAIL_REGX, 'validation.email_invalid'),
  });

export const addSingleUserResolver = (t: TFunction) =>
  yup.object({
    email: yup
      .string()
      .required('validation.email_required')
      .test('maxLength', 'validation.email_username_length', validateEmailUsernameLength)
      .matches(EMAIL_REGX, 'validation.email_invalid'),
    first_name: yup.string().required(t('validation.first_name_required')).max(64, 'validation.name_max_length'),
    last_name: yup.string().required(t('validation.last_name_required')).max(64, 'validation.name_max_length'),
    status: yup.string().required(t('validation.status_required')),
    accesses: yup.array().of(
      yup.object().shape({
        group_id: yup.string().required(t('validation.subgroup_required')),
        role_id: yup.string().required(t('validation.role_required')),
      }),
    ),
  });

export const tooltipResolver = (t: TFunction) =>
  yup.object().shape({
    mainText: yup
      .string()
      .required(t('validation.main_text_required'))
      .min(3, t('validation.main_text_min_input'))
      .max(2000, t('validation.main_text_max_input')),
  });
