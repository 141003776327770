import { createApi } from '@reduxjs/toolkit/query/react';

import { baseAssessmentQueryWithReauth } from '../../helpers';

import { DashboardSearchParams } from 'types/dashboardTypes';

import {
  GetVisualisationsPayload,
  GetVisualisationsResponse,
  UpdateVisualisationPayload,
  UpdateVisualisationResponse,
  UpdateVisualisationSectionPayload,
  UpdateVisualisationSectionResponse,
} from './types';

import { mappingApi, tagsMap } from '../mappingsApi/mappingsApi';

export const visualisationsApi = createApi({
  tagTypes: ['Visualisations'],
  reducerPath: 'visualisationsApi',
  baseQuery: baseAssessmentQueryWithReauth,
  endpoints: (builder) => ({
    getVisualisations: builder.query<GetVisualisationsResponse, GetVisualisationsPayload>({
      query: () => 'visualisations/',
      providesTags: () => ['Visualisations'],
    }),

    updateVisualisation: builder.mutation<UpdateVisualisationResponse, UpdateVisualisationPayload>({
      query: ({ id, description }) => ({
        url: `visualisations/${id}/`,
        method: 'PUT',
        body: description,
      }),
      invalidatesTags: ['Visualisations'],
      async onQueryStarted({ ...arg }: UpdateVisualisationPayload, { dispatch, queryFulfilled }) {
        try {
          const searchParams = new URLSearchParams(window.location.search);
          const completionId = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
          const { data: updatedVisualisation } = await queryFulfilled;

          if (completionId) {
            dispatch(
              mappingApi.util.updateQueryData(
                'getMappings',
                { completionId, visualisationType: arg.visualisationType },
                (draft) => {
                  if (!draft) return null;

                  draft.some((visualisation) => {
                    if (visualisation.id === updatedVisualisation.id) {
                      visualisation.description = updatedVisualisation.description;

                      return true;
                    } else {
                      const draftVisualisationSubGraph = visualisation.sub_graphs?.find(
                        (section) => section.id === updatedVisualisation.id,
                      );

                      if (draftVisualisationSubGraph) {
                        draftVisualisationSubGraph.description = updatedVisualisation.description;

                        return true;
                      }
                    }

                    return false;
                  });
                },
              ),
            );
          } else {
            dispatch(mappingApi.util.invalidateTags(tagsMap[arg.visualisationType]));
          }
        } catch {}
      },
    }),

    updateVisualisationSection: builder.mutation<UpdateVisualisationSectionResponse, UpdateVisualisationSectionPayload>(
      {
        query: ({ id, description }) => ({
          url: `visualisations/sections/${id}/`,
          method: 'PUT',
          body: description,
        }),
        invalidatesTags: ['Visualisations'],
        async onQueryStarted({ ...arg }: UpdateVisualisationSectionPayload, { dispatch, queryFulfilled }) {
          try {
            const searchParams = new URLSearchParams(window.location.search);
            const completionId = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
            const { data: updatedVisualisationSection } = await queryFulfilled;

            if (completionId) {
              dispatch(
                mappingApi.util.updateQueryData(
                  'getMappings',
                  { completionId, visualisationType: arg.visualisationType },
                  (draft) => {
                    if (!draft) return null;

                    draft.some((visualisation) => {
                      const draftVisualisationSection = visualisation.sections.find(
                        (section) => section.id === updatedVisualisationSection.id,
                      );

                      if (draftVisualisationSection) {
                        draftVisualisationSection.description = updatedVisualisationSection.description;
                        return true;
                      }

                      return false;
                    });
                  },
                ),
              );
            } else {
              dispatch(mappingApi.util.invalidateTags(tagsMap[arg.visualisationType]));
            }
          } catch {}
        },
      },
    ),
  }),
});

export const { useGetVisualisationsQuery, useLazyGetVisualisationsQuery } = visualisationsApi;

export const { useUpdateVisualisationMutation, useUpdateVisualisationSectionMutation } = visualisationsApi;
