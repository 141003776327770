import { CheckboxProps, Checkbox as CheckboxRsuite } from 'rsuite';

import styles from './Checkbox.module.scss';

const Checkbox = ({ children, ...props }: CheckboxProps) => {
  return (
    <CheckboxRsuite className={styles.Container} {...props}>
      {children}
    </CheckboxRsuite>
  );
};

export default Checkbox;
