import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';

import { findNestedObj } from 'helpers/findNestedObj';

import { Size } from 'types/enums';
import { SelectGroupHierarchy } from 'types/groupHierarchyTypes';
import { Tags } from 'types/userTypes';

import DeleteButton from '../../Buttons/DeleteButton/DeleteButton';
import Select from '../../Inputs/Select/Select';
import ModalWrapper from '../ModalWrapper';

import styles from './OrganizationSelect.module.scss';

type Props = {
  open: boolean;
  tag?: null | Tags;
  close: () => void;
  groups?: SelectGroupHierarchy[];
  openDelete: () => void;
  select?: (group: any) => void;
  isManageGroup?: boolean;
};

const OrganizationSelect = ({ tag, close, openDelete, groups, select, isManageGroup, ...props }: Props) => {
  const { t } = useTranslation();

  const [role, selectRole] = useState<string>('');
  const [group, selectGroup] = useState<string>('');

  const { data: roles, isFetching: isFetchingRoles } = useGetRolesQuery({ groupId: group }, { skip: !group });

  const rolesMapped = roles?.items.map((el) => ({ value: el.id, label: el.name })) || [];

  const title = isManageGroup
    ? t('users.add_to_another_organization')
    : `${tag ? t('general.edit') : t('general.add')} ${t('users.in_another_organization').toLowerCase()}`;

  useEffect(() => {
    if (tag) {
      selectRole(tag?.tag?.role?.value);
      selectGroup(tag?.tag?.group?.value);
    }
  }, [tag]);

  const clearData = () => {
    selectRole('');
    selectGroup('');
  };

  const handleClose = () => {
    clearData();
    close();
  };

  const onConfirmSelect = () => {
    clearData();
    if (select) {
      select({
        role: findNestedObj(rolesMapped, 'value', role),
        group: findNestedObj(groups, 'value', group),
      });
    }
  };

  return (
    <ModalWrapper
      {...props}
      title={title}
      subtitle={
        tag
          ? t('users.edit_user_in_another_organization_description')
          : t('users.add_user_to_another_organization_description')
      }
      close={handleClose}
      action={onConfirmSelect}
      actionTitle={t('general.save')}
      actionDisabled={!role || !group}
    >
      <Select
        required
        cascade
        data={groups}
        value={group}
        onSelect={(value) => selectGroup(value)}
        label={t('inputs.organization')}
        placeholder={t('inputs.organization_placeholder')}
      />

      <Select
        required
        value={role}
        data={rolesMapped}
        onSelect={(value) => selectRole(value)}
        label={isManageGroup ? t('inputs.role_in_the_group') : t('inputs.role')}
        placeholder={t('inputs.role_placeholder')}
        isLoading={isFetchingRoles}
      />

      {tag && (
        <div className={styles.DeleteUser}>
          <DeleteButton title={t('users.delete_from_organization')} clear={openDelete} size={Size.Sm} />
        </div>
      )}
    </ModalWrapper>
  );
};

export default OrganizationSelect;
