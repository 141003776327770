import { useTranslation } from 'react-i18next';

import styles from './Steps.module.scss';

type Props = {
  count: number;
  current: number;
};

const Steps = ({ count, current }: Props) => {
  const { t } = useTranslation();
  const progressPercentage = (current / count) * 100;

  return (
    <div className={styles.Container}>
      <div className={styles.Track}>
        <div className={styles.Progress} style={{ width: `${progressPercentage}%` }} />
      </div>
      <span className={styles.Counter}>{`${current} ${t('general.of').toLowerCase()} ${count}`}</span>
    </div>
  );
};

export default Steps;
