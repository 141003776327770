import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch } from 'hooks/redux';

import { addBoardElement, moveBoardElement } from 'store/features/createAssessmentSlice';

import { defaultCardValues } from '../constants';

import {
  ContainerBoardElement,
  DropdownBoardElement,
  Element,
  LikertBoardElement,
  MultipleColumnBoardElement,
  QuestionElement,
  QuestionSliderBoardElement,
  ThankBoardElement,
  WelcomeBoardElement,
  isQuestionElement,
} from 'types/boardElements';
import { CARD_TYPES, DragSource } from 'types/createAssessmentTypes';

export const useDragAndDrop = () => {
  const dispatch = useAppDispatch();

  const insertElement = ({
    element,
    dropIndex,
    parentNode,
  }: {
    element: Element;
    dropIndex: number;
    parentNode?: Element;
  }) => {
    const defaultValues = defaultCardValues[element.type as keyof typeof defaultCardValues];

    let newItem = {
      id: element.id || uuidv4(),
      type: element.type,
      parentId: parentNode ? parentNode.id : 'root',
      level: parentNode ? parentNode.level + 1 : 0,
      order: dropIndex + 1,
      source: 'content',
    };

    switch (element.type) {
      case CARD_TYPES.module:
      case CARD_TYPES.section:
        newItem = {
          ...newItem,
          name: element.name || '',
          weight: element.weight ? element.weight : defaultValues.weight,
          children: element.children ? element.children : defaultValues.children,
          calculation_type: element.calculation_type ? element.calculation_type : defaultValues.calculation_type,
        } as ContainerBoardElement;
        break;

      case CARD_TYPES.multiple_choice:
      case CARD_TYPES.single_choice:
        newItem = {
          ...newItem,
          weight: element.weight ? element.weight : defaultValues.weight,
          leftColumn: element.leftColumn ? element.leftColumn : defaultValues.leftColumn,
          rightColumn: element.rightColumn ? element.rightColumn : defaultValues.rightColumn,
        } as MultipleColumnBoardElement;
        break;

      case CARD_TYPES.dropdown:
        newItem = {
          ...newItem,
          weight: element.weight ? element.weight : defaultValues.weight,
          options: element.options ? element.options : defaultValues.options,
        } as DropdownBoardElement;
        break;

      case CARD_TYPES.likert:
        newItem = {
          ...newItem,
          weight: element.weight ? element.weight : defaultValues.weight,
          options: element.options ? element.options : defaultValues.options,
        } as LikertBoardElement;
        break;

      case CARD_TYPES.question_slider:
        newItem = {
          ...newItem,
          weight: element.weight ? element.weight : defaultValues.weight,
          min_value: element.min_value ? element.min_value : defaultValues.min_value,
          max_value: element.max_value ? element.max_value : defaultValues.max_value,
        } as QuestionSliderBoardElement;
        break;

      case CARD_TYPES.welcome_page:
      case CARD_TYPES.thankyou_page:
        newItem = {
          ...newItem,
          text_en: element.text_en ? element.text_en : defaultValues.text_en,
          description_en: element.description_en ? element.description_en : defaultValues.description_en,
          text_es: element.text_es ? element.text_es : defaultValues.text_es,
          description_es: element.description_es ? element.description_es : defaultValues.description_es,
        } as WelcomeBoardElement | ThankBoardElement;
        break;

      default:
        break;
    }

    if (isQuestionElement(element)) {
      newItem = {
        ...newItem,
        text_en: 'Add question',
        description_en: 'Add description',
        text_es: 'Agregar pregunta',
        description_es: 'Agregar descripción',
      } as QuestionElement;
    }

    dispatch(addBoardElement(newItem as Element));
  };

  // TODO: There is a bug when dragging an element to the underlying neighbor dropzone - it jumps below the underlying neighbor element
  const reorderElement = ({
    element,
    dropIndex,
    parentNode,
  }: {
    element: Element;
    dropIndex: number;
    parentNode?: Element;
  }) => {
    dispatch(
      moveBoardElement({
        ...element,
        parentId: parentNode?.id || 'root',
        level: parentNode ? parentNode.level + 1 : 0,
        order: dropIndex + 1,
      }),
    );
  };

  const dropElement = ({
    element,
    parentNode,
    dropIndex,
  }: {
    element: Element;
    parentNode?: Element;
    dropIndex: number;
  }) => {
    const isAside = element.source === DragSource.Aside;

    if (isAside) {
      insertElement({ element, dropIndex, parentNode });
    } else {
      reorderElement({ element, dropIndex, parentNode });
    }
  };

  return {
    handleDrop: dropElement,
  };
};
