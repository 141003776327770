import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseAuthQueryWithReauth } from '../../helpers';

import {
  CreateGroupPayload,
  CreateGroupResponse,
  DeleteGroupPayload,
  DeleteGroupResponse,
  GetAssessmentAssignHierarchyPayload,
  GetAssessmentAssignHierarchyResponse,
  GetGroupByIdPayload,
  GetGroupByIdResponse,
  GetGroupByTypePayload,
  GetGroupByTypeResponse,
  GetGroupHierarchyPayload,
  GetGroupHierarchyResponse,
  GetGroupPermissionsPayload,
  GetGroupPermissionsResponse,
  GetGroupsHierarchyNavigationPayload,
  GetGroupsHierarchyNavigationResponse,
  GetGroupsPayload,
  GetGroupsResponse,
  UpdateGroupPayload,
  UpdateGroupResponse,
} from './types';

import { selectOrganization, setHierarchy, setHierarchyLoading, setIsAdminGroup } from '../../features/navigationSlice';

export const groupsApi = createApi({
  tagTypes: [
    'Groups',
    'CurrentGroup',
    'AssessmentAssignHierarchy',
    'GroupPermissions',
    'GroupHierarchy',
    'NavigationHierarchy',
  ],
  reducerPath: 'groupsApi',
  baseQuery: baseAuthQueryWithReauth,
  endpoints: (builder) => ({
    getGroups: builder.query<GetGroupsResponse, GetGroupsPayload>({
      query: ({ groupId, ...params }) => ({
        url: '/groups/',
        params: params,
        headers: { group_id: groupId },
      }),
      transformResponse: (response: GetGroupsResponse) => ({
        ...response,
        items: response.items.map((item, i) => ({ ...item, fieldId: i + 1 })),
      }),
      providesTags: () => ['Groups'],
    }),

    getGroupById: builder.query<GetGroupByIdResponse, GetGroupByIdPayload>({
      query: (id) => ({
        url: `/groups/${id}/`,
        headers: { group_id: id },
      }),
      providesTags: () => ['CurrentGroup'],
    }),

    getGroupPermissions: builder.query<GetGroupPermissionsResponse, GetGroupPermissionsPayload>({
      query: (id) => ({ url: '/permissions/', params: { group_id: id } }),
      providesTags: ['GroupPermissions'],
    }),

    getAssessmentAssignHierarchy: builder.query<
      GetAssessmentAssignHierarchyResponse,
      GetAssessmentAssignHierarchyPayload
    >({
      query: () => '/groups/assign/hierarchy/',
      providesTags: ['AssessmentAssignHierarchy'],
    }),

    getGroupHierarchy: builder.query<GetGroupHierarchyResponse, GetGroupHierarchyPayload>({
      query: ({ search, orderBy = 'name' }) => ({
        url: '/groups/hierarchy/',
        params: {
          search,
          order_by: orderBy,
        },
      }),
      providesTags: ['GroupHierarchy'],
    }),

    getGroupsHierarchyNavigation: builder.query<
      GetGroupsHierarchyNavigationResponse,
      GetGroupsHierarchyNavigationPayload
    >({
      query: () => '/groups/hierarchy/navigation/',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setHierarchyLoading(true));
          const { data } = await queryFulfilled;
          dispatch(setHierarchy(data));
          dispatch(selectOrganization(data.items[0].id));
          if (data.items[0].path.path === '0') {
            dispatch(setIsAdminGroup(true));
          }
        } catch {}
      },
      providesTags: ['NavigationHierarchy'],
    }),

    getGroupByType: builder.query<GetGroupByTypeResponse, GetGroupByTypePayload>({
      query: ({ groupType }) => ({ url: '/groups/', params: { type: groupType } }),
    }),

    createGroup: builder.mutation<CreateGroupResponse, CreateGroupPayload>({
      query: (group) => ({
        url: '/groups/',
        method: 'POST',
        body: group,
        headers: {
          group_id: group.parent_id,
        },
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Groups', 'CurrentGroup', 'GroupHierarchy', 'NavigationHierarchy'],
    }),

    updateGroup: builder.mutation<UpdateGroupResponse, UpdateGroupPayload>({
      query: ({ id, group }) => ({
        url: `/groups/${id}/`,
        method: 'PUT',
        body: group,
        headers: {
          group_id: id,
        },
      }),
      invalidatesTags: ['Groups', 'CurrentGroup', 'GroupHierarchy'],
    }),

    deleteGroup: builder.mutation<DeleteGroupResponse, DeleteGroupPayload>({
      query: (id) => ({
        url: `/groups/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupByTypeQuery,
  useGetGroupHierarchyQuery,
  useGetGroupByIdQuery,
  useGetGroupPermissionsQuery,
  useGetAssessmentAssignHierarchyQuery,
  useGetGroupsHierarchyNavigationQuery,
  useLazyGetGroupsHierarchyNavigationQuery,
} = groupsApi;
export const { useDeleteGroupMutation, useCreateGroupMutation, useUpdateGroupMutation } = groupsApi;
