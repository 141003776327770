import { useTranslation } from 'react-i18next';

import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';

import DeleteButton from 'components/Buttons/DeleteButton/DeleteButton';

import { useElementTranslate } from '../../../hooks';

import { DropdownBoardElement } from 'types/boardElements';
import {
  OnElementDelete,
  SingleColumnOptionAdd,
  SingleColumnOptionChange,
  SingleColumnOptionDelete,
  TitleFieldsChange,
} from 'types/createAssessmentTypes';
import { Size } from 'types/enums';

import ArrowSVG from 'assets/svg/create-assesment/ArrowSVG';

import AddOption from '../../AddOption/AddOption';
import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';
import BoardInput from '../BoardInput/BoardInput';

import styles from '../BoardElements.module.scss';

type Props = {
  onElementDelete: OnElementDelete;
  element: DropdownBoardElement;
  onDropdownOptionAdd: SingleColumnOptionAdd;
  onDropdownOptionChange: SingleColumnOptionChange;
  onDropdownOptionDelete: SingleColumnOptionDelete;
  onTitleFieldsChange: TitleFieldsChange;
};

const Dropdown = ({
  element,
  onTitleFieldsChange,
  onDropdownOptionAdd,
  onDropdownOptionChange,
  onDropdownOptionDelete,
  onElementDelete,
}: Props) => {
  const { t } = useTranslation();
  const { nameValues } = useElementTranslate();

  return (
    <div className={classNames(styles.CardContainer, styles.DropdownCard)}>
      <div className={styles.InnerBody}>
        <CardTitleBlock onDelete={onElementDelete} element={element} onTitleChange={onTitleFieldsChange} />

        <div className={styles.SelectInput}>
          <span className={styles.SmallGreyText}>{t('general.dropdown_placeholder')}</span>

          <div>
            <ArrowSVG />
          </div>
        </div>

        <div className={styles.SelectMenu}>
          {element.options.map((el, index) => (
            <div key={index} className={classNames(styles.SelectMenuItem)}>
              <div className={styles.OptionBlock}>
                <TextareaAutosize
                  maxLength={280}
                  className={classNames(
                    styles.SmallGreyText,
                    styles.DropdownInput,
                    !el[nameValues] && styles.BorderError,
                  )}
                  value={el[nameValues]}
                  onChange={(e) => onDropdownOptionChange(element.id, index, nameValues, e.target.value)}
                />
              </div>

              <div className={styles.ScoreBlock}>
                <BoardInput
                  placeholder={t('general.score')}
                  value={el.score}
                  onChange={(e) => onDropdownOptionChange(element.id, index, 'score', e.target.value)}
                />

                <DeleteButton size={Size.Sm} clear={() => onDropdownOptionDelete(element.id, index)} />
              </div>
            </div>
          ))}

          <AddOption onClick={() => onDropdownOptionAdd(element.id)} />
        </div>
      </div>

      <BottomCardNavPanel
        weight={element.weight}
        onChange={(weight) => onTitleFieldsChange(element.id, 'weight', weight)}
      />
    </div>
  );
};

export default Dropdown;
