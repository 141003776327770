import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { mapEcosystem } from 'helpers/ecosystem';

import { DashboardResult, VisualisationTypes } from 'types/dashboardTypes';
import { ButtonFill, ButtonSize } from 'types/enums';

import DashboardTooltip from '../../../DashboardTooltip/DashboardTooltip';
import ChartWrapper from '../ChartWrapper/ChartWrapper';

import styles from './EcosystemCard.module.scss';

type EcosystemCardProps = {
  ecosystems: [DashboardResult] | [DashboardResult, DashboardResult];
};

export const EcosystemCard = ({ ecosystems }: EcosystemCardProps) => {
  const { t } = useTranslation();
  const [showSubGraphs, setShowSubGraphs] = useState(false);

  const [leftEcosystem, rightEcosystem] = ecosystems;
  const leftMappedEcosystem = mapEcosystem(leftEcosystem);
  let rightMappedEcosystem;

  if (rightEcosystem) {
    rightMappedEcosystem = mapEcosystem(rightEcosystem);
  }

  const { title, tooltip } = leftMappedEcosystem;

  return (
    <div className={styles.Container}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1} minWidth={0}>
          <h3 className={styles.Title}>{title}</h3>

          {tooltip && (
            <DashboardTooltip
              id={tooltip.id}
              visualisationType={VisualisationTypes.Ecosystem}
              title={tooltip.title}
              content={tooltip.content}
              placement="autoHorizontalEnd"
            />
          )}
        </Stack>

        <ButtonStyled
          size={ButtonSize.Small}
          fill={ButtonFill.Outlined}
          onClick={() => setShowSubGraphs(!showSubGraphs)}
        >
          {showSubGraphs ? t('general.hide') : t('general.details')}
        </ButtonStyled>
      </Stack>

      <Stack direction="row" justifyContent="space-around" spacing={10}>
        {leftMappedEcosystem && <ChartWrapper ecosystem={leftMappedEcosystem} showSubGraphs={showSubGraphs} />}

        {rightMappedEcosystem && <ChartWrapper ecosystem={rightMappedEcosystem} showSubGraphs={showSubGraphs} />}
      </Stack>
    </div>
  );
};
