import { useState } from 'react';

export const useSetRolesModal = () => {
  const [selectedRoleId, setRoleId] = useState('');
  const [setRolesModal, showRolesModal] = useState(false);

  const onModalRoleChange = (value: string) => {
    setRoleId(value);
  };

  const openRolesModal = () => {
    showRolesModal(true);
  };
  const closeRolesModal = () => {
    showRolesModal(false);
  };

  return {
    closeRolesModal,
    setRolesModal,
    onModalRoleChange,
    selectedRoleId,
    openRolesModal,
  };
};
