import { useTranslation } from 'react-i18next';

import ModalWrapper from '../ModalWrapper';

type Props = {
  open: boolean;
  close: () => void;
  handleDelete: () => void;
};

const DeleteFromGroup = ({ open, close, handleDelete }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      open={open}
      close={close}
      action={handleDelete}
      title={t('groups.delete_from_organization')}
      subtitle={t('groups.delete_from_organization_description')}
    />
  );
};

export default DeleteFromGroup;
