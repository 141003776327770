import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { BoardLanguage } from 'types/createAssessmentTypes';

import { ReactComponent as Globe } from 'assets/images/globe.svg';

import styles from './Languages.module.scss';

const Languages = () => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === BoardLanguage.En) i18n.changeLanguage(BoardLanguage.Es);
    else i18n.changeLanguage(BoardLanguage.En);
  };

  return (
    <button className={styles.Languages} onClick={changeLanguage}>
      <Globe className={styles.Icon} />
      <div className={styles.CurrentLanguage}>
        <div className={classNames({ [styles.Active]: i18n.language === BoardLanguage.En })}>en</div>/
        <div className={classNames({ [styles.Active]: i18n.language === BoardLanguage.Es })}>es</div>
      </div>
    </button>
  );
};

export default Languages;
