import { ContainerBoardElement, Element } from 'types/boardElements';

export const findSiblingElements = (elements: Element[], parentId: string) => {
  return elements.filter((element) => element.parentId === parentId);
};
export const findChildElements = (elements: Element[], parentId: string) => {
  const childElements: Element[] = [];

  const searchChildren = (parentId: string) => {
    for (const element of elements) {
      if (element.parentId === parentId) {
        childElements.push(element);
        searchChildren(element.id);
      }
    }
  };

  searchChildren(parentId);

  return childElements;
};
export const convertHierarchyIntoFlat = (hierarchy: Element[]) => {
  const flat: Element[] = [];

  const traverse = (element: Element, parentId?: string) => {
    const newNode = {
      ...element,
      parentId: parentId || 'root',
    } as Element;

    if ((element as ContainerBoardElement).children) {
      (newNode as ContainerBoardElement).children = [];

      for (const child of (element as ContainerBoardElement).children) {
        traverse(child, newNode.id);
      }
    }

    flat.push(newNode);
    return newNode.id;
  };

  for (const element of hierarchy) {
    traverse(element);
  }

  return flat;
};
export const convertFlatIntoHierarchy = (flat: Element[]) => {
  const hierarchy: Element[] = [];
  const hashTable = Object.create(null);

  flat.forEach((aData) => {
    hashTable[aData.id] = { ...aData };

    if ((aData as ContainerBoardElement).children) {
      hashTable[aData.id].children = [];
    }
  });

  flat.forEach((aData) => {
    if (aData.parentId === 'root') {
      hierarchy.push(hashTable[aData.id]);
    } else {
      hashTable[aData.parentId].children.push(hashTable[aData.id]);
    }
  });

  return hierarchy;
};
