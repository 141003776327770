export const DescendingSortIcon = () => {
  return (
    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.35987 5.43749L3.37668 7.60107L3.37668 0.409091C3.37668 0.183156 3.20879 0 3.0017 0C2.7946 0 2.62671 0.183156 2.62671 0.409091L2.62672 7.60476L0.640135 5.43748C0.493695 5.27772 0.25627 5.27772 0.10983 5.43748C-0.0366101 5.59724 -0.0366101 5.85626 0.10983 6.01602L2.72726 8.87153L2.73475 8.87993C2.76309 8.91086 2.79485 8.9358 2.82869 8.95475C2.88242 8.98494 2.94144 9.00002 3.00045 9L3.0017 9C3.11706 9 3.22026 8.94316 3.28905 8.85376L5.89017 6.01603C6.03661 5.85627 6.03661 5.59725 5.89017 5.43749C5.74373 5.27773 5.50631 5.27773 5.35987 5.43749Z"
        fill="#A6B4C1"
      />
    </svg>
  );
};
