import { Tag as RSTag, TagProps } from 'rsuite';

import './Tag.scss';

export const Tag = ({ children, ...props }: TagProps) => {
  return (
    <RSTag className="custom-tag" {...props}>
      {children}
    </RSTag>
  );
};
