import classNames from 'classnames';

import Checkbox from 'components/Inputs/Checkbox/Checkbox';

import styles from '../../AssessmentSettingTab.module.scss';

export const ItemRow = ({ name, onClick, checked }: { name: string; onClick: () => void; checked: boolean }) => {
  return (
    <div className={classNames(styles.UnitRow, checked && styles.Selected)} onClick={onClick}>
      <div className="GlobalCheckbox">
        <Checkbox checked={checked} />
      </div>

      <div className={styles.UnitName}>{name}</div>
    </div>
  );
};
