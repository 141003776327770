import React, { PropsWithChildren, Ref } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null;

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLButtonElement>>,
  ) => (
    <button
      {...props}
      ref={ref as React.RefObject<HTMLButtonElement>}
      className={classNames(className, styles.button, { [styles.active]: active })}
    />
  ),
);

export const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <div
      {...props}
      ref={ref as React.RefObject<HTMLDivElement>}
      className={classNames(className, styles.menu, { [styles.Disabled]: props.disabled })}
    />
  ),
);

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <Menu {...props} ref={ref as React.RefObject<HTMLDivElement>} className={classNames(className, styles.toolbar)} />
  ),
);
