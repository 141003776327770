import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import startCase from 'lodash/startCase';

import ChartDistribution from './components/ChartDistribution/ChartDistribution';
import ComparisonChartDistribution from './components/ComparisonChartDistribution/ComparisonChartDistribution';

import { roundPercentage } from 'helpers/dashboard';

import { DashboardResult, DashboardResultGroupScurve } from 'types/dashboardTypes';
import { Translations } from 'types/translationTypes';

import styles from './ChartInfo.module.scss';

type Props = {
  data: [DashboardResult | DashboardResultGroupScurve, DashboardResult | DashboardResultGroupScurve | undefined];
  isComparisonMode: boolean;
  isGroupDashboard: boolean;
  isPointUsers: boolean;
};

const ChartInfo = ({ isGroupDashboard, isComparisonMode, isPointUsers, data }: Props) => {
  const { t } = useTranslation();
  const lang = `text_${i18n.language}` as keyof Translations;
  const [assessmentScurveChart1, assessmentScurveChart2] = data;

  return (
    <>
      {isGroupDashboard &&
        (isComparisonMode ? (
          <div className={styles.ChartInfoComparisonContainer}>
            {(assessmentScurveChart1 as DashboardResultGroupScurve).sections.map((item, index) => (
              <ComparisonChartDistribution
                key={item.id}
                sections={[item, (assessmentScurveChart2 as DashboardResultGroupScurve)?.sections[index]]}
              />
            ))}
          </div>
        ) : (
          <div className={styles.ChartInfoContainer}>
            {(assessmentScurveChart1 as DashboardResultGroupScurve).sections.map((item) => (
              <ChartDistribution
                key={item.id}
                title={t('dashboard.team_distribution')}
                isPointUsers={isPointUsers}
                subTitle={
                  <>
                    <span>
                      <span>{`${roundPercentage(item.percentage * 100)}%`}&nbsp;</span>
                      <span>{`${t('general.in').toLowerCase()} ${startCase(item.name[lang])}`}</span>
                    </span>
                    <span>
                      <span>{`${item.amount}`}&nbsp;</span>
                      <span>{`${t(item.amount === 1 ? 'general.user' : 'general.users').toLowerCase()}`}</span>
                    </span>
                  </>
                }
              />
            ))}
          </div>
        ))}
    </>
  );
};

export default ChartInfo;
