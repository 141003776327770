import { useTranslation } from 'react-i18next';

import Level from './components/Level/Level';

import { useAppSelector } from 'hooks/redux';

import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';

import { GroupsHierarchyResponse } from 'types/groupHierarchyTypes';

import { ReactComponent as CaretIcon } from 'assets/images/caret.svg';

import styles from './GroupHierarchy.module.scss';

type Props = {
  data: GroupsHierarchyResponse;
  selected?: boolean;
};

const GroupHierarchy = ({ data }: Props) => {
  const { t } = useTranslation();
  const selectedGroup = useAppSelector((state) => state.auth.selected_group);

  const { data: roles } = useGetRolesQuery({ groupId: selectedGroup });

  return (
    <div>
      <div className={styles.ColumnHeaders}>
        <CaretIcon className={styles.Caret} />
        <div className={styles.Path}>#</div>
        <div className={styles.Name}>{t('inputs.name')}</div>
        <div className={styles.Description}>{t('general.description')}</div>
        <div className={styles.Actions}>{t('general.actions')}</div>
      </div>

      <ul className={styles.GroupHierarchy}>
        {data.items.map((item, index) => (
          <Level data={item} roles={roles} key={item.id} index={index} />
        ))}
      </ul>
    </div>
  );
};

export default GroupHierarchy;
