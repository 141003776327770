import { useTranslation } from 'react-i18next';

import { useGetVisualisationsQuery } from 'store/api/visualisationsApi/visualisationsApi';

import { Translations } from 'types/translationTypes';

export const useVisualisationsOptions = () => {
  const { i18n } = useTranslation();

  const { data, isLoading, isSuccess } = useGetVisualisationsQuery();

  const visualisationsOptions = isSuccess
    ? data.s_curve[0].sub_graphs.map((visualisation) => ({
        label: visualisation.name[`text_${i18n.language}` as keyof Translations],
        value: visualisation.id,
      }))
    : [];

  return { visualisationsOptions, isLoading };
};
