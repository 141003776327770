import {
  Element,
  InformationBoardElement,
  LongTextBoardElement,
  ModuleBoardElement,
  MultipleColumnBoardElement,
  NamedElement,
  QuestionSliderBoardElement,
  RootElement,
  SectionBoardElement,
  ShortTextBoardElement,
  SingleColumnBoardElement,
  ThankBoardElement,
  WelcomeBoardElement,
} from 'types/boardElements';
import { BaseOptionElement, CARD_TYPES } from 'types/createAssessmentTypes';

import { findChildElements } from './elements';

function isCalculationFieldInvalid(value: string) {
  const parsedValue = parseFloat(value);

  return isNaN(parsedValue) || parsedValue < 0;
}

function isElementNameInvalid(element: NamedElement) {
  return !element.text_en || !element.text_es;
}

function isBaseOptionElementInvalid(option: BaseOptionElement) {
  return isElementNameInvalid(option) || isCalculationFieldInvalid(option.score);
}

function welcomePageValidation(element: WelcomeBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }

  return errors;
}

function thankYouPageValidation(element: ThankBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }
  return errors;
}

function containerValidation(element: ModuleBoardElement | SectionBoardElement, elements: Element[]) {
  const children = findChildElements(elements, element.id);
  const errors: string[] = [];

  if (isCalculationFieldInvalid(element.weight)) {
    errors.push('assessment.validation.weight');
  }

  if (!children.length) {
    errors.push('assessment.validation.container_data');
  }

  return errors;
}

function informationValidation(element: InformationBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }

  return errors;
}

function questionSliderValidation(element: QuestionSliderBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }

  if (isCalculationFieldInvalid(element.weight)) {
    errors.push('assessment.validation.weight');
  }

  if (isNaN(element.min_value) || isNaN(element.max_value) || element.min_value >= element.max_value) {
    errors.push('assessment.validation.slider_values');
  }

  return errors;
}

function singleColumnElementValidation(element: SingleColumnBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }

  if (isCalculationFieldInvalid(element.weight)) {
    errors.push('assessment.validation.weight');
  }

  if (!element.options.length) {
    errors.push('assessment.validation.dropdown_data');
  }

  if (element.options.some(isBaseOptionElementInvalid)) {
    errors.push('assessment.validation.dropdown_data_options');
  }

  return errors;
}

function textElementValidation(element: ShortTextBoardElement | LongTextBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }

  if (!element.description_en || !element.description_es) {
    errors.push('assessment.validation.description_text');
  }

  return errors;
}

function multipleColumnElementValidation(element: MultipleColumnBoardElement) {
  const errors: string[] = [];

  if (isElementNameInvalid(element)) {
    errors.push('assessment.validation.question_text');
  }

  if (isCalculationFieldInvalid(element.weight)) {
    errors.push('assessment.validation.weight');
  }

  if (element.leftColumn.some(isBaseOptionElementInvalid) || element.rightColumn.some(isBaseOptionElementInvalid)) {
    errors.push('assessment.validation.choice_data');
  }

  return errors;
}

type ElementValidationFunctionMap = {
  [key in Element['type']]: (element: Extract<Element, { type: key }>, elements: RootElement[]) => string[];
};

const ElementValidationFunctionList: ElementValidationFunctionMap = {
  [CARD_TYPES.welcome_page]: welcomePageValidation,
  [CARD_TYPES.thankyou_page]: thankYouPageValidation,
  [CARD_TYPES.section]: containerValidation,
  [CARD_TYPES.module]: containerValidation,
  [CARD_TYPES.information]: informationValidation,
  [CARD_TYPES.short_text]: textElementValidation,
  [CARD_TYPES.long_text]: textElementValidation,
  [CARD_TYPES.question_slider]: questionSliderValidation,
  [CARD_TYPES.dropdown]: singleColumnElementValidation,
  [CARD_TYPES.likert]: singleColumnElementValidation,
  [CARD_TYPES.single_choice]: multipleColumnElementValidation,
  [CARD_TYPES.multiple_choice]: multipleColumnElementValidation,
};

export function getAssessmentElementErrors(elements: Element[]) {
  const validationErrors: string[] = [];
  const isWelcomePageAdded = Boolean(elements.find((element) => element.type === CARD_TYPES.welcome_page));
  const isThankYouPageAdded = Boolean(elements.find((element) => element.type === CARD_TYPES.thankyou_page));

  if (!isWelcomePageAdded) {
    validationErrors.push('assessment.validation.welcome_required');
  }

  validationErrors.push(
    ...elements
      .flatMap((elementToValidate) => {
        const validationRule = ElementValidationFunctionList[elementToValidate.type] as (
          element: Element,
          elements?: Element[],
        ) => string[];

        return validationRule(elementToValidate, elements);
      })
      .filter((validationError: string) => !!validationError),
  );

  if (!isThankYouPageAdded) {
    validationErrors.push('assessment.validation.thankyou_required');
  }

  return validationErrors;
}
