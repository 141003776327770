import { useMemo } from 'react';

import { useAppSelector } from 'hooks/redux';

import { ILocalizedDescription } from 'types/assessmentTypes';
import { BoardLanguage } from 'types/createAssessmentTypes';
import { Translations } from 'types/translationTypes';

type UseElementTranslate = {
  nameValues: keyof Translations;
  descriptionValues: keyof ILocalizedDescription;
};

export const useElementTranslate = (): UseElementTranslate => {
  const selectedLanguage = useAppSelector((store) => store.createAssessment.selectedLanguage);
  const nameValues = useMemo(() => (selectedLanguage === BoardLanguage.En ? 'text_en' : 'text_es'), [selectedLanguage]);
  const descriptionValues = useMemo(
    () => (selectedLanguage === BoardLanguage.En ? 'description_en' : 'description_es'),
    [selectedLanguage],
  );

  return {
    nameValues,
    descriptionValues,
  };
};
