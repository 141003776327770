import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GetVisualisationsResponse } from 'store/api/visualisationsApi/types';

import { FormElements, IVisualisationFormState } from 'types/createAssessmentTypes';
import { Translations } from 'types/translationTypes';
import { IResultsMappingVisualisation } from 'types/visualisationTypes';

export const useFormBuilder = (visualisations?: GetVisualisationsResponse) => {
  const { i18n } = useTranslation();
  const { s_curve = [], accelerants = [], ecosystem = [] } = visualisations || {};

  const buildFormData = useCallback(
    ({ id, name }: IResultsMappingVisualisation, size: FormElements, formState: IVisualisationFormState) => {
      const label = name[`text_${i18n.language}` as keyof Translations];

      formState.fieldNames[id] = label;

      return {
        id,
        label,
        size,
      };
    },
    [i18n.language],
  );

  const buildFormMarkup = useCallback(
    (
      visualisations: IResultsMappingVisualisation[],
      parentElementSize: FormElements,
      childElementSize: FormElements,
    ) => {
      const state = { fieldNames: {} };

      const markup = visualisations.map((visualisation) => {
        return {
          ...buildFormData(visualisation, parentElementSize, state),
          children: visualisation?.sub_graphs?.map((subVisualisation) =>
            buildFormData(subVisualisation, childElementSize, state),
          ),
        };
      });

      return { markup, state };
    },
    [buildFormData],
  );

  const scurveFormData = useMemo(
    () => buildFormMarkup(s_curve, FormElements.Large, FormElements.Small),
    [buildFormMarkup, s_curve],
  );
  const accelerantsFormData = useMemo(
    () => buildFormMarkup(accelerants, FormElements.Large, FormElements.Medium),
    [buildFormMarkup, accelerants],
  );
  const ecosystemFormData = useMemo(
    () => buildFormMarkup(ecosystem, FormElements.Large, FormElements.Medium),
    [buildFormMarkup, ecosystem],
  );

  return {
    scurveFormData,
    accelerantsFormData,
    ecosystemFormData,
  };
};
