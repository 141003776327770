import { useTranslation } from 'react-i18next';

import styles from './CustomNoRowsOverlay.module.scss';

type Props = {
  text?: string;
};
const CustomNoRowsOverlay = ({ text }: Props) => {
  const { t } = useTranslation();

  return <div className={styles.customNoRowsOverlay}>{text || t('general.no_items')}</div>;
};

export default CustomNoRowsOverlay;
