import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { ButtonFill, ButtonSize } from 'types/enums';

import BoardInput from '../BoardElements/BoardInput/BoardInput';

import styles from '../BoardElements/BoardElements.module.scss';

type Props = {
  weight?: string;
  onChange?: (value: string) => void;
};

const BottomCardNavPanel = ({ weight, onChange = () => {} }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.CardBottomLine)}>
      <div style={{ width: '107px' }}>
        <ButtonStyled fill={ButtonFill.Outlined} fullWidth size={ButtonSize.Small}>
          {t('general.previous')}
        </ButtonStyled>
      </div>

      {onChange && weight !== undefined && (
        <BoardInput
          placeholder={t('assessment.question_weight')}
          value={weight}
          onChange={(e) => onChange(e.target.value)}
        />
      )}

      <div style={{ width: '107px' }}>
        <ButtonStyled fill={ButtonFill.Contained} fullWidth size={ButtonSize.Small}>
          {t('general.next')}
        </ButtonStyled>
      </div>
    </div>
  );
};

export default BottomCardNavPanel;
