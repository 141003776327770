import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Box, Collapse, Stack } from '@mui/material';
import i18n from 'i18next';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DashboardTooltip from 'components/Dashboard/components/DashboardTooltip/DashboardTooltip';
import Loader from 'components/Loader/Loader';
import PointUsersComparisonModal from 'components/Modals/PointUsersComparisonModal/PointUsersComparisonModal';
import Swiper from 'components/Swiper/Swiper';

import Chart from './components/Chart/Chart';
import ChartTooltipOutlet from './components/Chart/ChartTooltip/ChartTooltipOutlet';
import ChartInfo from './components/Chart/components/ChartInfo/ChartInfo';
import ChartDistribution from './components/Chart/components/ChartInfo/components/ChartDistribution/ChartDistribution';
import LegendBottom from './components/LegendBottom/LegendBottom';
import LegendTop, { LegendTopItem } from './components/LegendTop/LegendTop';
import PointUsers from './components/PointUsers/PointUsers';

import useGetMappingPerSection from 'hooks/useGetMappingPerSection';

import { roundPercentage } from 'helpers/dashboard';

import { MOBILE_BREAKPOINT } from 'constants/';

import {
  DashboardResult,
  DashboardResultGroupScurve,
  DashboardType,
  IDashboardTooltipGroup,
  SelectedGroups,
  TooltipType,
  VisualisationTypes,
} from 'types/dashboardTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { Translations } from 'types/translationTypes';

import { ReactComponent as QuestionMark } from 'assets/images/question-mark.svg';

import pageStyles from '../../Dashboard.module.scss';
import styles from './SCurve.module.scss';

type SCurveProps = {
  requestId1: string;
  requestId2?: string;
  dashboardType: DashboardType;
  selectedGroups?: SelectedGroups;
};

const SCurve = ({ requestId1, requestId2 = '', dashboardType, selectedGroups }: SCurveProps) => {
  const { t } = useTranslation();
  const lang = `text_${i18n.language}` as keyof Translations;
  const [isChildChartsOpen, setChildChartsOpen] = useState(false);
  const isGroupDashboard = dashboardType === DashboardType.GROUP;
  const isComparisonMode = !!requestId2;
  const [currentPoints, setCurrentPoints] = useState<
    [IDashboardTooltipGroup | null, IDashboardTooltipGroup | null] | null
  >(null);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const [swipeableIndex, setSwipeableIndex] = useState(0);

  const {
    mappingsCompletion: assessmentScurveChartData1,
    mappingsCompletion2: assessmentScurveChartData2,
    isLoadingCompletion: isLoadingMappingsSCurveLeft,
    isLoadingCompletion2: isLoadingMappingsSCurveRight,
    isFetchingCompletion: isFetchingMappingsSCurveLeft,
    isFetchingCompletion2: isFetchingMappingsSCurveRight,
  } = useGetMappingPerSection(requestId1, requestId2, dashboardType, VisualisationTypes.SCurve);

  if (!assessmentScurveChartData1) return null;

  const [assessmentScurveChart1] = assessmentScurveChartData1;
  let assessmentScurveChart2: DashboardResult | undefined;

  if (assessmentScurveChartData2) {
    [assessmentScurveChart2] = assessmentScurveChartData2;
  }

  if (
    isLoadingMappingsSCurveLeft ||
    isFetchingMappingsSCurveLeft ||
    isLoadingMappingsSCurveRight ||
    isFetchingMappingsSCurveRight
  ) {
    return (
      <div className={pageStyles.Section}>
        <h3 className={pageStyles.SectionTitle}>{t('dashboard.scurve.title')}</h3>

        <Box m={4}>
          <Loader flexCenter size="md" />
        </Box>
      </div>
    );
  }

  if (!assessmentScurveChart1) return null;

  const mainTopLegendItems = assessmentScurveChart1.sections.map((section) => (
    <DashboardTooltip
      id={section.id}
      type={TooltipType.Section}
      visualisationType={VisualisationTypes.SCurve}
      content={section.description[lang]}
      placement={isMobile ? 'auto' : 'bottom'}
    >
      <LegendTopItem>
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>{section.name[lang]}</span> <QuestionMark />
        </Stack>
      </LegendTopItem>
    </DashboardTooltip>
  ));

  return (
    <div className={pageStyles.Section}>
      <h3 className={pageStyles.SectionTitle}>{t('dashboard.scurve.title')}</h3>

      <div className={pageStyles.SectionContent}>
        <div className={styles.ScurveContainer}>
          <div className={styles.ChartWrapper}>
            <Chart
              variant={isMobile ? 'child' : 'main'}
              data={[assessmentScurveChart1, assessmentScurveChart2]}
              dashboardType={dashboardType}
              isComparisonMode={isComparisonMode}
              selectedGroups={isComparisonMode ? selectedGroups : undefined}
              setCurrentPoints={setCurrentPoints}
              chartInfo={
                <ChartInfo
                  data={[assessmentScurveChart1, assessmentScurveChart2]}
                  isGroupDashboard={isGroupDashboard}
                  isComparisonMode={isComparisonMode}
                  isPointUsers={!!currentPoints}
                />
              }
              legendTop={<LegendTop items={mainTopLegendItems} />}
              legendBottom={isComparisonMode ? <LegendBottom dashboardType={dashboardType} direction="row" /> : null}
            />
            {!isComparisonMode && isGroupDashboard && (
              <div className={styles.DistributionContainer}>
                {(assessmentScurveChart1 as DashboardResultGroupScurve).sections.map((section) => (
                  <ChartDistribution
                    key={section.id}
                    title={`${roundPercentage(section.perfect_percentage * 100)}%\n${t('general.in').toLowerCase()} ${
                      section.name[lang]
                    }`}
                    subTitle={[t('dashboard.ideal_distribution')]}
                    section={section}
                    isPointUsers={!!currentPoints}
                  />
                ))}
              </div>
            )}
          </div>

          <PointUsersComparisonModal
            open={!!(currentPoints?.[0] && currentPoints?.[1])}
            close={() => setCurrentPoints(null)}
            pointsUsers={
              currentPoints?.[0] && currentPoints?.[1] ? [currentPoints[0].users, currentPoints[1].users] : null
            }
            selectedGroups={selectedGroups}
          />

          {!isMobile && isGroupDashboard && !isComparisonMode && currentPoints?.[0] && (
            <PointUsers users={currentPoints[0].users} onCancel={() => setCurrentPoints(null)} />
          )}
        </div>

        {assessmentScurveChart1.sub_graphs && (
          <div className={styles.ChildCharts}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <h3 className={styles.Title}>{t('dashboard.scurve.subgraphs_title')}</h3>

              <ButtonStyled
                size={ButtonSize.Small}
                fill={ButtonFill.Outlined}
                onClick={() => setChildChartsOpen((prevState) => !prevState)}
              >
                {isChildChartsOpen ? t('general.close') : t('general.details')}
              </ButtonStyled>
            </Stack>

            <Collapse in={isChildChartsOpen} unmountOnExit>
              <Box pt={1}>
                {isMobile ? (
                  <div className={styles.MobileChildChartsContainer}>
                    <Swiper index={swipeableIndex} onChangeIndex={(index) => setSwipeableIndex(index)}>
                      {assessmentScurveChart1.sub_graphs.map((chart, index) => (
                        <div className={styles.ChartWrapper} key={index}>
                          <Chart
                            variant="child"
                            data={[chart, assessmentScurveChart2?.sub_graphs?.[index]]}
                            legendTop={
                              <LegendTop
                                items={[
                                  <LegendTopItem>
                                    {isComparisonMode
                                      ? `${t('dashboard.scurve.responsibility')} ${index + 1}`
                                      : chart.name[lang]}
                                  </LegendTopItem>,
                                ]}
                              />
                            }
                            dashboardType={dashboardType}
                            legendBottom={
                              isComparisonMode ? (
                                <LegendBottom dashboardType={dashboardType} direction="column" />
                              ) : null
                            }
                            chartTooltipOutletId="mobile-chart-tooltip"
                          />
                        </div>
                      ))}
                    </Swiper>
                    <ChartTooltipOutlet id="mobile-chart-tooltip" />
                  </div>
                ) : (
                  <Stack direction="row" spacing={1}>
                    {assessmentScurveChart1.sub_graphs.map((chart, index) => (
                      <div className={styles.ChartWrapper} key={index}>
                        <Chart
                          variant="child"
                          data={[chart, assessmentScurveChart2?.sub_graphs?.[index]]}
                          legendTop={
                            <LegendTop
                              items={[
                                <LegendTopItem>
                                  {isComparisonMode
                                    ? `${t('dashboard.scurve.responsibility')} ${index + 1}`
                                    : chart.name[lang]}
                                </LegendTopItem>,
                              ]}
                            />
                          }
                          dashboardType={dashboardType}
                          legendBottom={
                            isComparisonMode ? <LegendBottom dashboardType={dashboardType} direction="column" /> : null
                          }
                        />
                      </div>
                    ))}
                  </Stack>
                )}
              </Box>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  );
};

export default SCurve;
