import { Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import i18n from 'i18next';

import { MOBILE_BREAKPOINT } from 'constants/';

import {
  DashboardResult,
  DashboardResultGroupScurve,
  DashboardType,
  IDashboardTooltip,
  IDashboardTooltipComparison,
  IDashboardTooltipGroup,
  IPoint,
  PointColor,
  SelectedGroups,
} from 'types/dashboardTypes';
import { Translations } from 'types/translationTypes';

import ChartTooltip from './ChartTooltip/ChartTooltip';

import styles from './Chart.module.scss';

const charts = {
  main: {
    viewBox: '0 0 1046 294',
    mask: 'M558 145.956C644 56.1606 671.5 29.3841 1019 25.4565C621.5 19.3841 627.374 74.3841 534.5 161.384C441.626 248.384 358.5 267.884 21 270.456C411.5 277.384 472 235.752 558 145.956Z',
    filler:
      'M22.821 271.226C419.706 273.207 456.821 238.226 550.821 150.726C644.821 63.2263 635.206 29.2074 1020.82 25.2261',
  },

  child: {
    viewBox: '0 0 300 270',
    mask: 'M159 130.508C189 14.5154 204 10 292 10C218 16 195 1.0163 162 130.508C129 260 89.5 259 10 259C99 259 129 246.501 159 130.508Z',
    filler: 'M10 259C93 259 126.5 256 160.5 131.5C194.5 7.00003 208.5 14 292 9.99998',
  },
};

const MIN_RADIUS_SIZE = 4;
const MAX_RADIUS_SIZE = 16;
const MIN_STROKE_SIZE = 1;
const MAX_STROKE_SIZE = 6;

const getPointTooltips = (
  scurve: DashboardResult | DashboardResultGroupScurve,
  dashboardType: DashboardType,
): IDashboardTooltip[] | IDashboardTooltipGroup[] => {
  const lang = `text_${i18n.language}` as keyof Translations;

  if (dashboardType === DashboardType.GROUP) {
    return (scurve as DashboardResultGroupScurve).sections
      .flatMap((section) => section.points)
      .sort((a, b) => a.cutoff_value - b.cutoff_value)
      .map((point) => ({
        id: point.id,
        title: point.name[lang],
        content: point.description[lang],
        percentage: point.percentage,
        amount: point.amount,
        users: point.users?.map((item) => ({
          id: item.id,
          firstName: item.first_name,
          lastName: item.last_name,
        })),
      }));
  } else {
    return (scurve as DashboardResult).sections
      .flatMap((section) => section.points)
      .sort((a, b) => a.cutoff_value - b.cutoff_value)
      .map((point) => ({
        id: point.id,
        title: point.name[lang],
        content: point.description[lang],
      }));
  }
};

export const getSCurveValue = (cutoffs: number[], value: number): number => {
  for (let i = cutoffs.length - 1; i >= 0; i--) {
    if (cutoffs[i] <= value) {
      return i + 1;
    }
  }
  return 1;
};

type ChartProps = {
  variant: 'main' | 'child';
  data: [DashboardResult | DashboardResultGroupScurve, DashboardResult | DashboardResultGroupScurve | undefined];
  legendTop: ReactNode;
  legendBottom?: ReactNode;
  chartInfo?: ReactNode;
  isComparisonMode?: boolean;
  dashboardType: DashboardType;
  selectedGroups?: SelectedGroups;
  setCurrentPoints?: Dispatch<SetStateAction<[IDashboardTooltipGroup | null, IDashboardTooltipGroup | null] | null>>;
  chartTooltipOutletId?: string;
};

const Chart = ({
  variant,
  data,
  legendTop,
  legendBottom,
  chartInfo,
  dashboardType,
  isComparisonMode,
  selectedGroups,
  setCurrentPoints,
  chartTooltipOutletId,
}: ChartProps) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const [tooltipData, setTooltipData] = useState<
    IDashboardTooltip | [IDashboardTooltipComparison, IDashboardTooltipComparison] | null
  >(null);
  const [tooltipCoords, setTooltipCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [currentPointIndex, setCurrentPointIndex] = useState<number | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const pointsWrapperRef = useRef<SVGGElement>(null);
  const combinedPointRef = useRef<SVGGElement>(null);
  const valuePoint1Ref = useRef<SVGCircleElement>(null);
  const valuePoint2Ref = useRef<SVGCircleElement>(null);

  const chart = charts[variant];
  const id = nanoid();

  const isGroupDashboard = dashboardType === DashboardType.GROUP;
  const isUserDashboard = dashboardType === DashboardType.USER;
  const isPointsUsersComparison = isGroupDashboard && isComparisonMode;

  const [assessment1, assessment2] = data;
  const cutoffs = assessment1.sections.flatMap((section) => section.points.map((point) => point.cutoff_value));

  let value1: number | undefined;
  let value2: number | undefined;

  if (isUserDashboard) {
    value1 = getSCurveValue(cutoffs, assessment1?.value);

    if (assessment2) {
      value2 = getSCurveValue(cutoffs, assessment2?.value);
    }
  }

  const pointTooltips = getPointTooltips(assessment1, dashboardType);

  const handleTooltipData = (containerElement: HTMLElement, pointElement: SVGElement, pointIndex: number) => {
    const containerElementRect = containerElement.getBoundingClientRect();
    const pointElementRect = pointElement.getBoundingClientRect();
    let x = pointElementRect.left - containerElementRect.left;
    const y = pointElementRect.top - containerElementRect.top + pointElementRect.height;
    setCurrentPointIndex(pointIndex);

    if (isComparisonMode && isGroupDashboard && assessment2) {
      const pointTooltips2 = getPointTooltips(assessment2, dashboardType);

      setTooltipData([pointTooltips[pointIndex], pointTooltips2[pointIndex]] as [
        IDashboardTooltipComparison,
        IDashboardTooltipComparison,
      ]);
    } else {
      setTooltipData(pointTooltips[pointIndex]);
    }

    const TOOLTIP_WIDTH = isMobile ? 192 : 260;

    if (pointElementRect.left + TOOLTIP_WIDTH > document.body.clientWidth) {
      x = x - TOOLTIP_WIDTH;
    }

    setTooltipCoords({ x, y });
  };

  const handleHideTooltip = () => {
    setTooltipData(null);
  };

  const handleDetails = () => {
    handleHideTooltip();
    handleCurrentPoints();
  };

  const handleCurrentPoints = () => {
    if (setCurrentPoints && currentPointIndex !== null && currentPointIndex >= 0) {
      const pointTooltip = pointTooltips[currentPointIndex] as IDashboardTooltipGroup;

      if (isComparisonMode && isGroupDashboard && assessment2) {
        const pointTooltips2 = getPointTooltips(assessment2, dashboardType);
        setCurrentPoints([
          pointTooltips[currentPointIndex] as IDashboardTooltipGroup,
          pointTooltips2[currentPointIndex] as IDashboardTooltipGroup,
        ]);

        return;
      }

      if (pointTooltip.amount) {
        setCurrentPoints([pointTooltip, null]);
      } else {
        setCurrentPoints(null);
      }
    }
  };

  const renderPoint = ({ id, index, pointIndex, radius, stroke, color, isComparisonPoint }: IPoint) => {
    return (
      <circle
        r={radius}
        cx={0}
        cy={0}
        onClick={
          isGroupDashboard && isComparisonMode
            ? () => {
                if (!containerRef.current || !pointsWrapperRef.current) return;
                handleTooltipData(
                  containerRef.current,
                  pointsWrapperRef.current.children[pointIndex || index] as SVGCircleElement,
                  index,
                );
              }
            : () => pointIndex !== undefined && handleCurrentPoints()
        }
        className={classNames(
          styles.Point,
          styles.LevelPoint,
          styles[`${isComparisonPoint ? 'LevelPointComparison' : 'LevelPoint'}${index + 1}`],
          styles[color],
          {
            [styles.Hidden]: isUserDashboard && (value1 === index + 1 || value2 === index + 1),
          },
        )}
        style={{ strokeWidth: stroke }}
        key={id}
        onMouseEnter={
          !isPointsUsersComparison
            ? () => {
                if (!containerRef.current || !pointsWrapperRef.current) return;
                handleTooltipData(
                  containerRef.current,
                  pointsWrapperRef.current.children[pointIndex || index] as SVGCircleElement,
                  index,
                );
              }
            : undefined
        }
        onMouseLeave={!isPointsUsersComparison ? handleHideTooltip : undefined}
      />
    );
  };

  const renderPoints = () => {
    if (isGroupDashboard) {
      return (assessment1 as DashboardResultGroupScurve).sections
        .flatMap((section) => section.points)
        .reduce((acc, point, index) => {
          const assessment2Point = (assessment2 as DashboardResultGroupScurve)?.sections.flatMap(
            (section) => section.points,
          )[index];

          const radius1 = MIN_RADIUS_SIZE + point.percentage * (MAX_RADIUS_SIZE - MIN_RADIUS_SIZE);
          let radius2 = MIN_RADIUS_SIZE;
          const stroke1 = MIN_STROKE_SIZE + point.percentage * (MAX_STROKE_SIZE - MIN_STROKE_SIZE);
          let stroke2 = MIN_STROKE_SIZE;
          const pointIndex = isComparisonMode ? index * 2 : index;

          if (isComparisonMode) {
            radius2 = MIN_RADIUS_SIZE + assessment2Point.percentage * (MAX_RADIUS_SIZE - MIN_RADIUS_SIZE);
            stroke2 = MIN_STROKE_SIZE + assessment2Point.percentage * (MAX_STROKE_SIZE - MIN_STROKE_SIZE);

            acc.push(
              renderPoint({
                radius: radius2,
                stroke: stroke2,
                id: point.id + 1,
                index,
                pointIndex,
                color: PointColor.Yellow,
                isComparisonPoint: true,
              }),
            );
          }

          acc.push(
            renderPoint({
              radius: radius1,
              stroke: stroke1,
              id: point.id + 2,
              index,
              pointIndex,
              color: PointColor.Green,
            }),
          );

          return acc;
        }, [] as ReactNode[]);
    }

    return (assessment1 as DashboardResult).sections
      .flatMap((section) => section.points)
      .map((point, index) => {
        const radius = 5;
        const stroke = 0;
        return renderPoint({ radius, stroke, id: point.id, index, color: PointColor.Green });
      });
  };

  return (
    <>
      {legendTop}

      <div className={styles.ChartWrapper} ref={containerRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={chart.viewBox}
          className={classNames(styles.Chart, variant === 'main' ? styles.Main : styles.Child)}
        >
          <line x1="33.3333%" y1="0" x2="33.3333%" y2="100%" stroke="#e6ebf0" />
          <line x1="66.6666%" y1="0" x2="66.6666%" y2="100%" stroke="#e6ebf0" />

          <g id={`paths-${id}`}>
            <clipPath id={`mask-${id}`}>
              <path d={chart.mask} />
            </clipPath>

            <path clipPath={`url(#mask-${id})`} className={styles.Path} d={chart.filler} />

            {isUserDashboard && (
              <>
                {value2 && (
                  <path
                    clipPath={`url(#mask-${id})`}
                    className={classNames(styles.Path, styles.Assessment2Path, styles[`Level${value2}`])}
                    d={chart.filler}
                  />
                )}

                <path
                  clipPath={`url(#mask-${id})`}
                  className={classNames(styles.Path, styles.Assessment1Path, styles[`Level${value1}`])}
                  d={chart.filler}
                />
              </>
            )}
          </g>

          <g id={`level-points-${id}`} ref={pointsWrapperRef}>
            {renderPoints()}
          </g>

          {!isGroupDashboard && (
            <g id={`value-points-${id}`}>
              {value1 === value2 ? (
                <g
                  ref={combinedPointRef}
                  className={classNames(
                    styles.Point,
                    styles.AssessmentValue1,
                    styles.Combined,
                    styles[`ValuePoint${value1}`],
                  )}
                  onMouseEnter={() => {
                    if (!containerRef.current || !combinedPointRef.current || !value1) return;

                    handleTooltipData(containerRef.current, combinedPointRef.current, value1 - 1);
                  }}
                  onMouseLeave={handleHideTooltip}
                >
                  <path d="M12 24C18.5 24 24 18.5 24 12C24 5.5 18.5 1.27261e-06 12 0L12 12L12 24Z" fill="#EAF0C0" />
                  <path
                    d="M12 0C5.5 -7.7941e-08 5.71395e-07 5.5 0 12C-5.71395e-07 18.5 5.5 24 12 24L12 0Z"
                    fill="#D3EFF1"
                  />
                  <path d="M12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4L12 12L12 20Z" fill="#CBE139" />
                  <path d="M12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20L12 12V4Z" fill="#1899A2" />
                </g>
              ) : (
                <>
                  <circle
                    ref={valuePoint1Ref}
                    cx={0}
                    cy={0}
                    r={8 + 4 / 2}
                    className={classNames(styles.Point, styles.AssessmentValue1, styles[`ValuePoint${value1}`])}
                    onMouseEnter={() => {
                      if (!containerRef.current || !valuePoint1Ref.current || !value1) return;

                      handleTooltipData(containerRef.current, valuePoint1Ref.current, value1 - 1);
                    }}
                    onMouseLeave={handleHideTooltip}
                  />

                  {value2 && (
                    <circle
                      ref={valuePoint2Ref}
                      cx={0}
                      cy={0}
                      r={8 + 4 / 2}
                      className={classNames(styles.Point, styles.AssessmentValue2, styles[`ValuePoint${value2}`])}
                      onMouseEnter={() => {
                        if (!containerRef.current || !valuePoint2Ref.current || !value2) return;

                        handleTooltipData(containerRef.current, valuePoint2Ref.current, value2 - 1);
                      }}
                      onMouseLeave={handleHideTooltip}
                    />
                  )}
                </>
              )}
            </g>
          )}
        </svg>

        {chartInfo}

        {tooltipData && (
          <ChartTooltip
            isGroupDashboard={isGroupDashboard}
            isComparisonMode={isComparisonMode}
            selectedGroups={selectedGroups}
            tooltipData={tooltipData}
            handleDetails={handleDetails}
            isPointsUsersComparison={isPointsUsersComparison}
            handleHideTooltip={handleHideTooltip}
            tooltipCoords={tooltipCoords}
            chartTooltipOutletId={chartTooltipOutletId}
          />
        )}
      </div>

      {legendBottom}
    </>
  );
};

export default Chart;
