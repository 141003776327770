import { ReactNode } from 'react';

import classNames from 'classnames';

import { CardColor } from 'types/enums';

import styles from './CardInfo.module.scss';

type Props = {
  children: ReactNode;
  color?: CardColor;
};

const CardInfo = ({ children, color = CardColor.Blue }: Props) => {
  const styledColor = {
    [CardColor.Yellow]: styles.Yellow,
    [CardColor.Blue]: styles.Blue,
    [CardColor.White]: styles.White,
  }[color];

  return (
    <div
      className={classNames(styles.InfoContainer, {
        [styledColor]: styledColor,
      })}
    >
      {children}
    </div>
  );
};

export default CardInfo;
