import GroupComparisonModal from './components/GroupComparisonModal/GroupComparisonModal';
import OwnComparisonModal from './components/OwnComparisonModal/OwnComparisonModal';

import { useAppSelector } from 'hooks/redux';

import { GetDashboardGroupsResponse } from 'store/api/assessmentApi/types';

import { ComparisonType } from 'types/assessmentTypes';

type Props = {
  open: boolean;
  close: () => void;
  groups?: GetDashboardGroupsResponse;
  isLoadingGroups: boolean;
};

const DashboardComparisonModal = ({ open, close, groups, isLoadingGroups }: Props) => {
  const { comparisonType } = useAppSelector((state) => state.permissions);

  if (comparisonType === ComparisonType.Own) {
    return <OwnComparisonModal open={open} close={close} />;
  }

  if ([ComparisonType.IndividualUser, ComparisonType.InGroup].includes(comparisonType as ComparisonType)) {
    return <GroupComparisonModal open={open} close={close} groups={groups} isLoadingGroups={isLoadingGroups} />;
  }

  return <></>;
};

export default DashboardComparisonModal;
