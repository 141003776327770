import { Collapse, Grid } from '@mui/material';

import { VisualisationTypes } from 'types/dashboardTypes';
import { IEcosystem } from 'types/ecosystemTypes';
import { Size } from 'types/enums';

import DashboardTooltip from '../../../DashboardTooltip/DashboardTooltip';
import DoughnutChart from '../DoughnutChart/DoughnutChart';

import styles from './ChartWrapper.module.scss';

type ChartWrapperProps = {
  ecosystem: IEcosystem;
  showSubGraphs: boolean;
};

const ChartWrapper = ({ ecosystem, showSubGraphs }: ChartWrapperProps) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Chart}>
        <DoughnutChart size={showSubGraphs ? Size.Md : Size.Lg} value={ecosystem.value} color={ecosystem.color} />
      </div>

      <Collapse in={showSubGraphs} unmountOnExit>
        <Grid container spacing={2} mt={1}>
          {ecosystem.subGraphs?.map((item) => {
            const { id, title, value, color, tooltip } = item;

            return (
              <Grid xs item key={id}>
                <div className={styles.SubChart}>
                  <div className={styles.DoughnutContainer}>
                    <DoughnutChart size={Size.Sm} value={value} color={color} />

                    {tooltip && (
                      <div className={styles.Tooltip}>
                        <DashboardTooltip
                          id={tooltip.id}
                          visualisationType={VisualisationTypes.Ecosystem}
                          title={tooltip.title}
                          content={tooltip.content}
                          placement="autoHorizontalEnd"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <h3 className={styles.Title}>{title}</h3>
              </Grid>
            );
          })}
        </Grid>
      </Collapse>
    </div>
  );
};

export default ChartWrapper;
