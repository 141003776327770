import { useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import classNames from 'classnames';

import { ReactComponent as EyeOff } from 'assets/images/eye-off.svg';
import { ReactComponent as EyeOn } from 'assets/images/eye-on.svg';

import InputLabel from '../InputLabel/InputLabel';

import styles from '../Inputs.module.scss';

type Props = {
  label: string;
  register?: UseFormRegisterReturn;
  placeholder?: string;
  required?: boolean;
  error_message?: string;
  error?: {
    invalid: boolean;
    isDirty: boolean;
    isTouched: boolean;
    error?: FieldError | undefined;
  };
};

const Password = ({ label, register, placeholder, error, error_message }: Props) => {
  const [show, toggle] = useState<boolean>(false);

  const eyeStyles = classNames(styles.PasswordVisible, error?.isDirty && error?.invalid && styles.InvalidIcon);

  const inputStyles = classNames(
    styles.Input,
    styles.Password,
    error?.invalid && styles.Invalid,
    error?.isDirty && !error.invalid && styles.Valid,
  );

  return (
    <div className={styles.CustomInput}>
      <InputLabel errorMessage={error_message} label={label} />

      <input
        className={inputStyles}
        type={show ? 'text' : 'password'}
        {...register}
        placeholder={placeholder}
        autoComplete="new-password"
      />
      <div className={eyeStyles} onClick={() => toggle(!show)}>
        {show ? <EyeOn /> : <EyeOff />}
      </div>
    </div>
  );
};

export default Password;
