import { useEffect, useState } from 'react';

import classNames from 'classnames';

import { InformationBoardElement } from 'types/boardElements';
import { OnElementDelete, TitleFieldsChange } from 'types/createAssessmentTypes';

import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';
import FileUploadBuilder from '../FileUploadBuilder/FileUploadBuilder';

import styles from '../BoardElements.module.scss';

type Props = {
  element: InformationBoardElement;
  onTitleFieldsChange: TitleFieldsChange;
  onElementDelete: OnElementDelete;
};

const Information = ({ onElementDelete, onTitleFieldsChange, element }: Props) => {
  const [imageURL, setImageURL] = useState<string>(element.image || '');

  useEffect(() => {
    onTitleFieldsChange(element.id, 'image', imageURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageURL]);

  return (
    <div className={classNames(styles.CardContainer, styles.ShortTextEntry)}>
      <div className={classNames(styles.InnerBody, styles.Information)}>
        <CardTitleBlock
          id={element.id}
          descriptionRequired
          onDelete={onElementDelete}
          element={element}
          onTitleChange={onTitleFieldsChange}
        />
      </div>

      <div className={styles.UploadContainer}>
        <FileUploadBuilder imageURL={imageURL} setImageURL={setImageURL} />
      </div>

      <BottomCardNavPanel />
    </div>
  );
};

export default Information;
