import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import Tabs from 'components/Tabs/Tabs';

import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';
import { IGroupHierarchyItem, IGroupsHierarchyUser } from 'types/groupHierarchyTypes';
import { MobileCardType, MobileCardVariant, MobileCardsData } from 'types/mobileTypes';

type Props = {
  data: {
    children: IGroupHierarchyItem[];
    users: IGroupsHierarchyUser[];
    path: {
      path: string;
    };
  };
  setMobileGroups: Dispatch<SetStateAction<IGroupHierarchyItem[] | null>>;
  mobileGroups: IGroupHierarchyItem[] | null;
};

enum Tab {
  Groups,
  Users,
}

const MobileCardsHierarchy = ({ data, setMobileGroups, mobileGroups }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const [activeTab, setActiveTab] = useState(data?.children?.length ? Tab.Groups : Tab.Users);

  useEffect(() => {
    setActiveTab(data.children.length ? Tab.Groups : Tab.Users);
  }, [data]);

  const mobileCardsDataGroups = useMemo<MobileCardsData>(
    () =>
      data.children.map((item) => ({
        row: [
          {
            columns: [
              {
                type: MobileCardType.Info,
                label: t('groups.table.name'),
                value: item.name,
                key: item.id + 1,
              },
              {
                type: MobileCardType.Ordinal,
                label: '#',
                value: item.path_in_tree,
                key: item.id + 2,
              },
            ],
            key: item.id + 1,
          },
          {
            columns: [
              {
                type: MobileCardType.Info,
                label: t('groups.table.creator'),
                value: item?.creator ? `${item.creator.first_name} ${item.creator.last_name}` : null,
                key: item.id + 1,
              },
            ],
            key: item.id + 2,
          },
          {
            columns: [
              {
                type: MobileCardType.Info,
                label: t('groups.table.description'),
                value: item.description || '',
                key: item.id + 1,
              },
            ],
            key: item.id + 3,
          },
          {
            columns: [
              {
                type: MobileCardType.Actions,
                actions: [
                  item.children.length || item.users.length ? (
                    <ButtonStyled
                      key="open"
                      onClick={() => setMobileGroups((prev) => [...(prev || []), item])}
                      fill={ButtonFill.Transparent}
                      size={isMobile ? ButtonSize.Link : ButtonSize.Small}
                    >
                      {t('general.open')}
                    </ButtonStyled>
                  ) : null,
                ],
                key: item.id + 1,
              },
            ],
            key: item.id + 4,
          },
        ],
        key: item.id,
      })),
    [data.children, isMobile, setMobileGroups, t],
  );

  const mobileCardsDataUsers = useMemo<MobileCardsData>(
    () =>
      data.users.map((item, index) => ({
        row: [
          {
            columns: [
              {
                type: MobileCardType.Info,
                label: t('assessment.table.name'),
                value: `${item.first_name || ''} ${item.last_name || ''}`.trim(),
                key: item.id + 1,
              },
              {
                type: MobileCardType.Ordinal,
                label: '#',
                value: index + 1,
                key: item.id + 2,
              },
            ],
            key: item.id + 1,
          },
        ],
        key: item.id,
      })),
    [data.users, t],
  );

  const tabs = [
    { title: mobileGroups?.length === 1 ? t('general.groups') : t('general.subgroups') },
    { title: t('general.users') },
  ];

  return (
    <>
      {mobileGroups && <Tabs tabs={tabs} activeTab={activeTab} changeTab={setActiveTab} underline fullWidth />}

      {activeTab === Tab.Groups && (
        <>
          {mobileCardsDataGroups?.length ? (
            <MobileCards
              data={mobileCardsDataGroups}
              variant={
                !mobileGroups
                  ? MobileCardVariant.Organization
                  : mobileGroups?.length === 1
                  ? MobileCardVariant.Group
                  : MobileCardVariant.Subgroup
              }
            />
          ) : (
            <NothingFoundText text={t('groups.table.empty_result')} />
          )}
        </>
      )}
      {activeTab === Tab.Users && (
        <>
          {mobileCardsDataUsers?.length ? (
            <MobileCards data={mobileCardsDataUsers} variant={MobileCardVariant.User} />
          ) : (
            <NothingFoundText text={t('users.table.empty_result')} />
          )}
        </>
      )}
    </>
  );
};

export default MobileCardsHierarchy;
