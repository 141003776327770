import { Dispatch, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Stack } from 'rsuite';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { useAppDispatch } from 'hooks/redux';

import {
  addMappings,
  enableSaveButton,
  setResultsMappingVerificationStatus,
} from 'store/features/createAssessmentSlice';

import { IMappingsFormData, IVisualisationFormData } from 'types/createAssessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';

import { ErrorMessage, MappingForm } from '..';

import styles from './CreateMappingForm.module.scss';

type Props = {
  formData: IVisualisationFormData;
  handlePreviousStep?: () => void;
  handleNextStep?: () => void;
  setIsCreating?: Dispatch<SetStateAction<boolean>>;
  currentStep: number;
  mappingsFormData: IMappingsFormData;
  setMappingsFormData: Dispatch<SetStateAction<IMappingsFormData>>;
};

export const CreateMappingForm = ({
  formData: {
    state: { fieldNames },
    markup,
  },
  handleNextStep,
  handlePreviousStep,
  setIsCreating,
  currentStep,
  mappingsFormData,
  setMappingsFormData,
}: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Record<string, string[]>>({ defaultValues: mappingsFormData[currentStep] });
  const dispatch = useAppDispatch();

  useEffect(() => {
    const subscription = watch((value) => setMappingsFormData((prevState) => ({ ...prevState, [currentStep]: value })));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const onSubmit: SubmitHandler<Record<string, string[]>> = (mappingData) => {
    dispatch(addMappings(mappingData));
    if (handleNextStep) {
      handleNextStep();
      return;
    }

    dispatch(setResultsMappingVerificationStatus(true));
    dispatch(enableSaveButton(true));
    setIsCreating?.(false);
  };

  return (
    <div className={classNames(styles.FormContainer)}>
      {!!Object.keys(errors).length && (
        <ErrorMessage
          title={t('assessment.validation.mapping_required_general')}
          errorFields={Object.keys(errors).map((error) => fieldNames[error])}
        />
      )}
      <MappingForm markup={markup} control={control} />
      <Stack className={styles.ActionButtons} justifyContent={handlePreviousStep ? 'space-between' : 'flex-end'}>
        {handlePreviousStep && (
          <ButtonStyled fill={ButtonFill.Outlined} size={ButtonSize.Small} onClick={handlePreviousStep}>
            {t('general.back')}
          </ButtonStyled>
        )}
        <ButtonStyled fill={ButtonFill.Contained} size={ButtonSize.Small} onClick={handleSubmit(onSubmit)}>
          {t('general.next')}
        </ButtonStyled>
      </Stack>
    </div>
  );
};
