import { useTranslation } from 'react-i18next';

import ModalWrapper from '../ModalWrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

const LeaveAssessmentTakeModal = ({ open, handleClose, handleConfirm, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      subtitle={t('modals.leave_diagnostic_take')}
      action={handleConfirm}
      actionTitle={t('general.exit')}
      open={open}
      close={handleClose}
      cancelTitle={t('general.back')}
      attention
    />
  );
};

export default LeaveAssessmentTakeModal;
