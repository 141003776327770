import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import startCase from 'lodash/startCase';

import ComparisonChartInfoItem from './components/ComparisonChartInfoItem/ComparisonChartInfoItem';

import { roundPercentage } from 'helpers/dashboard';

import { CardVariants, IDashboardResultSectionGroupScurve } from 'types/dashboardTypes';
import { Translations } from 'types/translationTypes';

import styles from './ComparisonChartDistribution.module.scss';

type Props = {
  sections: [IDashboardResultSectionGroupScurve | undefined, IDashboardResultSectionGroupScurve];
};

const ComparisonChartDistribution = ({ sections }: Props) => {
  const { t } = useTranslation();
  const lang = `text_${i18n.language}` as keyof Translations;
  const [leftSection, rightSection] = sections;

  return (
    <div className={styles.Container}>
      {leftSection && (
        <ComparisonChartInfoItem
          title={t('dashboard.team_distribution')}
          subTitle={[
            `${roundPercentage(leftSection.percentage * 100)}% ${t('general.in').toLowerCase()} ${startCase(
              leftSection.name[lang],
            )}`,
            `${leftSection.amount} ${t('general.users').toLowerCase()}`,
          ]}
          color={CardVariants.Blue}
        />
      )}
      {rightSection && (
        <ComparisonChartInfoItem
          title={t('dashboard.team_distribution')}
          subTitle={[
            `${roundPercentage(rightSection.percentage * 100)}% ${t('general.in').toLowerCase()} ${startCase(
              rightSection.name[lang],
            )}`,
            `${rightSection.amount} ${t('general.users').toLowerCase()}`,
          ]}
          color={CardVariants.Yellow}
        />
      )}
    </div>
  );
};

export default ComparisonChartDistribution;
