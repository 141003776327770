import classNames from 'classnames';

import { useAppSelector } from 'hooks/redux';
import { useHideScroll } from 'hooks/useHideScroll';

import styles from './HamburgerMenu.module.scss';

type Props = {
  onClick: () => {};
};

const HamburgerMenu = ({ onClick }: Props) => {
  useHideScroll();
  const isMobileMenuOpen = useAppSelector((state) => state.navigation.isMobileMenuOpen);

  return (
    <div onClick={onClick} className={classNames(styles.Hamburger, { [styles.Active]: isMobileMenuOpen })}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default HamburgerMenu;
