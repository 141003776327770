import classNames from 'classnames';

import { CardColor } from 'types/enums';

import styles from './LegendLine.module.scss';

type LegendLineProps = {
  color: CardColor;
};

const LegendLine = ({ color }: LegendLineProps) => {
  return (
    <div className={styles.Container}>
      <div
        className={classNames(styles.Line, {
          [styles.Blue]: color === CardColor.Blue,
          [styles.Yellow]: color === CardColor.Yellow,
        })}
      ></div>
    </div>
  );
};

export default LegendLine;
