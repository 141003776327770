import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { changeBoardLanguage } from 'store/features/createAssessmentSlice';

import { BoardLanguage } from 'types/createAssessmentTypes';

import styles from '../../AssessmentCreate.module.scss';

const LanguageSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedLanguage = useAppSelector((store) => store.createAssessment.selectedLanguage);

  const onLanguageSelect = (language: BoardLanguage) => {
    dispatch(changeBoardLanguage(language));
  };

  return (
    <div className={styles.LanguageSelector}>
      <p>{t('assessment.language_selector.current_language')}</p>
      <button
        onClick={() => onLanguageSelect(BoardLanguage.En)}
        className={classNames({ [styles.SelectedLang]: selectedLanguage === BoardLanguage.En })}
      >
        {t('assessment.language_selector.english')}
      </button>
      <button
        onClick={() => onLanguageSelect(BoardLanguage.Es)}
        className={classNames({ [styles.SelectedLang]: selectedLanguage === BoardLanguage.Es })}
      >
        {t('assessment.language_selector.spanish')}
      </button>
    </div>
  );
};

export default LanguageSelector;
