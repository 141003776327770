import { useTranslation } from 'react-i18next';

import PointUsersComparison from './components/PointUsersComparison/PointUsersComparison';

import { CardVariants } from 'types/assessmentTypes';
import { PointUser, SelectedGroups } from 'types/dashboardTypes';
import { ModalProps, ModalSize } from 'types/modalTypes';

import ModalWrapper from '../ModalWrapper';

import styles from './PointUsersComparisonModal.module.scss';

interface Props extends ModalProps {
  close: () => void;
  pointsUsers: [PointUser[], PointUser[]] | null;
  selectedGroups: SelectedGroups | undefined;
}

const PointUsersComparisonModal = ({ pointsUsers, selectedGroups, ...props }: Props) => {
  const { t } = useTranslation();

  const [leftUsers = [], rightUsers = []] = pointsUsers || [];
  const [leftGroup, rightGroup] = selectedGroups || [];

  return (
    <ModalWrapper
      title={t('modals.team_distribution_comparison')}
      subtitle={t('modals.team_distribution_comparison_description')}
      modalWrapperClassName={styles.ModalWrapper}
      modalSpacing={ModalSize.NoSpacing}
      {...props}
    >
      <div className={styles.Wrapper}>
        <PointUsersComparison users={leftUsers} groupName={leftGroup?.name || ''} />
        <PointUsersComparison users={rightUsers} groupName={rightGroup?.name || ''} variant={CardVariants.Yellow} />
      </div>
    </ModalWrapper>
  );
};

export default PointUsersComparisonModal;
