import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Element } from './boardElements';
import { CardTypes } from './createAssessmentTypes';
import { SortTypes } from './enums';
import { GroupHierarchyType } from './groupHierarchyTypes';
import { Translations } from './translationTypes';

export enum ComparisonType {
  Own = 'own_comparison',
  InGroup = 'group_dashboard_comparison',
  IndividualUser = 'other_users_dashboard_comparison',
}

export interface GetAssessmentById {
  id: string;
  groupId?: string;
}

export type TakeType = Exclude<CardTypes, 'module' | 'section'>;

export interface ITakeElement extends Translations, ILocalizedDescription {
  general_order: number;
  id: string;
  order: number;
  section_id: string;
  type: TakeType;
}

export type TakeElementAnswer = string | string[] | number;

export interface TakeElementWithAnswer extends ITakeElement {
  answer: TakeElementAnswer;
}

export interface Unit {
  id: string;
  title: string;
  assessments: IAssessment[];
  created_at: string;
  updated_at: string;
}

export interface IUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  organization: string;
  unit: string;
  role: string;
  photo: string;
}

export interface IGroup {
  id: string;
  name: string;
  description: string;
  type: GroupHierarchyType;
}

export interface IAssessment {
  id: string;
  title: string;
  description: string;
  image: string;
  created_at: Date;
  updated_at: Date;
  creator_id: string;
  creator_full_name: string;
  users: IUser[];
  groups: IGroup[];
  units: {
    id: string;
    title: string;
    created_at: Date;
    updated_at: Date;
  }[];
  is_assigned_to_current_user: boolean;
}

export interface DefaultAssessment {
  updated_at: string;
  title: string;
  created_at: string;
  description: string;
  image: string;
  is_deleted: boolean;
  creator_full_name: string;
  is_global: boolean;
  new_version_id: any;
  is_default: boolean;
  assessment_family_id: string;
  creator_id: string;
  id: string;
  organization_id: string;
}

export interface SearchAssessments {
  items: IAssessment[];
  total: number;
  page: number;
  size: number;
}

export interface AssessmentHierarchy {
  id: string;
  assessment_name: string;
  assessment_description: string;
  default_assessment: DefaultAssessment;
  show_context: boolean;
  users: ComparisonListUser[];
  groups: IGroup[];
  units: Unit[];
  children: Element[];
  type: string;
  mappings: IResultsMapping[];
  responsibility_mappings: IQuestionsMapping[];
  assessment_image: string;
}

export interface ISection {
  id: string;
  title: string;
  results: ISectionResult[];
}

export interface ISectionResult {
  id: string;
  score: number;
}

export interface ICompletionsItem {
  title: string;
  id: string;
  assessments: ICompletionsAssessment[];
}

export interface ICompletionsAssessment {
  id: string;
  title: string;
  description: string;
  creator: string;
  updated_at: string;
  created_at: string;
  creator_full_name: string;
  users: ICompletionsAssessmentUsers[];
  groups: ICompletionsAssessmentGroups[];
  last_assessment_take_date: string;
  last_assessment_take_id: string;
  last_assessment_take_user_id: string;
}

export interface ICompletionsAssessmentUsers {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  organization: null;
  unit: null;
  role: string;
  photo: null;
}

export interface ICompletionsAssessmentGroups {
  id: string;
  name: string;
  description: string;
}

export interface CompletionDetails {
  id: string;
  user_id: string;
  assessment_id: string;
  assessment_name: string;
  attempt_date: string;
  assessment_description: string;
}

export interface ComparisonList {
  completed_assessment_id: string;
  assessment_name: string;
  attempt_date: string;
}

export interface ComparisonListGroup {
  assessment_id: string;
  completed_assessment_id: string;
  assessment_name: string;
  date_from: string;
  date_to: string;
}

export interface ComparisonListUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  group_accesses: { organization: string; unit: string }[];
  role: string;
  photo: null | string;
}

export interface ILocalizedDescription {
  description_en: string;
  description_es: string;
}

export enum CardVariants {
  Yellow,
}

export interface IAssessmentInfo {
  id: string;
  assessment_name: string;
  assessment_description: string;
  attempt_date: string;
  assigned_to: string;
  group?: string;
}

export interface IComparisonMatch {
  left: IComparisonResult;
  right: IComparisonResult;
}

export interface IComparisonNonMatch {
  left: IComparisonResult[];
  right: IComparisonResult[];
}

export interface IComparisonResult {
  id: string;
  order: number;
  section_name: string;
  definition_id: string;
  assessment_id: string;
  parent_id: string;
  answers: IAnswer[];
  score: number;
}

export interface IAnswer {
  question_text_en: string;
  question_text_es: string;
  answer_text: string;
}

export enum BlockNames {
  User = 'user',
  Group = 'group',
  AssessmentTake = 'assessmentTake',
}

export enum MenuOptions {
  MyResults,
  Users,
  Groups,
}

export interface IMenuOption {
  value: MenuOptions;
  label: string;
}

export interface IRadioValues {
  [key: string]: { [key: string]: { value: string; title: string } };
}

export type HandleChangeRadioValueType = (
  event: ChangeEvent<HTMLInputElement>,
  name: string,
  title: string,
  order: number,
) => void;

export enum TagType {
  Organization,
  Group,
  SubGroup,
  Name,
}

export interface IAssessmentListItem {
  id: string;
  name: string;
  attemptDate: Date | null;
  assignedTo: string[];
  description?: string;
}

export interface ISection {
  title: string;
  answers: (string | number)[];
}

export interface Unit {
  id: string;
  title: string;
  total: number;
  assessments: IAssessment[];
}

export interface ITableProps {
  sort: ISort;
  search: string;
  page: number;
  size: number;
  isFetching: boolean;
  isLoading: boolean;
  onPageChange: (page: number) => void;
}

export interface ISort {
  sortBy: string;
  sortType: SortTypes;
}

export enum AssessmentListTab {
  LIST,
  RESULT,
}

export type OutletProps = {
  tableProps: ITableProps;
  activeTab: AssessmentListTab;
  changeTab: Dispatch<SetStateAction<number>>;
};

export interface IResultsMapping {
  visualisation_id: string;
  section_definition_ids: string[];
}

export interface IQuestionsMapping {
  visualisation_id: string;
  question_id: string;
}
