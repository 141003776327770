import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'assets/images/create-assessment-edit.svg';

import styles from './ImagePreview.module.scss';

type Props = {
  imageURL: string;
  onEdit?: () => void;
};

const ImagePreview = ({ imageURL, onEdit }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.PreviewContainer} onClick={onEdit}>
      <img src={imageURL} alt="Info graphic" />
      {onEdit && (
        <div className={styles.EditContainer}>
          <EditIcon />
          <span>{t('general.edit_image')}</span>
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
