import { IGroupHierarchyItem } from '../types/groupHierarchyTypes';

type Cascade = {
  value: string;
  label: string;
  children?: Cascade[];
};

export const transformCascade = (data: IGroupHierarchyItem) => {
  const obj: Cascade = { value: data?.id, label: data?.name };
  if (data?.children && data?.children.length > 0) obj.children = data?.children.map((item) => transformCascade(item));
  return obj;
};
