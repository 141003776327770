import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { setIsLoading, setPermissions, setRole } from 'store/features/userPermissionsSlice';

import { baseAuthQueryWithReauth } from '../../helpers';

import {
  CreateRolePermissionsPayload,
  CreateRolePermissionsResponse,
  FetchUserPermissionsPayload,
  FetchUserPermissionsResponse,
  GetRolePermissionsPayload,
  GetRolePermissionsResponse,
  PermissionsOverwritePayload,
  PermissionsOverwriteResponse,
} from './types';

import { groupsApi } from '../groupsApi/groupsApi';
import { rolesApi } from '../rolesApi/rolesApi';

export const permissionsApi = createApi({
  tagTypes: ['Permissions'],
  reducerPath: 'permissionsApi',
  baseQuery: baseAuthQueryWithReauth,
  endpoints: (builder) => ({
    getRolePermissions: builder.query<GetRolePermissionsResponse, GetRolePermissionsPayload>({
      query: (id) => ({
        url: '/permissions/',
        params: {
          role_id: id,
        },
      }),
      providesTags: () => ['Permissions'],
    }),

    createRolePermissions: builder.mutation<CreateRolePermissionsResponse, CreateRolePermissionsPayload>({
      query: (role) => ({
        url: '/permissions/',
        method: 'POST',
        body: role,
      }),
      invalidatesTags: ['Permissions'],
    }),

    permissionsOverwrite: builder.mutation<PermissionsOverwriteResponse, PermissionsOverwritePayload>({
      query: (permissions) => ({
        url: '/permissions/overwrite/',
        method: 'POST',
        body: permissions,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(rolesApi.util.invalidateTags(['RolePermissions']));
          dispatch(groupsApi.util.invalidateTags(['GroupPermissions']));
        } catch {}
      },
      invalidatesTags: ['Permissions'],
    }),

    fetchUserPermissions: builder.query<FetchUserPermissionsResponse, FetchUserPermissionsPayload>({
      query: (payload) => ({
        method: 'GET',
        url: `/roles-user/${payload.id}/`,
        params: {
          group_id: payload.group_id,
        },
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setIsLoading(true));
          const { data } = await queryFulfilled;
          dispatch(setRole(data.items?.[0]?.role_name) || '');
          dispatch(setPermissions(data.items));
          dispatch(groupsApi.util.invalidateTags(['AssessmentAssignHierarchy']));
          dispatch(setIsLoading(false));
        } catch (e) {}
      },
    }),
  }),
});

export const { useGetRolePermissionsQuery, useLazyFetchUserPermissionsQuery, useFetchUserPermissionsQuery } =
  permissionsApi;

export const { useCreateRolePermissionsMutation, usePermissionsOverwriteMutation } = permissionsApi;
