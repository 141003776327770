import { ReactNode, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';

import classNames from 'classnames';

import DeleteButton from 'components/Buttons/DeleteButton/DeleteButton';

import { ContainerBoardElement } from 'types/boardElements';
import { ContainerType } from 'types/createAssessmentTypes';
import { Size } from 'types/enums';

import { ReactComponent as AccordionArrow } from 'assets/images/accordion-arrow.svg';

import './Accordion.scss';

type Props = {
  dragRef: ConnectDragSource;
  container: ContainerBoardElement;
  children: ReactNode;
  opacity: number;
  onDelete: () => void;
};

const Accordion = ({ dragRef, container, children, opacity, onDelete }: Props) => {
  const [open, toggle] = useState(true);

  return (
    <div
      style={{ opacity }}
      className={classNames('Assessment_accordion', `level_${container.level}`, { hidden: !open })}
    >
      <div onClick={() => toggle(!open)} className="Accordion_header" ref={dragRef}>
        <div className="Accordion_title_wrapper">
          <div className="Accordion_title">
            <AccordionArrow className={classNames('Arrow', { rotated: open })} />

            {container.name}
          </div>

          <DeleteButton
            size={container.type === ContainerType.Section ? Size.Lg : Size.Md}
            clear={onDelete}
          ></DeleteButton>
        </div>
      </div>

      <div className={classNames('Accordion_body', { hidden: !open })}>{children}</div>
    </div>
  );
};

export default Accordion;
