import { IAssessment, Unit } from '../../types/assessmentTypes';

export const mapAssessmentsByUnits = (data: Unit[] | undefined, key: string) => {
  const result =
    data && Array.isArray(data)
      ? (data?.reduce(
          (
            acc: {
              [key: string]: {
                id: string;
                items: IAssessment[];
              };
            },
            item: Unit,
          ) => {
            if (item.assessments.length) {
              return {
                ...acc,
                [item[key as keyof object]]: {
                  id: item.id,
                  items: [...(acc[item[key as keyof object]]?.items || []), ...item.assessments],
                },
              };
            }
            return acc;
          },
          {},
        ) as { [key: string]: { items: IAssessment[]; id: string } })
      : null;
  return result;
};
