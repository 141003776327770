import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Eula from 'components/Eula/Eula';
import PasswordInput from 'components/Inputs/Password/Password';
import TextInput from 'components/Inputs/Text/Text';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Title from 'components/Title/Title';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useSignInMutation } from 'store/api/authApi/authApi';

import ROUTES from 'router/routes';

import { SignInResolver } from 'helpers/validations';

import { ButtonFill } from 'types/enums';
import { isServerErrorWithTranslation, isValidationServerError } from 'types/errorTypes';

import styles from './SignIn.module.scss';

type FormValues = {
  email: string;
  password: string;
};

const SignIn = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuth, loading } = useAppSelector((state) => state.auth);
  const dashboard = useAppSelector((state) => state.navigation.dashboard);

  const { register, handleSubmit, formState, getFieldState } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(SignInResolver),
  });

  const [signIn, { isLoading }] = useSignInMutation();

  useEffect(() => {
    if (!isAuth) return;

    const redirectLink = (state?.from?.pathname || '') + (state?.from?.search || '');

    if (redirectLink) {
      navigate(redirectLink);
    } else {
      navigate(dashboard);
    }
  }, [navigate, isAuth, dashboard, state?.from]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await signIn({
        email: data.email.trim(),
        password: data.password,
      }).unwrap();
    } catch (error) {
      if (isValidationServerError(error) && error.data.detail[0].type === 'value_error.email') {
        toast.error(t('validation.email_invalid'));
        return;
      }

      if (isServerErrorWithTranslation(error)) {
        toast.error(t(error.data.detail.error));
      }
    }
  };

  useDocumentTitle([t('page_titles.sign_in')]);

  return (
    <div className={styles.Container}>
      <ShadowBlock>
        <div className={styles.SignIn}>
          <Title title={t('general.sign_in')} description={t('general.sign_in_description')} />

          <form className={styles.SignInForm} onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label={t('inputs.email')}
              placeholder={t('inputs.email_placeholder')}
              register={register('email')}
              error={getFieldState('email', formState)}
              error_message={t(formState?.errors.email?.message || '')}
            />

            <PasswordInput
              label={t('inputs.password')}
              placeholder={t('inputs.password_placeholder')}
              register={register('password')}
              error={getFieldState('password', formState)}
              error_message={t(formState?.errors.password?.message || '')}
            />

            <Link to={ROUTES.FORGOT_PASSWORD} className={styles.ForgotLink}>
              {t('general.forgot_password')}?
            </Link>

            <Eula i18nKey="sign_in.eula">
              By continuing, you agree to Disruption Advisors'{' '}
              <a
                href="https://thedisruptionadvisors.com/wp-content/uploads/S-Curve-Insight-Tool-End-User-License-Agreement_231109.pdf"
                target="_blank"
                rel="noreferrer"
              >
                End Users Licensing Agreement
              </a>{' '}
              (EÚLA) and{' '}
              <a
                href="https://thedisruptionadvisors.com/wp-content/uploads/DA-Privacy-Policy-231109.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Eula>

            <ButtonStyled fullWidth clickType="submit" fill={ButtonFill.Contained} loading={isLoading || loading}>
              {t('general.sign_in')}
            </ButtonStyled>
          </form>
        </div>
      </ShadowBlock>
    </div>
  );
};

export default SignIn;
