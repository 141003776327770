import { Fragment } from 'react';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Main from './components/Main/Main';

import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';

type Props = {
  children?: React.ReactNode;
};

const PageLayout = ({ children }: Props) => {
  const auth = useAuth();
  const isLoadingPermissions = useAppSelector((state) => state.permissions.isLoading);
  const isLoggedIn = !!auth.user && !isLoadingPermissions;

  return (
    <Fragment>
      <Header />
      <Main showMenu={isLoggedIn} content={children} />
      <Footer />
    </Fragment>
  );
};

export default PageLayout;
