import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { Pagination } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import orderBy from 'lodash/orderBy';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Loader from 'components/Loader/Loader';
import CompareAssessmentModal from 'components/Modals/CompareAssessmentModal/CompareAssessmentModal';
import EditUnitModal from 'components/Modals/EditUnitModal/EditUnitModal';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';

import AccordionCustom from '../AssessmentListItem/components/AccordionCustom';
import AssignedToCell from '../Table/components/AssignedToCell/AssignedToCell';
import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';
import useTableControl from 'hooks/useTableControl';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { formatDate } from 'helpers/date';
import { getCurrentPageItems } from 'helpers/pagination';
import { getFieldId } from 'helpers/table';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ITableProps } from 'types/assessmentTypes';
import { ICompletionsAssessment } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize, SortTypes } from 'types/enums';
import { MobileCardType, MobileCardsData } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Compare } from 'assets/images/compare.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import Table from '../Table/Table';

import styles from '../AssessmentListItem/AssessmentListItem.module.scss';

type Props = {
  tableProps: ITableProps;
  unitId?: string;
  unitTitle?: string;
  total?: number;
  assessments: ICompletionsAssessment[];
};

type DataTable = {
  id: string;
  assessmentId: string;
  lastAssessmentTakeId: string;
  lastAssessmentTakeUserId: string;
  fieldId: number;
  name: string;
  creator: string;
  description: string;
  assignedTo: string[];
  attemptDate: Date | null;
};

const AssessmentResultItem = ({ tableProps, unitId, unitTitle, total, assessments }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { unit, own_results, group_dashboard_comparison, other_users_dashboard_comparison, own_comparison } =
    useAppSelector((state) => state.permissions.permissions);
  const { page, onPageChange } = useTableControl('title');
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const [openEditUnitModal, toggleEditUnitModal] = useState(false);
  const [openAssessmentCompareModal, toggleAssessmentCompareModal] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState<ICompletionsAssessment | null>(null);

  const isSearch = Boolean(tableProps.search);

  const pageTableProps = {
    ...tableProps,
    page,
    onPageChange,
  };

  const handleCloseEditUnitModal = () => toggleEditUnitModal(false);
  const handleCloseAssessmentCompareModal = () => toggleAssessmentCompareModal(false);

  const onRowClick = ({ row: { lastAssessmentTakeId } }: GridRowParams) => {
    navigate(ROUTES.DIAGNOSTIC_COMPLETION(lastAssessmentTakeId));
  };

  const getActions = useCallback(
    (row: DataTable) => {
      const actions: ReactNode[] = [];

      if (isMobile) {
        actions.push(
          <ButtonStyled
            key="open"
            fill={ButtonFill.Transparent}
            size={ButtonSize.Link}
            onClick={() => navigate(ROUTES.DIAGNOSTIC_COMPLETION(row.lastAssessmentTakeId))}
          >
            {t('general.open')}
          </ButtonStyled>,
        );
      }

      if (
        !isMobile &&
        (checkPermission(own_comparison, [PermissionsAction.READ]) ||
          checkPermission(group_dashboard_comparison, [PermissionsAction.READ]) ||
          checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ]))
      ) {
        actions.push(
          <ButtonStyled
            key="compare"
            onClick={(e) => {
              e.stopPropagation();
              const currentAssessment = assessments.find((item) => item.id === row.assessmentId);
              if (currentAssessment) {
                setCurrentAssessment(currentAssessment);
              }
              toggleAssessmentCompareModal(true);
            }}
            fill={ButtonFill.Transparent}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            icon={<Compare />}
          >
            {t('general.compare')}
          </ButtonStyled>,
        );
      }

      return actions;
    },
    [assessments, group_dashboard_comparison, other_users_dashboard_comparison, isMobile, navigate, own_comparison, t],
  );

  const columns = useMemo<GridColDef<DataTable>[]>(
    () => [
      { field: 'fieldId', headerName: '#', sortable: false, width: 30 },
      {
        field: 'name',
        headerName: t('assessment.table.name'),
        sortable: false,
        width: 150,
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('assessment.table.description'),
        width: 200,
        sortable: false,
        flex: 1,
        valueFormatter: (data) => data.value || ' - ',
      },
      {
        field: 'attemptDate',
        headerName: t('assessment.table.attempt_date'),
        type: 'date',
        width: 100,
        sortable: false,
        flex: 1,
        valueFormatter: (data) => (data.value ? formatDate(data.value) : ' - '),
      },
      {
        field: 'creator',
        headerName: t('assessment.table.creator'),
        width: 150,
        sortable: false,
        flex: 1,
        valueFormatter: (data) => data.value || ' - ',
      },
      {
        field: 'assignedTo',
        headerName: t('assessment.table.assigned_to'),
        width: 150,
        sortable: false,
        valueFormatter: (data) => data.value[0] || t('general.not_assigned'),
        renderCell: (params) => <AssignedToCell value={params.value} formattedValue={params.formattedValue} />,
      },
      {
        field: 'actions',
        headerName: t('assessment.table.actions'),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => getActions(params.row),
      },
    ],
    [getActions, t],
  );

  const dataTable = assessments.map((item, index) => {
    const {
      id,
      last_assessment_take_date,
      last_assessment_take_id,
      last_assessment_take_user_id,
      title,
      description,
      creator_full_name,
      groups,
      users,
    } = item;

    const assignedToGroups = groups.map((item) => item.name);
    const assignedToUsers = users.map((item) => `${item.first_name} ${item.last_name}`);

    return {
      id: id + index,
      assessmentId: id,
      lastAssessmentTakeId: last_assessment_take_id,
      lastAssessmentTakeUserId: last_assessment_take_user_id,
      fieldId: index + 1,
      name: title,
      creator: creator_full_name,
      description: description,
      assignedTo: [...assignedToGroups, ...assignedToUsers],
      attemptDate: last_assessment_take_date ? new Date(last_assessment_take_date) : null,
    };
  });

  const mobileCardsData = useMemo<MobileCardsData>(
    () =>
      isMobile && dataTable
        ? orderBy(
            dataTable,
            ['name'],
            [tableProps.sort.sortType === SortTypes.ASC ? SortTypes.ASC : SortTypes.DESC],
          ).map((item, index) => ({
            row: [
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.name'),
                    value: item.name,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Ordinal,
                    label: '#',
                    value: !!tableProps.search
                      ? getFieldId({ page: tableProps.page, size: 12, index, total: dataTable.length })
                      : index + 1,
                    key: item.id + 2,
                  },
                ],
                key: item.id + 1,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.description'),
                    value: item.description,
                    key: item.id + 1,
                  },
                ],
                key: item.id + 2,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.attempt_date'),
                    value: item.attemptDate ? formatDate(item.attemptDate.toString()) : ' - ',
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Info,
                    label: t('assessment.table.assigned_to'),
                    value: (
                      <AssignedToCell
                        value={item.assignedTo}
                        formattedValue={item.assignedTo[0] || t('general.not_assigned')}
                      />
                    ),
                    key: item.id + 2,
                  },
                ],
                key: item.id + 3,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Actions,
                    actions: getActions(item),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 4,
              },
            ],
            key: item.id,
          }))
        : [],
    [dataTable, getActions, isMobile, t, tableProps.page, tableProps.search, tableProps.sort.sortType],
  );

  return (
    <Fragment>
      {unitTitle && (
        <EditUnitModal
          open={openEditUnitModal}
          handleClose={handleCloseEditUnitModal}
          unitName={unitTitle}
          unitId={unitId || ''}
        />
      )}
      <CompareAssessmentModal
        open={openAssessmentCompareModal}
        handleClose={handleCloseAssessmentCompareModal}
        completionAssessment={currentAssessment}
      />

      {isSearch ? (
        <>
          {isMobile ? (
            <div className={styles.MobileContainer}>
              {tableProps.isLoading || tableProps.isFetching ? (
                <Loader flexCenter size="md" />
              ) : (
                <>
                  {mobileCardsData?.length && total ? (
                    <>
                      <MobileCards data={mobileCardsData} />
                      <div className={styles.PaginationContainer}>
                        <Pagination
                          color="primary"
                          shape="rounded"
                          page={tableProps.page}
                          count={Math.ceil(total / tableProps.size)}
                          onChange={(_, value) => tableProps.onPageChange(value)}
                        />
                      </div>
                    </>
                  ) : (
                    <NothingFoundText text={t('general.no_items')} />
                  )}
                </>
              )}
            </div>
          ) : (
            <Table assessments={dataTable} columns={columns} onRowClick={onRowClick} total={total} {...tableProps} />
          )}
        </>
      ) : (
        <AccordionCustom
          accordionContent={
            isMobile ? (
              <>
                <MobileCards data={getCurrentPageItems(mobileCardsData, page, pageTableProps.size)} />
                {mobileCardsData.length ? (
                  <div className={styles.PaginationContainer}>
                    <Pagination
                      color="primary"
                      shape="rounded"
                      page={page}
                      count={Math.ceil(dataTable.length / pageTableProps.size)}
                      onChange={(_, value) => onPageChange(value)}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <Table
                assessments={dataTable}
                columns={columns}
                onRowClick={
                  checkPermission(own_results, [PermissionsAction.READ]) ||
                  checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ])
                    ? onRowClick
                    : undefined
                }
                {...pageTableProps}
              />
            )
          }
          accordionSummaryChildren={
            <Fragment>
              <span className={styles.AccordionSummaryChildrenTitleContainer}>
                <span className={styles.AccordionSummaryChildrenTitle}>{unitTitle}</span>
                <span className={styles.AccordionSummaryChildrenCount}>({assessments.length})</span>
              </span>
              {checkPermission(unit, [PermissionsAction.UPDATE]) && (
                <ButtonStyled
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleEditUnitModal(true);
                  }}
                  fill={ButtonFill.Transparent}
                  size={ButtonSize.Small}
                  icon={<Edit />}
                >
                  {t('assessment.edit_unit')}
                </ButtonStyled>
              )}
            </Fragment>
          }
        />
      )}
    </Fragment>
  );
};

export default AssessmentResultItem;
