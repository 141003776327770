import { createApi } from '@reduxjs/toolkit/query/react';

import { permissionsApi } from 'store/api/permissionsApi/permissionsApi';
import { selectGroup } from 'store/features/navigationSlice';

import { baseAuthQueryWithReauth } from '../../helpers';

import { RoleLevel } from 'types/roleTypes';

import {
  GetMyProfilePayload,
  GetMyProfileResponse,
  UpdateProfileWithDataInvalidationPayload,
  UpdateProfileWithDataInvalidationResponse,
  UpdateProfileWithoutDataInvalidationPayload,
  UpdateProfileWithoutDataInvalidationResponse,
} from './types';

import { setAuth, setLoadingUser } from '../../features/authSlice';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  tagTypes: ['Profile'],
  baseQuery: baseAuthQueryWithReauth,
  endpoints: (builder) => ({
    getMyProfile: builder.query<GetMyProfileResponse, GetMyProfilePayload>({
      query: () => '/current-user/',
      providesTags: ['Profile'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(setLoadingUser(true));
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuth(data));
          if (data.groups[0].role_level !== RoleLevel.Nested) {
            dispatch(selectGroup(data.groups[0].group_id));
            dispatch(
              permissionsApi.endpoints.fetchUserPermissions.initiate({
                id: data.id,
                group_id: data.groups[0].group_id,
              }),
            );
          }
        } catch (error) {
          dispatch(setLoadingUser(false));
        }
      },
    }),

    updateProfileWithDataInvalidation: builder.mutation<
      UpdateProfileWithDataInvalidationResponse,
      UpdateProfileWithDataInvalidationPayload
    >({
      query: ({ id, user, groupId }) => ({
        url: `/users/${id}/`,
        method: 'PUT',
        body: user,
        headers: { group_id: groupId },
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          profileApi.util.updateQueryData('getMyProfile', undefined, (draft) => {
            Object.assign(draft, patch.user);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Profile'],
    }),

    updateProfileWithoutDataInvalidation: builder.mutation<
      UpdateProfileWithoutDataInvalidationResponse,
      UpdateProfileWithoutDataInvalidationPayload
    >({
      query: ({ id, user, groupId }) => ({
        url: `/users/${id}/`,
        method: 'PUT',
        body: user,
        headers: { group_id: groupId },
      }),
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useUpdateProfileWithDataInvalidationMutation,
  useUpdateProfileWithoutDataInvalidationMutation,
} = profileApi;
