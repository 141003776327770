import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { IDashboardResultSectionGroupScurve } from 'types/dashboardTypes';

import { ReactComponent as Arrow } from 'assets/images/arrow-sort.svg';

import styles from './ChartDistribution.module.scss';

type Props = {
  title: string;
  subTitle: ReactNode;
  section?: IDashboardResultSectionGroupScurve;
  footer?: boolean;
  isPointUsers: boolean;
};

const ChartDistribution = ({ title, subTitle, section, isPointUsers }: Props) => {
  const { t } = useTranslation();
  const isHigh = section && section?.percentage > section?.perfect_percentage;

  return (
    <div className={styles.ChartInfo}>
      <h3 className={styles.Title}>{title}</h3>

      <div className={classNames(styles.ChartInfoLabel, { [styles.ChartInfoLabelColumn]: isPointUsers })}>
        {subTitle}
      </div>

      {section && section.percentage !== section.perfect_percentage && (
        <div className={styles.ChartDescription}>
          <span className={classNames(styles.ChartDescriptionLabel, { [styles.High]: isHigh })}>
            <Arrow className="arrow-divider" />
            {isHigh ? t('general.high') : t('general.low')}
          </span>
        </div>
      )}
    </div>
  );
};

export default ChartDistribution;
