import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Slider from 'components/Slider/Slider';

import { MOBILE_BREAKPOINT } from 'constants/';

import { QuestionSliderTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

import styles from './QuestionSlider.module.scss';

type Props = {
  element: QuestionSliderTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  onChangeAnswer: (value: number) => void;
};

const SliderWrapper = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  return isMobile ? <div className={styles.SliderContainer}>{children}</div> : children;
};

const QuestionSlider = ({ element, loading, onNext, onPrev, onChangeAnswer }: Props) => {
  const { i18n } = useTranslation();

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{
        loading,
        onNext,
        onPrev,
        disabled: !element.answer || element.answer > element.max_value || element.answer < element.min_value,
      }}
    >
      <input
        className={styles.SliderInput}
        min={element.min_value}
        max={element.max_value}
        type="number"
        value={element.answer || element.min_value}
        onChange={(e) => onChangeAnswer(+e.target.value)}
      />

      <SliderWrapper>
        <Slider
          value={element.answer || element.min_value}
          onChange={(e, value) => onChangeAnswer(value as number)}
          min={element.min_value}
          max={element.max_value}
        />
      </SliderWrapper>
    </ElementWrapper>
  );
};

export default QuestionSlider;
