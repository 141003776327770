import { ComparisonType } from './assessmentTypes';
import { DashboardType } from './dashboardTypes';

export interface Permission {
  id: string;
  entity: string;
  action: string;
  role_id: string;
  group_id: string | null;
}

export enum permissionsEntity {
  ROLE = 'role',
  USER = 'user',
  GROUP = 'group',
  ASSESSMENT = 'assessment',
  MODULE = 'module',
  SECTION = 'section',
  UNIT = 'unit',
  GROUP_DASHBOARD_COMPARISON = 'group_dashboard_comparison',
  OTHER_USERS_DASHBOARD_COMPARISON = 'other_users_dashboard_comparison',
  OWN_COMPARISON = 'own_comparison',
  OWN_RESULTS = 'own_results',
  ADDITIONAL_FIELD = 'additional_field',
  GROUPS_USERS_REPORT = 'groups_users_report',
  ACTIVITY_REPORT = 'activity_report',
  INVISIBLE_ROLE = 'invisible_role',
}

export interface Permissions {
  [permissionsEntity.GROUP]: string[];
  [permissionsEntity.USER]: string[];
  [permissionsEntity.ROLE]: string[];
  [permissionsEntity.INVISIBLE_ROLE]: string[];
  [permissionsEntity.ASSESSMENT]: string[];
  [permissionsEntity.SECTION]: string[];
  [permissionsEntity.MODULE]: string[];
  [permissionsEntity.UNIT]: string[];
  [permissionsEntity.ADDITIONAL_FIELD]: string[];
  [permissionsEntity.GROUPS_USERS_REPORT]: string[];
  [permissionsEntity.ACTIVITY_REPORT]: string[];
  [permissionsEntity.OWN_RESULTS]: string[];
  [permissionsEntity.OWN_COMPARISON]: string[];
  [permissionsEntity.GROUP_DASHBOARD_COMPARISON]: string[];
  [permissionsEntity.OTHER_USERS_DASHBOARD_COMPARISON]: string[];
}

export interface IPermissionsStore {
  permissions: Permissions;
  comparisonType: ComparisonType | string;
  dashboardType: DashboardType | null;
  isIndividualUser: boolean;
  role: string;
  isLoading: boolean;
}

export enum PermissionsAction {
  READ = 'read',
  ASSIGN = 'assign',
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}

export interface PermissionsCreate {
  role_id?: string;
  group_id?: string;
  entity: string;
  action: string;
}

export interface PermissionsOverwrite {
  role_id?: string;
  group_id?: string;
  entity: string;
  actions: string[];
}
