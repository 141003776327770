import { ILocalizedDescription } from './assessmentTypes';
import { BaseOptionElement, CARD_TYPES, CalculationType, DragSource } from './createAssessmentTypes';
import { Translations } from './translationTypes';

export interface BaseBoardElement {
  id: string;
  level: number;
  order: number;
  source: DragSource;
  parentId: string;
}

export interface NamedElement extends Translations {}

export interface DescribedElement extends ILocalizedDescription {}

export interface WeightedElement {
  weight: string;
}

type ModuleChildren =
  | ShortTextBoardElement
  | LongTextBoardElement
  | InformationBoardElement
  | DropdownBoardElement
  | QuestionSliderBoardElement
  | SingleChoiceBoardElement
  | MultipleChoiceBoardElement;

type SectionChildren =
  | ShortTextBoardElement
  | LongTextBoardElement
  | InformationBoardElement
  | DropdownBoardElement
  | QuestionSliderBoardElement
  | SingleChoiceBoardElement
  | MultipleChoiceBoardElement
  | ModuleBoardElement
  | SectionBoardElement;

export interface ContainerBoardElement extends BaseBoardElement, WeightedElement {
  type: typeof CARD_TYPES.section | typeof CARD_TYPES.module;
  name: string;
  children: SectionChildren[] | ModuleChildren[];
  calculation_type: CalculationType;
}

export interface SectionBoardElement extends ContainerBoardElement {
  type: typeof CARD_TYPES.section;
  children: SectionChildren[];
}

export interface ModuleBoardElement extends ContainerBoardElement {
  type: typeof CARD_TYPES.module;
  children: ModuleChildren[];
}

export interface WelcomeBoardElement extends BaseBoardElement, NamedElement, DescribedElement {
  type: typeof CARD_TYPES.welcome_page;
}

export interface ThankBoardElement extends BaseBoardElement, NamedElement, DescribedElement {
  type: typeof CARD_TYPES.thankyou_page;
}

export interface ShortTextBoardElement extends BaseBoardElement, NamedElement, DescribedElement {
  type: typeof CARD_TYPES.short_text;
}

export interface LongTextBoardElement extends BaseBoardElement, NamedElement, DescribedElement {
  type: typeof CARD_TYPES.long_text;
}

export interface InformationBoardElement extends BaseBoardElement, NamedElement, DescribedElement {
  type: typeof CARD_TYPES.information;
  image?: string;
}

export interface SingleColumnBoardElement extends BaseBoardElement, NamedElement, DescribedElement, WeightedElement {
  type: typeof CARD_TYPES.dropdown | typeof CARD_TYPES.likert;
  options: BaseOptionElement[];
}

export interface DropdownBoardElement extends SingleColumnBoardElement {
  type: typeof CARD_TYPES.dropdown;
}

export interface LikertBoardElement extends SingleColumnBoardElement {
  type: typeof CARD_TYPES.likert;
}

export interface QuestionSliderBoardElement extends BaseBoardElement, NamedElement, DescribedElement, WeightedElement {
  type: typeof CARD_TYPES.question_slider;
  min_value: number;
  max_value: number;
}

export interface MultipleColumnBoardElement extends BaseBoardElement, NamedElement, DescribedElement, WeightedElement {
  type: typeof CARD_TYPES.single_choice | typeof CARD_TYPES.multiple_choice;
  leftColumn: BaseOptionElement[];
  rightColumn: BaseOptionElement[];
}

export interface SingleChoiceBoardElement extends MultipleColumnBoardElement {
  type: typeof CARD_TYPES.single_choice;
}

export interface MultipleChoiceBoardElement extends MultipleColumnBoardElement {
  type: typeof CARD_TYPES.multiple_choice;
}

export type RootElement = WelcomeBoardElement | ThankBoardElement | SectionBoardElement;

export type QuestionElement =
  | ShortTextBoardElement
  | LongTextBoardElement
  | InformationBoardElement
  | DropdownBoardElement
  | LikertBoardElement
  | QuestionSliderBoardElement
  | SingleChoiceBoardElement
  | MultipleChoiceBoardElement;

export const isQuestionElement = (element: Element): element is QuestionElement => {
  const questionElementTypes = [
    CARD_TYPES.short_text,
    CARD_TYPES.long_text,
    CARD_TYPES.information,
    CARD_TYPES.dropdown,
    CARD_TYPES.likert,
    CARD_TYPES.question_slider,
    CARD_TYPES.single_choice,
    CARD_TYPES.multiple_choice,
  ];

  return questionElementTypes.includes((element as QuestionElement).type);
};

export type Element =
  | WelcomeBoardElement
  | ThankBoardElement
  | ShortTextBoardElement
  | LongTextBoardElement
  | InformationBoardElement
  | DropdownBoardElement
  | LikertBoardElement
  | QuestionSliderBoardElement
  | SingleChoiceBoardElement
  | MultipleChoiceBoardElement
  | SectionBoardElement
  | ModuleBoardElement;
