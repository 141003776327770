import { MouseEvent, SyntheticEvent } from 'react';

import classNames from 'classnames';
import { CheckTreePicker } from 'rsuite';
import { ValueType } from 'rsuite/CheckTreePicker';

import { TagValue } from './components/TagValue/TagValue';

import { IMultipleSelectOption, ISelectOption } from 'types/createAssessmentTypes';

import './TagTreePicker.scss';

type Props = {
  values: string[];
  label: string;
  labelClassName?: string;
  invalid: boolean;
  errorMessage?: string;
  onChange: (value: ValueType, event?: SyntheticEvent<Element, Event>) => void;
  data: IMultipleSelectOption[];
  placeholder: string;
};

export const TagTreePicker = ({
  values,
  label,
  invalid,
  errorMessage,
  onChange,
  data,
  placeholder,
  labelClassName,
}: Props) => {
  const onTagRemove = (event: MouseEvent, removedItem: ISelectOption) => {
    event.stopPropagation();
    onChange(values.filter((value: string) => value !== removedItem.value));
  };

  return (
    <>
      <div className="label-container">
        <span className={classNames('label-text', labelClassName)}>{label}</span>
        {errorMessage && <span className="error-message">{errorMessage}</span>}
      </div>
      <CheckTreePicker
        value={values}
        onChange={onChange}
        className={classNames('custom-tag-tree-picker', { invalid })}
        menuClassName="custom-tag-tree-picker-menu"
        renderValue={(_, checkedItems) => <TagValue onTagRemove={onTagRemove} checkedItems={checkedItems} />}
        data={data}
        placeholder={placeholder}
        searchable={false}
        cleanable={false}
        cascade={false}
        defaultExpandAll
      />
    </>
  );
};
