import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { ITag } from 'types/addAssigneeModalTypes';
import { TagType } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';

import CrossSvgIcon from 'assets/svg/CrossSvgIcon';

import styles from './Tags.module.scss';

type Props = {
  column?: boolean;
  tags: ITag[];
  handleDelete?: (tag: ITag) => void;
  handleViewAll?: () => void;
};

const Tags = ({ tags, handleDelete, column, handleViewAll }: Props) => {
  const [isMore, setIsMore] = useState(false);
  const { t } = useTranslation();

  const previewCount = 7;
  const previewTags = column ? tags : tags.slice(0, previewCount);
  const moreTags = tags.slice(previewCount);

  const renderDeleteButton = (data: ITag) =>
    handleDelete && (
      <button onClick={() => handleDelete(data)}>
        <CrossSvgIcon />
      </button>
    );

  if (column) {
    return (
      <ul className={classNames(styles.Tags, { [styles.Column]: column })}>
        {previewTags.map(({ title, type, id }, index) => (
          <li
            key={index}
            className={classNames({
              [styles.Organization]: type === TagType.Organization,
              [styles.Group]: type === TagType.Group,
              [styles.SubGroup]: type === TagType.SubGroup,
              [styles.Name]: type === TagType.Name,
            })}
          >
            <span>{title}</span>
            {renderDeleteButton({ title, type, id })}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className={classNames(styles.Tags, { [styles.Column]: column })}>
      {previewTags.map(({ title, type, id }, index) => (
        <li
          key={index}
          className={classNames({
            [styles.Organization]: type === TagType.Organization,
            [styles.Group]: type === TagType.Group,
            [styles.SubGroup]: type === TagType.SubGroup,
            [styles.Name]: type === TagType.Name,
          })}
        >
          <span>{title}</span>
          {renderDeleteButton({ title, type, id })}
        </li>
      ))}
      {moreTags.length && isMore ? (
        <>
          {moreTags.map(({ title, type, id }, index) => (
            <li
              key={index}
              className={classNames({
                [styles.Group]: type === TagType.Group,
                [styles.SubGroup]: type === TagType.SubGroup,
                [styles.Name]: type === TagType.Name,
              })}
            >
              <span>{title}</span>
              {renderDeleteButton({ title, type, id })}
            </li>
          ))}
        </>
      ) : null}
      {moreTags.length ? (
        <li className={styles.More} onClick={() => (isMore ? setIsMore(false) : setIsMore(true))}>
          <span>{isMore ? t('general.show_less') : `+ ${moreTags.length} ${t('general.more').toLowerCase()}`}</span>
        </li>
      ) : null}
      {handleViewAll && (
        <ButtonStyled size={ButtonSize.Tag} fill={ButtonFill.Contained} onClick={handleViewAll}>
          {t('general.view_all')}
        </ButtonStyled>
      )}
    </ul>
  );
};

export default Tags;
