import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';

import TextArea from 'components/Inputs/Text/components/TextArea/TextArea';

import {
  useUpdateVisualisationMutation,
  useUpdateVisualisationSectionMutation,
} from 'store/api/visualisationsApi/visualisationsApi';

import { tooltipResolver } from 'helpers/validations';

import { TooltipType, VisualisationTypes } from 'types/dashboardTypes';
import { ModalProps } from 'types/modalTypes';
import { Translations } from 'types/translationTypes';

import ModalWrapper from '../ModalWrapper';

interface EditTooltipModalProps extends ModalProps {
  tooltip: {
    id: string;
    content: string;
    type: TooltipType;
    visualisationType: VisualisationTypes;
  };
}

type FormValues = {
  mainText: string;
};

const EditTooltipModal = ({ tooltip, open, close }: EditTooltipModalProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, getFieldState, reset } = useForm<FormValues>({
    resolver: yupResolver(tooltipResolver(t)),
    mode: 'all',
    defaultValues: { mainText: tooltip.content },
  });

  const [updateChartTooltip, { isLoading: isChartTooltipUpdating }] = useUpdateVisualisationMutation();
  const [updateChartSectionTooltip, { isLoading: isSectionTooltipUpdating }] = useUpdateVisualisationSectionMutation();

  const updateTooltip = (mainText: string) => {
    const lang = `text_${i18n.language}` as keyof Translations;
    const updatedTooltipData = {
      id: tooltip.id,
      description: {
        [lang]: mainText,
      },
      visualisationType: tooltip.visualisationType,
    };

    if (tooltip.type === TooltipType.Section) {
      updateChartSectionTooltip(updatedTooltipData)
        .unwrap()
        .then(() => {
          close?.();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      updateChartTooltip(updatedTooltipData)
        .then(() => {
          close?.();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleCancel = () => {
    reset({ mainText: tooltip.content });
    close?.();
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateTooltip(data.mainText);
    close?.();
  };

  return (
    <ModalWrapper
      title={t('dashboard.edit_tooltip_modal.title')}
      subtitle={t('dashboard.edit_tooltip_modal.description')}
      open={open}
      close={handleCancel}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.save')}
      status={isChartTooltipUpdating || isSectionTooltipUpdating}
      actionDisabled={!formState.isValid}
    >
      <TextArea
        label={t('inputs.tooltip_main_text')}
        register={register('mainText')}
        placeholder={t('inputs.tooltip_main_text')}
        rows={4}
        error={getFieldState('mainText', formState)}
        error_message={t(formState?.errors.mainText?.message || '')}
      />
    </ModalWrapper>
  );
};

export default EditTooltipModal;
