import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { orderBy } from 'lodash';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import GroupHierarchy from 'components/GroupHierarchy/GroupHierarchy';
import MobileGroupHierarchy from 'components/GroupHierarchyMobile/MobileGroupHierarchy';
import Loader from 'components/Loader/Loader';
import MobileBack from 'components/MobileBack/MobileBack';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';

import MobileCard from 'pages/Roles/RoleList/components/MobileCard/MobileCard';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useGetGroupHierarchyQuery } from 'store/api/groupsApi/groupsApi';
import { setSearch, setSort } from 'store/features/groupHierarchySlice';

import ROUTES from 'router/routes';

import { getFlatSearchHierarchy } from 'helpers/groupHierarchy';

import { MIN_SEARCH_LENGTH, MOBILE_BREAKPOINT } from 'constants/';

import { SortTypes } from 'types/enums';
import { IGroupHierarchyItem } from 'types/groupHierarchyTypes';
import { MobileCardType, MobileCardVariant } from 'types/mobileTypes';

import styles from './GroupHierarchyPage.module.scss';

const GroupHierarchyPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sort = useAppSelector((state) => state.groupHierarchy.sort);
  const search = useAppSelector((state) => state.groupHierarchy.search);
  const selectedGroup = useAppSelector((state) => state.navigation.selectedGroupId);
  const debouncedValue = useDebounce(search, 500);
  const [mobileGroups, setMobileGroups] = useState<IGroupHierarchyItem[] | null>(null);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const { data, isSuccess, isFetching } = useGetGroupHierarchyQuery({
    search: debouncedValue.length < MIN_SEARCH_LENGTH ? '' : debouncedValue,
    orderBy: sort,
    selectedGroup,
  });

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [isMobile, mobileGroups]);

  useEffect(() => {
    if (data?.items) {
      if (isMobile && debouncedValue) {
        const searchHierarchy = getFlatSearchHierarchy([data.items[0]]);

        if (searchHierarchy.length) {
          setMobileGroups(searchHierarchy.reverse());
          return;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, data]);

  useEffect(() => {
    if (isMobile && mobileGroups?.length) {
      setMobileGroups((prev) => {
        if (prev && prev.length) {
          const lastGroup = prev?.at(-1);
          if (lastGroup) {
            return [
              ...prev.slice(0, -1),
              {
                ...lastGroup,
                children: orderBy(
                  lastGroup.children,
                  [(item) => item.name],
                  [sort === 'name' ? SortTypes.ASC : SortTypes.DESC],
                ),
              },
            ];
          }
        }

        return prev;
      });
    }
  }, [isMobile, mobileGroups?.length, sort]);

  const onSearch = (value: string) => {
    dispatch(setSearch(value));
  };

  const onSort = () => {
    const value = sort === 'name' ? '-name' : 'name';
    dispatch(setSort(value));
  };

  const getMobileBreadcrumbs = () => {
    return isMobile && mobileGroups
      ? mobileGroups.map((item, index) => {
          const isActive = index === mobileGroups.length - 1;

          return {
            name: item.name,
            active: isActive,
            onClick: !isActive
              ? () => {
                  setMobileGroups((prev) => (prev ? prev.slice(0, index + 1) : null));
                }
              : undefined,
          };
        })
      : [];
  };

  useDocumentTitle([t('page_titles.group_hierarchy')]);

  return (
    <div className={styles.GroupHierarchyPage}>
      <Breadcrumb
        items={[
          { name: t('groups.group_list_short'), href: ROUTES.GROUP_LIST },
          {
            name: t('groups.group_hierarchy'),
            href: ROUTES.GROUP_HIERARCHY,
            onClick: () => {
              setMobileGroups(null);
            },
            active: !mobileGroups,
          },
          ...getMobileBreadcrumbs(),
        ]}
      />

      <PageTitle
        title={t('groups.group_hierarchy')}
        search={search}
        sort={sort === 'name' ? SortTypes.ASC : SortTypes.DESC}
        onSort={onSort}
        onSearch={onSearch}
        middleChildren={
          isMobile && mobileGroups?.at(-1) ? (
            <MobileCard
              data={[
                {
                  columns: [
                    {
                      type: MobileCardType.Info,
                      label: t('general.description'),
                      value: mobileGroups.at(-1)!.description || '',
                      key: mobileGroups.at(-1)!.id + 1,
                    },
                  ],
                  key: mobileGroups.at(-1)!.id,
                },
              ]}
              variant={MobileCardVariant.Clear}
            />
          ) : null
        }
        controls
        titleTopColumn={isMobile}
      >
        {isMobile && mobileGroups?.at(-1) ? (
          <MobileBack
            title={mobileGroups!.at(-1)!.name}
            handleBack={() => {
              setMobileGroups((prev) => (prev && prev.length > 1 ? prev?.slice(0, -1) : null));
            }}
          />
        ) : null}
      </PageTitle>
      {isFetching ? (
        <Loader flexCenter size="md" />
      ) : (
        isSuccess && (
          <>
            {isMobile ? (
              <>
                {data?.items.length ? (
                  <MobileGroupHierarchy
                    data={data.items}
                    mobileGroups={mobileGroups}
                    setMobileGroups={setMobileGroups}
                  />
                ) : (
                  <NothingFoundText text={t('groups.table.empty_result')} />
                )}
              </>
            ) : (
              <GroupHierarchy data={data} />
            )}
          </>
        )
      )}
    </div>
  );
};

export default GroupHierarchyPage;
