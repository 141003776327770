import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Loader from 'components/Loader/Loader';
import CreateOrEditSectionModal from 'components/Modals/CreateOrEditSectionModal/CreateOrEditSectionModal';
import DeleteModuleModal from 'components/Modals/DeleteModuleModal/DeleteModuleModal';

import { useAppSelector } from 'hooks/redux';

import { useModulesSidebar } from '../../hooks';

import { useGetSectionsQuery } from 'store/api/assessmentApi/assessmentApi';

import { checkPermission } from 'helpers/checkUserPermission';

import { ContainerType } from 'types/createAssessmentTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import ArrowSVG from 'assets/svg/create-assesment/ArrowSVG';
import PlusSVG from 'assets/svg/create-assesment/PlusSVG';

import { ModuleItem } from '../ModulesSidebar/ModulesSidebar';

import styles from '../../AssessmentCreate.module.scss';

const SectionsSidebar = () => {
  const { t } = useTranslation();
  const section = useAppSelector((state) => state.permissions.permissions.section);

  const [showSections, setShowSections] = useState(true);

  const { data: sections, isLoading } = useGetSectionsQuery({ type: ContainerType.Section });

  const { editModule, toggleAddModal, addModule, deleteModuleModal, toggleModuleDelete, toggleEditModule } =
    useModulesSidebar();

  return (
    <div>
      <section className={styles.DropdownSection}>
        {addModule && (
          <CreateOrEditSectionModal
            type={ContainerType.Section}
            open={addModule}
            handleClose={() => toggleAddModal(false)}
          />
        )}

        {editModule && (
          <CreateOrEditSectionModal
            type={ContainerType.Section}
            edit={{ name: editModule.name, id: editModule.id }}
            open={editModule.open}
            handleClose={() => toggleEditModule({ open: false, id: null, name: '' })}
          />
        )}

        <div className={styles.DropdownSection_Header}>
          <div onClick={() => setShowSections((prev) => !prev)} className={styles.ModuleBlock}>
            <ArrowSVG className={classNames(showSections && styles.ExpandSvg)} />
            <p>{t('general.sections')}</p>
          </div>

          {checkPermission(section, [PermissionsAction.CREATE]) && (
            <PlusSVG onClick={() => toggleAddModal(true)} className={styles.AddButton} />
          )}
        </div>

        {showSections && (
          <div className={styles.Modules}>
            {isLoading && (
              <div className={styles.LoaderContainer}>
                <Loader center />
              </div>
            )}

            {sections &&
              sections.map((section) => (
                <ModuleItem
                  key={section.id + section.title}
                  type={ContainerType.Section}
                  item={section}
                  selectModuleToEdit={toggleEditModule}
                  toggleModuleDelete={toggleModuleDelete}
                />
              ))}
          </div>
        )}

        <DeleteModuleModal state={deleteModuleModal} handleClose={() => toggleModuleDelete({ open: false, id: '' })} />
      </section>
    </div>
  );
};

export default SectionsSidebar;
