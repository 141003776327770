import classNames from 'classnames';

import { SingleChoiceBoardElement } from 'types/boardElements';
import {
  ChoiceType,
  ColumnType,
  MultipleColumnOptionAdd,
  MultipleColumnOptionChange,
  MultipleColumnOptionDelete,
  OnElementDelete,
  TitleFieldsChange,
} from 'types/createAssessmentTypes';

import AddOption from '../../AddOption/AddOption';
import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';
import ChoiceOption from '../ChoiceOption/ChoiceOption';

import styles from '../BoardElements.module.scss';

type SingleChoiceProps = {
  onElementDelete: OnElementDelete;
  element: SingleChoiceBoardElement;
  onTitleFieldsChange: TitleFieldsChange;
  onChoiceOptionAdd: MultipleColumnOptionAdd;
  onChoiceOptionChange: MultipleColumnOptionChange;
  onChoiceOptionDelete: MultipleColumnOptionDelete;
};
const SingleChoice = ({
  onElementDelete,
  onTitleFieldsChange,
  element,
  onChoiceOptionAdd,
  onChoiceOptionChange,
  onChoiceOptionDelete,
}: SingleChoiceProps) => {
  return (
    <div className={classNames(styles.CardContainer, styles.ShortTextEntry)}>
      <div className={styles.InnerBody}>
        <CardTitleBlock onDelete={onElementDelete} element={element} onTitleChange={onTitleFieldsChange} />

        <div className={styles.ChoiceBlock}>
          <div>
            <div>
              {element.leftColumn.map((option, index) => (
                <ChoiceOption
                  key={index}
                  option={option}
                  columnType={ColumnType.Left}
                  isDeleteShown={element.leftColumn.length > 1}
                  choiceType={ChoiceType.Single}
                  onChange={(field, value) =>
                    onChoiceOptionChange({
                      elementId: element.id,
                      columnType: ColumnType.Left,
                      optionId: index,
                      field,
                      value,
                    })
                  }
                  onDelete={() => onChoiceOptionDelete(element.id, index, ColumnType.Left)}
                />
              ))}
            </div>

            <div className={classNames(styles.AddOptionBlock, styles.Bordered)}>
              <AddOption onClick={() => onChoiceOptionAdd(element.id, ColumnType.Left)} />
            </div>
          </div>

          <div>
            <div>
              {element.rightColumn.map((option, index) => (
                <ChoiceOption
                  key={index}
                  option={option}
                  columnType={ColumnType.Right}
                  isDeleteShown={element.rightColumn.length > 1}
                  choiceType={ChoiceType.Single}
                  onChange={(field, value) =>
                    onChoiceOptionChange({
                      elementId: element.id,
                      columnType: ColumnType.Right,
                      optionId: index,
                      field,
                      value,
                    })
                  }
                  onDelete={() => onChoiceOptionDelete(element.id, index, ColumnType.Right)}
                />
              ))}
            </div>

            <div className={classNames(styles.AddOptionBlock, styles.Bordered)}>
              <AddOption onClick={() => onChoiceOptionAdd(element.id, ColumnType.Right)} />
            </div>
          </div>
        </div>
      </div>

      <BottomCardNavPanel
        weight={element.weight}
        onChange={(weight) => onTitleFieldsChange(element.id, 'weight', weight)}
      />
    </div>
  );
};

export default SingleChoice;
