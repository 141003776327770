import { MobileCardVariant, MobileCardsData } from 'types/mobileTypes';

import MobileCard from '../MobileCard/MobileCard';

import styles from './MobileCards.module.scss';

type Props = {
  data: MobileCardsData;
  variant?: MobileCardVariant;
};

const MobileCards = ({ data, variant }: Props) => {
  return (
    <div className={styles.MobileContainer}>
      <div className={styles.MobileCards}>
        {data.map((item) => (
          <MobileCard key={item.key} data={item.row} variant={item.variant || variant} clickAction={item.clickAction} />
        ))}
      </div>
    </div>
  );
};

export default MobileCards;
