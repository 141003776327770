import { UseFormSetValue } from 'react-hook-form';

import { Loader } from 'rsuite';

import { useGetUnitsQuery } from 'store/api/unitsApi/unitsApi';

import { AssessmentSettings } from 'types/createAssessmentTypes';

import { ItemRow } from '../ItemRow/ItemRow';

import styles from '../../AssessmentSettingTab.module.scss';

interface Props {
  setValue: UseFormSetValue<AssessmentSettings>;
  error: string;
  unitList: string[];
}
const UnitList = ({ setValue, error, unitList }: Props) => {
  const { data, isLoading } = useGetUnitsQuery({ page: 1, size: 100, search: '', order_by: '' });

  const toggleIds = (id: string) => {
    if (!unitList.includes(id)) {
      setValue('unit_ids', [...unitList, id]);
    } else {
      const newArr = unitList.filter((el) => el !== id);
      setValue('unit_ids', newArr);
    }
  };
  return (
    <div className={styles.SectionListContainer}>
      {isLoading && <Loader size="md" center className={styles.UnitsLoader} />}

      {error && <small>{error}</small>}

      {data?.items.map((unit) => (
        <ItemRow
          key={unit.id}
          checked={unitList.includes(unit.id)}
          name={unit.title}
          onClick={() => toggleIds(unit.id)}
        />
      ))}
    </div>
  );
};

export default UnitList;
