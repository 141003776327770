import { useEffect, useRef } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { Loader } from 'rsuite';

import GroupHierarchyPicker from 'components/GroupHierarchyPicker/GroupHierarchyPicker';

import { useAppSelector } from 'hooks/redux';

import { useGetAssessmentAssignHierarchyQuery } from 'store/api/groupsApi/groupsApi';

import { AssessmentSettings } from 'types/createAssessmentTypes';

import styles from '../../AssessmentSettingTab.module.scss';

type Props = {
  setValue: UseFormSetValue<AssessmentSettings>;
  error: string;
  userList: string[];
  groupList: string[];
};

const AssigneeLists = ({ setValue, error, userList, groupList }: Props) => {
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { data: hierarchy, refetch, isLoading, isFetching, isSuccess } = useGetAssessmentAssignHierarchyQuery();
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current) {
      refetch();
    }

    firstUpdate.current = false;
  }, [refetch, selectedGroupId]);

  const toggleUserIds = (id: string) => {
    if (!userList.includes(id)) {
      setValue('user_ids', [...userList, id]);
    } else {
      const newArr = userList.filter((el) => el !== id);
      setValue('user_ids', newArr);
    }
  };

  const toggleGroupIds = (id: string) => {
    if (!groupList.includes(id)) {
      setValue('group_ids', [...groupList, id]);
    } else {
      const newArr = groupList.filter((el) => el !== id);
      setValue('group_ids', newArr);
    }
  };

  const onSelectEntity = (value: string, entity: string) => {
    if (entity === 'user') toggleUserIds(value);
    else toggleGroupIds(value);
  };

  return (
    <div className={styles.SectionListContainer}>
      {(isLoading || isFetching) && <Loader size="md" center className={styles.UnitsLoader} />}

      {error && <small>{error}</small>}

      {isSuccess && !isFetching && (
        <GroupHierarchyPicker
          data={hierarchy}
          list={{ users: userList, groups: groupList }}
          onSelectEntity={onSelectEntity}
        />
      )}
    </div>
  );
};

export default AssigneeLists;
