import React, { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react';

import classNames from 'classnames';
import { Loader } from 'rsuite';

import { ButtonFill, ButtonSize } from 'types/enums';

import styles from './ButtonStyled.module.scss';

type Props = {
  children?: ReactNode;
  fill: ButtonFill;
  size?: ButtonSize;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  clickType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  form?: string;
  icon?: ReactNode;
  justifyStart?: boolean;
  wrapText?: boolean;
};

const ButtonStyled = ({
  children,
  fill,
  fullWidth,
  disabled,
  loading,
  onClick,
  form,
  icon,
  justifyStart,
  wrapText,
  size = ButtonSize.Normal,
  clickType = 'button',
}: Props) => {
  const classes = classNames(styles.ButtonStyled, styles[fill], styles[size], {
    [styles.FullWidth]: fullWidth,
    [styles.JustifyStart]: justifyStart,
    [styles.WrapText]: wrapText,
  });

  return (
    <button type={clickType} className={classes} disabled={disabled || loading} onClick={onClick} form={form}>
      {loading ? (
        <Loader size="sm" />
      ) : (
        <>
          {icon}
          <span className={styles.ChildrenContainer}>{children}</span>
        </>
      )}
    </button>
  );
};

export default ButtonStyled;
