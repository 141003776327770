import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import TextInput from 'components/Inputs/Text/Text';
import TextArea from 'components/Inputs/Text/components/TextArea/TextArea';
import Loader from 'components/Loader/Loader';
import UploadCSV from 'components/Modals/UploadCSV/UploadCSV';
import PageTitle from 'components/PageTitle/PageTitle';
import Tabs from 'components/Tabs/Tabs';

import Permissions from './components/Permissions';
import UserManage from './components/UserManage';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useToggle } from 'hooks/useToggle';

import {
  useGetGroupByIdQuery,
  useGetGroupPermissionsQuery,
  useUpdateGroupMutation,
} from 'store/api/groupsApi/groupsApi';
import { usePermissionsOverwriteMutation } from 'store/api/permissionsApi/permissionsApi';
import { useSaveUsersFromCsvMutation } from 'store/api/usersApi/usersApi';
import { removeSecondGroup, setSecondGroupId } from 'store/features/navigationSlice';
import { clearUsersCsv } from 'store/features/usersSlice';

import ROUTES from 'router/routes';

import { copyObjectWithoutMethods } from 'helpers/copyObjectWithoutMethods';
import { handleNoPermissionError } from 'helpers/handleNoPermissionError';
import { createGroupForm } from 'helpers/validations';

import { ButtonFill, ButtonSize } from 'types/enums';
import { Permissions as PermissionsType } from 'types/permissionsTypes';

import { initialPermissions } from '../../Roles/RolePermissions/RolePermissions';

import styles from './GroupManage.module.scss';

export type FormValues = {
  name: string;
  description: string;
  user_limit: number;
  permissions: PermissionsType;
  parent_id: string;
  type: string;
};

const initialState = {
  name: '',
  description: '',
  user_limit: 0,
  permissions: {
    role: [],
    group: [],
    user: [],
    assessment: [],
    module: [],
    section: [],
    unit: [],
    group_dashboard_comparison: [],
    other_users_dashboard_comparison: [],
    own_comparison: [],
    own_results: [],
    all_results: [],
    additional_field: [],
    groups_users_report: [],
    activity_report: [],
    invisible_role: [],
  },
  parent_id: '',
  type: '',
};

const GroupManage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { id: groupId } = useParams();

  const { manageGroupId } = useAppSelector((state) => state.navigation);
  const { usersFromCsv, loaded } = useAppSelector((state) => state.users);

  const {
    register,
    getValues,
    control,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    defaultValues: initialState,
    resolver: yupResolver(createGroupForm),
    mode: 'onChange',
  });

  const isClearCSVUsersRef = useRef<boolean>(loaded);

  const [uploadCSVModalStatus, toggleUploadCSVModal] = useToggle(false);
  const [isUsersFromCSV, setIsUsersFromCSV] = useState(loaded);
  const [activeTab, changeTab] = useState(0);
  const [isUsersChanged, setIsUsersChanged] = useState(false);

  const { currentData: data, isSuccess, isLoading: isLoadingGroup, isError } = useGetGroupByIdQuery(groupId!);
  const { data: permissions, isSuccess: permsLoaded } = useGetGroupPermissionsQuery(groupId);

  const [saveUsers] = useSaveUsersFromCsvMutation();
  const [updateGroup, { isLoading: isGroupUpdating }] = useUpdateGroupMutation();
  const [permissionsOverwrite] = usePermissionsOverwriteMutation();

  const isFormEdited = !!Object.keys(dirtyFields).length || isUsersChanged || isUsersFromCSV;

  useEffect(() => {
    isClearCSVUsersRef.current = loaded;
  }, [loaded]);

  useEffect(() => {
    if (groupId && groupId !== manageGroupId) {
      dispatch(setSecondGroupId(groupId));
    }

    return () => {
      dispatch(removeSecondGroup());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (isClearCSVUsersRef.current) {
        dispatch(clearUsersCsv());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isError || !groupId) navigate(ROUTES.GROUP_LIST);
  }, [groupId, isError, navigate]);

  useEffect(() => {
    if (isSuccess && data) {
      const { name, description, user_limit, parent_id, type } = data;
      reset({
        ...getValues(),
        name,
        description,
        parent_id,
        type,
        user_limit,
      });
    }
  }, [data, isSuccess, reset, getValues]);

  const parsePermissions = useCallback(() => {
    const perms: PermissionsType = copyObjectWithoutMethods(initialPermissions);
    if (perms) {
      Object.keys(perms).forEach((perm_el) => {
        if (permissions?.items) {
          perms[perm_el as keyof typeof perms] = permissions?.items
            .filter((el) => el.entity === perm_el)
            .map((el) => el.action);
        }
      });
    }
    reset({ ...getValues(), permissions: { ...perms } });
  }, [getValues, permissions?.items, reset]);

  useEffect(() => {
    if (permsLoaded) parsePermissions();
  }, [parsePermissions, permsLoaded]);

  const tabs = [{ title: t('groups.manage_users') }, { title: t('groups.manage_group_perms') }];

  const onSave: SubmitHandler<FormValues> = (data) => {
    const preparedData = Object.keys(data.permissions).map((entity) => {
      return {
        group_id: groupId,
        entity,
        actions: data.permissions[entity as keyof PermissionsType] || [],
      };
    });

    if (isUsersFromCSV && usersFromCsv) {
      const payload = usersFromCsv.items.map((item) => ({
        email: item.email,
        first_name: item.first_name,
        last_name: item.last_name,
        is_active: item.is_active,
        accesses: item.accesses,
      }));

      saveUsers(payload)
        .unwrap()
        .then(() => toast.success(t('general.success')))
        .then(() => navigate(ROUTES.USER_LIST))
        .catch((error) => {
          handleNoPermissionError(error.status);
          toast.error(error.data.detail[0].msg);
        });
      dispatch(clearUsersCsv());
    }

    if (groupId) {
      updateGroup({ id: groupId, group: data })
        .unwrap()
        .then(() =>
          permissionsOverwrite(preparedData)
            .unwrap()
            .then(() => navigate(ROUTES.GROUP_LIST))
            .catch((error) => {
              handleNoPermissionError(error.status);
              toast.error(t('general.error'));
            }),
        );
    }
  };

  useDocumentTitle([t('page_titles.group_manage')]);

  if (isLoadingGroup) {
    return <Loader center size="lg" />;
  }

  return (
    <div className={styles.GroupManage}>
      <Breadcrumb
        items={[
          { name: t('groups.group_list_short'), href: ROUTES.GROUP_LIST },
          {
            name: t('groups.manage_group'),
            href: ROUTES.GROUP_MANAGE(''),
            active: true,
          },
        ]}
      />

      <UploadCSV
        fromGroup
        onUserUploaded={setIsUsersFromCSV}
        open={uploadCSVModalStatus}
        handleClose={toggleUploadCSVModal}
      />

      <PageTitle
        title={
          <div className={styles.GroupName}>
            <TextInput
              label={t('inputs.name')}
              required
              classes={styles.TitleInput}
              register={register('name')}
              error_message={t(errors.name?.message || '')}
            />
          </div>
        }
      >
        <div className={styles.ButtonGroup}>
          <ButtonStyled
            fullWidth
            fill={ButtonFill.Outlined}
            size={ButtonSize.Small}
            onClick={() => navigate(ROUTES.GROUP_LIST)}
          >
            {t('general.cancel')}
          </ButtonStyled>
          <ButtonStyled
            fullWidth
            fill={ButtonFill.Contained}
            size={ButtonSize.Small}
            onClick={handleSubmit(onSave)}
            disabled={usersFromCsv.items.some((item) => item.errors.length) || !isFormEdited}
            loading={isGroupUpdating}
          >
            {t('general.save')}
          </ButtonStyled>
        </div>
      </PageTitle>

      <div className={styles.GroupManage_Content}>
        <div className={styles.LeftSide}>
          <TextArea
            rows={4}
            label={t('general.description')}
            register={register('description')}
            placeholder={t('inputs.description_placeholder')}
            error_message={t(errors.description?.message || '')}
          />
          <TextInput
            label={t('inputs.user_limit')}
            register={register('user_limit')}
            required
            error_message={t(errors.user_limit?.message || '')}
          />
        </div>

        <div className={styles.RightSide}>
          <Tabs tabs={tabs} activeTab={activeTab} changeTab={changeTab} />

          <div className={classNames({ [styles.DisabledTab]: activeTab !== 0 })}>
            <UserManage
              onOpenUploadCSVModal={toggleUploadCSVModal}
              isUsersFromCSV={isUsersFromCSV}
              setIsUsersFromCSV={setIsUsersFromCSV}
              isUsersChanged={isUsersChanged}
              setIsUsersChanged={setIsUsersChanged}
              isClearCSVUsersRef={isClearCSVUsersRef}
            />
          </div>

          <div className={classNames({ [styles.DisabledTab]: activeTab !== 1 })}>
            <Permissions control={control} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupManage;
