import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import Section from './components/Section/Section';

import { useAppSelector } from 'hooks/redux';

import { useAssessmentAssigneesOverwriteMutation } from 'store/api/assessmentApi/assessmentApi';
import { useGetGroupByTypeQuery } from 'store/api/groupsApi/groupsApi';
import { useGetUsersAllQuery } from 'store/api/usersApi/usersApi';

import { ISection, ISectionItems, ISelectedSections, ITag } from 'types/addAssigneeModalTypes';
import { TagType } from 'types/assessmentTypes';
import { GroupHierarchyType } from 'types/groupHierarchyTypes';
import { ModalSize } from 'types/modalTypes';

import Loader from '../../Loader/Loader';
import Tags from '../../Tags/Tags';
import ModalWrapper from '../ModalWrapper';

import styles from './AddAssigneeModal.module.scss';

type Props = {
  open: boolean;
  selectedSections: ISelectedSections;
  handleClose: () => void;
};

const AddAssigneeModal = ({ open, handleClose, selectedSections, ...props }: Props) => {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const [selectData, setSelectData] = useState<ISection[] | null | undefined>(null);

  const {
    data: organization,
    isSuccess: isSuccessOrganization,
    isFetching: isFetchingOrganization,
  } = useGetGroupByTypeQuery(
    { groupType: GroupHierarchyType.Organization, groupId: selectedGroupId },
    {
      skip: !open,
    },
  );
  const {
    data: group,
    isSuccess: isSuccessGroup,
    isFetching: isFetchingGroup,
  } = useGetGroupByTypeQuery({ groupType: GroupHierarchyType.Group, groupId: selectedGroupId }, { skip: !open });
  const {
    data: subgroup,
    isSuccess: isSuccessSubgroup,
    isFetching: isFetchingSubgroup,
  } = useGetGroupByTypeQuery({ groupType: GroupHierarchyType.Subgroup, groupId: selectedGroupId }, { skip: !open });
  const {
    data: users,
    isSuccess: isSuccessUsers,
    isFetching: isFetchingUsers,
  } = useGetUsersAllQuery(selectedGroupId, { skip: !open });
  const [assessmentAssigneesOverwrite, { isLoading: isLoadingAssessmentAssigneesOverwrite }] =
    useAssessmentAssigneesOverwriteMutation();

  const isSuccess = isSuccessOrganization && isSuccessGroup && isSuccessSubgroup && isSuccessUsers;
  const isLoading = isFetchingGroup || isFetchingSubgroup || isFetchingUsers || isFetchingOrganization;

  const prevAssignees = useRef<ISection[]>([]);

  const tags =
    selectData?.reduce((acc: ITag[], data) => {
      const checkedKeys = Object.keys(data.items).filter((item) => data.items[item].value);
      const result = checkedKeys.map((item) => ({ title: item, type: data.type, id: data.items[item].id }));
      acc.push(...result);
      return acc;
    }, []) || [];

  const sections = useMemo(
    () => [
      {
        title: t('general.organization'),
        value: Boolean(selectedSections.organizations?.length),
        items: organization?.map((item) => ({
          title: item.name,
          id: item.id,
          value: Boolean(selectedSections.organizations?.some((organization) => organization.id === item.id)),
        })),
        type: TagType.Organization,
      },
      {
        title: t('general.group'),
        value: Boolean(selectedSections.groups?.length),
        items: group?.map((item) => ({
          title: item.name,
          id: item.id,
          value: Boolean(selectedSections.groups?.some((group) => group.id === item.id)),
        })),
        type: TagType.Group,
      },
      {
        title: t('general.subgroup'),
        value: Boolean(selectedSections.subgroups?.length),
        items: subgroup?.map((item) => ({
          title: item.name,
          id: item.id,
          value: Boolean(selectedSections.subgroups?.some((subgroup) => subgroup.id === item.id)),
        })),
        type: TagType.SubGroup,
      },
      {
        title: t('general.user'),
        value: Boolean(selectedSections.users?.length),
        items: users?.map((item) => ({
          title: `${item.first_name} ${item.last_name}`,
          id: item.id,
          value: Boolean(selectedSections.users?.some((user) => user.id === item.id)),
        })),
        type: TagType.Name,
      },
    ],
    [
      t,
      organization,
      group,
      subgroup,
      users,
      selectedSections.organizations,
      selectedSections.groups,
      selectedSections.subgroups,
      selectedSections.users,
    ],
  );

  useEffect(() => {
    if (isSuccess) {
      const mappedSections = sections.map((item) => ({
        value: item.value,
        title: item.title,
        type: item.type,
        items: item?.items?.reduce((acc: ISectionItems, item: { title: string; id: string; value: boolean }) => {
          acc[item.title] = { title: item.title, value: item.value, id: item.id };
          return acc;
        }, {}) as ISectionItems,
      }));

      setSelectData(mappedSections);
      prevAssignees.current = mappedSections;
    }
  }, [isSuccess, sections]);

  const handleDeleteTag = (tag: ITag) => {
    const find = selectData?.find((item) => tag.type === item.type);
    setSelectData((prevState) => {
      if (find && prevState) {
        const res = [...prevState];
        res[find.type] = {
          ...res[find.type],
          items: {
            ...prevState[find.type].items,
            [tag.title]: { ...prevState[find.type].items[tag.title], value: false },
          },
        };

        res[find.type] = {
          ...res[find.type],
          value: Object.keys(res[find.type].items).some((item) => res[find.type].items[item].value),
        };

        return res;
      }
    });
  };

  const addAssignees = async () => {
    if (selectData) {
      const flattenItems = (data: ISection[]) => data?.flatMap((item) => Object.values(item.items));

      const oldArray = flattenItems(prevAssignees.current);
      const newArray = flattenItems(selectData);
      const diffArray = differenceWith(oldArray, newArray, isEqual);

      const idValueMapOld: { [key: string]: boolean } = oldArray.reduce(
        (map, item) => {
          if (item.id && item.value) {
            map[item.id] = true;
          }
          return map;
        },
        {} as { [key: string]: boolean },
      );

      const idValueMapNew = newArray.reduce(
        (map, item) => {
          if (item.id && item.value) {
            map[item.id] = true;
          }
          return map;
        },
        {} as { [key: string]: boolean },
      );

      const removed = diffArray
        .filter((item) => idValueMapOld[item.id] && !idValueMapNew[item.id])
        .map((item) => item.id);

      const added = diffArray
        .filter((item) => !idValueMapOld[item.id] && idValueMapNew[item.id])
        .map((item) => item.id);

      await assessmentAssigneesOverwrite({
        id,
        payload: { removed, added },
      });
      handleClose();
    }
  };

  return (
    <ModalWrapper
      {...props}
      title={t('assessment.add_assignee')}
      open={open}
      close={handleClose}
      actionTitle={t('general.save')}
      cancelTitle={t('general.close')}
      status={isLoadingAssessmentAssigneesOverwrite}
      action={addAssignees}
      modalSpacing={ModalSize.NoSpacing}
    >
      <div className={styles.Description}>{t('assessment.add_assignee_description')}</div>

      {isLoading ? (
        <div className={styles.LoaderContainer}>
          <Loader flexCenter size="md" />
        </div>
      ) : (
        <>
          {tags.length ? (
            <div className={styles.TagsContainer}>
              <Tags tags={tags} handleDelete={handleDeleteTag} />
            </div>
          ) : null}
          <div className={styles.Accordions}>
            {selectData?.map((item, index) => <Section key={index} setData={setSelectData} section={item} />)}
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default AddAssigneeModal;
