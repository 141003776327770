import React from 'react';
import { MdAddLink, MdLinkOff } from 'react-icons/md';

import { ReactComponent as BoldIcon } from 'assets/svg/editor/bold.svg';
import { ReactComponent as AlignCenterIcon } from 'assets/svg/editor/center.svg';
import { ReactComponent as ItalicIcon } from 'assets/svg/editor/italic.svg';
import { ReactComponent as AlignLeftIcon } from 'assets/svg/editor/left.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/editor/link.svg';
import { ReactComponent as ListIcon } from 'assets/svg/editor/list.svg';
import { ReactComponent as AlignRightIcon } from 'assets/svg/editor/right.svg';
import { ReactComponent as StrikethroughIcon } from 'assets/svg/editor/strikethrough.svg';
import { ReactComponent as UnderlineIcon } from 'assets/svg/editor/underline.svg';

type Props = {
  children?: React.ReactNode;
};

const Icon = ({ children }: Props) => {
  switch (children) {
    case 'bold': {
      return <BoldIcon />;
    }
    case 'italic': {
      return <ItalicIcon />;
    }
    case 'underline': {
      return <UnderlineIcon />;
    }
    case 'delete': {
      return <StrikethroughIcon />;
    }
    case 'left': {
      return <AlignLeftIcon />;
    }
    case 'center': {
      return <AlignCenterIcon />;
    }
    case 'right': {
      return <AlignRightIcon />;
    }
    case 'bulleted-list': {
      return <ListIcon />;
    }
    case 'link': {
      return <LinkIcon />;
    }
    case 'add_link': {
      return <MdAddLink size={18} />;
    }
    case 'link_off': {
      return <MdLinkOff size={18} />;
    }
    case 'color_picker': {
      return <LinkIcon />;
    }
  }

  return <span>Unknown icon</span>;
};

export default Icon;
