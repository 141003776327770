import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';

import { MOBILE_BREAKPOINT_HEADER } from 'constants/';

import { IGroupNavigation } from 'types/navigationTypes';

import Level from '../Level/Level';

import styles from './MiddleLevels.module.scss';

type Props = {
  levels: IGroupNavigation[];
  onSelectLevel: (value: string, levelIndex: number) => void;
  disabled: boolean;
};

const MiddleLevels = ({ levels, onSelectLevel, disabled }: Props) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT_HEADER,
  });
  const containerRef = useRef(null);
  const [isShowNested, setIsShowNested] = useState(false);

  useEffect(() => {
    const handleClose = (event: KeyboardEvent) => {
      const isActive = document.activeElement === containerRef.current;

      if (event.key === 'Escape') {
        setIsShowNested(false);
      }

      if (isActive && (event.key === 'Enter' || event.key === ' ')) {
        setIsShowNested((prev) => !prev);
      }
    };

    window.addEventListener('keyup', handleClose);

    return () => window.removeEventListener('keyup', handleClose);
  }, []);

  return (
    <ClickAwayListener onClickAway={() => !isMobile && setIsShowNested(false)}>
      <div
        ref={containerRef}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          if (e.target !== e.currentTarget) return;
          setIsShowNested(!isShowNested);
        }}
        className={classNames(styles.Container, {
          [styles.Active]: isShowNested,
        })}
      >
        <span>{`+${levels.length}`}</span>

        {isShowNested && (
          <div className="MiddleLevelsNestedContainer">
            {levels
              .filter((item) => item.data.length > 0)
              .map((item, index) => (
                <Level
                  key={index + item.selectedValue}
                  levelIndex={index + 1}
                  level={item}
                  onSelectLevel={onSelectLevel}
                  showArrow={levels.length > index + 1}
                  disabled={disabled}
                  nested
                />
              ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default MiddleLevels;
