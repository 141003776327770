import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Loader } from 'rsuite';

import Level from './components/Level/Level';
import MiddleLevels from './components/MiddleLevels/MiddleLevels';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useLazyFetchUserPermissionsQuery } from 'store/api/permissionsApi/permissionsApi';
import {
  selectGroup,
  selectOrganization,
  setGroupNavigation,
  setIsAdminGroup,
  setManageGroupNavigation,
} from 'store/features/navigationSlice';
import { selectorHierarchyNormalized } from 'store/selectors/hierarchy';

import ROUTES from 'router/routes';

import { mapToGroupNavigationData } from 'helpers/mappers/mapToGroupNavigationData';

import {
  GroupHierarchyType,
  IGroupsHierarchyNavigationItem,
  NormalizedGroupsHierarchy,
} from 'types/groupHierarchyTypes';
import { IGroupNavigation } from 'types/navigationTypes';
import { RoleLevel } from 'types/roleTypes';
import { CurrentUser } from 'types/userTypes';

import { ReactComponent as ArrowIcon } from 'assets/images/header-arrow.svg';

import styles from './HeaderNavigation.module.scss';

const getUpdatedGroupNavigation = (levels: IGroupNavigation[], value: string, index: number) => {
  /* Function that updates groupNavigation, with changes of current level  */
  const selected = levels[index].data.find((el) => el.value === value) ?? ({} as IGroupsHierarchyNavigationItem);
  levels.splice(index + 1);
  levels.splice(index, 1, { ...levels[index], selectedValue: value });
  levels.push({
    selectedValue: value,
    data: selected.children.map(mapToGroupNavigationData),
  });

  return levels;
};

const HeaderNavigation = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const user = useAppSelector((state) => state.auth.user);
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const isLoading = useAppSelector((state) => state.navigation.isLoading);
  const normalizedGroupHierarchy = useAppSelector(selectorHierarchyNormalized);
  const groupNavigation = useAppSelector((state) => state.navigation.groupNavigation);
  const manageGroupNavigation = useAppSelector((state) => state.navigation.manageGroupNavigation);
  const manageGroupId = useAppSelector((state) => state.navigation.manageGroupId);
  const [fetchUserPermissions] = useLazyFetchUserPermissionsQuery();

  const hasManageGroupId = !!manageGroupId;
  const headerNavigation = hasManageGroupId ? manageGroupNavigation : groupNavigation;
  const hasChildrenLast = headerNavigation?.slice(-1)?.[0]?.data.length !== 0;
  const hasChildrenSecond = headerNavigation?.[1]?.data.length !== 0;
  const lastLevelOffset = hasChildrenLast ? 1 : 2;
  const lastLevelIndex = headerNavigation.length - lastLevelOffset;
  const firstLevel = headerNavigation[0];
  const middleLevels = headerNavigation.slice(1, -lastLevelOffset);
  const lastLevel = headerNavigation.length > 1 ? headerNavigation[lastLevelIndex] : undefined;
  const isRenderLastLevel = hasChildrenSecond && lastLevel;

  const autoSetHeaderNavigation = useCallback(() => {
    const getCurrentGroupHierarchy = (id: string) => {
      const getPathToGroupHierarchy = (id: string, isFirstCall: boolean = true): IGroupNavigation[] => {
        const hierarchyStructure = [];
        const currentHierarchyLevel = normalizedGroupHierarchy[id];

        if (currentHierarchyLevel) {
          if (isFirstCall && currentHierarchyLevel.children.length && !hasManageGroupId) {
            hierarchyStructure.push({
              data: currentHierarchyLevel.children.map((item) => mapToGroupNavigationData(item)),
              selectedValue: '',
            });
          }
          const currentHierarchyParentId = currentHierarchyLevel?.parentId;
          hierarchyStructure.push({
            data:
              currentHierarchyParentId && normalizedGroupHierarchy[currentHierarchyParentId]
                ? normalizedGroupHierarchy[currentHierarchyParentId].children.map(mapToGroupNavigationData)
                : Object.keys(normalizedGroupHierarchy)
                    .filter((item) => normalizedGroupHierarchy[item].parentId === currentHierarchyParentId)
                    .map((item) => mapToGroupNavigationData(normalizedGroupHierarchy[item])),
            selectedValue: currentHierarchyLevel.id,
          });

          if (currentHierarchyParentId && normalizedGroupHierarchy[currentHierarchyParentId]) {
            hierarchyStructure.push(...getPathToGroupHierarchy(currentHierarchyParentId, false));
          }
        }

        return hierarchyStructure;
      };

      return getPathToGroupHierarchy(id).reverse();
    };

    if (hasManageGroupId) {
      dispatch(setManageGroupNavigation(getCurrentGroupHierarchy(manageGroupId)));
    } else {
      dispatch(setGroupNavigation(getCurrentGroupHierarchy(selectedGroupId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, normalizedGroupHierarchy, hasManageGroupId, manageGroupId, selectedGroupId]);

  useEffect(() => {
    if (Object.keys(normalizedGroupHierarchy).length && user) {
      const isNested = user.groups[0].role_level === RoleLevel.Nested;

      if (isNested && !selectedGroupId) {
        const currentGroupId = user.groups[0].group_id;

        const findElementWithAccess = (element: NormalizedGroupsHierarchy): NormalizedGroupsHierarchy | null => {
          const siblings =
            element.parentId && normalizedGroupHierarchy[element.parentId]
              ? normalizedGroupHierarchy[element.parentId].children
              : [];

          for (const sibling of siblings) {
            if (sibling.hasAccess) {
              return sibling;
            }
          }

          const children = element.children || [];

          for (const child of children) {
            if (child.has_access) {
              return child;
            }

            const foundElement = findElementWithAccess(child);

            if (foundElement) {
              return foundElement;
            }
          }

          return null;
        };

        const nextAvailableGroupId =
          findElementWithAccess(normalizedGroupHierarchy[currentGroupId])?.id || currentGroupId;
        dispatch(selectGroup(nextAvailableGroupId));
        fetchUserPermissions({ id: user.id, group_id: nextAvailableGroupId });
      }

      if (selectedGroupId) {
        autoSetHeaderNavigation();
      }
    }
  }, [autoSetHeaderNavigation, dispatch, fetchUserPermissions, normalizedGroupHierarchy, selectedGroupId, user]);

  const getBaseAssessmentEditRoute = () => {
    return ROUTES.DIAGNOSTIC_EDIT().split(':', 1)[0];
  };

  const handleFetchUserPermissions = (user: CurrentUser, value: string) => {
    if (
      user &&
      ![ROUTES.DIAGNOSTIC_CREATE, getBaseAssessmentEditRoute()].some((item) => location.pathname.startsWith(item))
    ) {
      fetchUserPermissions({ id: user.id, group_id: value });
    }
  };

  const onSelectLevel = (value: string, index: number) => {
    dispatch(selectGroup(value));
    dispatch(setIsAdminGroup(normalizedGroupHierarchy[value].path.path === '0'));
    if (normalizedGroupHierarchy[value].type === GroupHierarchyType.Organization) {
      dispatch(selectOrganization(value));
    }

    if (user) {
      handleFetchUserPermissions(user, value);
    }

    const updatedGroupNavigation = getUpdatedGroupNavigation([...groupNavigation], value, index);
    dispatch(setGroupNavigation(updatedGroupNavigation));
  };

  if (isLoading) {
    return <Loader speed="normal" />;
  }

  if (headerNavigation.length === 0) {
    return null;
  }

  return (
    <div className={styles.Container}>
      <Level levelIndex={0} level={firstLevel} onSelectLevel={onSelectLevel} disabled={hasManageGroupId} />
      {isRenderLastLevel && <ArrowIcon className={styles.ArrowIcon} />}
      {middleLevels.length > 0 && (
        <>
          <MiddleLevels levels={middleLevels} onSelectLevel={onSelectLevel} disabled={hasManageGroupId} />
          <ArrowIcon className={styles.ArrowIcon} />
        </>
      )}
      {isRenderLastLevel && (
        <Level
          levelIndex={lastLevelIndex}
          level={lastLevel}
          onSelectLevel={onSelectLevel}
          disabled={hasManageGroupId}
        />
      )}
    </div>
  );
};

export default HeaderNavigation;
