import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Scrollable from 'components/Scrollable/Scrollable';

import { CardVariants } from 'types/assessmentTypes';
import { PointUser } from 'types/dashboardTypes';

import styles from './PointUsersComparison.module.scss';

interface Props {
  variant?: CardVariants;
  users: PointUser[];
  groupName: string;
}

const PointUsersComparison = ({ variant, users, groupName }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.Container, { [styles.Yellow]: variant === CardVariants.Yellow })}>
      <div className={styles.Header}>
        <span className={styles.Group}>{groupName}</span>
        <span className={styles.People}>{`${users.length} ${t(
          users.length === 1 ? 'general.person' : 'general.people',
        )}`}</span>
      </div>
      <div className={styles.Body}>
        {users.length ? (
          <div className={styles.ScrollableContainer}>
            <Scrollable variant={variant} maxHeight={320}>
              {users.map(({ id, firstName, lastName }) => (
                <div key={id} className={styles.User}>
                  <span>{`${firstName} ${lastName}`}</span>
                </div>
              ))}
            </Scrollable>
          </div>
        ) : (
          <div className={classNames(styles.EmptyGroup, { [styles.Yellow]: variant === CardVariants.Yellow })}>
            {t('groups.empty_group')}
          </div>
        )}
      </div>
    </div>
  );
};

export default PointUsersComparison;
