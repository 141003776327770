import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select/Select';
import ManageUserTags from 'components/SecondaryGroupsTags/SecondaryGroupsTags';

import { useAppSelector } from 'hooks/redux';
import { useToggle } from 'hooks/useToggle';

import { useGetGroupHierarchyQuery } from 'store/api/groupsApi/groupsApi';
import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';

import { mapToSelectGroupHierarchy } from 'helpers/mappers/mapToSelectGroupHierarchy';

import { ModalProps } from 'types/modalTypes';
import { Accesses, Tag, Tags } from 'types/userTypes';

import ModalWrapper from '../ModalWrapper';

interface Props extends ModalProps {
  onConfirm: (accesses: Accesses[]) => void;
}

type FormValues = {
  group: string;
  role: string;
};

const initialState = {
  group: '',
  role: '',
};

const CopyUsers = ({ onConfirm, ...props }: Props) => {
  const { t } = useTranslation();
  const selectedGroup = useAppSelector((state) => state.navigation.selectedGroupId);
  const [openSelect, toggleSelect] = useToggle(false);
  const [openView, toggleView] = useToggle(false);
  const [openDelete, toggleDelete] = useToggle(false);
  const [secondaryGroups, changeSecondaryGroups] = useState<Tag[]>([]);
  const [tag, selectTag] = useState<Tags | null>(null);

  const { handleSubmit, control, reset, resetField, watch } = useForm<FormValues>({
    defaultValues: initialState,
    mode: 'onSubmit',
  });

  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupHierarchyQuery({
    search: '',
    orderBy: 'name',
    selectedGroup,
  });

  const group = watch('group');
  const role = watch('role');

  const { currentData: roles, isFetching: isFetchingRoles } = useGetRolesQuery({ groupId: group }, { skip: !group });

  const clearData = () => {
    reset(initialState);
    changeSecondaryGroups([]);
  };

  const onCloseModal = () => {
    clearData();
    if (props.close) {
      props.close();
    }
  };

  const onSubmit = () => {
    onConfirm([
      { group_id: group, role_id: role },
      ...secondaryGroups.map((el) => ({
        group_id: el.group.value,
        role_id: el.role.value,
      })),
    ]);
    clearData();
  };

  useEffect(() => {
    resetField('role');
  }, [group, resetField]);

  const mappedGroups = groupsData?.items.map((el) => mapToSelectGroupHierarchy(el)) || [];

  const renderManageUserTags = (
    <ManageUserTags
      openSelect={openSelect}
      openView={openView}
      openDelete={openDelete}
      toggleSelect={toggleSelect}
      toggleView={toggleView}
      toggleDelete={toggleDelete}
      secondaryGroups={secondaryGroups}
      changeSecondaryGroups={changeSecondaryGroups}
      tag={tag}
      selectTag={selectTag}
      groups={mappedGroups}
      isManageGroup
    />
  );

  return openSelect || openView || openDelete ? (
    renderManageUserTags
  ) : (
    <ModalWrapper
      close={onCloseModal}
      open={props.open}
      title={t('groups.manage.copy_users')}
      subtitle={t('groups.manage.copy_users_desc')}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.apply')}
      cancelTitle={t('general.cancel')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="group"
          render={({ field: { onChange, value } }) => (
            <Select
              required
              cascade
              value={value}
              onSelect={onChange}
              data={mappedGroups}
              isLoading={isLoadingGroups}
              label={t('inputs.group')}
              placeholder={t('inputs.group_placeholder')}
            />
          )}
        />

        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value } }) => (
            <Select
              required
              data={
                roles?.items.map((role) => ({
                  value: role.id,
                  label: role.name,
                })) || []
              }
              isLoading={isFetchingRoles}
              value={value}
              onSelect={onChange}
              label={t('users.table.user_role')}
              placeholder={t('inputs.role_placeholder')}
            />
          )}
        />

        {renderManageUserTags}
      </form>
    </ModalWrapper>
  );
};

export default CopyUsers;
