import React from 'react';

const CrossSvgIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.7688 1.34778C12.0771 1.03945 12.0771 0.539564 11.7688 0.231242C11.4604 -0.0770806 10.9605 -0.0770806 10.6522 0.231242L6 4.88347L1.34778 0.231242C1.03945 -0.0770806 0.539564 -0.0770806 0.231242 0.231242C-0.0770804 0.539564 -0.0770807 1.03945 0.231242 1.34778L4.88347 6L0.231242 10.6522C-0.0770804 10.9605 -0.0770807 11.4604 0.231242 11.7688C0.539564 12.0771 1.03945 12.0771 1.34778 11.7688L6 7.11653L10.6522 11.7688C10.9605 12.0771 11.4604 12.0771 11.7688 11.7688C12.0771 11.4604 12.0771 10.9605 11.7688 10.6522L7.11653 6L11.7688 1.34778Z"
        fill="#A6B4C1"
      />
    </svg>
  );
};

export default CrossSvgIcon;
