import { Element } from './boardElements';
import { Translations } from './translationTypes';
import { KeysOfUnion } from './utilityTypes';

export const CARD_TYPES = {
  welcome_page: 'welcome_page',
  thankyou_page: 'thankyou_page',
  short_text: 'short_text',
  information: 'information',
  long_text: 'long_text',
  dropdown: 'dropdown',
  likert: 'likert',
  module: 'module',
  section: 'section',
  question_slider: 'question_slider',
  single_choice: 'single_choice',
  multiple_choice: 'multiple_choice',
} as const;

export type CardTypes = keyof typeof CARD_TYPES;

export enum ContainerType {
  Module = 'module',
  Section = 'section',
}

export enum BoardLanguage {
  En = 'en',
  Es = 'es',
}

export enum DragSource {
  Aside = 'aside',
  Content = 'content',
}

export type TitleFieldsChange = (elementId: string, field: KeysOfUnion<Element>, value: string | number) => void;

export enum CalculationType {
  Summary = 'summary',
  Average = 'average',
}

export type CalculationTypeChange = (elementId: string, value: CalculationType) => void;

export interface BaseOptionElement {
  text_en: string;
  text_es: string;
  score: string;
  order: number;
}

export type SingleColumnOptionAdd = (elementId: string) => void;

export type SingleColumnOptionChange = (
  elementId: string,
  optionId: number,
  field: keyof Translations | 'score',
  value: string,
) => void;

export type SingleColumnOptionDelete = (elementId: string, optionIndex: number) => void;

export enum ColumnType {
  Left = 'leftColumn',
  Right = 'rightColumn',
}

export enum ChoiceType {
  Single = 'single',
  Multiple = 'multiple',
}

export type MultipleColumnOptionAdd = (elementId: string, columnType: ColumnType) => void;

export type MultipleColumnOptionChange = ({
  elementId,
  columnType,
  optionId,
  field,
  value,
}: {
  elementId: string;
  columnType: ColumnType;
  optionId: number;
  field: keyof Translations | 'score';
  value: string;
}) => void;

export type MultipleColumnOptionDelete = (elementId: string, optionIndex: number, columnType: ColumnType) => void;

export type OnElementDelete = () => void;

export type EditSection = {
  title: string;
  section_type: ContainerType;
  id: string | number;
};

export type SectionItem = { id: string; section_type: ContainerType; title: string };

export type GetSections = {
  items: SectionItem[];
  page: string;
  total: string;
  size: string;
};

export type EditModalState = {
  name: string;
  open: boolean;
  id: any;
};

export type DeleteModalState = {
  open: boolean;
  id: any;
};

export interface IMultipleSelectOption {
  value: string | number;
  label: string;
  children: IMultipleSelectOption[];
}

export enum FormElements {
  Large = 24,
  Medium = 12,
  Small = 8,
}

export interface IVisualisationFormMarkup {
  id: string;
  label: string;
  size: FormElements;
  children?: IVisualisationFormMarkup[];
}

export interface IVisualisationFormState {
  fieldNames: Record<string, string>;
}

export interface IVisualisationFormData {
  markup: IVisualisationFormMarkup[];
  state: IVisualisationFormState;
}

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISectionData {
  allSections: ISelectOption[];
  sectionsOptions: IMultipleSelectOption[];
}

export interface IMappingsFormData {
  [key: string]: Record<string, string[]>;
}

export type AssessmentSettings = {
  assessment_name: string;
  assessment_description: string;
  assessment_image: string;
  is_default: boolean;
  show_context: boolean;
  user_ids: string[];
  group_ids: string[];
  unit_ids: string[];
};

export interface Mapping {
  visualisation_id: string;
  section_definition_ids: string[];
}

export interface ResponsibilityMapping {
  visualisation_id: string;
  question_id: string;
}
