import { ReactNode } from 'react';

export enum MobileCardType {
  Info = 'info',
  Ordinal = 'ordinal',
  Actions = 'actions',
  Error = 'error',
}

interface InfoItem {
  type: MobileCardType.Info;
  label: string;
  value: ReactNode;
  key: string;
}

interface OrdinalItem {
  type: MobileCardType.Ordinal;
  label: string;
  value: string | number;
  key: string;
}

export interface ActionsItem {
  type: MobileCardType.Actions;
  label?: string;
  actions: ReactNode[];
  key: string;
}

interface ErrorItem {
  type: MobileCardType.Error;
  value: string;
  key: string;
}

export type MobileCardData = { columns: (InfoItem | ActionsItem | OrdinalItem | ErrorItem)[]; key: string }[];

export enum MobileCardVariant {
  Organization = 'organization',
  Group = 'group',
  Subgroup = 'subgroup',
  User = 'user',
  Clear = 'clear',
  Selected = 'selected',
  FullSelected = 'full_selected',
  Error = 'error',
}

export type MobileCardsData = {
  row: MobileCardData;
  key: string;
  variant?: MobileCardVariant;
  clickAction?: (() => void) | undefined;
}[];
