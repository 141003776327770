import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { RootState } from './store';

import { ORANIZATION_ID_STORAGE_KEY } from '../constants';

import { signOut } from './features/authSlice';
import { resetGroups } from './features/navigationSlice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  return next(action);
};

type BaseQueryResponse = {
  data: {
    access: string;
    refresh: string;
  };
};

let isRefresh = false;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AUTH_API,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('access');
    const group_id =
      headers.get('group_id') ||
      (getState() as RootState).navigation.manageGroupId ||
      (getState() as RootState).navigation.selectedGroupId;

    if (token && !isRefresh) headers.set('authorization', `Bearer ${token}`);
    if (window.location.pathname !== '/groups/edit' && group_id) headers.set('group_id', group_id);

    return headers;
  },
});

const baseAssessmentQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_ASSESSMENT_API,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('access');
    const group_id = headers.get('group_id') || (getState() as RootState).navigation.selectedGroupId;
    const organization_id =
      headers.get('organization_id') ||
      (getState() as RootState).navigation.selectedOrganizationId ||
      localStorage.getItem(ORANIZATION_ID_STORAGE_KEY);
    if (token && !isRefresh) headers.set('authorization', `Bearer ${token}`);
    if (group_id) headers.set('group_id', group_id);
    if (organization_id) headers.set('organization_id', organization_id);
    return headers;
  },
});

export const baseAssessmentQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseAssessmentQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    isRefresh = true;
    const refreshToken = localStorage.getItem('refresh');
    const refreshResult = (await baseQuery(
      {
        url: '/auth/refresh-token/',
        method: 'POST',
        body: {
          token: refreshToken,
        },
      },
      api,
      extraOptions,
    )) as BaseQueryResponse;

    if (refreshResult.data) {
      isRefresh = false;
      localStorage.setItem('access', refreshResult.data.access);
      localStorage.setItem('refresh', refreshResult.data.refresh);
      result = await baseAssessmentQuery(args, api, extraOptions);
    } else {
      isRefresh = false;
      localStorage.clear();
      api.dispatch(signOut());
      api.dispatch(resetGroups());
    }
  }

  return result;
};

export const baseAuthQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    isRefresh = true;
    const refreshToken = localStorage.getItem('refresh');
    const refreshResult = (await baseQuery(
      {
        url: '/auth/refresh-token/',
        method: 'POST',
        body: {
          token: refreshToken,
        },
      },
      api,
      extraOptions,
    )) as BaseQueryResponse;

    if (refreshResult.data) {
      isRefresh = false;
      localStorage.setItem('access', refreshResult.data.access);
      localStorage.setItem('refresh', refreshResult.data.refresh);
      result = await baseQuery(args, api, extraOptions);
    } else {
      isRefresh = false;
      localStorage.clear();
      api.dispatch(signOut());
      api.dispatch(resetGroups());
    }
  }

  return result;
};
