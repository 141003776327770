import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import SelectTags from 'components/Inputs/SelectTags/SelectTags';

import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as AssessmentPerm } from 'assets/images/assessment.svg';
import { ReactComponent as GroupPerm } from 'assets/images/group-perm.svg';
import { ReactComponent as ModulePerm } from 'assets/images/module.svg';
import { ReactComponent as RolesPerm } from 'assets/images/roles-perm.svg';
import { ReactComponent as SectionPerm } from 'assets/images/section.svg';
import { ReactComponent as UnitPerm } from 'assets/images/unit.svg';
import { ReactComponent as UserPerm } from 'assets/images/user-perm.svg';

import { FormValues } from '../GroupManage';

import styles from '../GroupManage.module.scss';

type Props = {
  control: Control<FormValues, unknown>;
};

const Permissions = ({ control }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <form className={styles.PermissionsWrapper}>
        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <RolesPerm />
              {t('general.roles')}
            </div>

            <div className={styles.Line} />
          </div>

          <Controller
            name="permissions.role"
            control={control}
            render={({ field: { onChange, value } }) => <SelectTags value={value} onChange={onChange} />}
          />
        </div>

        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <GroupPerm /> {t('general.groups')}
            </div>
            <div className={styles.Line} />
          </div>

          <Controller
            name="permissions.group"
            control={control}
            render={({ field: { onChange, value } }) => <SelectTags value={value} onChange={onChange} />}
          />
        </div>

        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <UserPerm /> {t('general.users')}
            </div>
            <div className={styles.Line} />
          </div>

          <Controller
            name="permissions.user"
            control={control}
            render={({ field: { onChange, value } }) => <SelectTags value={value} onChange={onChange} />}
          />
        </div>

        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <AssessmentPerm /> {t('general.diagnostic')}
            </div>
            <div className={styles.Line} />
          </div>

          <Controller
            control={control}
            name="permissions.assessment"
            render={({ field: { onChange, value } }) => (
              <SelectTags value={value} onChange={onChange} isAssessmentPermission />
            )}
          />
        </div>

        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <ModulePerm /> {t('general.module')}
            </div>
            <div className={styles.Line} />
          </div>

          <Controller
            control={control}
            name="permissions.module"
            render={({ field: { onChange, value } }) => (
              <SelectTags value={value} onChange={onChange} isEditPermissions />
            )}
          />
        </div>

        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <SectionPerm /> {t('general.section')}
            </div>
            <div className={styles.Line} />
          </div>

          <Controller
            control={control}
            name="permissions.section"
            render={({ field: { onChange, value } }) => (
              <SelectTags value={value} onChange={onChange} isEditPermissions />
            )}
          />
        </div>

        <div className={styles.PermissionsBlock}>
          <div className={styles.RoleAddHR}>
            <div className={styles.Line} />
            <div className={styles.Title}>
              <UnitPerm /> {t('general.unit')}
            </div>
            <div className={styles.Line} />
          </div>

          <Controller
            control={control}
            name="permissions.unit"
            render={({ field: { onChange, value } }) => (
              <SelectTags value={value} onChange={onChange} isEditPermissions />
            )}
          />
        </div>
      </form>
      <div className={classNames(styles.PermissionsBlock, styles.Checkboxes)}>
        <div className={styles.Col}>
          <Controller
            control={control}
            name="permissions.own_results"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.READ}
                onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
              >
                {t('roles.own_results')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="permissions.own_comparison"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.READ}
                onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
              >
                {t('roles.own_comparison')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="permissions.other_users_dashboard_comparison"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.READ}
                onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
              >
                {t('roles.other_users_dashboard_comparison')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="permissions.group_dashboard_comparison"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.READ}
                onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
              >
                {t('roles.group_dashboard_comparison')}
              </Checkbox>
            )}
          />
        </div>
        <div className={styles.Col}>
          <Controller
            control={control}
            name="permissions.additional_field"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.UPDATE}
                onChange={() => onChange(!value[0] ? [PermissionsAction.UPDATE] : [])}
              >
                {t('roles.additional_field')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="permissions.groups_users_report"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.READ}
                onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
              >
                {t('roles.groups_users_report')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="permissions.activity_report"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value && value[0] === PermissionsAction.READ}
                onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
              >
                {t('roles.activity_report')}
              </Checkbox>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default Permissions;
