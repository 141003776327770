import classNames from 'classnames';

import styles from './ErrorMessage.module.scss';

type Props = {
  title: string;
  errorFields: string[];
  className?: string;
};

export const ErrorMessage = ({ title, errorFields, className }: Props) => {
  return (
    <div className={classNames(styles.ErrorContainer, className)}>
      <span className={styles.Title}>{title}</span>
      <div className={styles.ErrorFields}>
        {errorFields.map((errorField) => (
          <div key={errorField} className={styles.ErrorField}>
            {errorField}
          </div>
        ))}
      </div>
    </div>
  );
};
