import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { generateKey } from 'helpers/generateKey';

import { baseAuthQueryWithReauth } from '../../helpers';

import {
  CopyUsersBulkPayload,
  CopyUsersBulkResponse,
  CreateUserPayload,
  CreateUserResponse,
  DeactivateUsersBulkPayload,
  DeactivateUsersBulkResponse,
  DeleteUserPayload,
  DeleteUserResponse,
  DeleteUsersBulkPayload,
  DeleteUsersBulkResponse,
  GetUserByIdPayload,
  GetUserByIdResponse,
  GetUsersAllPayload,
  GetUsersAllResponse,
  GetUsersPayload,
  GetUsersResponse,
  SaveUsersFromCsvPayload,
  SaveUsersFromCsvResponse,
  UpdateUserPayload,
  UpdateUserResponse,
  UpdateUsersBulkPayload,
  UpdateUsersBulkResponse,
  UploadUsersCsvPayload,
  UploadUsersCsvResponse,
  ValidateUserFromCsvPayload,
  ValidateUserFromCsvResponse,
} from './types';

import { setCSVUsers } from '../../features/usersSlice';
import { groupsApi } from '../groupsApi/groupsApi';

export const usersApi = createApi({
  tagTypes: ['Users', 'User'],
  reducerPath: 'usersApi',
  baseQuery: baseAuthQueryWithReauth,
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponse, GetUsersPayload>({
      query: ({ groupId, ...params }) => ({
        url: '/users/',
        params,
        headers: { group_id: groupId },
      }),
      transformResponse: (response: GetUsersResponse) => ({
        ...response,
        items: response.items.map((item, i) => ({ ...item, fieldId: i + 1 })),
      }),
      providesTags: ['Users'],
    }),

    getUsersAll: builder.query<GetUsersAllResponse, GetUsersAllPayload>({
      query: () => '/users/all/',
    }),

    getUserById: builder.query<GetUserByIdResponse, GetUserByIdPayload>({
      query: (id) => `/users/${id}/`,
      providesTags: ['User'],
    }),

    createUser: builder.mutation<CreateUserResponse, CreateUserPayload>({
      query: (user) => ({
        url: '/users/',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(
            groupsApi.endpoints.getGroupHierarchy.initiate(
              { search: '', orderBy: 'name', selectedGroup: '' },
              { subscribe: false, forceRefetch: true },
            ),
          );
        } catch (e) {}
      },
    }),

    uploadUsersCsv: builder.mutation<UploadUsersCsvResponse, UploadUsersCsvPayload>({
      query: (file) => ({
        url: '/users-upload/csv-file/',
        method: 'POST',
        body: file,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        const { data } = await queryFulfilled;
        if (!('detail' in data)) {
          dispatch(
            setCSVUsers({
              items: data.map((user, index) => ({ ...user, fieldId: index + 1, itemId: index + 1, id: generateKey() })),
            }),
          );
        }
      },
    }),

    saveUsersFromCsv: builder.mutation<SaveUsersFromCsvResponse, SaveUsersFromCsvPayload>({
      query: (users) => ({
        url: '/users-upload/save-csv-results/',
        method: 'POST',
        body: users,
      }),
      invalidatesTags: ['Users'],
    }),

    validateUserFromCsv: builder.mutation<ValidateUserFromCsvResponse, ValidateUserFromCsvPayload>({
      query: (user) => ({
        url: '/users-upload/validate-csv-user/',
        method: 'POST',
        body: user,
      }),
    }),

    updateUser: builder.mutation<UpdateUserResponse, UpdateUserPayload>({
      query: ({ id, user }) => ({
        url: `/users/${id}/`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: ['Users', 'User'],
    }),

    updateUsersBulk: builder.mutation<UpdateUsersBulkResponse, UpdateUsersBulkPayload>({
      query: ({ userIds, accesses }) => ({
        url: '/users/bulk-update/',
        method: 'PUT',
        body: {
          user_ids: userIds,
          accesses,
        },
      }),
      invalidatesTags: ['Users', 'User'],
    }),

    deleteUser: builder.mutation<DeleteUserResponse, DeleteUserPayload>({
      query: (id) => ({ url: `/users/${id}/`, method: 'DELETE' }),
      invalidatesTags: ['Users', 'User'],
    }),

    deleteUsersBulk: builder.mutation<DeleteUsersBulkResponse, DeleteUsersBulkPayload>({
      query: ({ groupId, usersIds }) => ({
        url: `/groups/${groupId}/users/`,
        method: 'DELETE',
        body: {
          user_ids: usersIds,
        },
      }),
      invalidatesTags: ['Users', 'User'],
    }),

    deactivateUsersBulk: builder.mutation<DeactivateUsersBulkResponse, DeactivateUsersBulkPayload>({
      query: ({ usersIds }) => ({
        url: '/users/deactivate/',
        method: 'POST',
        body: {
          user_ids: usersIds,
        },
      }),
      invalidatesTags: ['Users', 'User'],
    }),

    copyUsersBulk: builder.mutation<CopyUsersBulkResponse, CopyUsersBulkPayload>({
      query: ({ accesses, userIds }) => ({
        url: '/groups-access/bulk-copy/',
        method: 'POST',
        body: { accesses, user_ids: userIds },
      }),
    }),
  }),
});

export const { useGetUsersQuery, useGetUsersAllQuery, useGetUserByIdQuery } = usersApi;

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUploadUsersCsvMutation,
  useSaveUsersFromCsvMutation,
  useValidateUserFromCsvMutation,
  useDeleteUsersBulkMutation,
  useDeactivateUsersBulkMutation,
  useUpdateUsersBulkMutation,
  useCopyUsersBulkMutation,
} = usersApi;
