import classNames from 'classnames';

import Avatar from 'components/Avatar/Avatar';

import { IGroupAvatar } from 'types/dashboardTypes';

import styles from './GroupedAvatars.module.scss';

type Props = {
  isChecked?: boolean;
  displayCount?: number;
  avatars: IGroupAvatar[];
};

const GroupedAvatars = ({ isChecked, avatars, displayCount }: Props) => {
  const DISPLAY_COUNT = displayCount || 4;

  return (
    <div className={classNames(styles.Container, { [styles.Checked]: isChecked })}>
      {avatars.slice(0, DISPLAY_COUNT).map((item) => (
        <Avatar key={item.name} src={item.src}>
          {item.name}
        </Avatar>
      ))}

      {avatars.length > DISPLAY_COUNT && <div className={styles.More}>+{avatars.length - DISPLAY_COUNT}</div>}
    </div>
  );
};

export default GroupedAvatars;
