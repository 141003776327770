import { ReactNode, RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { OverlayTriggerHandle } from 'rsuite/Picker';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { OverlayTriggerType } from 'rsuite/esm/Overlay/OverlayTrigger';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import EditTooltipModal from 'components/Modals/EditTooltipModal/EditTooltipModal';
import Tooltip from 'components/Tooltip/Tooltip';

import { useAppSelector } from 'hooks/redux';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { TooltipType, VisualisationTypes } from 'types/dashboardTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';
import { ReactComponent as QuestionMark } from 'assets/images/question-mark.svg';

import styles from './DashboardTooltip.module.scss';

type DashboardTooltipContentProps = {
  title?: string;
  content: string;
  isEditable?: boolean;
  triggerRef?: RefObject<OverlayTriggerHandle>;
  onModalOpen?: (open: boolean) => void;
};

export const DashboardTooltipContent = ({
  title,
  content,
  isEditable = false,
  triggerRef,
  onModalOpen,
}: DashboardTooltipContentProps) => {
  const { t } = useTranslation();
  const { additional_field } = useAppSelector((state) => state.permissions.permissions);

  return (
    <div className={styles.TooltipContent}>
      {title && <h1>{title}</h1>}
      <p className={styles.Content}>{content}</p>
      {isEditable && checkPermission(additional_field, [PermissionsAction.UPDATE]) && (
        <ButtonStyled
          onClick={() => {
            onModalOpen?.(true);
            triggerRef?.current?.close();
          }}
          fill={ButtonFill.Transparent}
          size={ButtonSize.Link}
          icon={<Edit />}
        >
          {t('general.edit')}
        </ButtonStyled>
      )}
    </div>
  );
};

type DashboardTooltipProps = {
  id?: string;
  type?: TooltipType;
  visualisationType: VisualisationTypes;
  title?: string;
  content: string;
  children?: ReactNode;
  placement?: TypeAttributes.Placement;
  trigger?: OverlayTriggerType;
  open?: boolean;
  enterable?: boolean;
};

const DashboardTooltip = ({
  id,
  type = TooltipType.Chart,
  visualisationType,
  title,
  content,
  children,
  placement,
  trigger,
  open,
  enterable,
}: DashboardTooltipProps) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const triggerRef = useRef<OverlayTriggerHandle>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const isEditable = !!id;

  return (
    <>
      <Tooltip
        content={
          <DashboardTooltipContent
            title={title}
            content={content}
            isEditable={isEditable}
            triggerRef={triggerRef}
            onModalOpen={setModalOpen}
          />
        }
        placement={placement}
        trigger={trigger}
        open={open}
        enterable={enterable}
        ref={triggerRef}
        width={isMobile ? 192 : 260}
      >
        <div className={styles.Tooltip}>{children || <QuestionMark />}</div>
      </Tooltip>

      {isEditable && (
        <EditTooltipModal
          tooltip={{
            id,
            content,
            type,
            visualisationType,
          }}
          open={isModalOpen}
          close={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default DashboardTooltip;
