import { ReactNode, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'rsuite';
import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Permissions } from 'types/permissionsTypes';
import { RoleBulkTransformed } from 'types/roleTypes';

import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right.svg';

import RoleElement from '../RoleElement/RoleElement';

import styles from './PermissionsTable.module.scss';

type WrapperProps = {
  navigationPrevRef: RefObject<HTMLButtonElement>;
  navigationNextRef: RefObject<HTMLButtonElement>;
  children: ReactNode;
};

const Wrapper = ({ navigationPrevRef, navigationNextRef, children }: WrapperProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Content}>
      <div className={styles.Header}>
        <button ref={navigationPrevRef} className={styles.ControlButton}>
          <ArrowLeft />
        </button>

        <div className={styles.HeaderTitle}>{t('roles.user_roles')}</div>

        <button ref={navigationNextRef} className={styles.ControlButton}>
          <ArrowRight />
        </button>
      </div>

      {children}
    </div>
  );
};

type PermissionsTableProps = {
  roles: RoleBulkTransformed[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  onUpdateRolePermissions: (roleId: string, permissions: Permissions) => void;
};

const PermissionsTable = ({ roles, isLoading, isFetching, onUpdateRolePermissions }: PermissionsTableProps) => {
  const { t } = useTranslation();

  const navigationNextRef = useRef<HTMLButtonElement>(null);
  const navigationPrevRef = useRef<HTMLButtonElement>(null);

  if (isLoading || isFetching) {
    return (
      <Wrapper navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef}>
        <div className={styles.Loader}>
          <Loader size="lg" />
        </div>
      </Wrapper>
    );
  }

  if (!roles.length) {
    return (
      <Wrapper navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef}>
        <div className={styles.NoResults}>{t('roles.no_search_results')}</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef}>
      <Swiper
        className={styles.ScrolledContent}
        mousewheel={{ forceToAxis: true }}
        spaceBetween={0}
        slidesPerView={'auto'}
        allowTouchMove={false}
        preventClicks={false}
        preventClicksPropagation={false}
        modules={[Mousewheel, Navigation, FreeMode]}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          if (navigationNextRef.current) {
            swiper.navigation.nextEl = navigationNextRef.current;
          }
          if (navigationPrevRef.current) {
            swiper.navigation.prevEl = navigationPrevRef.current;
          }
        }}
      >
        {roles.map((role) => (
          <SwiperSlide key={role.role_id} className={styles.ScrolledElement}>
            <RoleElement role={role} onUpdateRolePermissions={onUpdateRolePermissions} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export default PermissionsTable;
