import { useTranslation } from 'react-i18next';

import { ModalProps } from 'types/modalTypes';

import Select from '../../Inputs/Select/Select';
import ModalWrapper from '../ModalWrapper';

interface Props extends ModalProps {
  roles: { value: string; label: string }[] | undefined;
  role: string;
  selectRole: (id: string) => void;
  onConfirm: () => void;
}

const SetRoles = ({ roles, role, selectRole, onConfirm, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      title={t('groups.manage.set_role')}
      subtitle={t('groups.manage.set_role_desc')}
      action={onConfirm}
      actionTitle={t('general.apply')}
      actionDisabled={!role}
      close={props.close}
    >
      <Select
        data={roles}
        value={role}
        onSelect={(value) => selectRole(value as string)}
        label={t('users.table.user_role')}
        placeholder={t('inputs.role_placeholder')}
      />
    </ModalWrapper>
  );
};

export default SetRoles;
