import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import orderBy from 'lodash/orderBy';

import { useAppSelector } from 'hooks/redux';

import { checkPermission } from 'helpers/checkUserPermission';

import { MIN_SEARCH_LENGTH } from 'constants/';

import { ISelectedSections, ITag } from 'types/addAssigneeModalTypes';
import { SortTypes } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import Scrollable from '../../Scrollable/Scrollable';
import SearchBar from '../../SearchBar/SearchBar';
import Tags from '../../Tags/Tags';
import AddAssigneeModal from '../AddAssigneeModal/AddAssigneeModal';
import ModalWrapper from '../ModalWrapper';

import styles from './AllAssigneesModal.module.scss';

type Props = {
  open: boolean;
  tags: ITag[];
  selectedSections: ISelectedSections;
  handleClose: () => void;
};

const AllAssigneesModal = ({ open, handleClose, tags, selectedSections, ...props }: Props) => {
  const { t } = useTranslation();
  const assessment = useAppSelector((state) => state.permissions.permissions.assessment);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortTypes>(SortTypes.ASC);
  const [openAddAssigneeModal, toggleAddAssigneeModal] = useState(false);

  const filteredTags =
    search.length < MIN_SEARCH_LENGTH ? tags : tags.filter((item) => item.title.toLowerCase().includes(search));
  const sortedTags = orderBy(filteredTags, [(item) => item.title.toLowerCase()], [sort]);

  const hasAssignPermission = checkPermission(assessment, [PermissionsAction.ASSIGN]);

  const handleCloseAddAssigneeModal = () => toggleAddAssigneeModal(false);

  const resetSearch = () => {
    setSearch('');
    setSort(SortTypes.ASC);
  };

  return openAddAssigneeModal ? (
    <AddAssigneeModal
      selectedSections={selectedSections}
      open={openAddAssigneeModal}
      handleClose={handleCloseAddAssigneeModal}
    />
  ) : (
    <ModalWrapper
      {...props}
      title={t('assessment.all_assignees')}
      subtitle={t('assessment.all_assignees_description')}
      cancelTitle={t('general.close')}
      open={open}
      close={handleClose}
      action={hasAssignPermission ? () => toggleAddAssigneeModal(true) : undefined}
      actionTitle={t('assessment.add_assignee')}
      clean={resetSearch}
    >
      <SearchBar search={search} onSearch={setSearch} sort={sort} onSort={setSort} />
      <Scrollable>
        {sortedTags.length ? (
          <div className={styles.TagsContainer}>
            <Tags tags={sortedTags} />
          </div>
        ) : (
          <span>{t('assessment.nothing_found')}</span>
        )}
      </Scrollable>
    </ModalWrapper>
  );
};

export default AllAssigneesModal;
