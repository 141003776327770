import React from 'react';

const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.4403 6.56066C16.6927 5.81314 15.4808 5.81311 14.7332 6.5606L7.33829 13.9548C7.15725 14.1359 7.02085 14.3566 6.93989 14.5994L6.02567 17.3421C5.96578 17.5218 6.01254 17.7198 6.14646 17.8538C6.28038 17.9877 6.47846 18.0344 6.65813 17.9746L9.40087 17.0603C9.64368 16.9794 9.86432 16.843 10.0453 16.662L17.4402 9.26783C18.1878 8.52029 18.1878 7.30823 17.4403 6.56066ZM15.4403 7.26774C15.7973 6.91074 16.3761 6.91076 16.7331 7.26777C17.0902 7.6248 17.0902 8.20367 16.7331 8.56069L15.9994 9.29437L14.7065 8.00148L15.4403 7.26774ZM13.9993 8.70855L15.2922 10.0014L9.33823 15.9549C9.26701 16.0261 9.18019 16.0798 9.08464 16.1116L7.29058 16.7096L7.88858 14.9157C7.92044 14.8201 7.97412 14.7332 8.04536 14.662L13.9993 8.70855Z"
      fill="#1899A2"
    />
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#1899A2" />
  </svg>
);

export default EditIcon;
