export const MIN_SEARCH_LENGTH = 2;
export const MAX_DESCR_FORM_LENGTH = 160;
export const MAX_NAME_FORM_LENGTH = 64;
export const MAX_SHORT_TEXT_ENTRY_LENGTH = 140;
export const MAX_LONG_TEXT_ENTRY_LENGTH = 2000;
export const MAX_LONG_TEXT_ENTRY_LENGTH_ALLOWED_KEYS = ['Backspace', 'Delete'];
export const ACCEPTED_MAPPINGS_NUMBERS = [0, 3];
export const MAX_QUESTION_MAPPING_NUMBER = 3;
export const MAX_UPLOAD_FILE_SIZE = 10485760;

export const ORANIZATION_ID_STORAGE_KEY = 'organization_id';

export const MOBILE_BREAKPOINT_VALUE = 932;
export const MOBILE_BREAKPOINT_HEADER_VALUE = 1120;
export const MOBILE_BREAKPOINT = `(max-width: ${MOBILE_BREAKPOINT_VALUE}px)`;
export const MOBILE_BREAKPOINT_HEADER = `(max-width: ${MOBILE_BREAKPOINT_HEADER_VALUE}px)`;
export const LANDSCAPE_BREAKPOINT = `(orientation:landscape)`;
