import { MouseEventHandler, ReactNode } from 'react';

export enum ModalSize {
  NoSpacing = 'NoSpacing',
}

export interface ModalProps {
  open: boolean;
  overflow?: boolean;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  actionTitle?: string;
  cancelTitle?: string;
  cleanAfterExited?: () => void;
  header?: ReactNode;
  footer?: ReactNode;
  status?: boolean;
  isForm?: boolean;
  action?: MouseEventHandler<HTMLButtonElement>;
  close?: () => void;
  clean?: () => void;
  modalSpacing?: ModalSize;
  modalWrapperClassName?: string;
  actionDisabled?: boolean;
  attention?: boolean;
  footerDeleteAction?: () => void;
  footerDeleteActionTitle?: string;
  large?: boolean;
}
