import styles from './Label.module.scss';

type Props = {
  label: string;
  image?: string | null;
};

const Label = ({ label, image }: Props) => {
  return (
    <div className={styles.Container}>
      <span>
        {image && <img src={image} alt={label} />}
        {label}
      </span>
    </div>
  );
};

export default Label;
