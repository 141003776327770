import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { Pagination } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import CheckboxButton from 'components/Buttons/SelectButton/CheckboxButton';
import DataTable from 'components/DataTable/DataTable';
import Select from 'components/Inputs/Select/Select';
import MobileBottomConfirmation from 'components/MobileBottomConfirmation/MobileBottomConfirmation';
import SetRoles from 'components/Modals/SetRoles/SetRoles';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';
import SortButton from 'components/SortButton/SortButton';

import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useSetRolesModal } from 'hooks/useSetRolesModal';
import useTableControl from 'hooks/useTableControl';

import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';
import { useSaveUsersFromCsvMutation } from 'store/api/usersApi/usersApi';
import { clearUsersCsv, setUserToEdit, updateCSVUsers } from 'store/features/usersSlice';

import ROUTES from 'router/routes';

import { handleNoPermissionError } from 'helpers/handleNoPermissionError';
import { getCurrentPageItems } from 'helpers/pagination';

import { MOBILE_BREAKPOINT } from 'constants/';

import { BulkAction, ButtonFill, ButtonSize, SortTypes } from 'types/enums';
import { MobileCardType, MobileCardVariant, MobileCardsData } from 'types/mobileTypes';
import { ActionType, ExtendedParsedUser, ParsedCSVUsers } from 'types/userTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from './UserAddCsv.module.scss';

const UserAddCsv = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const access: boolean = useLocation().state?.access;
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const { page, onPageChange, onSorting, sort, size } = useTableControl('');

  const { setRolesModal, onModalRoleChange, selectedRoleId, closeRolesModal, openRolesModal } = useSetRolesModal();
  const shouldClearUsersCSV = useRef(true);
  const [action, setAction] = useState<ActionType>('');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const usersFromCsv = useAppSelector((state) => state.users.usersFromCsv);
  const selectedGroup = useAppSelector((state) => state.auth.selected_group);

  const isSortable = !usersFromCsv.items.some((item) => item.errors.length);

  const { data: roles, isSuccess: rolesLoaded } = useGetRolesQuery({ groupId: selectedGroup });
  const [saveUsers, { isLoading }] = useSaveUsersFromCsvMutation();

  const data = {
    items: sortBy(
      orderBy(
        usersFromCsv.items,
        ['first_name'],
        [sort.sortType === SortTypes.ASC ? SortTypes.ASC : SortTypes.DESC],
      ).map((item) => ({
        ...item,
        errorMessages: item.errors.map((item) =>
          item.name && item.error
            ? t(item.error, {
                name: item.name[0].toUpperCase() + item.name.slice(1).toLowerCase().replace('_', ' '),
                ...(item.items?.length ? { value: item.items[0] } : {}),
              })
            : '',
        ),
      })),
      [(item) => !item.errors.length],
    ),
    total: usersFromCsv.items.length,
  };

  useEffect(() => {
    if (!access || !usersFromCsv?.items) navigate(ROUTES.USER_LIST);
  }, [access, navigate, usersFromCsv]);

  useEffect(() => {
    return () => {
      if (shouldClearUsersCSV.current) {
        dispatch(clearUsersCsv());
      }
    };
  }, [dispatch]);

  const checkCurrentPage = useCallback(
    (items: any[]) => {
      const currentItems = getCurrentPageItems(items, page, size);
      if (currentItems.length === 0) {
        onPageChange(Math.ceil(items.length / size));
      }
    },
    [onPageChange, page, size],
  );

  const bulkRoleChange = () => {
    const copiedData = { ...usersFromCsv };
    copiedData.items = copiedData.items.map((row) => {
      return selectionModel.includes(row.itemId)
        ? {
            ...row,
            accesses: row.accesses.map((item) => ({
              ...item,
              role_id: selectedRoleId,
              role_name: roles?.items.find((el) => el.id === selectedRoleId)?.name || '',
            })),
          }
        : { ...row };
    });

    dispatch(updateCSVUsers(copiedData));
    setSelectionModel([]);
    closeRolesModal();
  };

  const bulkDeactivate = () => {
    const copiedData = { ...usersFromCsv };

    copiedData.items = copiedData.items.map((row) =>
      selectionModel.includes(row.itemId) ? { ...row, is_active: false } : { ...row },
    );

    dispatch(updateCSVUsers(copiedData));
    setSelectionModel([]);
  };

  const bulkDelete = () => {
    const copiedData: ParsedCSVUsers = { ...usersFromCsv };
    copiedData.items = copiedData.items.filter((item) => !selectionModel.includes(item.itemId));
    dispatch(updateCSVUsers(copiedData));
    setSelectionModel([]);
    if (isMobile) {
      checkCurrentPage(copiedData.items);
    }
  };

  const onApplyClick = () => {
    switch (action) {
      case BulkAction.Delete:
        bulkDelete();
        break;
      case BulkAction.Role:
        openRolesModal();
        break;
      case BulkAction.Deactivate:
        bulkDeactivate();
        break;
    }
  };

  const selectUserToEdit = useCallback(
    (user: ExtendedParsedUser) => {
      dispatch(setUserToEdit(user));
      navigate(ROUTES.USER_ADD, { state: { isCSV: true } });
    },
    [dispatch, navigate],
  );

  const saveButtonHandler = () => {
    const payload = usersFromCsv.items.map((item) => ({
      email: item.email,
      first_name: item.first_name,
      last_name: item.last_name,
      is_active: item.is_active,
      accesses: item.accesses,
    }));

    saveUsers(payload)
      .unwrap()
      .then(() => navigate(ROUTES.USER_LIST))
      .catch((error) => handleNoPermissionError(error.status));
  };

  const deleteRowHandler = useCallback(
    (id: number) => {
      const copiedData: ParsedCSVUsers = { ...usersFromCsv };
      copiedData.items = copiedData.items.filter((el: ExtendedParsedUser) => el.itemId !== id);
      dispatch(updateCSVUsers(copiedData));
      if (isMobile) {
        checkCurrentPage(copiedData.items);
      }
    },
    [checkCurrentPage, dispatch, isMobile, usersFromCsv],
  );

  const getRoleValue = (row: ExtendedParsedUser) => `${row.accesses[0]?.role_name || ''}`;

  const handleSelectAll = () => {
    data?.items?.length && setSelectionModel(selectionModel.length ? [] : data.items.map((item) => item.itemId));
  };

  const getActions = useCallback(
    (row: ExtendedParsedUser) => [
      ...(isMobile
        ? [
            <CheckboxButton
              key="select"
              checked={selectionModel.includes(row.itemId)}
              onClick={() => {
                setSelectionModel((prev) => {
                  if (prev.includes(row.itemId)) {
                    return prev.filter((item) => item !== row.itemId);
                  }
                  return [...prev, row.itemId];
                });
              }}
              children={t('general.select')}
            />,
          ]
        : []),

      <ButtonStyled
        key="edit"
        onClick={() => {
          shouldClearUsersCSV.current = false;
          selectUserToEdit(row);
        }}
        fill={ButtonFill.Transparent}
        size={isMobile ? ButtonSize.Link : ButtonSize.Small}
        icon={<Edit />}
      >
        {t('general.edit')}
      </ButtonStyled>,
      <ButtonStyled
        key="delete"
        fill={ButtonFill.TransparentRed}
        size={isMobile ? ButtonSize.Link : ButtonSize.Small}
        onClick={(e) => {
          e.stopPropagation();
          row?.itemId && deleteRowHandler(row.itemId);
        }}
        icon={<Delete />}
      >
        {t('general.delete')}
      </ButtonStyled>,
    ],
    [deleteRowHandler, isMobile, selectUserToEdit, selectionModel, t],
  );

  const columns = useMemo<GridColDef<ExtendedParsedUser>[]>(
    () => [
      { field: 'fieldId', headerName: '#', sortable: false, width: 20 },
      {
        field: 'fullName',
        headerName: t('users.table.name'),
        description: '',
        sortable: isSortable,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => `${params.row.first_name || ''} ${params.row.last_name || ''}`,
      },
      {
        field: 'role',
        headerName: t('users.table.user_role'),
        description: '',
        sortable: isSortable,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<any, ExtendedParsedUser>) => getRoleValue(params.row),
      },
      {
        headerName: t('general.actions'),
        field: 'actions',
        headerAlign: 'left',
        width: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, ExtendedParsedUser>) => getActions(params.row),
      },
    ],
    [getActions, isSortable, t],
  );

  const mobileCardsData = useMemo<MobileCardsData>(
    () =>
      isMobile && data?.items
        ? data.items.map((item, index) => ({
            row: [
              ...(item?.errorMessages?.length
                ? [
                    {
                      columns: [
                        {
                          type: MobileCardType.Error,
                          value: item?.errorMessages?.join(', ') || '',
                          key: item.id + 1,
                        },
                      ],
                      key: item.id + 1,
                    },
                  ]
                : ([] as any[])),
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('users.table.name'),
                    value: `${item.first_name} ${item.last_name}`,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Ordinal,
                    label: '#',
                    value: index + 1,
                    key: item.id + 2,
                  },
                ],
                key: item.id + 2,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('users.table.user_role'),
                    value: getRoleValue(item),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 3,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Actions,
                    actions: getActions(item as ExtendedParsedUser),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 4,
              },
            ],
            key: item.id + Math.random(),
            variant: selectionModel.includes(item.itemId) ? MobileCardVariant.Selected : undefined,
          }))
        : [],
    [data.items, getActions, isMobile, selectionModel, t],
  );

  useDocumentTitle([t('page_titles.user_add_csv')]);

  return (
    <div className={styles.AddFromCSV}>
      {rolesLoaded && (
        <SetRoles
          open={setRolesModal}
          close={closeRolesModal}
          roles={roles.items.map((el) => ({ value: el.id, label: el.name }))}
          role={selectedRoleId}
          selectRole={onModalRoleChange}
          onConfirm={bulkRoleChange}
        />
      )}

      <PageTitle title={t('csv.add_user_from_csv')}>
        <ButtonStyled fill={ButtonFill.Outlined} size={ButtonSize.Small} onClick={() => navigate(ROUTES.USER_LIST)}>
          {t('general.cancel')}
        </ButtonStyled>

        <ButtonStyled
          disabled={!usersFromCsv.items?.length || usersFromCsv.items.some((item) => item.errors.length)}
          onClick={saveButtonHandler}
          loading={isLoading}
          fill={ButtonFill.Contained}
          size={ButtonSize.Small}
        >
          {t('general.save')}
        </ButtonStyled>
      </PageTitle>

      <div className={styles.BulkAction}>
        <div className={styles.Selector}>
          <Select
            size="sm"
            value={action}
            onSelect={(value) => setAction(value as ActionType)}
            label={t('csv.bulk_action')}
            placeholder={t('csv.select_action')}
            data={[
              { value: BulkAction.Delete, label: t('csv.delete_users') },
              { value: BulkAction.Role, label: t('csv.set_role') },
              { value: BulkAction.Deactivate, label: t('csv.deactivate_users') },
            ]}
          />
        </div>

        {isMobile ? (
          <SortButton sortType={sort.sortType} onSort={onSorting} />
        ) : (
          <ButtonStyled
            disabled={!selectionModel.length}
            onClick={onApplyClick}
            fill={ButtonFill.Outlined}
            size={ButtonSize.Small}
          >
            {t('general.apply')}
          </ButtonStyled>
        )}
      </div>

      {isMobile ? (
        <div className={styles.MobileContainer}>
          {mobileCardsData.length ? (
            <div className={styles.SelectAllButtonContainer}>
              <CheckboxButton
                checked={!!selectionModel.length}
                onClick={handleSelectAll}
                children={t('general.select_all')}
              />
            </div>
          ) : null}

          <>
            {mobileCardsData?.length ? (
              <MobileCards data={getCurrentPageItems(mobileCardsData, page, size)} />
            ) : (
              <NothingFoundText text={t('users.table.empty_result')} />
            )}
          </>

          {data?.items.length ? (
            <div className={styles.PaginationContainer}>
              <Pagination
                color="primary"
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / size)}
                onChange={(_, value) => onPageChange(value)}
              />
            </div>
          ) : null}

          <MobileBottomConfirmation active={!!(selectionModel.length && mobileCardsData.length)}>
            <span className={styles.SelectedText}>
              <span className={styles.Count}>
                {selectionModel.length} {selectionModel.length > 1 ? t('general.items') : t('general.item')}{' '}
              </span>
              {t('general.selected')}
            </span>
            <ButtonStyled
              disabled={!selectionModel.length || !action}
              onClick={onApplyClick}
              fill={ButtonFill.Outlined}
              size={ButtonSize.Small}
            >
              {t('general.apply')}
            </ButtonStyled>
          </MobileBottomConfirmation>
        </div>
      ) : (
        <DataTable
          enableSelectionOnClick
          getRowId={(row: ExtendedParsedUser) => row.fieldId}
          selectionModel={selectionModel}
          columns={columns}
          data={data}
          isLoading={false}
          page={page}
          changePage={onPageChange}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
        />
      )}
    </div>
  );
};

export default UserAddCsv;
