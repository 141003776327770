import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useDocumentTitle = (chunks: string[]): void => {
  const { i18n } = useTranslation();

  useEffect(() => {
    chunks.push('S Curve™');
    document.title = chunks.filter(Boolean).join(' | ');
  }, [chunks, i18n.language]);
};
