import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Loader from 'components/Loader/Loader';

import { MIN_SEARCH_LENGTH } from 'constants/';

import { CardVariants } from 'types/assessmentTypes';

type Props = {
  items: { title: string; value: string }[] | null;
  search: string;
  isLoading?: boolean;
  variant?: CardVariants;
};

const RadioValues = ({ items, search, isLoading, variant }: Props) => {
  const { t } = useTranslation();
  const filteredRadioButtons =
    search.length < MIN_SEARCH_LENGTH
      ? items
      : items?.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));

  const formTextColor = variant === CardVariants.Yellow ? 'var(--matte-black)' : 'var(--refined-teal)';
  const formColor = variant === CardVariants.Yellow ? 'var(--chartreuse)' : 'var(--refined-teal)';
  const formBackgroundColor = variant === CardVariants.Yellow ? 'var(--tusk)' : 'var(--light-teal)';

  const FormControlLabelSx = {
    margin: '0',
    padding: '6px 2px',
    border: '1px solid var(--cool-grey)',
    borderRadius: '6px',

    '.MuiFormControlLabel-label': {
      fontSize: '14px',
    },

    ':has(span.Mui-checked)': {
      border: `1px solid ${formColor}`,
      backgroundColor: formBackgroundColor,
    },
  };

  const RadioSx = {
    marginLeft: '0px',

    '& svg': {
      width: '16px',
      height: '16px',
    },

    '&.Mui-checked': {
      color: formTextColor,

      '& + .MuiFormControlLabel-label': {
        color: formTextColor,
      },
    },
  };

  return (
    <Fragment>
      {isLoading || items === null ? (
        <Loader flexCenter size="md" />
      ) : filteredRadioButtons?.length ? (
        filteredRadioButtons.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            label={item.title}
            sx={FormControlLabelSx}
            control={<Radio sx={RadioSx} />}
          />
        ))
      ) : (
        <span>{t('assessment.nothing_found')}</span>
      )}
    </Fragment>
  );
};

export default RadioValues;
