import { Permissions } from './permissionsTypes';
import { BaseUser } from './userTypes';

export interface Role {
  id: string;
  name: string;
  description?: string;
  organization_id?: string;
  level?: string;
  creator?: BaseUser;
}

export interface RolePermissions {
  permissions: { action: string; entity: string }[];
  role_id: string;
  role_name: string;
}

export interface RoleBulkTransformed {
  permissions: Permissions;
  role_id: string;
  role_name: string;
}

export enum RoleLevel {
  Nested = 'nested',
  Current = 'current',
  CurrentAndNested = 'current_and_nested',
}
