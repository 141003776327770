import { ReactNode } from 'react';

import Menu from 'components/Menu/Menu';

import styles from './Main.module.scss';

type Props = {
  showMenu: boolean;
  content: ReactNode;
};

const Main = ({ showMenu, content }: Props) => (
  <main className={styles.Main}>
    <div className={styles.Container}>
      {showMenu && <Menu />}
      {content}
    </div>
  </main>
);

export default Main;
