import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { BlockNames, CardVariants, HandleChangeRadioValueType, IRadioValues, MenuOptions } from 'types/assessmentTypes';

import Block from '../Block/Block';
import Line from '../Line/Line';

import styles from '../../AssessmentCompletion.module.scss';

type Props = {
  order: number;
  radioValues: IRadioValues;
  isSelectAnotherUser?: boolean;
  hidden?: boolean;
  variant?: CardVariants;
  handleChangeRadioValue: HandleChangeRadioValueType;
  menuOption?: MenuOptions;
};

const SelectManyCard = ({
  order,
  hidden,
  variant,
  isSelectAnotherUser,
  radioValues,
  handleChangeRadioValue,
  menuOption,
}: Props) => {
  const { t } = useTranslation();

  const userValue = radioValues[BlockNames.User][BlockNames.User + order].value;

  return (
    <div
      className={classNames(styles.Container, {
        [styles.Hidden]: hidden,
      })}
    >
      <Block
        title={t('assessment.user')}
        order={order}
        name={BlockNames.User}
        hidden={false}
        variant={variant}
        isSelectAnotherUser={isSelectAnotherUser}
        radioValues={radioValues}
        handleChangeRadioValue={handleChangeRadioValue}
        menuOption={menuOption}
      />
      <Line variant={variant} />
      <Block
        title={t('assessment.date')}
        order={order}
        name={BlockNames.AssessmentTake}
        hidden={order === 2 && isSelectAnotherUser ? false : !userValue}
        variant={variant}
        radioValues={radioValues}
        handleChangeRadioValue={handleChangeRadioValue}
        menuOption={menuOption}
      />
    </div>
  );
};

export default SelectManyCard;
