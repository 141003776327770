import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Select from 'components/Inputs/Select/Select';

import { UserStatus } from 'helpers/enums';

import styles from './Status.module.scss';

type PropsRow = {
  required: boolean;
  value: string;
  onChange: (value: string) => void;
  error: any;
  errorMessage: string;
  isActive?: never;
  isRow?: never;
};

type PropsSelect = {
  isActive: boolean;
  isRow: boolean;
  required?: never;
  value?: never;
  onChange?: never;
  error?: never;
  errorMessage?: never;
};

type Props = PropsRow | PropsSelect;

const Status = ({ isActive, isRow, required, value, onChange, error, errorMessage }: Props) => {
  const { t } = useTranslation();

  const userStatusData = [
    {
      value: UserStatus.Active,
      label: (
        <div className={styles.Active}>
          <div className={styles.Status} />
          {t('general.active')}
        </div>
      ),
    },
    {
      value: UserStatus.Disabled,
      label: (
        <div className={styles.Disabled}>
          <div className={styles.Status} />
          {t('general.disabled')}
        </div>
      ),
    },
  ];

  return isRow ? (
    <div className={styles.StatusRow}>
      <span>{t('inputs.status')}</span>
      <div className={styles.StatusField}>
        <div className={classNames(isActive ? styles.Active : styles.Disabled)}>
          <div className={styles.Status} />
          {isActive ? t('general.active') : t('general.disabled')}
        </div>
      </div>
    </div>
  ) : (
    <Select
      required={required}
      value={value}
      onSelect={onChange!}
      label={t('inputs.status')}
      data={userStatusData}
      error={error}
      error_message={errorMessage}
    />
  );
};

export default Status;
