import { useEffect, useMemo, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';

import Loader from 'components/Loader/Loader';

import { useUploadImageMutation } from 'store/api/assessmentApi/assessmentApi';

import { toBase64 } from 'helpers/file';

import { MAX_UPLOAD_FILE_SIZE } from 'constants/index';

import { AssessmentSettings } from 'types/createAssessmentTypes';

import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import EditIcon from 'assets/svg/create-assesment/EditIconSvg';

import styles from '../../AssessmentSettingTab.module.scss';

type Props = {
  setValue: UseFormSetValue<AssessmentSettings>;
  error: string;
  preview: string;
};

const FileUpload = ({ setValue, error, preview }: Props) => {
  const [image, setImage] = useState<FileType[]>([]);
  const [imageError, setImageError] = useState<null | string>(null);
  const uploader = useRef();
  const { t } = useTranslation();
  const reader = useMemo(() => new FileReader(), []);

  const [uploadImage, { isLoading: isLoadingUploadImage }] = useUploadImageMutation();

  const onImageUpload = (file: FileType[]) => {
    if (file[0].blobFile) {
      const fileSize = file[0].blobFile.size;

      if (fileSize <= MAX_UPLOAD_FILE_SIZE) {
        setImage(file);
      } else {
        setImage([]);
        setImageError(t('validation.max_file_size'));
      }
    }
  };

  useEffect(() => {
    if (error) {
      setImageError(error);
    }
  }, [error]);

  useEffect(() => {
    if (imageError) {
      setTimeout(() => {
        setImageError(null);
      }, 1000);
    }
  }, [imageError]);

  useEffect(() => {
    if (image[0] && image[0].blobFile) {
      toBase64(image[0].blobFile, reader).then((base64) => {
        uploadImage({ image_base64: base64 as string })
          .unwrap()
          .then(({ image_url }) => setValue('assessment_image', image_url));
      });
    }
  }, [image, reader, setValue, uploadImage]);

  const handleEditPreview = () => {
    setImage([]);
    setValue('assessment_image', '');
  };

  return (
    <div className={styles.FileUploader}>
      {preview ? (
        <div className={styles.PreviewContainer}>
          <img src={preview} alt="" />
          <EditIcon onClick={handleEditPreview} />
        </div>
      ) : (
        <Uploader
          onChange={(fileList) => onImageUpload(fileList)}
          action=""
          ref={uploader}
          accept=".png, .jpg, .jpeg"
          fileListVisible={false}
          className={classNames(styles.DndBlock, imageError && styles.DndError)}
          autoUpload={false}
          multiple={false}
          draggable
          fileList={image}
        >
          <div>
            {isLoadingUploadImage ? (
              <Loader flexCenter />
            ) : (
              <>
                <small className={styles.ErrorText}>{imageError}</small>
                <UploadIcon className={styles.DragImage} />
                <p className={styles.DragText}>{t('csv.drag_title')}</p>
                <div className={styles.DragSelectText}>
                  <p className={styles.ActiveText}>{t('csv.drag_select_text_1')} </p> {t('csv.drag_select_text_2')}
                </div>
              </>
            )}
          </div>
        </Uploader>
      )}
    </div>
  );
};

export default FileUpload;
