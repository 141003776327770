import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';

import { DashboardTooltipContent } from 'components/Dashboard/components/DashboardTooltip/DashboardTooltip';

import ComparisonTooltip from '../components/ComparisonTooltip/ComparisonTooltip';
import TooltipBody from '../components/TooltipBody/TooltipBody';

import { IDashboardTooltip, IDashboardTooltipComparison, SelectedGroups } from 'types/dashboardTypes';

import styles from './ChartTooltip.module.scss';

type Props = {
  isGroupDashboard: boolean;
  isComparisonMode?: boolean;
  selectedGroups?: SelectedGroups;
  tooltipData: IDashboardTooltip | [IDashboardTooltipComparison, IDashboardTooltipComparison];
  handleDetails: () => void;
  isPointsUsersComparison?: boolean;
  handleHideTooltip: () => void;
  tooltipCoords: {
    x: number;
    y: number;
  };
  chartTooltipOutletId?: string;
};

const ChartTooltip = ({
  isGroupDashboard,
  isComparisonMode,
  selectedGroups,
  tooltipData,
  handleDetails,
  isPointsUsersComparison,
  handleHideTooltip,
  tooltipCoords,
  chartTooltipOutletId,
}: Props) => {
  const [breadcrumbOutlet, setBreadcrumbOutlet] = useState<HTMLDivElement>();

  useEffect(() => {
    const outletElement = chartTooltipOutletId && (document.getElementById(chartTooltipOutletId) as HTMLDivElement);
    if (outletElement) {
      setBreadcrumbOutlet(outletElement);
    }
  }, [chartTooltipOutletId]);

  const renderTooltipContent = () => {
    if (isGroupDashboard) {
      if (isComparisonMode && selectedGroups) {
        return (
          <ComparisonTooltip
            tooltipData={tooltipData as [IDashboardTooltipComparison, IDashboardTooltipComparison]}
            selectedGroups={selectedGroups}
            handleDetails={handleDetails}
          />
        );
      }

      return (
        <div className={classNames(styles.PointTooltip, styles.DashboardGroup)}>
          <TooltipBody tooltipData={tooltipData as IDashboardTooltipComparison} />
        </div>
      );
    }

    return (
      <div className={styles.PointTooltip}>
        <DashboardTooltipContent
          title={(tooltipData as IDashboardTooltip)?.title}
          content={(tooltipData as IDashboardTooltip)?.content}
        />
      </div>
    );
  };

  if (!breadcrumbOutlet) {
    return (
      <ClickAwayListener onClickAway={isPointsUsersComparison ? handleHideTooltip : () => {}}>
        <div
          style={{
            top: `${tooltipCoords.y}px`,
            left: `${tooltipCoords.x}px`,
            pointerEvents: isPointsUsersComparison ? 'all' : 'none',
          }}
          className={styles.TooltipContainer}
          key={`${tooltipCoords.x},${tooltipCoords.y}`}
        >
          {renderTooltipContent()}
        </div>
      </ClickAwayListener>
    );
  }

  return createPortal(
    <ClickAwayListener onClickAway={isPointsUsersComparison ? handleHideTooltip : () => {}}>
      <div
        style={{
          top: `${tooltipCoords.y}px`,
          left: `${tooltipCoords.x}px`,
          pointerEvents: isPointsUsersComparison ? 'all' : 'none',
        }}
        className={styles.TooltipContainer}
        key={`${tooltipCoords.x},${tooltipCoords.y}`}
      >
        {renderTooltipContent()}
      </div>
    </ClickAwayListener>,
    breadcrumbOutlet,
  );
};

export default ChartTooltip;
