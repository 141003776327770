import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import AddSingleUser from 'components/Modals/AddSingleUser/AddSingleUser';

import { useAppSelector } from 'hooks/redux';

import { GetRolesResponse } from 'store/api/rolesApi/types';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { descriptionFieldTransform } from 'helpers/descriptionFieldTranform';

import { ButtonFill, ButtonSize, Size } from 'types/enums';
import { GroupHierarchyType, IGroupHierarchyItem } from 'types/groupHierarchyTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as CaretIcon } from 'assets/images/caret.svg';
import { ReactComponent as AddIcon } from 'assets/images/plus.svg';

import styles from './LevelHeader.module.scss';

type Props = {
  size: Size;
  data: IGroupHierarchyItem;
  roles: GetRolesResponse | undefined;
  open: boolean;
  toggle: () => void;
};

const LevelHeader = ({ size, data, roles, open, toggle }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.permissions.permissions.user);
  const group = useAppSelector((state) => state.permissions.permissions.group);

  const [addUser, showAddUser] = useState(false);
  const haveChildren = data.children.length > 0 || data.users.length > 0;

  const hasCreateUserPermission = checkPermission(user, [PermissionsAction.CREATE]);
  const hasCreateGroupPermission = checkPermission(group, [PermissionsAction.CREATE]);

  const createGroupText = {
    [Size.Lg]: data.path.path === '0' ? t('groups.create_organization') : t('groups.create_group'),
    [Size.Md]: data.type === GroupHierarchyType.Organization ? t('groups.create_group') : t('groups.create_subgroup'),
    [Size.Sm]: t('groups.create_subgroup'),
  }[size].toUpperCase();

  const handleToggle = () => haveChildren && toggle();

  const handleAddUser = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showAddUser(true);
  };

  return (
    <>
      <div
        className={classNames(styles.Element, { [styles.Active]: open }, size && styles[size])}
        onClick={handleToggle}
      >
        {haveChildren ? (
          <CaretIcon className={classNames(styles.Caret, { [styles.Active]: open })} />
        ) : (
          <div className={styles.FakeCaret}> - </div>
        )}
        <div className={styles.Path}>{data.path_in_tree}</div>
        <div className={styles.Name}>
          <span onClick={() => navigate(ROUTES.GROUP_EDIT(data.id), { state: { isGroupHierarchy: true } })}>
            {data.name}
          </span>
        </div>
        <div className={styles.Description}>{descriptionFieldTransform(data.description || '-')}</div>
        {(hasCreateUserPermission || hasCreateGroupPermission) && (
          <div className={styles.Actions}>
            {hasCreateUserPermission && (
              <ButtonStyled
                onClick={handleAddUser}
                fill={ButtonFill.Transparent}
                size={ButtonSize.Small}
                icon={<AddIcon />}
              >
                {t('users.add_user')}
              </ButtonStyled>
            )}
            {hasCreateGroupPermission && (
              <Link
                className="font14 weight400"
                to={`${ROUTES.GROUP_ADD}?group=${data.id}`}
                state={{ isGroupHierarchy: true }}
              >
                <ButtonStyled
                  onClick={undefined}
                  fill={ButtonFill.Transparent}
                  size={ButtonSize.Small}
                  icon={<AddIcon />}
                >
                  {createGroupText}
                </ButtonStyled>
              </Link>
            )}
          </div>
        )}
      </div>

      <AddSingleUser open={addUser} groups={{ items: [data] }} group_id={data.id} close={() => showAddUser(false)} />
    </>
  );
};

export default LevelHeader;
