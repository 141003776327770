import { ChangeEvent } from 'react';

import classNames from 'classnames';
import { ValueType } from 'rsuite/esm/Checkbox';

import Checkbox from 'components/Inputs/Checkbox/Checkbox';

import { descriptionFieldTransform } from 'helpers/descriptionFieldTranform';

import { Size } from 'types/enums';
import { GroupHierarchyType, IGroupHierarchyItem, IGroupsHierarchyList } from 'types/groupHierarchyTypes';

import { ReactComponent as CaretIcon } from 'assets/images/caret.svg';

import styles from './LevelHeader.module.scss';

type Props = {
  data: IGroupHierarchyItem;
  list: IGroupsHierarchyList;
  size: Size;
  onSelectEntity: (value: string, entity: string) => void;
  open: boolean;
  toggle: () => void;
};

const LevelHeader = ({ size, data, list, onSelectEntity, open, toggle }: Props) => {
  const haveChildren = !!data.children.length || !!data.users.length;

  const handleToggle = () => haveChildren && toggle();

  const itemChecked = list.groups.includes(data.id);

  const onSelect = (value: ValueType = '', checked: boolean, event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSelectEntity(value.toString(), GroupHierarchyType.Group);
  };

  return (
    <>
      <div
        className={classNames(styles.Element, size && styles[size], {
          [styles.Active]: open,
          [styles.Checked]: itemChecked,
        })}
        onClick={handleToggle}
      >
        {haveChildren ? (
          <CaretIcon className={classNames(styles.Caret, { [styles.Active]: open })} />
        ) : (
          <div className={styles.FakeCaret}> - </div>
        )}

        <div className="GlobalCheckbox">
          <Checkbox
            value={data.id}
            checked={list.groups.includes(data.id)}
            onChange={onSelect}
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        <div className={styles.Path}>{data.path.path}</div>
        <div className={styles.Name}>
          <span>{data.name}</span>
        </div>
        <div className={styles.Description}>{descriptionFieldTransform(data.description || '-')}</div>
      </div>
    </>
  );
};

export default LevelHeader;
