import { ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Collapse } from '@mui/material';
import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { MOBILE_BREAKPOINT } from 'constants/';

import { BarValue } from 'types/accelerantTypes';
import { DashboardType, IDashboardResultPoint, IDashboardResultPointGroupAccelerants } from 'types/dashboardTypes';
import { IDashboardTooltip, VisualisationTypes } from 'types/dashboardTypes';
import { ButtonFill, ButtonSize, Size } from 'types/enums';

import DashboardTooltip from '../../../DashboardTooltip/DashboardTooltip';
import Bars from '../Bars/Bars';
import GroupBars from '../GroupBars/GroupBars';

import styles from './Accelerant.module.scss';

type Props = {
  value: BarValue;
  title: string;
  label?: ReactNode;
  size?: typeof Size.Sm | typeof Size.Lg;
  tooltip?: IDashboardTooltip;
  children?: ReactElement;
  points: IDashboardResultPoint[] | IDashboardResultPointGroupAccelerants[];
  dashboardType: DashboardType;
};

const Accelerant = ({ title, value, tooltip, children, points, dashboardType, size = Size.Lg }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const [isOpen, setIsOpen] = useState(false);

  const RenderBars = {
    [DashboardType.GROUP]: (
      <GroupBars size={size} value={value} points={points as IDashboardResultPointGroupAccelerants[]} />
    ),
    [DashboardType.USER]: <Bars size={size} value={value} />,
  };

  return (
    <div className={styles.Container}>
      <div className={classNames(styles.TitleContainer, styles[size])}>
        <span className={styles.Title}>{title}</span>
        {tooltip && (
          <div className={styles.DashboardTooltipContainer}>
            <DashboardTooltip
              id={tooltip.id}
              visualisationType={VisualisationTypes.Accelerants}
              title={tooltip.title}
              content={tooltip.content}
              placement="auto"
            />
          </div>
        )}
        {isMobile && children && (
          <div className={styles.ButtonContainer}>
            <ButtonStyled
              fullWidth
              size={ButtonSize.Small}
              fill={ButtonFill.Outlined}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? t('general.hide') : t('general.details')}
            </ButtonStyled>
          </div>
        )}
      </div>

      <div className={styles.AccelerantsContainer}>
        {RenderBars[dashboardType]}
        {!isMobile && children && (
          <div className={styles.ButtonContainer}>
            <ButtonStyled
              fullWidth
              size={ButtonSize.Small}
              fill={ButtonFill.Outlined}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? t('general.close') : t('general.details')}
            </ButtonStyled>
          </div>
        )}
      </div>

      <Collapse in={isOpen} unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

export default Accelerant;
