import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import classNames from 'classnames';

import { leadingZero, roundPercentage } from 'helpers/dashboard';

import { BarValue } from 'types/accelerantTypes';
import { IDashboardResultPointGroupAccelerants } from 'types/dashboardTypes';
import { Size } from 'types/enums';

import styles from './GroupBars.module.scss';

type Props = {
  value: BarValue;
  points: IDashboardResultPointGroupAccelerants[];
  size?: typeof Size.Sm | typeof Size.Lg;
};

const GroupBars = ({ value, points, size = Size.Lg }: Props) => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  const activeBackground = {
    [BarValue.Low]: styles.Low,
    [BarValue.Medium]: styles.Medium,
    [BarValue.High]: styles.High,
  }[value];

  const [left, middle, right] = points;

  return (
    <div
      ref={ref}
      className={classNames(styles.Container, styles[size], activeBackground, styles.AnimationSm, {
        [styles.AnimationLg]: inView && size === Size.Lg,
      })}
    >
      <div className={classNames(styles.BarContainer)}>
        <div className={styles.BarWrapper}>
          <div className={classNames(styles.Bar)}>
            <span className={classNames({ [styles.Visible]: value === BarValue.Low })}>{t('general.low')}</span>
          </div>
        </div>
        <div className={styles.BarValue}>
          <span>{`${leadingZero(roundPercentage(left.percentage * 100))}%`}</span>
          <span>{leadingZero(left.amount)}</span>
        </div>
      </div>
      <div className={classNames(styles.BarContainer)}>
        <div className={styles.BarWrapper}>
          <div className={classNames(styles.Bar)}>
            <span className={classNames({ [styles.Visible]: value === BarValue.Medium })}>{t('general.medium')}</span>
          </div>
        </div>
        <div className={styles.BarValue}>
          <span>{`${leadingZero(roundPercentage(middle.percentage * 100))}%`}</span>
          <span>{leadingZero(middle.amount)}</span>
        </div>
      </div>
      <div className={classNames(styles.BarContainer)}>
        <div className={styles.BarWrapper}>
          <div className={classNames(styles.Bar)}>
            <span className={classNames({ [styles.Visible]: value === BarValue.High })}>{t('general.high')}</span>
          </div>
        </div>
        <div className={styles.BarValue}>
          <span>{`${leadingZero(roundPercentage(right.percentage * 100))}%`}</span>
          <span>{leadingZero(right.amount)}</span>
        </div>
      </div>
    </div>
  );
};

export default GroupBars;
