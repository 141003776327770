import { useSearchParams } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import Loader from 'components/Loader/Loader';

import { useAppSelector } from 'hooks/redux';
import { useComparisonInfo } from 'hooks/useComparisonInfo';

import { ComparisonType } from 'types/assessmentTypes';
import { DashboardSearchParams, DashboardType } from 'types/dashboardTypes';
import { CardColor } from 'types/enums';

import DashboardCardInfo from '../../../DashboardCardInfo/DashboardCardInfo';
import LegendLine from '../LegendLine/LegendLine';

type LegendBottomProps = {
  dashboardType: DashboardType;
  direction: 'row' | 'column';
};

const LegendBottom = ({ dashboardType, direction }: LegendBottomProps) => {
  const { comparisonType } = useAppSelector((state) => state.permissions);

  const isOwnDashboard = comparisonType === ComparisonType.Own;

  const [searchParams] = useSearchParams();

  const groupIdLeft = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const groupIdRight = searchParams.get(DashboardSearchParams.GROUP_2) || '';

  const userIdLeft = searchParams.get(DashboardSearchParams.USER_1) || '';
  const userIdRight = searchParams.get(DashboardSearchParams.USER_2) || '';

  const completionIdLeft = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
  const completionIdRight = searchParams.get(DashboardSearchParams.COMPLETION_2) || '';

  const { isLoading, groupLeft, groupRight, userLeft, userRight, completionLeft, completionRight } = useComparisonInfo({
    dashboardType,
    groupIdLeft,
    groupIdRight,
    userIdLeft,
    userIdRight,
    completionIdLeft,
    completionIdRight,
  });

  const renderComparisonInfoLabel = (side: 'left' | 'right') => {
    const group = side === 'left' ? groupLeft : groupRight;
    const user = side === 'left' ? userLeft : userRight;
    const completion = side === 'left' ? completionLeft : completionRight;
    const cardColor = side === 'left' ? CardColor.Blue : CardColor.Yellow;

    if (dashboardType === DashboardType.GROUP) {
      return (
        <DashboardCardInfo
          group={group}
          groupColor={cardColor}
          assessmentName={completion?.assessment_name}
          assessmentNameColor={cardColor}
        />
      );
    }

    if (dashboardType === DashboardType.USER) {
      if (isOwnDashboard) {
        return (
          <DashboardCardInfo
            assessmentName={completion?.assessment_name}
            assessmentNameColor={cardColor}
            hideUserName
          />
        );
      }

      return (
        <DashboardCardInfo
          user={user}
          userColor={cardColor}
          assessmentName={completion?.assessment_name}
          assessmentNameColor={cardColor}
          hideUserName
        />
      );
    }

    return null;
  };

  if (isLoading) {
    return (
      <Box mt={2}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box mt={2} overflow="hidden">
      <Stack
        direction={direction}
        alignItems={direction === 'row' ? 'center' : 'flex-start'}
        spacing={direction === 'row' ? 8 : 1}
      >
        <Stack direction="row" alignItems="center" spacing={4} minWidth={0} width={'100%'}>
          <Box flexShrink={0}>
            <LegendLine color={CardColor.Blue} />
          </Box>

          {renderComparisonInfoLabel('left')}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={4} minWidth={0} width={'100%'}>
          <Box flexShrink={0}>
            <LegendLine color={CardColor.Yellow} />
          </Box>

          {renderComparisonInfoLabel('right')}
        </Stack>
      </Stack>
    </Box>
  );
};

export default LegendBottom;
