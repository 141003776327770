import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Accelerants from 'components/Dashboard/components/Accelerants/Accelerants';
import Ecosystem from 'components/Dashboard/components/Ecosystem/Ecosystem';
import SCurve from 'components/Dashboard/components/SCurve/SCurve';
import Loader from 'components/Loader/Loader';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useGetCompletionByIdQuery } from 'store/api/assessmentApi/assessmentApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { DashboardType } from 'types/dashboardTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import AssessmentInfo from '../AssessmentInfo/AssessmentInfo';

import styles from './AssessmentCompare.module.scss';

const AssessmentCompare = () => {
  const { t } = useTranslation();
  const { id1: requestId1 = '', id2: requestId2 = '' } = useParams();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { other_users_dashboard_comparison, own_comparison } = useAppSelector((state) => state.permissions.permissions);
  const hasPermission =
    checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ]) ||
    checkPermission(own_comparison, [PermissionsAction.READ]);

  const {
    data: completionLeft,
    isLoading: isLoadingCompletionLeft,
    isFetching: isFetchingCompletionLeft,
  } = useGetCompletionByIdQuery({ id: requestId1, groupId: selectedGroupId }, { skip: !requestId1 });

  useDocumentTitle([t('page_titles.diagnostic_compare')]);

  if (!hasPermission) {
    return (
      <div className={styles.AssessmentComparePage}>
        <NothingFoundText text={t('general.no_permission')} />
      </div>
    );
  }

  return isLoadingCompletionLeft || isFetchingCompletionLeft ? (
    <Loader flexCenter size="md" />
  ) : (
    <div className={styles.AssessmentComparePage}>
      <Breadcrumb
        items={[
          { name: t('assessment.diagnostic_results'), href: ROUTES.DIAGNOSTIC_RESULTS },
          {
            name: t('assessment.comparison'),
            href: ROUTES.DIAGNOSTIC_COMPARE(requestId1, requestId2),
            active: true,
          },
        ]}
      />

      <PageTitle title={t('assessment.comparison')} />

      {completionLeft && <AssessmentInfo completionLeft={completionLeft} />}

      <div>
        <SCurve dashboardType={DashboardType.USER} requestId1={requestId1} requestId2={requestId2} />

        <Accelerants dashboardType={DashboardType.USER} requestId1={requestId1} requestId2={requestId2} />

        <Ecosystem dashboardType={DashboardType.USER} requestId1={requestId1} requestId2={requestId2} />
      </div>
    </div>
  );
};

export default AssessmentCompare;
