import { Avatar as AvatarMUI } from '@mui/material';

import styles from './Avatar.module.scss';

type Props = {
  src: string | null | undefined;
  children: string;
};

const Avatar = ({ src, children }: Props) => {
  const splittedName = children.split(' ');
  const firstName = splittedName[0];
  const lastName = splittedName[1];
  const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();

  return (
    <AvatarMUI src={src || ''} className={styles.Container}>
      {initials}
    </AvatarMUI>
  );
};

export default Avatar;
