import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@mui/material';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { IAccelerant } from 'types/accelerantTypes';
import { DashboardType } from 'types/dashboardTypes';
import { ButtonFill, ButtonSize, Size } from 'types/enums';

import Accelerant from '../Accelerant/Accelerant';

import styles from './AccelerantsComparison.module.scss';

type Props = {
  accelerants: [IAccelerant, IAccelerant];
  dashboardType: DashboardType;
};

const AccelerantsComparison = ({ accelerants, dashboardType }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [leftAccelerant, rightAccelerant] = accelerants;

  return (
    <div className={styles.SectionWrapper}>
      <div className={styles.ComparisonContainer}>
        <div className={styles.AccelerantContainer}>
          <Accelerant
            size={Size.Lg}
            title={leftAccelerant.title}
            value={leftAccelerant.value}
            tooltip={leftAccelerant.tooltip}
            points={leftAccelerant.points}
            dashboardType={dashboardType}
          />
          <Collapse in={isOpen} unmountOnExit>
            <div className={styles.AdditionalAccelerants}>
              {leftAccelerant.subAccelerants?.map((item) => (
                <Accelerant
                  key={item.id}
                  size={Size.Sm}
                  title={item.title}
                  value={item.value}
                  tooltip={item.tooltip}
                  points={item.points}
                  dashboardType={dashboardType}
                />
              ))}
            </div>
          </Collapse>
        </div>

        <div className={styles.AccelerantContainer}>
          <Accelerant
            size={Size.Lg}
            title={rightAccelerant.title}
            value={rightAccelerant.value}
            tooltip={rightAccelerant.tooltip}
            points={rightAccelerant.points}
            dashboardType={dashboardType}
          />
          <Collapse in={isOpen} unmountOnExit>
            <div className={styles.AdditionalAccelerants}>
              {rightAccelerant.subAccelerants?.map((item) => (
                <Accelerant
                  key={item.id}
                  size={Size.Sm}
                  title={item.title}
                  value={item.value}
                  tooltip={item.tooltip}
                  points={item.points}
                  dashboardType={dashboardType}
                />
              ))}
            </div>
          </Collapse>
        </div>
      </div>
      <div className={styles.ButtonContainer}>
        <ButtonStyled fullWidth size={ButtonSize.Small} fill={ButtonFill.Outlined} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? t('general.close') : t('general.details')}
        </ButtonStyled>
      </div>
    </div>
  );
};

export default AccelerantsComparison;
