import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from '@mui/material/Pagination';
import { useGridApiContext } from '@mui/x-data-grid';

import Select from '../../../Inputs/Select/Select';

type Props = {
  page: number;
  pageSize?: number;
  count: number;
  changePage: (value: number) => void;
  enablePageSize: boolean;
  changePageSize: Dispatch<
    SetStateAction<{
      pageSize: number;
      page: number;
    }>
  >;
};

const PAGE_SIZES = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];

export const CustomPagination = ({ page, pageSize = 10, count, changePage, enablePageSize, changePageSize }: Props) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();

  const changePaginationPage = (value: number) => {
    changePageSize((prev) => ({ ...prev, page: Number(value) }));
    changePage(value);
    apiRef.current.setPage(value - 1);
  };

  if (enablePageSize) {
    return (
      <div className="pagination-page-size">
        <div className="per-size-block">
          <div className="per-size-block__text">{t('general.rows_per_page')}</div>
          <Select
            value={pageSize}
            size="sm"
            data={PAGE_SIZES}
            onSelect={(value) => changePageSize((prev) => ({ ...prev, pageSize: Number(value) }))}
            placement="auto"
          />
        </div>

        <Pagination
          color="primary"
          shape="rounded"
          page={page}
          count={Math.ceil(count / pageSize)}
          onChange={(event, value) => changePaginationPage(value)}
        />
      </div>
    );
  } else {
    return (
      <Pagination
        color="primary"
        shape="rounded"
        page={page}
        count={Math.ceil(count / pageSize)}
        onChange={(event, value) => changePaginationPage(value)}
      />
    );
  }
};
