import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import Status from 'components/Status/Status';

import { useGetRolesQuery } from 'store/api/rolesApi/rolesApi';
import { useCreateUserMutation } from 'store/api/usersApi/usersApi';

import { UserStatus } from 'helpers/enums';
import { handleNoPermissionError } from 'helpers/handleNoPermissionError';
import { transformCascade } from 'helpers/transformForSelect';
import { addSingleUserResolver } from 'helpers/validations';

import { GroupsHierarchyResponse, IGroupHierarchyItem } from 'types/groupHierarchyTypes';
import { ModalProps } from 'types/modalTypes';

import Select from '../../Inputs/Select/Select';
import Text from '../../Inputs/Text/Text';
import ModalWrapper from '../ModalWrapper';

import styles from './AddSingleUser.module.scss';

interface Props extends ModalProps {
  group_id: string;
  groups: GroupsHierarchyResponse | undefined;
}

type FormValues = {
  email: string;
  first_name: string;
  last_name: string;
  status: UserStatus | '';
  accesses: [
    {
      group_id: string;
      role_id: string;
    },
  ];
};

const AddSingleUser = ({ groups, group_id, ...props }: Props) => {
  const { t } = useTranslation();
  const [createUser, { isLoading }] = useCreateUserMutation();

  const { register, control, handleSubmit, reset, getFieldState, formState, watch } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(addSingleUserResolver(t)),
    defaultValues: {
      accesses: [{ group_id }],
    },
  });

  const { data: roles, isFetching: isFetchingRoles } = useGetRolesQuery({
    groupId: watch('accesses.0.group_id'),
  });

  const resetForm = () =>
    reset({
      email: '',
      first_name: '',
      last_name: '',
      status: '',
      accesses: [
        {
          group_id: '',
          role_id: '',
        },
      ],
    });

  const onCloseModal = () => {
    resetForm();
    if (props.close) {
      props.close();
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (data) =>
    createUser({
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      accesses: data.accesses,
      is_active: data.status === UserStatus.Active,
    })
      .unwrap()
      .then(() => onCloseModal())
      .catch((error) => handleNoPermissionError(error.status));

  return (
    <ModalWrapper
      {...props}
      title={t('groups.manage.add_single')}
      close={onCloseModal}
      footerDeleteAction={resetForm}
      footerDeleteActionTitle={t('general.clear_all')}
      action={handleSubmit(onSubmit)}
      actionTitle={t('groups.manage.add_single')}
      status={isLoading}
      large
    >
      <form className={styles.Container}>
        <div className={styles.Column}>
          <Controller
            control={control}
            name="accesses.0.group_id"
            render={({ field: { onChange, value } }) => (
              <Select
                data={groups?.items.map((el) => transformCascade(el))}
                label={t('inputs.sub_group')}
                cascade
                onSelect={onChange}
                value={value}
                error={getFieldState('accesses.0.group_id', formState)}
                error_message={t(formState?.errors?.accesses?.[0]?.group_id?.message || '')}
              />
            )}
          />

          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Status
                required
                value={value}
                onChange={onChange}
                error={getFieldState('status', formState)}
                errorMessage={t(formState?.errors.status?.message || '')}
              />
            )}
          />

          <Text
            required
            register={register('last_name')}
            label={t('inputs.last_name')}
            placeholder={t('inputs.last_name_placeholder')}
            error={getFieldState('last_name', formState)}
            error_message={t(formState?.errors?.last_name?.message || '')}
          />
        </div>
        <div className={styles.Column}>
          <Controller
            control={control}
            name="accesses.0.role_id"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                data={roles?.items.map((el) => transformCascade({ id: el.id, name: el.name } as IGroupHierarchyItem))}
                label={t('inputs.role')}
                onSelect={onChange}
                value={value}
                isLoading={isFetchingRoles}
                error={getFieldState('accesses.0.role_id', formState)}
                error_message={t(formState?.errors?.accesses?.[0]?.role_id?.message || '')}
              />
            )}
          />

          <Text
            required
            label={t('inputs.first_name')}
            placeholder={t('inputs.first_name_placeholder')}
            register={register('first_name')}
            error={getFieldState('first_name', formState)}
            error_message={t(formState?.errors?.first_name?.message || '')}
          />

          <Text
            required
            type="email"
            register={register('email')}
            label={t('inputs.email')}
            placeholder={t('inputs.email_placeholder')}
            error={getFieldState('email', formState)}
            error_message={t(formState?.errors?.email?.message || '')}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default AddSingleUser;
