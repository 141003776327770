import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import Scrollable from 'components/Scrollable/Scrollable';

import { LANDSCAPE_BREAKPOINT, MOBILE_BREAKPOINT } from 'constants/';

import CardFooter, { FooterProps } from '../../../CardFooter/CardFooter';
import CardHeader from '../../../CardHeader/CardHeader';

import styles from './ElementWrapper.module.scss';

type Props = {
  title: string;
  subtitle: string;
  children: ReactNode;
  footerProps: FooterProps;
  visible?: boolean;
};

const ElementWrapper = ({ title, subtitle, children, footerProps, visible }: Props) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const isLandscape = useMediaQuery({
    query: LANDSCAPE_BREAKPOINT,
  });

  const content = (
    <>
      <div className={styles.CardHeaderContainer}>
        <CardHeader title={title} subtitle={subtitle} />
      </div>
      {children}
    </>
  );

  return (
    <div className={styles.CardContainer}>
      {isMobile ? (
        <Scrollable
          height={isLandscape ? 'auto' : 'calc(100dvh - 370px)'}
          paddingX={16}
          paddingY={24}
          visible={visible}
        >
          {content}
        </Scrollable>
      ) : (
        <div className={styles.DesktopContainer}>{content}</div>
      )}

      <CardFooter {...footerProps} />
    </div>
  );
};

export default ElementWrapper;
