import { createSlice } from '@reduxjs/toolkit';
import { initialPermissions } from 'pages/Roles/RolePermissions/RolePermissions';

import { RootState } from '../store';

import { getIsIndividualUser } from 'helpers/checkUserPermission';
import { copyObjectWithoutMethods } from 'helpers/copyObjectWithoutMethods';

import { ComparisonType } from 'types/assessmentTypes';
import { DashboardType } from 'types/dashboardTypes';
import { IPermissionsStore, Permissions, PermissionsAction } from 'types/permissionsTypes';

const initialState: IPermissionsStore = {
  comparisonType: '',
  dashboardType: null,
  isIndividualUser: false,
  role: '',
  isLoading: false,
  permissions: copyObjectWithoutMethods(initialPermissions),
};

const getHighestComparisonType = (permissions: Permissions): ComparisonType | string => {
  if (permissions.other_users_dashboard_comparison.includes(PermissionsAction.READ)) {
    return ComparisonType.IndividualUser;
  }

  if (permissions.group_dashboard_comparison.includes(PermissionsAction.READ)) {
    return ComparisonType.InGroup;
  }

  if (permissions.own_comparison.includes(PermissionsAction.READ)) {
    return ComparisonType.Own;
  }

  return '';
};

const getDashboardType = (permissions: Permissions): DashboardType | null => {
  if (
    permissions.other_users_dashboard_comparison.includes(PermissionsAction.READ) ||
    permissions.group_dashboard_comparison.includes(PermissionsAction.READ)
  ) {
    return DashboardType.GROUP;
  }

  if (permissions.own_results.includes(PermissionsAction.READ)) {
    return DashboardType.USER;
  }

  return null;
};

const userPermissionsSlice = createSlice({
  name: 'User permissions',
  initialState,
  reducers: {
    setRole(state, action) {
      state.role = action.payload;
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setPermissions(state, action) {
      state.permissions = {
        group: action.payload[0]?.permissions.group ?? [],
        user: action.payload[0]?.permissions.user ?? [],
        role: action.payload[0]?.permissions.role ?? [],
        assessment: action.payload[0]?.permissions.assessment ?? [],
        module: action.payload[0]?.permissions.module ?? [],
        section: action.payload[0]?.permissions.section ?? [],
        unit: action.payload[0]?.permissions.unit ?? [],
        group_dashboard_comparison: action.payload[0]?.permissions.group_dashboard_comparison ?? [],
        other_users_dashboard_comparison: action.payload[0]?.permissions.other_users_dashboard_comparison ?? [],
        own_comparison: action.payload[0]?.permissions.own_comparison ?? [],
        own_results: action.payload[0]?.permissions.own_results ?? [],
        additional_field: action.payload[0]?.permissions.additional_field ?? [],
        groups_users_report: action.payload[0]?.permissions.groups_users_report ?? [],
        activity_report: action.payload[0]?.permissions.activity_report ?? [],
        invisible_role: action.payload[0]?.permissions.invisible_role ?? [],
      };
      state.isIndividualUser = getIsIndividualUser(state.permissions);
      state.comparisonType = getHighestComparisonType(state.permissions);
      state.dashboardType = getDashboardType(state.permissions);
      state.isLoading = false;
    },
  },
});

export const { setRole, setPermissions, setIsLoading } = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;

export const getCurrentPermissions = (state: RootState) => state.permissions.permissions;
