import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';

import Search from 'components/Inputs/Search/Search';
import Scrollable from 'components/Scrollable/Scrollable';

import { useAppSelector } from 'hooks/redux';

import {
  useGetComparisonListQuery,
  useGetComparisonUsersAllQuery,
  useGetComparisonUsersQuery,
  useGetDashboardGroupsQuery,
} from 'store/api/assessmentApi/assessmentApi';

import { formatDateDetailed } from 'helpers/date';

import { BlockNames, CardVariants, HandleChangeRadioValueType, IRadioValues, MenuOptions } from 'types/assessmentTypes';
import { ComparisonListUser } from 'types/assessmentTypes';

import RadioValues from '../RadioValues/RadioValues';

import styles from './Block.module.scss';

type Props = {
  title: string;
  order: number;
  isSelectAnotherUser?: boolean;
  name: string;
  radioValues: IRadioValues;
  hideSearch?: boolean;
  hidden?: boolean;
  variant?: CardVariants;
  handleChangeRadioValue: HandleChangeRadioValueType;
  menuOption?: MenuOptions;
};

const Block = ({
  title,
  order,
  hidden,
  variant,
  isSelectAnotherUser,
  name,
  radioValues,
  handleChangeRadioValue,
  hideSearch,
  menuOption,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);
  const [search, setSearch] = useState('');
  const [radioButtons, setRadioButtons] = useState<{ value: string; title: string }[] | null>(null);
  const radioValue = radioValues[name][name + order].value;
  const radioName = name + order;
  const assessmentTake1Value = radioValues[BlockNames.AssessmentTake][BlockNames.AssessmentTake + 1].value;
  const group1Value = radioValues[BlockNames.Group][BlockNames.Group + 1].value;
  const userValue =
    menuOption === MenuOptions.MyResults ? user?.id || '' : radioValues[BlockNames.User][BlockNames.User + order].value;
  const assessmentTakeValue =
    name === BlockNames.AssessmentTake && order === 2
      ? radioValues[BlockNames.AssessmentTake][BlockNames.AssessmentTake + 1].value
      : '';

  const {
    data: groups,
    isFetching: isLoadingGroups,
    isSuccess: isSuccessGroups,
  } = useGetDashboardGroupsQuery(undefined, { skip: menuOption !== MenuOptions.Groups });

  const {
    data: users1,
    isFetching: isLoadingUsers1,
    isSuccess: isSuccessUsers1,
  } = useGetComparisonUsersQuery(
    {
      groupId: group1Value,
    },
    { skip: !group1Value || menuOption !== MenuOptions.Groups },
  );

  const {
    data: users2,
    isFetching: isLoadingUsers2,
    isSuccess: isSuccessUsers2,
  } = useGetComparisonUsersQuery(
    { assessmentTakeId: assessmentTake1Value, groupId: group1Value },
    { skip: !assessmentTake1Value || menuOption !== MenuOptions.Groups },
  );

  const {
    data: usersAll1,
    isFetching: isLoadingUsersAll1,
    isSuccess: isSuccessUsersAll1,
  } = useGetComparisonUsersAllQuery({}, { skip: menuOption !== MenuOptions.Users });

  const {
    data: usersAll2,
    isFetching: isLoadingUsersAll2,
    isSuccess: isSuccessUsersAll2,
  } = useGetComparisonUsersAllQuery(
    { assessmentTakeId: assessmentTake1Value },
    { skip: !assessmentTake1Value || menuOption !== MenuOptions.Users },
  );

  const {
    data: assessmentTakes,
    isFetching: isLoadingAssessmentTakes,
    isSuccess: isSuccessAssessmentTakes,
  } = useGetComparisonListQuery(
    { comparisonType, userId: userValue, comparedAssessmentId: assessmentTakeValue },
    { skip: !userValue },
  );

  const isLoading = useMemo(() => {
    if (name === BlockNames.Group) {
      return isLoadingGroups;
    }
    if (name === BlockNames.User && order === 1) {
      if (menuOption === MenuOptions.Users) {
        return isLoadingUsersAll1;
      }
      return isLoadingUsers1;
    }
    if (name === BlockNames.User && order === 2) {
      if (menuOption === MenuOptions.Users) {
        return isLoadingUsersAll2;
      }
      return isLoadingUsers2;
    }
    if (name === BlockNames.AssessmentTake) {
      return isLoadingAssessmentTakes;
    }

    return false;
  }, [
    name,
    order,
    isLoadingGroups,
    menuOption,
    isLoadingUsers1,
    isLoadingUsersAll1,
    isLoadingUsers2,
    isLoadingUsersAll2,
    isLoadingAssessmentTakes,
  ]);

  useEffect(() => {
    if (name === BlockNames.Group && isSuccessGroups) {
      setRadioButtons(groups.map((item) => ({ title: item.name, value: item.id })));
    }
  }, [groups, isSuccessGroups, name]);

  useEffect(() => {
    let users: ComparisonListUser[] | null = null;
    let isSuccess = false;
    if (menuOption === MenuOptions.Users && isSuccessUsersAll1) {
      users = usersAll1;
      isSuccess = isSuccessUsersAll1;
    }

    if (menuOption === MenuOptions.Groups && isSuccessUsers1) {
      users = users1;
      isSuccess = isSuccessUsers1;
    }

    if (name === BlockNames.User && order === 1 && users && isSuccess) {
      setRadioButtons(users?.map((item) => ({ title: `${item.first_name} ${item.last_name}`, value: item.id })));
    }
  }, [isSuccessUsers1, isSuccessUsersAll1, menuOption, name, order, users1, usersAll1]);

  useEffect(() => {
    let users: ComparisonListUser[] | null = null;
    let isSuccess = false;

    if (menuOption === MenuOptions.Users && isSuccessUsersAll2) {
      users = usersAll2;
      isSuccess = isSuccessUsersAll2;
    }

    if (menuOption === MenuOptions.Groups && isSuccessUsers2) {
      users = users2;
      isSuccess = isSuccessUsers2;
    }

    if (name === BlockNames.User && order === 2 && isSuccess && users) {
      setRadioButtons(users?.map((item) => ({ title: `${item.first_name} ${item.last_name}`, value: item.id })));
    }
  }, [isSuccessUsers2, isSuccessUsersAll2, menuOption, name, order, users2, usersAll2]);

  useEffect(() => {
    if (name === BlockNames.AssessmentTake && isSuccessAssessmentTakes) {
      setRadioButtons(
        assessmentTakes?.map((item) => ({
          title: `${item.assessment_name} ${formatDateDetailed(item.attempt_date)}`,
          value: item?.completed_assessment_id,
        })),
      );
    }
  }, [assessmentTakes, isSuccessAssessmentTakes, name]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentRadioValue = radioButtons?.find((item) => item.value === event.target.value);

    if (currentRadioValue) {
      handleChangeRadioValue(event, name, currentRadioValue.title, order);
    }
  };

  const fixedSection = () => {
    if (name === BlockNames.Group) {
      return (
        <div className={styles.BlockUsers}>
          <span>{radioValues[BlockNames.Group][BlockNames.Group + 1].title}</span>
        </div>
      );
    }

    if (name === BlockNames.User) {
      return (
        <div className={styles.BlockUsers}>
          <span>{radioValues[BlockNames.User][BlockNames.User + 1].title}</span>
        </div>
      );
    }
  };

  return (
    <div
      className={classNames(styles.Block, {
        [styles.Hidden]: hidden,
      })}
    >
      <div className={styles.BlockHeader}>
        <Fragment>
          <h3>
            {title} {order}
          </h3>
          {!hideSearch && (
            <Search onSearch={setSearch} value={search} placeholder={t('assessment.search_placeholder')} />
          )}
        </Fragment>
      </div>
      {isSelectAnotherUser ? (
        fixedSection()
      ) : (
        <Scrollable variant={variant} paddingX={16} maxHeight={260}>
          <FormControl sx={FormControlSx}>
            <RadioGroup name={radioName} value={radioValue} onChange={handleChange}>
              <RadioValues isLoading={isLoading} items={radioButtons} variant={variant} search={search} />
            </RadioGroup>
          </FormControl>
        </Scrollable>
      )}
    </div>
  );
};

const FormControlSx = {
  width: '100%',

  '.MuiFormControlLabel-root': {
    '&:not(:last-of-type)': {
      marginBottom: '4px',
    },
  },
};

export default Block;
