import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import Label from 'components/Label/Label';
import Loader from 'components/Loader/Loader';

import ComparisonHeaderInfo from '../HeaderInfo/components/ComparisonHeaderInfo/ComparisonHeaderInfo';
import Accelerant from './components/Accelerant/Accelerant';
import AccelerantsComparison from './components/AccelerantsComparison/AccelerantsComparison';

import useGetMappingPerSection from 'hooks/useGetMappingPerSection';

import { mapAccelerants, mapComparisonAccelerants } from 'helpers/accelerants';

import { IAccelerant } from 'types/accelerantTypes';
import {
  DashboardResult,
  DashboardResultGroupAccelerants,
  DashboardType,
  SelectedGroups,
  VisualisationTypes,
} from 'types/dashboardTypes';
import { Size } from 'types/enums';

import pageStyles from '../../Dashboard.module.scss';
import styles from './Accelerants.module.scss';

type Props = {
  selectedGroups?: SelectedGroups;
  requestId1: string;
  requestId2?: string;
  dashboardType: DashboardType;
};

const Accelerants = ({ selectedGroups, requestId1, requestId2 = '', dashboardType }: Props) => {
  const { t } = useTranslation();

  const {
    mappingsCompletion: mappingsAccelerantsLeft,
    mappingsCompletion2: mappingsAccelerantsRight,
    isLoadingCompletion: isLoadingMappingsAccelerantsLeft,
    isLoadingCompletion2: isLoadingMappingsAccelerantsRight,
    isFetchingCompletion: isFetchingMappingsAccelerantsLeft,
    isFetchingCompletion2: isFetchingMappingsAccelerantsRight,
  } = useGetMappingPerSection(requestId1, requestId2, dashboardType, VisualisationTypes.Accelerants);

  const isComparisonMode = !!requestId2;

  const items = [mappingsAccelerantsLeft, mappingsAccelerantsRight] as [
    DashboardResult[] | DashboardResultGroupAccelerants[] | null | undefined,
    DashboardResult[] | DashboardResultGroupAccelerants[] | null | undefined,
  ];

  const mappedAccelerants = isComparisonMode ? mapComparisonAccelerants(items) : mapAccelerants(items[0]);

  const isLoading =
    isLoadingMappingsAccelerantsLeft ||
    isFetchingMappingsAccelerantsLeft ||
    isLoadingMappingsAccelerantsRight ||
    isFetchingMappingsAccelerantsRight;

  return (
    <div className={pageStyles.Section}>
      <h3 className={pageStyles.SectionTitle}>{t('dashboard.accelerants.title')}</h3>

      {(dashboardType === DashboardType.GROUP || (dashboardType === DashboardType.USER && isComparisonMode)) &&
        !isLoading && (
          <div className={pageStyles.SectionLabel}>
            <span>
              {isComparisonMode
                ? t('dashboard.accelerants.comparison_average_responses_for')
                : t('dashboard.accelerants.average_responses_for')}
            </span>
            {isComparisonMode ? (
              <ComparisonHeaderInfo dashboardType={dashboardType} isHideButton />
            ) : (
              selectedGroups && selectedGroups[0] && <Label label={selectedGroups[0].name} />
            )}
          </div>
        )}

      {isLoading ? (
        <Box m={4}>
          <Loader flexCenter size="md" />
        </Box>
      ) : (
        <div className={pageStyles.SectionContent}>
          {isComparisonMode ? (
            <div className={styles.ComparisonContainer}>
              {(mappedAccelerants as [IAccelerant, IAccelerant][]).map((item, index) => (
                <AccelerantsComparison accelerants={item} key={index} dashboardType={dashboardType} />
              ))}
            </div>
          ) : (
            <div className={styles.SectionWrapper}>
              {(mappedAccelerants as IAccelerant[]).map((item) => (
                <div key={item.id} className={styles.AccelerantContainer}>
                  <Accelerant
                    title={item.title}
                    value={item.value}
                    tooltip={item.tooltip}
                    points={item.points}
                    dashboardType={dashboardType}
                  >
                    <div className={styles.AdditionalAccelerants}>
                      {item.subAccelerants?.map((item) => (
                        <Accelerant
                          key={item.id}
                          size={Size.Sm}
                          title={item.title}
                          value={item.value}
                          tooltip={item.tooltip}
                          points={item.points}
                          dashboardType={dashboardType}
                        />
                      ))}
                    </div>
                  </Accelerant>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Accelerants;
