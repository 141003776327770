import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppSelector } from 'hooks/redux';

import { selectThankYouPageAdded, selectWelcomePageAdded } from 'store/selectors/assessment';

import { getMenu } from '../../constants';

import { CARD_TYPES, CardTypes, DragSource } from 'types/createAssessmentTypes';

import AsideComponent from '../AsideComponent/AsideComponent';
import ModulesSidebar from '../ModulesSidebar/ModulesSidebar';
import SectionsSidebar from '../SectionsSidebar/SectionsSidebar';

import styles from '../../AssessmentCreate.module.scss';

const Aside = () => {
  const { t } = useTranslation();

  const boardElements = useAppSelector((store) => store.createAssessment.boardElements);
  const isWelcomePageAdded = useSelector(selectWelcomePageAdded);
  const isThankYouPageAdded = useSelector(selectThankYouPageAdded);

  const isMenuItemEnabled = (menuItemType: CardTypes) => {
    if (menuItemType !== CARD_TYPES.welcome_page && boardElements.length === 0) return false;

    if (menuItemType === CARD_TYPES.welcome_page && isWelcomePageAdded) return false;

    if (menuItemType === CARD_TYPES.thankyou_page && isThankYouPageAdded) return false;

    return true;
  };

  return (
    <aside className={styles.AssessmentAside}>
      {getMenu(t).map(({ icon, title, type }) => (
        <AsideComponent
          disabled={!isMenuItemEnabled(type)}
          key={title}
          icon={icon}
          name={title}
          type={type}
          component={{ type: type, source: DragSource.Aside }}
        />
      ))}

      <SectionsSidebar />
      <ModulesSidebar />
    </aside>
  );
};

export default Aside;
