import i18n from 'i18next';

import { BarValue, IAccelerant } from 'types/accelerantTypes';
import {
  DashboardResult,
  DashboardResultGroupAccelerants,
  IDashboardResultPoint,
  IDashboardResultPointGroupAccelerants,
} from 'types/dashboardTypes';
import { Translations } from 'types/translationTypes';

export const getAccelerantValue = (
  points: IDashboardResultPoint[] | IDashboardResultPointGroupAccelerants[],
  value: number,
): BarValue => {
  const [point1, point2, point3] = points;

  if (point1.cutoff_value <= value && value < point2.cutoff_value) {
    return BarValue.Low;
  }

  if (point2.cutoff_value <= value && value < point3?.cutoff_value) {
    return BarValue.Medium;
  }

  if (point3?.cutoff_value <= value) {
    return BarValue.High;
  }

  return BarValue.Low;
};

export const mapAccelerants = (
  accelerants: DashboardResult[] | DashboardResultGroupAccelerants[] | null | undefined,
): IAccelerant[] | undefined => {
  const lang = `text_${i18n.language}` as keyof Translations;

  return accelerants
    ? accelerants.map((item) => ({
        id: item.id,
        title: item.name[lang],
        value: getAccelerantValue(item.sections[0].points, item.value),
        points: item.sections[0].points,
        tooltip: {
          id: item.id,
          title: item.name[lang],
          content: item.description[lang],
        },
        subAccelerants: item.sub_graphs?.map((item) => ({
          id: item.id,
          title: item.name[lang],
          value: getAccelerantValue(item.sections[0].points, item.value),
          points: item.sections[0].points,
          tooltip: {
            id: item.id,
            title: item.name[lang],
            content: item.description[lang],
          },
        })),
      }))
    : [];
};

export const mapComparisonAccelerants = (
  accelerants: [
    DashboardResult[] | DashboardResultGroupAccelerants[] | null | undefined,
    DashboardResult[] | DashboardResultGroupAccelerants[] | null | undefined,
  ],
): [IAccelerant, IAccelerant][] | undefined => {
  const [firstDashboardResult, secondDashboardResult] = accelerants;
  const firstAccelerant = mapAccelerants(firstDashboardResult);
  const secondAccelerant = mapAccelerants(secondDashboardResult);

  if (firstAccelerant?.length && secondAccelerant?.length) {
    return firstAccelerant.reduce((acc: [IAccelerant, IAccelerant][], item, index): [IAccelerant, IAccelerant][] => {
      acc.push([item, secondAccelerant[index]]);
      return acc;
    }, []);
  }

  return [];
};
