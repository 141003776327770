import { TagDescription, createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseAssessmentQueryWithReauth } from '../../helpers';

import {
  GetContextsPayload,
  GetContextsResponse,
  GetMappingsGroupAccelerantsPayload,
  GetMappingsGroupAccelerantsResponse,
  GetMappingsGroupEcosystemPayload,
  GetMappingsGroupEcosystemResponse,
  GetMappingsGroupPayload,
  GetMappingsGroupResponse,
  GetMappingsPayload,
  GetMappingsResponse,
} from './types';

type AvailableTag = TagDescription<'SCurveMapping' | 'AccelerantMapping' | 'EcosystemMapping' | 'Context'>;

export const tagsMap = {
  s_curve: ['SCurveMapping' as AvailableTag],
  accelerants: ['AccelerantMapping' as AvailableTag],
  ecosystem: ['EcosystemMapping' as AvailableTag],
};

export const mappingApi = createApi({
  tagTypes: ['SCurveMapping', 'AccelerantMapping', 'EcosystemMapping', 'Context'],
  reducerPath: 'mappingApi',
  baseQuery: baseAssessmentQueryWithReauth,
  endpoints: (builder) => ({
    getMappings: builder.query<GetMappingsResponse, GetMappingsPayload>({
      query: ({ completionId, visualisationType }) => ({
        url: `/mappings/${completionId}/`,
        params: {
          visualisation_type: visualisationType,
        },
      }),
      providesTags: (result, error, arg: GetMappingsPayload) => {
        return tagsMap[arg.visualisationType];
      },
    }),

    getMappingsGroupScurve: builder.query<GetMappingsGroupResponse, GetMappingsGroupPayload>({
      query: ({ groupId, assessmentId, dateFrom, dateTo }) => ({
        url: `/mappings/group/${groupId}/s-curve/`,
        params: assessmentId
          ? {
              assessment_id: assessmentId,
              date_from: dateFrom,
              date_to: dateTo,
            }
          : {},
      }),

      providesTags: (result, error, arg: GetMappingsGroupPayload) => {
        return tagsMap[arg.visualisationType];
      },
    }),

    getMappingsGroupAccelerants: builder.query<GetMappingsGroupAccelerantsResponse, GetMappingsGroupAccelerantsPayload>(
      {
        query: ({ groupId, assessmentId, dateFrom, dateTo }) => ({
          url: `/mappings/group/${groupId}/accelerants/`,
          params: assessmentId
            ? {
                assessment_id: assessmentId,
                date_from: dateFrom,
                date_to: dateTo,
              }
            : {},
        }),

        providesTags: (result, error, arg: GetMappingsGroupAccelerantsPayload) => {
          return tagsMap[arg.visualisationType];
        },
      },
    ),

    getMappingsGroupEcosystem: builder.query<GetMappingsGroupEcosystemResponse, GetMappingsGroupEcosystemPayload>({
      query: ({ groupId, assessmentId, dateFrom, dateTo }) => ({
        url: `/mappings/group/${groupId}/ecosystem/`,
        params: assessmentId
          ? {
              assessment_id: assessmentId,
              date_from: dateFrom,
              date_to: dateTo,
            }
          : {},
      }),

      providesTags: (result, error, arg: GetMappingsGroupEcosystemPayload) => {
        return tagsMap[arg.visualisationType];
      },
    }),

    getContexts: builder.query<GetContextsResponse, GetContextsPayload>({
      query: ({ completionId }) => `/context/${completionId}/`,
      providesTags: ['Context'],
    }),
  }),
});

export const {
  useLazyGetMappingsQuery,
  useLazyGetMappingsGroupScurveQuery,
  useLazyGetMappingsGroupAccelerantsQuery,
  useLazyGetMappingsGroupEcosystemQuery,
  useLazyGetContextsQuery,
  useGetContextsQuery,
} = mappingApi;
