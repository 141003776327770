import { ReactNode } from 'react';

import { Stack } from '@mui/material';

import styles from './LegendTop.module.scss';

type LegendTopItemProps = {
  children: ReactNode;
};

export const LegendTopItem = ({ children }: LegendTopItemProps) => {
  return <div className={styles.Title}>{children}</div>;
};

type LegendTopProps = {
  items: ReactNode[];
};

const LegendTop = ({ items }: LegendTopProps) => {
  return (
    <Stack className={styles.Container} direction="row" alignItems="center">
      {items.map((item, index) => (
        <div className={styles.Item} key={index}>
          {item}
        </div>
      ))}
    </Stack>
  );
};

export default LegendTop;
