import React from 'react';
import { Control } from 'react-hook-form';

import classNames from 'classnames';
import { Grid, Row } from 'rsuite';

import { IVisualisationFormMarkup } from 'types/createAssessmentTypes';

import { MappingFormItem } from '..';

import styles from './MappingForm.module.scss';

type Props = {
  markup: IVisualisationFormMarkup[];
  control: Control;
};
export const MappingForm = ({ markup, control }: Props) => {
  return (
    // TODO: There is error message in browser console: "<form> cannot appear as a descendant of <form>"
    <form>
      <Grid fluid className={styles.GridContainer}>
        {markup.map((formElement) => (
          <React.Fragment key={formElement.id}>
            <Row className={styles.FormRow}>
              <MappingFormItem control={control} formElement={formElement} labelClassName="large" />
            </Row>
            <Row className={classNames(styles.FormRow, styles.ChildRow)}>
              {formElement?.children?.map((formChildrenElement) => (
                <MappingFormItem key={formChildrenElement.id} control={control} formElement={formChildrenElement} />
              ))}
            </Row>
          </React.Fragment>
        ))}
      </Grid>
    </form>
  );
};
