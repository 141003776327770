import { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Loader } from 'rsuite';

import CreateOrEditSectionModal from 'components/Modals/CreateOrEditSectionModal/CreateOrEditSectionModal';
import DeleteModuleModal from 'components/Modals/DeleteModuleModal/DeleteModuleModal';

import { useAppSelector } from 'hooks/redux';

import { useModulesSidebar } from '../../hooks';

import { useGetSectionsQuery } from 'store/api/assessmentApi/assessmentApi';

import { checkPermission } from 'helpers/checkUserPermission';

import { ContainerType, DragSource, EditModalState, SectionItem } from 'types/createAssessmentTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import ArrowSVG from 'assets/svg/create-assesment/ArrowSVG';
import EditOutlinedSVG from 'assets/svg/create-assesment/EditOutlinedSVG';
import MediumDeleteSVG from 'assets/svg/create-assesment/MediumDeleteSVG';
import PlusSVG from 'assets/svg/create-assesment/PlusSVG';

import styles from '../../AssessmentCreate.module.scss';

const ModulesSidebar = () => {
  const { t } = useTranslation();
  const { module } = useAppSelector((state) => state.permissions.permissions);

  const [showModules, setShowModules] = useState(true);

  const { data: modules, isLoading } = useGetSectionsQuery({ type: ContainerType.Module });

  const { editModule, toggleAddModal, addModule, deleteModuleModal, toggleModuleDelete, toggleEditModule } =
    useModulesSidebar();

  return (
    <section className={styles.DropdownSection}>
      {addModule && (
        <CreateOrEditSectionModal
          type={ContainerType.Module}
          open={addModule}
          handleClose={() => toggleAddModal(false)}
        />
      )}

      {editModule && (
        <CreateOrEditSectionModal
          type={ContainerType.Module}
          edit={{ name: editModule.name, id: editModule.id }}
          open={editModule.open}
          handleClose={() => toggleEditModule({ open: false, id: null, name: '' })}
        />
      )}

      <div className={styles.DropdownSection_Header}>
        <div onClick={() => setShowModules((prev) => !prev)} className={styles.ModuleBlock}>
          <ArrowSVG className={classNames(showModules && styles.ExpandSvg)} />
          <p>{t('general.modules')}</p>
        </div>

        {checkPermission(module, [PermissionsAction.CREATE]) && (
          <PlusSVG onClick={() => toggleAddModal(true)} className={styles.AddButton} />
        )}
      </div>

      {showModules && (
        <div className={styles.Modules}>
          {isLoading && (
            <div className={styles.LoaderContainer}>
              <Loader center />
            </div>
          )}

          {modules &&
            modules.map((module) => (
              <ModuleItem
                key={module.id + module.title}
                type={ContainerType.Module}
                item={module}
                selectModuleToEdit={toggleEditModule}
                toggleModuleDelete={toggleModuleDelete}
              />
            ))}
        </div>
      )}

      <DeleteModuleModal state={deleteModuleModal} handleClose={() => toggleModuleDelete({ open: false, id: '' })} />
    </section>
  );
};

export default ModulesSidebar;

interface IModuleItem {
  item: SectionItem;
  selectModuleToEdit: (state: EditModalState) => void;
  toggleModuleDelete: (state: { open: boolean; id: any }) => void;
  type: ContainerType;
}

export const ModuleItem = ({ item, selectModuleToEdit, toggleModuleDelete, type }: IModuleItem) => {
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const boardElements = useAppSelector((store) => store.createAssessment.boardElements);

  const [, drag] = useDrag(() => ({
    type,
    item: { id: item.id, type, source: DragSource.Aside, name: item.title },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  }));

  const hasUpdatePermission = checkPermission(permissions[type], [PermissionsAction.UPDATE]);
  const hasDeletePermission = checkPermission(permissions[type], [PermissionsAction.DELETE]);

  const isDisabled = boardElements.some((element) => element.id === item.id);

  return (
    <div id={item.id} ref={isDisabled ? undefined : drag} className={classNames(styles.ModuleItem)}>
      <p className={classNames({ [styles.Disabled]: isDisabled })}>{item.title}</p>

      {(hasUpdatePermission || hasDeletePermission) && (
        <div className={styles.IconsBlock}>
          {hasUpdatePermission && (
            <div className={classNames({ [styles.Disabled]: isDisabled })}>
              <EditOutlinedSVG onClick={() => selectModuleToEdit({ name: item.title, id: item.id, open: true })} />
            </div>
          )}

          {hasDeletePermission && (
            <div>
              <MediumDeleteSVG onClick={() => toggleModuleDelete({ open: true, id: item.id })} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
