import Dropdown from '../components/BoardElements/Dropdown/Dropdown';
import Information from '../components/BoardElements/Information/Information';
import Likert from '../components/BoardElements/Likert/Likert';
import LongTextEntry from '../components/BoardElements/LongTextEntry/LongTextEntry';
import MultipleChoice from '../components/BoardElements/MultipleChoice/MultipleChoice';
import QuestionSlider from '../components/BoardElements/QuestionSlider/QuestionSlider';
import ShortTextEntry from '../components/BoardElements/ShortTextEntry/ShortTextEntry';
import SingleChoice from '../components/BoardElements/SingleChoice/SingleChoice';
import ThankYouPage from '../components/BoardElements/ThankYouPage/ThankYouPage';
import WelcomePage from '../components/BoardElements/WelcomePage/WelcomePage';
import Section from '../components/Section/Section';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import {
  addMappings,
  deleteQuestionMapping,
  removeBoardElement,
  updateBoardElement,
} from 'store/features/createAssessmentSlice';

import { findChildElements } from 'helpers/assessments/elements';
import { copyObjectWithoutMethods } from 'helpers/copyObjectWithoutMethods';

import { Element, MultipleColumnBoardElement, SingleColumnBoardElement } from 'types/boardElements';
import {
  BaseOptionElement,
  CARD_TYPES,
  CalculationTypeChange,
  ColumnType,
  ContainerType,
  MultipleColumnOptionAdd,
  MultipleColumnOptionChange,
  MultipleColumnOptionDelete,
  SingleColumnOptionAdd,
  SingleColumnOptionChange,
  SingleColumnOptionDelete,
  TitleFieldsChange,
} from 'types/createAssessmentTypes';

export const useCardManipulate = () => {
  const dispatch = useAppDispatch();

  const boardElements = useAppSelector((store) => store.createAssessment.boardElements);
  const mappings = useAppSelector((state) => state.createAssessment.mappings);

  const getElementById = (elementId: string): Element | undefined =>
    boardElements.find((element) => element.id === elementId);

  const changeTitleFields: TitleFieldsChange = (elementId, field, value) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);
    updatedElement[field] = value;

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const changeCalculationType: CalculationTypeChange = (elementId, value) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);
    updatedElement.calculation_type = value;

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const addSingleColumnOption: SingleColumnOptionAdd = (elementId) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);

    const options = updatedElement.options;
    const optionsLength = options.length;
    const lastOptionOrder = options[optionsLength - 1].order;

    (updatedElement as SingleColumnBoardElement).options.push({
      text_en: `Option ${optionsLength + 1}`,
      text_es: `Opción ${optionsLength + 1}`,
      score: '1.0',
      order: lastOptionOrder + 1,
    } as BaseOptionElement);

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const changeSingleColumnOption: SingleColumnOptionChange = (elementId, optionId, field, value) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);

    (updatedElement as SingleColumnBoardElement).options[optionId][field] = value;

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const deleteSingleColumnOption: SingleColumnOptionDelete = (elementId, optionIndex) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);

    (updatedElement as SingleColumnBoardElement).options.splice(optionIndex, 1);

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const addMultipleColumnOption: MultipleColumnOptionAdd = (elementId, columnType) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);

    const column = updatedElement[columnType];
    const columnLength = column.length;
    const optionNumber = columnType === ColumnType.Left ? columnLength * 2 + 1 : (columnLength + 1) * 2;
    const lastColumnOptionOrder = column[columnLength - 1].order;

    (updatedElement as MultipleColumnBoardElement)[columnType].push({
      text_en: `Option ${optionNumber}`,
      text_es: `Opción ${optionNumber}`,
      score: '1.0',
      order: lastColumnOptionOrder + 1,
    } as BaseOptionElement);

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const changeMultipleColumnOption: MultipleColumnOptionChange = ({
    elementId,
    columnType,
    optionId,
    field,
    value,
  }) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);

    (updatedElement as MultipleColumnBoardElement)[columnType][optionId][field] = value;

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const deleteMultipleColumnOption: MultipleColumnOptionDelete = (elementId, optionIndex, columnType) => {
    const elementToUpdate = getElementById(elementId);

    if (!elementToUpdate) return;

    const updatedElement = copyObjectWithoutMethods(elementToUpdate);

    (updatedElement as MultipleColumnBoardElement)[columnType].splice(optionIndex, 1);

    dispatch(updateBoardElement({ elementId, updatedElement }));
  };

  const removeElementMappings = (removedElements: Element[]) => {
    removedElements.forEach((element) => {
      if (element.type === CARD_TYPES.section) {
        Object.entries(mappings).forEach(([visualisationId, sectionIds]) => {
          if (sectionIds.includes(element.id)) {
            dispatch(addMappings({ [visualisationId]: sectionIds.filter((sectionId) => sectionId !== element.id) }));
          }
        });
      }

      if (element.type === CARD_TYPES.section || element.type === CARD_TYPES.module) {
        removeElementMappings(findChildElements(boardElements, element.id));
      }

      if (element.type === CARD_TYPES.short_text) {
        dispatch(deleteQuestionMapping({ questionId: element.id }));
      }
    });
  };

  const removeElement = (elementId: string) => {
    const elementToRemove = getElementById(elementId);

    if (!elementToRemove) return;

    removeElementMappings([elementToRemove]);
    dispatch(removeBoardElement({ elementId, isRecursive: true }));
  };

  const renderCards = (element: Element) => {
    switch (element.type) {
      case CARD_TYPES.section:
        return (
          <Section
            key={element.id}
            element={element}
            type={ContainerType.Section}
            onTitleFieldsChange={changeTitleFields}
            onCalculationTypeChange={changeCalculationType}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.module:
        return (
          <Section
            key={element.id}
            element={element}
            type={ContainerType.Module}
            onTitleFieldsChange={changeTitleFields}
            onCalculationTypeChange={changeCalculationType}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.welcome_page:
        return (
          <WelcomePage
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.thankyou_page:
        return (
          <ThankYouPage
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.multiple_choice:
        return (
          <MultipleChoice
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onChoiceOptionAdd={addMultipleColumnOption}
            onChoiceOptionChange={changeMultipleColumnOption}
            onChoiceOptionDelete={deleteMultipleColumnOption}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.single_choice:
        return (
          <SingleChoice
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onChoiceOptionAdd={addMultipleColumnOption}
            onChoiceOptionChange={changeMultipleColumnOption}
            onChoiceOptionDelete={deleteMultipleColumnOption}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.dropdown:
        return (
          <Dropdown
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onDropdownOptionAdd={addSingleColumnOption}
            onDropdownOptionChange={changeSingleColumnOption}
            onDropdownOptionDelete={deleteSingleColumnOption}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.likert:
        return (
          <Likert
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onDropdownOptionAdd={addSingleColumnOption}
            onDropdownOptionChange={changeSingleColumnOption}
            onDropdownOptionDelete={deleteSingleColumnOption}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.short_text:
        return (
          <ShortTextEntry
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.information:
        return (
          <Information
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.long_text:
        return (
          <LongTextEntry
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      case CARD_TYPES.question_slider:
        return (
          <QuestionSlider
            key={element.id}
            element={element}
            onTitleFieldsChange={changeTitleFields}
            onElementDelete={() => removeElement(element.id)}
          />
        );
      default:
        return null;
    }
  };

  return {
    renderCards,
  };
};
