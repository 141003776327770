import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import Select from 'components/Inputs/Select/Select';
import SelectTags from 'components/Inputs/SelectTags/SelectTags';
import TextInput from 'components/Inputs/Text/Text';
import Loader from 'components/Loader/Loader';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Tabs from 'components/Tabs/Tabs';
import Title from 'components/Title/Title';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import {
  useCreateRolePermissionsMutation,
  useGetRolePermissionsQuery,
  usePermissionsOverwriteMutation,
} from 'store/api/permissionsApi/permissionsApi';
import { useCreateRoleMutation, useGetRoleByIdQuery, useUpdateRoleMutation } from 'store/api/rolesApi/rolesApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { copyObjectWithoutMethods } from 'helpers/copyObjectWithoutMethods';

import { MAX_DESCR_FORM_LENGTH, MAX_NAME_FORM_LENGTH } from 'constants/';

import { ButtonFill } from 'types/enums';
import { Permission, Permissions, PermissionsAction, PermissionsCreate } from 'types/permissionsTypes';
import { Role, RoleLevel } from 'types/roleTypes';

import { ReactComponent as AssessmentPerm } from 'assets/images/assessment.svg';
import { ReactComponent as GroupPerm } from 'assets/images/group-perm.svg';
import { ReactComponent as ModulePerm } from 'assets/images/module.svg';
import { ReactComponent as RolesPerm } from 'assets/images/roles-perm.svg';
import { ReactComponent as SectionPerm } from 'assets/images/section.svg';
import { ReactComponent as UnitPerm } from 'assets/images/unit.svg';
import { ReactComponent as UserPerm } from 'assets/images/user-perm.svg';

import { initialPermissions } from '../RolePermissions/RolePermissions';

import styles from './RoleAdd.module.scss';

type Props = {
  edit?: boolean;
};

type FormValues = {
  name: string;
  description: string;
  level: string;
  is_global: boolean;
  is_invisible: boolean;
  permissions: Permissions;
};

type FormError = {
  data: {
    detail: string;
  };
  status: number;
};

enum Tab {
  RoleName,
  RolePermission,
}

const RoleAdd = ({ edit }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [createdRole, setCreatedRole] = useState<Role | null>(null);
  const [activeTab, setActiveTab] = useState(Tab.RoleName);

  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const isAdminGroup = useAppSelector((state) => state.navigation.isAdminGroup);
  const { invisible_role } = useAppSelector((state) => state.permissions.permissions);

  const {
    data: roleData,
    isSuccess: roleFetched,
    isLoading: isLoadingRole,
    isError,
  } = useGetRoleByIdQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  const {
    data: permissions,
    isLoading: isLoadingRolePermissions,
    isSuccess: permissionsLoaded,
  } = useGetRolePermissionsQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [createRole, { isLoading: creatingRole }] = useCreateRoleMutation();
  const [updateRole, { isLoading: updatingRole }] = useUpdateRoleMutation();
  const [createRolePermissions, { isLoading: creatingPerm }] = useCreateRolePermissionsMutation();
  const [permissionsOverwrite] = usePermissionsOverwriteMutation();

  const LEVELS = [
    { value: RoleLevel.Current, label: t('general.current') },
    { value: RoleLevel.Nested, label: t('general.nested') },
    { value: RoleLevel.CurrentAndNested, label: t('general.current_and_nested') },
  ];

  const { register, control, reset, handleSubmit, getValues, getFieldState, formState, trigger } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      level: LEVELS[2].value,
      is_global: false,
      is_invisible: false,
      permissions: copyObjectWithoutMethods(initialPermissions),
    },
  });

  useEffect(() => {
    if (isError) navigate(ROUTES.ROLE_LIST);
  }, [isError, navigate]);

  useEffect(() => {
    if (edit && roleFetched) reset({ ...getValues(), ...roleData });
  }, [edit, getValues, reset, roleData, roleFetched]);

  const parsePermissions = useCallback(() => {
    const updatedPermissions: Permissions = copyObjectWithoutMethods(initialPermissions);

    Object.keys(updatedPermissions).forEach((entity) => {
      if (permissions?.items) {
        updatedPermissions[entity as keyof Permissions] = permissions.items
          .filter((permission: Permission) => permission.entity === entity)
          .map((permission: Permission) => permission.action);
      }
    });

    reset({ ...getValues(), permissions: { ...updatedPermissions } });
  }, [getValues, permissions?.items, reset]);

  useEffect(() => {
    if (permissionsLoaded) parsePermissions();
  }, [parsePermissions, permissionsLoaded]);

  const updatePermissions = (permissions: Permissions) => {
    const preparedData = Object.keys(permissions).map((entity) => ({
      role_id: createdRole?.id || id,
      entity,
      actions: permissions[entity as keyof Permissions],
    }));

    permissionsOverwrite(preparedData).then(() => navigate(ROUTES.ROLE_LIST));
  };

  const onSubmit: SubmitHandler<FormValues> = async ({
    name,
    description,
    level,
    is_global,
    is_invisible,
    permissions,
  }) => {
    const newRole = {
      organization_id: selectedGroupId,
      name,
      description,
      level,
      is_global,
      is_invisible,
    };

    switch (activeTab) {
      case Tab.RoleName:
        setActiveTab(Tab.RolePermission);
        break;

      case Tab.RolePermission:
        const hasSelectedPermissions = Object.keys(permissions).some(
          (key) => permissions[key as keyof Permissions].length,
        );

        if (edit) {
          if (hasSelectedPermissions && id) {
            await updateRole({ id, role: newRole });
            updatePermissions(permissions);
          } else {
            toast.error<string>(t('roles.no_selected_permissions'));
          }
        } else {
          try {
            if (hasSelectedPermissions) {
              const role = await createRole(newRole).unwrap();
              setCreatedRole(role);
              const newPermissions = Object.keys(permissions)
                .map((key) =>
                  permissions[key as keyof Permissions].map((action: string) => ({
                    entity: key,
                    action,
                    role_id: role.id,
                  })),
                )
                .flat(Infinity);
              await createRolePermissions(newPermissions as PermissionsCreate[]);
              navigate(ROUTES.ROLE_LIST);
            } else {
              toast.error<string>(t('roles.no_selected_permissions'));
            }
          } catch (error) {
            const typedError = error as FormError;
            toast.error<string>(typedError.data.detail);
          }
        }
        break;

      default:
        console.error('Step does not exist');
    }
  };

  useDocumentTitle(edit ? [t('page_titles.role_edit')] : [t('page_titles.role_add')]);

  if ((isLoadingRole || isLoadingRolePermissions) && edit) {
    return <Loader center size="lg" />;
  }

  return (
    <div className={styles.Container}>
      <ShadowBlock>
        <Breadcrumb
          items={[
            { name: t('roles.role_list'), href: ROUTES.ROLE_LIST },
            edit
              ? {
                  name: t('roles.edit_role'),
                  href: ROUTES.ROLE_EDIT(id),
                  active: true,
                }
              : {
                  name: t('roles.add_new_role'),
                  href: ROUTES.ROLE_ADD,
                  active: true,
                },
          ]}
        />

        <div>
          <Title title={edit ? t('roles.edit_role') : t('roles.add_new_role')} />

          <Tabs
            tabs={[
              { title: t('roles.role_name') },
              {
                title: t('roles.role_permissions'),
                shouldChangeTab: () => trigger('name'),
              },
            ]}
            activeTab={activeTab}
            changeTab={setActiveTab}
            underline
            center
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            {activeTab === Tab.RoleName && (
              <div>
                <Title description={edit ? t('roles.edit_role_description') : t('roles.create_role_description')} />

                <TextInput
                  required
                  label={t('inputs.name')}
                  placeholder={t('inputs.role_name_placeholder')}
                  error={getFieldState('name', formState)}
                  error_message={formState?.errors?.name?.message}
                  register={register('name', {
                    required: { value: true, message: t('roles.name_validation') },
                    maxLength: { value: MAX_NAME_FORM_LENGTH, message: t('validation_messages.max_64') },
                  })}
                />

                <TextInput
                  label={t('inputs.role_description')}
                  placeholder={t('inputs.role_description_placeholder')}
                  error_message={formState?.errors?.description?.message}
                  register={register('description', {
                    maxLength: { value: MAX_DESCR_FORM_LENGTH, message: t('validation_messages.max_160') },
                  })}
                />

                <Controller
                  control={control}
                  name="level"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      isLoading={false}
                      data={LEVELS}
                      label={t('inputs.level')}
                      placeholder={t('inputs.select_level')}
                      onSelect={onChange}
                    />
                  )}
                />

                <div className={styles.CheckboxContainer}>
                  {isAdminGroup && (
                    <Controller
                      control={control}
                      name="is_global"
                      render={({ field: { onChange, value } }) => (
                        <Checkbox checked={value} onChange={() => onChange(!value)}>
                          {t('inputs.global_role')}
                        </Checkbox>
                      )}
                    />
                  )}

                  {checkPermission(invisible_role, [PermissionsAction.CREATE]) && (
                    <Controller
                      control={control}
                      name="is_invisible"
                      render={({ field: { onChange, value } }) => (
                        <Checkbox checked={value} onChange={() => onChange(!value)}>
                          {t('inputs.invisible_role')}
                        </Checkbox>
                      )}
                    />
                  )}
                </div>
              </div>
            )}

            {activeTab === Tab.RolePermission && (
              <div>
                <Title
                  description={
                    edit ? t('roles.edit_role_permissions_description') : t('roles.create_role_permissions_description')
                  }
                />

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <RolesPerm />
                      {t('general.roles')}
                    </div>

                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.role"
                    render={({ field: { onChange, value } }) => <SelectTags value={value} onChange={onChange} />}
                  />
                </div>

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <GroupPerm /> {t('general.groups')}
                    </div>
                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.group"
                    render={({ field: { onChange, value } }) => <SelectTags value={value} onChange={onChange} />}
                  />
                </div>

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <UserPerm /> {t('general.users')}
                    </div>
                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.user"
                    render={({ field: { onChange, value } }) => <SelectTags value={value} onChange={onChange} />}
                  />
                </div>

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <AssessmentPerm /> {t('general.diagnostic')}
                    </div>
                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.assessment"
                    render={({ field: { onChange, value } }) => (
                      <SelectTags value={value} onChange={onChange} isAssessmentPermission />
                    )}
                  />
                </div>

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <ModulePerm /> {t('general.module')}
                    </div>
                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.module"
                    render={({ field: { onChange, value } }) => (
                      <SelectTags value={value} onChange={onChange} isEditPermissions />
                    )}
                  />
                </div>

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <SectionPerm /> {t('general.section')}
                    </div>
                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.section"
                    render={({ field: { onChange, value } }) => (
                      <SelectTags value={value} onChange={onChange} isEditPermissions />
                    )}
                  />
                </div>

                <div className={styles.PermissionsBlock}>
                  <div className={styles.RoleAddHR}>
                    <div className={styles.Line} />
                    <div className={styles.Title}>
                      <UnitPerm /> {t('general.unit')}
                    </div>
                    <div className={styles.Line} />
                  </div>

                  <Controller
                    control={control}
                    name="permissions.unit"
                    render={({ field: { onChange, value } }) => (
                      <SelectTags value={value} onChange={onChange} isEditPermissions />
                    )}
                  />
                </div>

                <div className={classNames(styles.PermissionsBlock, styles.Checkboxes)}>
                  <Controller
                    control={control}
                    name="permissions.own_results"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.READ}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
                      >
                        {t('roles.own_results')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.own_comparison"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.READ}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
                      >
                        {t('roles.own_comparison')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.other_users_dashboard_comparison"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.READ}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
                      >
                        {t('roles.other_users_dashboard_comparison')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.group_dashboard_comparison"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.READ}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
                      >
                        {t('roles.group_dashboard_comparison')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.additional_field"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.UPDATE}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.UPDATE] : [])}
                      >
                        {t('roles.additional_field')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.groups_users_report"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.READ}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
                      >
                        {t('roles.groups_users_report')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.activity_report"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.READ}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.READ] : [])}
                      >
                        {t('roles.activity_report')}
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="permissions.invisible_role"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value && value[0] === PermissionsAction.CREATE}
                        onChange={() => onChange(!value[0] ? [PermissionsAction.CREATE] : [])}
                      >
                        {t('roles.invisible_role')}
                      </Checkbox>
                    )}
                  />
                </div>
              </div>
            )}

            <div className={styles.ButtonsContainer}>
              <ButtonStyled fill={ButtonFill.Outlined} onClick={() => navigate(-1)}>
                {t('general.cancel')}
              </ButtonStyled>

              <ButtonStyled
                fill={ButtonFill.Contained}
                clickType="submit"
                loading={creatingRole || updatingRole || creatingPerm}
              >
                {activeTab === Tab.RoleName ? t('general.next') : t('general.save')}
              </ButtonStyled>
            </div>
          </form>
        </div>
      </ShadowBlock>
    </div>
  );
};

export default RoleAdd;
