import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import TextInput from 'components/Inputs/Text/Text';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Title from 'components/Title/Title';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useResetPasswordMutation } from 'store/api/authApi/authApi';

import ROUTES from 'router/routes';

import { ForgotResolver } from 'helpers/validations';

import { ButtonFill } from 'types/enums';

import styles from './ForgotPassword.module.scss';

type FormValues = {
  email: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit, formState, getFieldState } = useForm<FormValues>({
    resolver: yupResolver(ForgotResolver),
  });

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const goBack = () => navigate(-1);

  const onSubmit: SubmitHandler<FormValues> = (data) => resetPassword(data);

  useDocumentTitle([t('page_titles.forgot_password')]);

  return (
    <div className={styles.Container}>
      <ShadowBlock>
        <Breadcrumb
          items={[
            { name: t('general.login'), href: ROUTES.LOGIN },
            {
              name: t('general.forgot_password'),
              href: ROUTES.FORGOT_PASSWORD,
              active: true,
            },
          ]}
        />

        <div className={styles.ForgotPassword}>
          <Title title={t('general.forgot_password')} description={t('general.forgot_password_description')} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              register={register('email')}
              label={t('inputs.email')}
              placeholder={t('inputs.email_placeholder')}
              error={getFieldState('email', formState)}
              error_message={t(formState.errors?.email?.message || '')}
            />

            <div className={styles.ForgotPasswordControls}>
              <ButtonStyled fill={ButtonFill.Outlined} onClick={goBack}>
                {t('general.back')}
              </ButtonStyled>
              <ButtonStyled clickType="submit" fill={ButtonFill.Contained} loading={isLoading}>
                {t('general.submit')}
              </ButtonStyled>
            </div>
          </form>
        </div>
      </ShadowBlock>
    </div>
  );
};

export default ForgotPassword;
