import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Pagination } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import DeleteUserModal from 'components/Modals/DeleteUserModal/DeleteUserModal';
import UploadCSV from 'components/Modals/UploadCSV/UploadCSV';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';

import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import { useGetUsersQuery } from 'store/api/usersApi/usersApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { getFieldId } from 'helpers/table';

import { MIN_SEARCH_LENGTH, MOBILE_BREAKPOINT } from 'constants/';

import { ButtonFill, ButtonSize, DeleteType } from 'types/enums';
import { MobileCardType, MobileCardsData } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';
import { User } from 'types/userTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from './UserList.module.scss';

interface Row extends User {
  fieldId: number;
}

const UserList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { user } = useAppSelector((state) => state.permissions.permissions);
  const { page, size, sort, search, onPageChange, onSearching, onSorting } = useTableControl('first_name');
  const debouncedSearch = useDebounce(search, 500);
  const [openCsv, setOpenCsv] = useState(false);
  const [deleteType, changeDeleteType] = useState<DeleteType>(DeleteType.Soft);
  const [selectedUser, setUser] = useState<Row | null>(null);
  const { data, isFetching, isLoading } = useGetUsersQuery({
    page,
    size,
    order_by: sort.sortBy,
    search: debouncedSearch.length < MIN_SEARCH_LENGTH ? '' : debouncedSearch,
    groupId: selectedGroupId,
  });
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const getUserOrganizationValue = (
    groupAccesses: {
      organization: string;
      unit?: string | undefined;
    }[],
  ) => {
    const organizations = [...new Set(groupAccesses.map((item) => item.organization))];
    return organizations.length ? organizations.join(', ') : '-';
  };

  const getUserUnitValue = (
    groupAccesses: {
      organization: string;
      unit?: string | undefined;
    }[],
  ) => {
    const organizations = groupAccesses.map((item) => item.organization);
    const units = groupAccesses.map((item) => item.unit).filter((item) => item && !organizations.includes(item));
    return units.length ? units.join(', ') : '-';
  };

  const getActions = useCallback(
    (row: Row) => {
      const actions: ReactNode[] = [];

      if (checkPermission(user, [PermissionsAction.UPDATE])) {
        actions.push(
          <ButtonStyled
            key="edit"
            onClick={() => navigate(ROUTES.USER_EDIT(row.id))}
            fill={ButtonFill.Transparent}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            icon={<Edit />}
          >
            {t('general.edit')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(user, [PermissionsAction.DELETE])) {
        actions.push(
          <ButtonStyled
            key="delete"
            fill={ButtonFill.TransparentRed}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            onClick={() => setUser(row)}
            icon={<Delete />}
          >
            {t('general.delete')}
          </ButtonStyled>,
        );
      }

      return actions;
    },
    [isMobile, navigate, t, user],
  );

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'fieldId',
        headerName: '#',
        sortable: false,
        width: 30,
      },
      {
        field: 'first_name',
        headerName: t('users.table.name'),
        sortable: false,
        flex: 1,
        valueGetter: (params) => params.row.first_name + ' ' + params.row.last_name,
      },
      {
        field: 'organization',
        headerName: t('users.table.user_organization'),
        sortable: false,
        flex: 1,
        valueGetter: (params) => getUserOrganizationValue(params.row.group_accesses),
      },
      {
        field: 'unit',
        headerName: t('users.table.user_unit'),
        headerAlign: 'center',
        align: 'center',
        width: 100,
        sortable: false,
        valueGetter: (params) => getUserUnitValue(params.row.group_accesses),
      },
      {
        field: 'role',
        headerName: t('users.table.user_role'),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        sortable: false,
      },
      {
        headerName: t('general.actions'),
        field: 'actions',
        headerAlign: 'left',
        width: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, Row>) => getActions(params.row),
      },
    ],
    [getActions, t],
  );

  const mobileCardsData = useMemo<MobileCardsData>(
    () =>
      isMobile && data?.items
        ? data.items.map((item, index) => ({
            row: [
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('users.table.name'),
                    value: `${item.first_name} ${item.last_name}`,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Ordinal,
                    label: '#',
                    value: getFieldId({ size: size, page, index: index, total: data?.items.length }),
                    key: item.id + 2,
                  },
                ],
                key: item.id + 1,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('users.table.user_organization'),
                    value: getUserOrganizationValue(item.group_accesses),
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Info,
                    label: t('users.table.user_unit'),
                    value: getUserUnitValue(item.group_accesses),
                    key: item.id + 2,
                  },
                ],
                key: item.id + 2,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('users.table.user_role'),
                    value: item.role,
                    key: item.id + 1,
                  },
                ],
                key: item.id + 3,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Actions,
                    actions: getActions(item as Row),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 4,
              },
            ],
            key: item.id,
          }))
        : [],
    [data?.items, getActions, isMobile, page, size, t],
  );

  const getPageTitleAction = () => {
    const actions = [];

    if (checkPermission(user, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="add_csv"
          size={ButtonSize.Small}
          fill={ButtonFill.Outlined}
          onClick={() => setOpenCsv(true)}
          icon={<AddIcon />}
          wrapText={isMobile}
        >
          {t('general.add_csv')}
        </ButtonStyled>,
      );
    }

    if (checkPermission(user, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="add_user"
          size={ButtonSize.Small}
          fill={ButtonFill.Contained}
          onClick={() => navigate(ROUTES.USER_ADD)}
          icon={<AddIcon />}
          wrapText={isMobile}
        >
          {t('users.add_user')}
        </ButtonStyled>,
      );
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  useDocumentTitle([t('page_titles.user_list')]);

  return (
    <div className={styles.RolePage}>
      <UploadCSV open={openCsv} handleClose={() => setOpenCsv(false)} />

      <DeleteUserModal
        selectedUserId={selectedUser?.id}
        deleteType={deleteType}
        open={!!selectedUser}
        close={() => setUser(null)}
        change={changeDeleteType}
      />

      <PageTitle
        controls
        searchPlaceholder={t('users.search_placeholder')}
        title={t('users.user_list')}
        search={search}
        sort={sort.sortType}
        onSearch={onSearching}
        onSort={onSorting}
        titleTopColumn={isMobile}
      >
        {pageTitleActions.length ? pageTitleActions : null}
      </PageTitle>

      {isMobile ? (
        <div className={styles.MobileContainer}>
          {isLoading || isFetching ? (
            <Loader flexCenter size="md" />
          ) : (
            <>
              {mobileCardsData?.length ? (
                <MobileCards data={mobileCardsData} />
              ) : (
                <NothingFoundText text={t('users.table.empty_result')} />
              )}
            </>
          )}
          {data?.items.length ? (
            <div className={styles.PaginationContainer}>
              <Pagination
                color="primary"
                shape="rounded"
                page={page}
                count={Math.ceil((data?.total || 0) / (data?.size || 0))}
                onChange={(_, value) => onPageChange(value)}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <DataTable
          data={data}
          emptyResultText={t('users.table.empty_result')}
          columns={columns}
          isLoading={isLoading || isFetching}
          page={page}
          changePage={onPageChange}
        />
      )}
    </div>
  );
};

export default UserList;
