import { ReactNode } from 'react';

import classNames from 'classnames';

import { CardVariants } from 'types/dashboardTypes';

import styles from './OptionCard.module.scss';

type Props = {
  variant?: CardVariants;
  title: string;
  children: ReactNode;
};

const OptionCard = ({ variant = CardVariants.Blue, title, children }: Props) => {
  return (
    <div className={classNames(styles.Card, { [styles[variant]]: variant })}>
      <h3 className={styles.Title}>{title}</h3>

      {children}
    </div>
  );
};

export default OptionCard;
