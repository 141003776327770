import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import PasswordInput from 'components/Inputs/Password/Password';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Title from 'components/Title/Title';

import PasswordValidationCheckbox from '../components/PasswordValidationCheckbox/PasswordValidationCheckbox';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useChangePasswordMutation } from 'store/api/authApi/authApi';
import { ChangePasswordPayload } from 'store/api/authApi/types';

import ROUTES from 'router/routes';

import { SetupPassResolver } from 'helpers/validations';

import { ButtonFill } from 'types/enums';

import styles from './ChangePassword.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
  oldPassword: string;
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const navigate = useNavigate();
  const { register, getFieldState, formState, handleSubmit, setError } = useForm<FormValues>({
    resolver: yupResolver(SetupPassResolver),
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const resetPasswordData: ChangePasswordPayload = {
      old_password: data.oldPassword,
      new_password: data.password,
      check_password: data.confirmPassword,
    };

    changePassword(resetPasswordData)
      .unwrap()
      .then((resp) => {
        toast.success(t('general.password_change_success'));
        navigate(ROUTES.LOGIN);
      })
      .catch((error) => {
        const { status } = error;

        if (status === 403) {
          setError('oldPassword', {
            type: 'server',
            message: error.data.detail,
          });
        }
      });
  };

  useDocumentTitle([t('page_titles.change_password')]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <ShadowBlock>
          <Breadcrumb
            items={[
              {
                name: isAuth ? t('profile.personal_profile_settings') : t('general.login'),
                href: isAuth ? ROUTES.PERSONAL_PROFILE_SETTINGS : ROUTES.LOGIN,
              },
              {
                name: t('general.setup_pass'),
                href: ROUTES.RESET_PASSWORD,
                active: true,
              },
            ]}
          />

          <div className={styles.PasswordSetup}>
            <Title title={t('general.setup_pass')} description={t('general.create_pass_description')} />

            <form className={styles.PasswordSetup_Form} onSubmit={handleSubmit(onSubmit)}>
              <PasswordInput
                label={t('inputs.old_password')}
                placeholder={t('inputs.password_placeholder')}
                register={register('oldPassword')}
                error={getFieldState('oldPassword', formState)}
                error_message={t(formState.errors.oldPassword?.message || '')}
              />

              <PasswordInput
                label={t('inputs.setup_password')}
                placeholder={t('inputs.password_placeholder')}
                register={register('password')}
                error={getFieldState('password', formState)}
                error_message={t(formState.errors.password?.message ? t('sign_up.stronger_message') : '')}
              />

              <div className={styles.SignUpValidation}>
                <div className={styles.ValidationTitle}>{t('sign_up.validation_title')}</div>

                <div
                  style={{
                    opacity: formState.errors?.password?.message ? '1' : '0',
                    transition: '300ms ease-in-out all',
                  }}
                ></div>

                <div className={styles.ValidationBlock}>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.length" formState={formState} />
                    {t('sign_up.password_length')}
                  </div>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.capital" formState={formState} />
                    {t('sign_up.password_capital')}
                  </div>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.number" formState={formState} />
                    {t('sign_up.password_number')}
                  </div>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.symbol" formState={formState} />
                    {t('sign_up.password_symbol')}
                  </div>
                </div>
              </div>

              <PasswordInput
                label={t('inputs.setup_confirm')}
                placeholder={t('inputs.confirm_password')}
                register={register('confirmPassword')}
                error={getFieldState('confirmPassword', formState)}
                error_message={t(formState.errors?.confirmPassword?.message || '')}
              />

              <ButtonStyled
                fill={ButtonFill.Contained}
                clickType="submit"
                fullWidth
                loading={isLoading}
                disabled={!formState.isValid}
              >
                {t('general.confirm')}
              </ButtonStyled>
            </form>
          </div>
        </ShadowBlock>
      </div>
    </div>
  );
};

export default ChangePassword;
