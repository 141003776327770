import { Size } from 'types/enums';

import { ReactComponent as DeleteIcon } from 'assets/images/delete-icon.svg';

import styles from './DeleteButton.module.scss';

type Props = {
  title?: string;
  size?: Size;
  clear: () => void;
};

const DeleteButton = ({ title, clear, size = Size.Lg }: Props) => {
  const onClear = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    clear();
  };

  return (
    <button className={styles.DeleteButton} onClick={(e) => onClear(e)} type="reset">
      <div className={size && styles[size]}>
        <DeleteIcon />
      </div>
      {title && <div className={styles.Title}>{title}</div>}
    </button>
  );
};

export default DeleteButton;
