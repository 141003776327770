import { MutableRefObject, useEffect, useState } from 'react';

function usePopup(popupRef: MutableRefObject<HTMLDivElement | null>) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    const handleDocumentClick = (ev: Event) => {
      const clickedComponent = ev.target as Node;
      if (!popupRef?.current?.contains(clickedComponent)) {
        setShowPopup(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [popupRef]);

  return [showPopup, setShowPopup] as const;
}

export default usePopup;
