import { Trans, useTranslation } from 'react-i18next';

import styles from './Eula.module.scss';

type Props = {
  i18nKey: string;
  children: React.ReactNode;
};

const Eula = ({ i18nKey, children }: Props) => {
  const { t } = useTranslation();

  return (
    <p className={styles.Eula}>
      <Trans i18nKey={i18nKey} t={t}>
        {children}
      </Trans>
    </p>
  );
};

export default Eula;
