import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateUnitMutation } from 'store/api/unitsApi/unitsApi';

import { unitResolver } from 'helpers/validations';

import TextInput from '../../Inputs/Text/Text';
import ModalWrapper from '../ModalWrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
};

type FormValues = {
  name: string;
};

const NewUnitModal = ({ open, handleClose, ...props }: Props) => {
  const { t } = useTranslation();

  const { register, handleSubmit, formState, getFieldState, reset, setError } = useForm<FormValues>({
    resolver: yupResolver(unitResolver),
    mode: 'all',
  });

  const [createUnit] = useCreateUnitMutation();

  const handleCancel = () => {
    handleClose();
    reset({ name: '' });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    createUnit(data.name.trim())
      .unwrap()
      .then(() => {
        handleClose();
        reset({ name: '' });
      })
      .catch((error) => {
        const { status } = error;
        const { detail } = error.data;
        if (status === 400) {
          setError('name', {
            message: t(detail.error, {
              entity: t('general.unit'),
              value: detail.items.title,
              field: t('server.field.name').toLowerCase(),
            }),
          });
        }
      });
  };

  return (
    <ModalWrapper
      {...props}
      title={t('assessment.new_unit')}
      open={open}
      close={handleCancel}
      action={handleSubmit(onSubmit)}
      subtitle={t('assessment.new_unit_description')}
      actionTitle={t('general.confirm')}
      actionDisabled={!formState.isValid}
    >
      <TextInput
        register={register('name')}
        label={t('inputs.unit_name')}
        placeholder={t('inputs.unit_name')}
        error={getFieldState('name', formState)}
        error_message={t(formState?.errors.name?.message || '')}
      />
    </ModalWrapper>
  );
};

export default NewUnitModal;
