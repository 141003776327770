import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Location, Transforms } from 'slate';

import usePopup from '../../../../utils/hooks/usePopup';

import { CustomEditor } from 'types/richTextEditorTypes';

import Icon from '../../../../common/Icon';
import { insertLink } from '../../../../utils/link';

import styles from '../../Link.module.scss';

type Props = {
  editor: CustomEditor;
};

const LinkButton = ({ editor }: Props) => {
  const format = 'link';
  const iconLink = 'link';
  const iconAddLink = 'add_link';
  const { t } = useTranslation();
  const linkInputRef = useRef<HTMLDivElement | null>(null);
  const [showInput, setShowInput] = usePopup(linkInputRef);
  const [url, setUrl] = useState('');
  const [showInNewTab, setShowInNewTab] = useState<boolean>(false);
  const [selection, setSelection] = useState<Location | null>(null);
  const handleInsertLink = () => {
    if (selection) {
      Transforms.select(editor, selection);
    }
    insertLink(editor, { url, showInNewTab });
    setUrl('');
    setShowInput((prev) => !prev);
    setShowInNewTab(false);
  };
  const toggleLink = () => {
    setSelection(editor.selection);
    setShowInput((prev) => !prev);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === 'checkbox') {
      setShowInNewTab((prev) => !prev);
    } else {
      setUrl(event.target.value);
    }
  };
  return (
    <div ref={linkInputRef} className="popup-wrapper">
      <button onClick={toggleLink} className={styles.button} title={format}>
        <Icon>{iconLink}</Icon>
      </button>
      {showInput && (
        <div className={styles.popup}>
          <div className={styles.popupWrapper}>
            <input type="text" placeholder="https://google.com" value={url} onChange={handleInputChange} />
            <button onClick={handleInsertLink}>
              <Icon>{iconAddLink}</Icon>
            </button>
          </div>
          <label>
            <input type="checkbox" checked={showInNewTab} onChange={handleInputChange} />
            <span>{t('general.open_in_new_tab')}</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default LinkButton;
