import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select/Select';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useVisualisationsOptions } from 'pages/Assessments/AssessmentCreate/hooks';

import { addQuestionMapping } from 'store/features/createAssessmentSlice';

import styles from './QuestionMapping.module.scss';

export const QuestionMapping = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const questionMappings = useAppSelector((state) => state.createAssessment.questionMappings);
  const { visualisationsOptions, isLoading } = useVisualisationsOptions();

  const { control } = useForm({
    defaultValues: questionMappings,
  });

  return (
    <div className={styles.Container}>
      {Object.entries(questionMappings).map(([questionId, { questionName }]) => (
        <Controller
          key={questionId}
          name={questionId}
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (value: string) => {
              onChange({ visualisationId: value, questionName });
              dispatch(addQuestionMapping({ questionId, visualisationId: value, questionName }));
            };
            return (
              <Select
                value={value.visualisationId}
                isLoading={isLoading}
                data={visualisationsOptions}
                label={questionName}
                labelClassNames={{
                  InputLabels: styles.QuestionLabelContainer,
                  InputLabel: styles.QuestionLabel,
                }}
                placeholder={t('inputs.select_placeholder')}
                onSelect={handleChange}
                selectClassName="custom-select-visualisations"
                selectMenuClassName="custom-select-menu-visualisations"
              />
            );
          }}
        />
      ))}
    </div>
  );
};
