import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import * as Sentry from '@sentry/react';
import Gleap from 'gleap';

import { store } from './store/store';

import './helpers/i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite/dist/rsuite.min.css';

import './index.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);

if (process.env.REACT_APP_GLEAP_TOKEN) {
  Gleap.initialize(process.env.REACT_APP_GLEAP_TOKEN);
}

if (process.env.REACT_APP_SENTRY_TOKEN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_TOKEN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <App />
  </Provider>,
);

reportWebVitals();
