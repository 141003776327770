import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select/Select';

import { DropdownTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

type Props = {
  element: DropdownTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  onChangeAnswer: (value: ValueType) => void;
};

type ValueType = string | number | undefined;

const Dropdown = ({ element, loading, onNext, onPrev, onChangeAnswer }: Props) => {
  const { t, i18n } = useTranslation();

  const sortedOptions = [...element.options].sort((a, b) => a.order - b.order);

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{
        loading,
        onNext,
        onPrev,
        disabled: !element.answer,
      }}
    >
      <Select
        value={element.answer}
        onSelect={(value: ValueType) => onChangeAnswer(value)}
        data={sortedOptions.map((el) => ({
          value: el.id,
          label: el[`text_${i18n.language}` as keyof Translations],
        }))}
        placeholder={t('general.dropdown_placeholder')}
      />
    </ElementWrapper>
  );
};

export default Dropdown;
