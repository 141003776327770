import { useTranslation } from 'react-i18next';

import { ModalSize } from 'types/modalTypes';

import ModalWrapper from '../ModalWrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleOpenDemo: () => void;
};

const DemoModal = ({ open, handleClose, handleOpenDemo, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      open={open}
      close={handleClose}
      cancelTitle={t('general.back')}
      modalSpacing={ModalSize.NoSpacing}
      subtitle={t('assessment.demo_modal_description')}
      action={handleOpenDemo}
      actionTitle={t('general.ok')}
      attention
    />
  );
};

export default DemoModal;
