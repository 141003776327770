import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { Col } from 'rsuite';

import { TagTreePicker } from 'components/TagTreePicker/TagTreePicker';

import { useAppSelector } from 'hooks/redux';

import { FormElements, IVisualisationFormMarkup } from 'types/createAssessmentTypes';

import styles from './MappingFormItem.module.scss';

type Props = {
  control: Control;
  formElement: IVisualisationFormMarkup;
  labelClassName?: string;
};
export const MappingFormItem = ({ control, formElement, labelClassName }: Props) => {
  const { t } = useTranslation();
  const sectionsOptions = useAppSelector((state) => state.createAssessment.sectionsOptions);
  const options = cloneDeep(sectionsOptions);

  return (
    <Col
      className={classNames(styles.FormItem, {
        [styles.Small]: formElement.size === FormElements.Small,
      })}
      xs={formElement.size}
    >
      <Controller
        name={formElement.id}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('assessment.validation.mapping_required_single'),
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error, invalid } }) => {
          return (
            <TagTreePicker
              data={options}
              values={value}
              onChange={onChange}
              label={formElement.label}
              labelClassName={labelClassName}
              invalid={invalid}
              errorMessage={error?.message}
              placeholder={t('general.dropdown_placeholder')}
            />
          );
        }}
      />
    </Col>
  );
};
