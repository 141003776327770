import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Avatar } from 'rsuite';

import { useAppSelector } from 'hooks/redux';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { Size } from 'types/enums';
import { IGroupsHierarchyUser } from 'types/groupHierarchyTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import styles from './LevelUser.module.scss';

type Props = { data: IGroupsHierarchyUser; size?: Size };

const LevelUser = ({ size, data }: Props) => {
  const { user } = useAppSelector((state) => state.permissions.permissions);
  const hasUpdatePermission = checkPermission(user, [PermissionsAction.UPDATE]);
  const fullName = `${data.first_name} ${data.last_name}`;

  return (
    <li>
      <div className={classNames(styles.Element, size && styles[size as Size.Lg])}>
        <div className={styles.Avatar}>
          <Avatar size="xs" circle src={data.photo || undefined} alt={data.first_name[0]}>
            {data.first_name[0]}
          </Avatar>
        </div>
        {hasUpdatePermission ? (
          <Link className={styles.Name} to={ROUTES.USER_EDIT(data.id)}>
            {fullName}
          </Link>
        ) : (
          <div className={styles.Name}>{fullName}</div>
        )}
      </div>
    </li>
  );
};

export default LevelUser;
