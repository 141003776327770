import { Link, useNavigate } from 'react-router-dom';

import { Avatar } from '@mui/material';

import { useAppSelector } from 'hooks/redux';

import { useSignOutMutation } from 'store/api/authApi/authApi';
import { useGetMyProfileQuery } from 'store/api/profileApi/profileApi';

import ROUTES from 'router/routes';

import { ReactComponent as LogOut } from 'assets/images/exit.svg';

import styles from './UserState.module.scss';

const UserState = () => {
  const navigate = useNavigate();
  const { role } = useAppSelector((state) => state.permissions);

  const { data: user } = useGetMyProfileQuery();
  const [signOut] = useSignOutMutation();

  const signOutHandler = () => {
    signOut().then(() => {
      navigate(ROUTES.LOGIN, { replace: true });
      localStorage.clear();
      window.location.reload();
    });
  };

  return (
    <div className={styles.UserState}>
      <div className={styles.UserInfo}>
        <Link to={ROUTES.PERSONAL_PROFILE_SETTINGS} className={styles.Avatar}>
          <Avatar className={styles.Avatar} alt={user?.first_name} src={user?.photo ?? ''} />
        </Link>

        <div className={styles.UserData}>
          <span className={styles.Name}>
            {user?.first_name} {user?.last_name}
          </span>
          {role && <span className={styles.Role}>{role}</span>}
        </div>
      </div>

      <div className={styles.SignOut}>
        <button className={styles.SignOutButton} onClick={signOutHandler}>
          <LogOut />
        </button>
      </div>
    </div>
  );
};

export default UserState;
