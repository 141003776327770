export interface IServerErrorWithTranslation {
  data: {
    detail: {
      error: string;
      name: string;
      items: {
        [key: string]: string | string[];
      };
    };
  };
  status: number;
}

export const isServerErrorWithTranslation = (error: unknown): error is IServerErrorWithTranslation => {
  return Boolean((error as IServerErrorWithTranslation)?.data?.detail?.error);
};

export interface IValidationServerError {
  data: {
    detail: {
      loc: string[];
      msg: string;
      type: string;
    }[];
  };
  status: number;
}

export const isValidationServerError = (error: unknown): error is IValidationServerError => {
  return Boolean((error as IValidationServerError)?.data?.detail?.[0]?.type);
};

export interface IServerError {
  error?: { data: { detail: string } };
  data?: { detail: string };
}

export enum ErrorEntities {
  Group = 'Groups',
  User = 'Users',
}
