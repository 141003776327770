import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import classNames from 'classnames';

import { BarValue } from 'types/accelerantTypes';
import { Size } from 'types/enums';

import styles from './Bars.module.scss';

type Props = {
  value: BarValue;
  size?: typeof Size.Sm | typeof Size.Lg;
};

const Bars = ({ value, size = Size.Lg }: Props) => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  const activeBackground = {
    [BarValue.Low]: styles.Low,
    [BarValue.Medium]: styles.Medium,
    [BarValue.High]: styles.High,
  }[value];

  return (
    <div
      ref={ref}
      className={classNames(styles.Container, styles[size], activeBackground, styles.AnimationSm, {
        [styles.AnimationLg]: inView && size === Size.Lg,
      })}
    >
      <div className={styles.BarContainer}>
        <div className={styles.Bar}>
          <span className={classNames({ [styles.Visible]: value === BarValue.Low })}>{t('general.low')}</span>
        </div>
      </div>
      <div className={styles.BarContainer}>
        <div className={styles.Bar}>
          <span className={classNames({ [styles.Visible]: value === BarValue.Medium })}>{t('general.medium')}</span>
        </div>
      </div>
      <div className={styles.BarContainer}>
        <div className={styles.Bar}>
          <span className={classNames({ [styles.Visible]: value === BarValue.High })}>{t('general.high')}</span>
        </div>
      </div>
    </div>
  );
};

export default Bars;
