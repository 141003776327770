import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack } from 'rsuite';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useVisualisationDisplayMode } from '../../hooks';

import { addMappings } from 'store/features/createAssessmentSlice';

import { IVisualisationFormData } from 'types/createAssessmentTypes';
import { ButtonFill, ButtonSize, ResultsMappingDisplayMode, ResultsMappingStep } from 'types/enums';

import { ErrorMessage, MappingForm, VisualisationBlock, VisualisationData } from '..';

import styles from './MappingData.module.scss';

type Props = {
  scurveFormData: IVisualisationFormData;
  accelerantsFormData: IVisualisationFormData;
  ecosystemFormData: IVisualisationFormData;
};

export const MappingData = ({ scurveFormData, accelerantsFormData, ecosystemFormData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mappings = useAppSelector((state) => state.createAssessment.mappings);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: mappings,
  });
  const { visualisationDisplayMode, handleEditVisualisation, resetAllDisplayModes, isEditing } =
    useVisualisationDisplayMode();

  const fieldNames = {
    ...scurveFormData.state.fieldNames,
    ...accelerantsFormData.state.fieldNames,
    ...ecosystemFormData.state.fieldNames,
  };

  const markup = {
    [ResultsMappingStep.Scurve]: scurveFormData.markup,
    [ResultsMappingStep.Accelerants]: accelerantsFormData.markup,
    [ResultsMappingStep.Ecosystem]: ecosystemFormData.markup,
  };

  const onSubmit: SubmitHandler<Record<string, string[]>> = (mappingData) => {
    dispatch(addMappings(mappingData));

    resetAllDisplayModes();
  };

  return (
    <div className={styles.Container}>
      {!!Object.keys(errors).length && (
        <ErrorMessage
          className={styles.ErrorMessage}
          title={t('assessment.validation.mapping_required_general')}
          errorFields={Object.keys(errors).map((error) => fieldNames[error])}
        />
      )}
      {Object.keys(ResultsMappingStep).map((visualisation) => {
        const visualisationEntity = visualisation as keyof typeof ResultsMappingStep;
        const isViewMode =
          visualisationDisplayMode[ResultsMappingStep[visualisationEntity]] === ResultsMappingDisplayMode.View;

        return (
          <VisualisationBlock
            key={visualisationEntity}
            title={ResultsMappingStep[visualisationEntity]}
            handleEditVisualisation={handleEditVisualisation}
            showEditButton={isViewMode}
          >
            {isViewMode ? (
              <VisualisationData markup={markup[ResultsMappingStep[visualisationEntity]]} />
            ) : (
              <MappingForm markup={markup[ResultsMappingStep[visualisationEntity]]} control={control} />
            )}
          </VisualisationBlock>
        );
      })}
      {isEditing && (
        <Stack justifyContent="flex-end">
          <ButtonStyled fill={ButtonFill.Contained} size={ButtonSize.Small} onClick={handleSubmit(onSubmit)}>
            {t('general.save')}
          </ButtonStyled>
        </Stack>
      )}
    </div>
  );
};
