import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from 'types/modalTypes';

import ModalWrapper from '../ModalWrapper';

const ResendResultsModal = (props: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      title={t('dashboard.resend_results_modal.title')}
      subtitle={t('dashboard.resend_results_modal.description')}
      actionTitle={t('dashboard.resend')}
      cancelTitle={t('general.cancel')}
    />
  );
};

export default ResendResultsModal;
