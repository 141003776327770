export interface GroupsHierarchyResponse {
  items: IGroupHierarchyItem[];
}

export interface GroupsHierarchyPayload {
  search: string;
  orderBy: 'name' | '-name';
  selectedGroup: string;
}

export interface GroupsHierarchyNavigationResponse {
  items: IGroupsHierarchyNavigationItem[];
  total: number;
  page: number;
  size: number;
}

export interface IGroupsHierarchyUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  photo: string | null;
}

export interface IGroupHierarchyItemBase {
  created_at: string;
  creator?: IGroupsHierarchyUser | null;
  creator_id?: string | null;
  path: {
    path: string;
  };
  path_in_tree: string;
  name: string;
  user_limit: number | null;
  matches_search_term: boolean;
  updated_at: string;
  type: GroupHierarchyType;
  id: string;
  description: string | null;
  parent_id: string | null;
  is_disabled?: boolean;
  level?: string;
  selected?: boolean;
  has_access: boolean;
}
export interface IGroupHierarchyItem extends IGroupHierarchyItemBase {
  users: IGroupsHierarchyUser[];
  children: IGroupHierarchyItem[];
}

export interface IGroupsHierarchyNavigationItem extends IGroupHierarchyItemBase {
  users?: never;
  children: IGroupsHierarchyNavigationItem[];
  hasAccess: boolean;
}

export interface IGroupsHierarchyList {
  groups: string[];
  users: string[];
}

export enum GroupHierarchyType {
  Organization = 'organisation',
  Group = 'group',
  Subgroup = 'subgroup',
}

export interface NormalizedGroupsHierarchy {
  id: string;
  parentId?: string;
  name: string;
  type: GroupHierarchyType;
  children: IGroupsHierarchyNavigationItem[];
  path: Record<string, string>;
  hasAccess: boolean;
}

export interface SelectGroupHierarchy {
  value: string;
  label: string;
  children?: SelectGroupHierarchy[] | undefined;
}
