import { ReactElement } from 'react';

import { GroupDashboard } from './groupTypes';
import { Translations } from './translationTypes';
import { User } from './userTypes';

export enum VisualisationTypes {
  SCurve = 's_curve',
  Accelerants = 'accelerants',
  Ecosystem = 'ecosystem',
}

export interface MappingsPayload {
  completionId: string;
  visualisationType: VisualisationTypes;
}

export interface MappingsGroupPayload {
  groupId: string;
  assessmentId: string | null;
  visualisationType: VisualisationTypes;
  dateFrom?: string | null;
  dateTo?: string | null;
}

export interface ContextsPayload {
  completionId: string;
}

export interface DashboardResult {
  id: string;
  index: number;
  parent_id: null | string;
  name: Translations;
  description: Translations;
  sections: DashboardResultSection[];
  value: number;
  sub_graphs?: DashboardResult[];
}

export interface DashboardResultGroupAccelerants extends DashboardResult {
  sections: IDashboardResultSectionGroupAccelerants[];
  sub_graphs: DashboardResultGroupAccelerants[];
  value: never;
}

export interface DashboardResultGroupScurve extends DashboardResult {
  sections: IDashboardResultSectionGroupScurve[];
  sub_graphs: DashboardResultGroupScurve[];
  value: never;
}

export interface DashboardResultSection {
  id: string;
  index: number;
  name: Translations;
  description: Translations;
  points: IDashboardResultPoint[];
}

export interface IDashboardResultPointGroupScurve extends IDashboardResultPoint {
  percentage: number;
  amount: number;
  users: User[];
}

export interface PointUser {
  id: string;
  firstName: string;
  lastName: string;
}

export interface IDashboardResultPointGroupAccelerants extends IDashboardResultPoint {
  percentage: number;
  amount: number;
}

export interface IDashboardResultSectionGroupScurve extends DashboardResultSection {
  percentage: number;
  amount: number;
  points: IDashboardResultPointGroupScurve[];
  perfect_percentage: number;
  distribution_hint: Translations;
}

export interface IDashboardResultSectionGroupAccelerants extends DashboardResultSection {
  percentage: number;
  amount: number;
  points: IDashboardResultPointGroupAccelerants[];
}

export interface IDashboardResultPoint {
  id: string;
  index: number;
  cutoff_value: number;
  color: string | null;
  name: Translations;
  description: Translations;
}

export interface Context {
  id: string;
  question: Translations;
  answer: string;
}

export interface IDashboardTooltip {
  id?: string;
  title: string;
  content: string;
}

export interface IDashboardTooltipGroup extends IDashboardTooltip {
  percentage: number;
  amount: number;
  users: PointUser[];
}

export interface IDashboardTooltipComparison extends IDashboardTooltip {
  amount: number;
  percentage: number;
}

export enum TooltipType {
  Chart,
  Section,
}

export interface SelectorItem {
  value: string;
  title: string;
  img?: string | null;
  avatars?: IGroupAvatar[];
  description?: string;
  actions?: ReactElement[];
}

export interface IGroupAvatar {
  src: string | null;
  name: string;
}

export interface IPoint {
  radius: number;
  stroke: number;
  id: string;
  index: number;
  pointIndex?: number;
  color: PointColor;
  isComparisonPoint?: boolean;
}

export type SelectedGroups = [GroupDashboard | undefined, GroupDashboard | undefined];

export enum PointColor {
  Yellow = 'Yellow',
  Green = 'Green',
}

export enum CardVariants {
  Blue = 'Blue',
  Yellow = 'Yellow',
}

export enum SelectorVariant {
  Group = 'Group',
  User = 'User',
}

export enum DashboardType {
  USER = 'user',
  GROUP = 'group',
}

export enum DashboardSearchParams {
  ASSESSMENT_1 = 'assessment_1',
  ASSESSMENT_2 = 'assessment_2',
  COMPLETION_1 = 'completion_1',
  COMPLETION_2 = 'completion_2',
  GROUP_1 = 'group_1',
  GROUP_2 = 'group_2',
  USER_1 = 'user_1',
  USER_2 = 'user_2',
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
}

export enum Tab {
  SCurve,
  Accelerants,
  Ecosystem,
  Context,
}
