import { useTranslation } from 'react-i18next';

import ModalWrapper from 'components/Modals/ModalWrapper';

import { ModalProps } from 'types/modalTypes';
import { IContextItem } from 'types/resultsContext';

import ContextItem from '../ContextItem/ContextItem';

interface Props extends ModalProps {
  question: IContextItem | null;
}

const ContextModal = ({ question, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      title={t('dashboard.context.modal_title')}
      subtitle={t('dashboard.context.modal_subtitle')}
      close={props.close}
      cancelTitle={t('general.close')}
    >
      <ContextItem inModal question={question} />
    </ModalWrapper>
  );
};

export default ContextModal;
