import { useTranslation } from 'react-i18next';

import { formatDateDetailed } from 'helpers/date';

import { CompletionDetails } from 'types/assessmentTypes';

import styles from './AssessmentInfo.module.scss';

type Props = {
  completionLeft: CompletionDetails;
};

const AssessmentInfo = ({ completionLeft }: Props) => {
  const { t } = useTranslation();
  const { attempt_date, assessment_description } = completionLeft;

  return (
    <div className={styles.Container}>
      {attempt_date && (
        <div className={styles.AssessmentInfo}>
          <span>{t('assessment.attempt_date')}</span>
          <p>{formatDateDetailed(attempt_date)}</p>
        </div>
      )}

      <div className={styles.AssessmentInfo}>
        <span>{t('assessment.description')}</span>
        <p>{assessment_description}</p>
      </div>
    </div>
  );
};

export default AssessmentInfo;
