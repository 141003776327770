import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ResultsMappingStep } from 'types/enums';

import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from './VisualisationBlock.module.scss';

type Props = {
  children: ReactNode;
  title: ResultsMappingStep;
  handleEditVisualisation: (visualisationName: ResultsMappingStep) => void;
  showEditButton: boolean;
};
export const VisualisationBlock = ({ children, title, handleEditVisualisation, showEditButton }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.VisualisationContainer}>
      <div className={styles.VisualisationHeader}>
        <span className={styles.Title}>{title}</span>
        {showEditButton && (
          <button type="button" onClick={() => handleEditVisualisation(title)} className={styles.EditButton}>
            <Edit fill="white" color="white" />
            {t('general.edit')}
          </button>
        )}
      </div>
      {children}
    </div>
  );
};
