import Level from './components/Level/Level';

import { IGroupsHierarchyList } from 'types/groupHierarchyTypes';
import { GroupsHierarchyResponse } from 'types/groupHierarchyTypes';

import styles from './GroupHierarchyPicker.module.scss';

type Props = {
  data: GroupsHierarchyResponse;
  list: IGroupsHierarchyList;
  onSelectEntity: (value: string, entity: string) => void;
};

// Using this component for selecting group and user for Assessment Assign

const GroupHierarchyPicker = ({ data, list, onSelectEntity }: Props) => (
  <div>
    <ul className={styles.GroupHierarchy}>
      {data.items.map((element, index) => (
        <Level index={index} data={element} key={element.id} list={list} onSelectEntity={onSelectEntity} />
      ))}
    </ul>
  </div>
);

export default GroupHierarchyPicker;
