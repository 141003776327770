import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import CustomNoRowsOverlay from 'components/DataTable/components/CustomNoRowsOverlay/CustomNoRowsOverlay';
import Loader from 'components/Loader/Loader';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useGetCompletionsQuery } from 'store/api/assessmentApi/assessmentApi';
import { GetCompletionsResponse, GetCompletionsSearchResponse } from 'store/api/assessmentApi/types';

import { checkPermission } from 'helpers/checkUserPermission';

import { MIN_SEARCH_LENGTH } from 'constants/';

import { ICompletionsItem } from 'types/assessmentTypes';
import { AssessmentListTab, OutletProps } from 'types/assessmentTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import AssessmentResultItem from '../../../AssessmentResultItem/AssessmentResultItem';

import styles from './AssessmentResults.module.scss';

const AssessmentResultsOutlet = () => {
  const { t } = useTranslation();
  const { tableProps, changeTab } = useOutletContext<OutletProps>();
  const { page, size, sort, search, onPageChange } = tableProps;
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { own_results, other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);

  useEffect(() => {
    changeTab(AssessmentListTab.RESULT);
  }, [changeTab]);

  let params = null;
  let result: GetCompletionsResponse | GetCompletionsSearchResponse | null = null;

  const isValidMinSearchLength = search.length >= MIN_SEARCH_LENGTH;

  if (isValidMinSearchLength) {
    params = { page, size, search, order_by: sort };
  }

  const { data, refetch, isFetching, isLoading } = useGetCompletionsQuery(params);

  useEffect(() => {
    refetch();
  }, [refetch, selectedGroupId]);

  if (data && !isFetching) {
    if (isValidMinSearchLength) {
      result = data as GetCompletionsSearchResponse;
    } else {
      result = data as GetCompletionsResponse;
    }
  }

  const tablePropsWithStatus = {
    data,
    page,
    size,
    sort,
    search: isValidMinSearchLength ? search : '',
    onPageChange,
    isFetching,
    isLoading,
  };

  useDocumentTitle([t('page_titles.diagnostic_results')]);

  const renderList = () => {
    if (
      !(
        checkPermission(own_results, [PermissionsAction.READ]) ||
        checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ])
      )
    ) {
      return (
        <div className={styles.NoResults}>
          <span>{t('assessment.no_own_results_permission')}</span>
        </div>
      );
    }

    if (!result) {
      return <Loader flexCenter size="md" />;
    }

    if (isValidMinSearchLength) {
      return (
        <AssessmentResultItem
          assessments={(result as GetCompletionsSearchResponse).items}
          tableProps={tablePropsWithStatus}
          total={(result as GetCompletionsSearchResponse).total}
        />
      );
    }

    if (!(result as ICompletionsItem[]).length) {
      return <CustomNoRowsOverlay text={t('assessment.no_diagnostic_results')} />;
    }

    return (result as ICompletionsItem[]).map((item) => (
      <AssessmentResultItem
        key={item.id}
        unitId={item.id}
        unitTitle={item.title}
        assessments={item.assessments}
        tableProps={tablePropsWithStatus}
      />
    ));
  };

  return <>{renderList()}</>;
};

export default AssessmentResultsOutlet;
