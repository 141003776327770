import { ReactNode } from 'react';
import SwipeableViews from 'react-swipeable-views';

import styles from './Swiper.module.scss';

type Props = { children: ReactNode[]; index: number; onChangeIndex: (index: number) => void };

const Swiper = ({ children, index, onChangeIndex }: Props) => {
  return (
    <div className={styles.Container}>
      <SwipeableViews index={index} onChangeIndex={onChangeIndex}>
        {children}
      </SwipeableViews>

      <div className={styles.Dots}>
        {children.map((_, itemIndex) => {
          return (
            <button
              key={itemIndex}
              className={itemIndex === index ? styles.Active : undefined}
              onClick={() => onChangeIndex(itemIndex)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Swiper;
