import { Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import GroupedAvatars from 'components/GroupedAvatars/GroupedAvatars';
import Label from 'components/Label/Label';
import MobileBack from 'components/MobileBack/MobileBack';
import MobileBottomConfirmation from 'components/MobileBottomConfirmation/MobileBottomConfirmation';
import ResendResultsModal from 'components/Modals/ResendResultsModal/ResendResultsModal';

import { useAppSelector } from 'hooks/redux';

import { useGetLastCompletionQuery, useSendVisualizationPdfMutation } from 'store/api/assessmentApi/assessmentApi';
import { GetDashboardGroupsResponse } from 'store/api/assessmentApi/types';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ComparisonType } from 'types/assessmentTypes';
import {
  DashboardSearchParams,
  DashboardType,
  IGroupAvatar,
  SelectorItem,
  SelectorVariant,
  Tab,
} from 'types/dashboardTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';
import { PdfUserPayload } from 'types/userTypes';

import { ReactComponent as ArrowIcon } from 'assets/images/caret.svg';
import { ReactComponent as ResendIcon } from 'assets/images/resend.svg';
import { ReactComponent as UserIcon } from 'assets/images/user.svg';

import SelectionCard from '../SelectionCard/SelectionCard';

import DashboardRegularStyles from '../../../../DashboardRegular.module.scss';
import styles from './GroupSelector.module.scss';

type Props = {
  dashboardType: DashboardType;
  isSelectAnotherUser: boolean;
  toggleIsSelectAnotherUser: Dispatch<SetStateAction<void>>;
  setActiveMobileTab?: Dispatch<SetStateAction<Tab>>;
  groups?: GetDashboardGroupsResponse;
  isLoadingGroups: boolean;
};

const GroupSelector = ({
  dashboardType,
  isSelectAnotherUser,
  toggleIsSelectAnotherUser,
  setActiveMobileTab,
  groups,
  isLoadingGroups,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const { comparisonType } = useAppSelector((state) => state.permissions);
  const { other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileGroupSelected, setMobileGroupSelected] = useState<SelectorItem | null>(null);
  const [mobileUserSelected, setMobileUserSelected] = useState<SelectorItem | null>(null);
  const [resendResultsPayload, setResendResultsPayload] = useState<PdfUserPayload | null>(null);

  const groupId = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const userId = searchParams.get(DashboardSearchParams.USER_1) || '';

  const users = groups?.find((group) => group.id === groupId)?.users;

  const { currentData: lastCompletion } = useGetLastCompletionQuery({ userId }, { skip: !userId || !users });

  const [sendVisualizationPdf, { isLoading: isLoadingSendVisualizationPdf }] = useSendVisualizationPdfMutation();

  const selectedGroup = groups?.find((group) => group.id === groupId);
  const selectedUser = users?.find((user) => user.id === userId);

  const handleSelectAnotherUser = useCallback(
    (id: string) => {
      searchParams.set(DashboardSearchParams.GROUP_1, id);
      setSearchParams(searchParams);
      toggleIsSelectAnotherUser();
    },
    [searchParams, setSearchParams, toggleIsSelectAnotherUser],
  );

  const handleResend = useCallback(
    (payload: PdfUserPayload) => {
      sendVisualizationPdf(payload)
        .unwrap()
        .then(() => toast.success(t('dashboard.email_sent_successfully')))
        .catch(() => {
          toast.error(t('dashboard.email_is_not_sent'));
        })
        .finally(() => setResendResultsPayload(null));
    },
    [sendVisualizationPdf, t],
  );

  const groupItems =
    groups?.map((item) => {
      return {
        value: item.id,
        title: item.name,
        avatars: item.users.map((item) => ({
          name: `${item.first_name} ${item.last_name}`,
          src: item.photo,
        })) as IGroupAvatar[],
        actions: [
          {
            title: t('dashboard.resend'),
            action: (e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setResendResultsPayload({
                users: item.users.map((item) => ({
                  id: item.id,
                  email: item.email,
                  first_name: item.first_name,
                  last_name: item.last_name,
                })),
              });
            },
            icon: <ResendIcon />,
            fill: ButtonFill.Outlined,
          },
          {
            title: t(
              checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ])
                ? 'dashboard.select_user'
                : 'dashboard.view_users',
            ),
            action: (e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              handleSelectAnotherUser(item.id);
            },
            icon: <UserIcon />,
            fill: ButtonFill.Contained,
          },
        ].map((action) => (
          <ButtonStyled
            key={action.title}
            fill={action.fill}
            size={ButtonSize.Small}
            onClick={action.action}
            icon={action.icon}
            wrapText
          >
            {action.title}
          </ButtonStyled>
        )),
      };
    }) || [];

  const userItems =
    users?.map((item) => {
      return {
        value: item.id,
        title: `${item.first_name} ${item.last_name}`,
        img: item.photo,
        actions: [
          {
            title: t('dashboard.resend'),
            action: () =>
              setResendResultsPayload({
                users: [
                  {
                    id: item.id,
                    email: item.email,
                    first_name: item.first_name,
                    last_name: item.last_name,
                  },
                ],
              }),
            icon: <ResendIcon />,
          },
        ].map((action) => (
          <ButtonStyled
            key={action.title}
            fill={ButtonFill.Outlined}
            size={ButtonSize.Small}
            onClick={action.action}
            icon={action.icon}
            wrapText
          >
            {action.title}
          </ButtonStyled>
        )),
      };
    }) || [];

  const onSelect = useCallback(
    (searchParam: DashboardSearchParams, value: string) => {
      searchParams.set(searchParam, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleResendResultsModalClose = () => {
    setResendResultsPayload(null);
  };

  const handleResendResultsModalAction = () => {
    if (resendResultsPayload) {
      handleResend(resendResultsPayload);
    }
  };

  useEffect(() => {
    if (!isLoadingGroups && !isMobile) {
      if (!selectedGroup || !groupId) {
        if (groups?.length) {
          onSelect(DashboardSearchParams.GROUP_1, groups[0].id);
          return;
        }
        searchParams.delete(DashboardSearchParams.GROUP_1);
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (lastCompletion) {
      searchParams.set(DashboardSearchParams.COMPLETION_1, lastCompletion.id);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(DashboardSearchParams.COMPLETION_1);
      setSearchParams(searchParams);
    }
  }, [lastCompletion, searchParams, setSearchParams]);

  useEffect(() => {
    if (isSelectAnotherUser) {
      return;
    }

    searchParams.delete(DashboardSearchParams.USER_1);
    searchParams.delete(DashboardSearchParams.COMPLETION_1);
    setSearchParams(searchParams);
  }, [dashboardType, isSelectAnotherUser, onSelect, searchParams, setSearchParams, userId, users]);

  useEffect(() => {
    if (comparisonType !== ComparisonType.IndividualUser) {
      searchParams.delete(DashboardSearchParams.USER_1);
      searchParams.delete(DashboardSearchParams.COMPLETION_1);
      searchParams.delete(DashboardSearchParams.USER_2);
      searchParams.delete(DashboardSearchParams.COMPLETION_2);
      setSearchParams(searchParams);
    }
  }, [comparisonType, searchParams, setSearchParams]);

  if (isMobile) {
    const isShowGroupSelector = !groupId && !isSelectAnotherUser;
    const isShowUserSelector = !userId && isSelectAnotherUser;

    const handleBack = () => {
      const clearAll = () => {
        searchParams.delete(DashboardSearchParams.USER_1);
        searchParams.delete(DashboardSearchParams.GROUP_1);
        setSearchParams(searchParams);
        setMobileUserSelected(null);
        setMobileGroupSelected(null);
      };

      if (selectedUser) {
        searchParams.delete(DashboardSearchParams.USER_1);
        setSearchParams(searchParams);
        setMobileUserSelected(null);
        return;
      }

      if (isSelectAnotherUser) {
        clearAll();
        toggleIsSelectAnotherUser();
        return;
      }

      clearAll();
    };

    return (
      <div className={classNames(styles.MobileContainer, !isShowGroupSelector && !isShowUserSelector && styles.Hide)}>
        <ResendResultsModal
          open={!!resendResultsPayload}
          close={handleResendResultsModalClose}
          action={handleResendResultsModalAction}
          status={isLoadingSendVisualizationPdf}
        />
        {selectedGroup && (
          <>
            <Breadcrumb
              items={[
                { name: t('general.dashboard'), href: '/' },
                {
                  name: selectedGroup.name,
                  onClick: selectedUser
                    ? () => {
                        searchParams.delete(DashboardSearchParams.USER_1);
                        searchParams.delete(DashboardSearchParams.COMPLETION_1);
                        setSearchParams(searchParams);
                      }
                    : undefined,
                  active: !!selectedGroup && !userId,
                },
                ...(selectedUser
                  ? [
                      {
                        name: `${selectedUser.first_name} ${selectedUser.last_name}`,
                        active: !!selectedUser,
                      },
                    ]
                  : []),
              ]}
            />
            <div className={styles.MobileBackContainer}>
              <MobileBack
                title={selectedUser ? t('dashboard.user_results') : selectedGroup.name}
                column={!!selectedUser}
                handleBack={handleBack}
              >
                {selectedUser
                  ? mobileUserSelected && <Label label={mobileUserSelected.title} image={mobileUserSelected?.img} />
                  : selectedGroup && (
                      <GroupedAvatars
                        avatars={
                          selectedGroup.users.map((item) => ({
                            name: `${item.first_name} ${item.last_name}`,
                            src: item.photo,
                          })) as IGroupAvatar[]
                        }
                        displayCount={3}
                      />
                    )}
              </MobileBack>
            </div>
          </>
        )}

        {isShowGroupSelector && (
          <SelectionCard
            title={t('dashboard.group_selection')}
            items={groupItems}
            isLoading={isLoadingGroups}
            selectedValue={groupId}
            onSelect={(value: string) => onSelect(DashboardSearchParams.GROUP_1, value)}
            variant={SelectorVariant.Group}
            mobileItemSelected={mobileGroupSelected}
            setMobileItemSelected={setMobileGroupSelected}
          />
        )}

        {isShowUserSelector && (
          <SelectionCard
            title={t('dashboard.user_selection')}
            items={userItems}
            isLoading={isLoadingGroups}
            selectedValue={isMobile && mobileUserSelected ? mobileUserSelected.value : userId}
            onSelect={(value: string) => setMobileUserSelected(userItems.find((item) => item.value === value) || null)}
            variant={SelectorVariant.User}
          />
        )}

        {(isShowUserSelector || isShowGroupSelector) && (
          <MobileBottomConfirmation active={(!!mobileGroupSelected && !isSelectAnotherUser) || !!mobileUserSelected}>
            <div className={styles.MobileButtonContainer}>
              <ButtonStyled
                size={ButtonSize.Small}
                fill={ButtonFill.Contained}
                onClick={() => {
                  setActiveMobileTab?.(Tab.SCurve);
                  if (isSelectAnotherUser) {
                    onSelect(DashboardSearchParams.USER_1, mobileUserSelected!.value);
                    return;
                  }
                  onSelect(DashboardSearchParams.GROUP_1, mobileGroupSelected!.value);
                }}
              >
                {!!mobileGroupSelected && !isSelectAnotherUser
                  ? t('dashboard.show_group_results')
                  : t('dashboard.show_user_results')}
              </ButtonStyled>
            </div>
          </MobileBottomConfirmation>
        )}
      </div>
    );
  }

  return (
    <div>
      <ResendResultsModal
        open={!!resendResultsPayload}
        close={handleResendResultsModalClose}
        action={handleResendResultsModalAction}
        status={isLoadingSendVisualizationPdf}
      />
      {isSelectAnotherUser && selectedGroup && (
        <button
          className={styles.SelectionBreadCrumb}
          onClick={() => {
            searchParams.delete(DashboardSearchParams.USER_1);
            setSearchParams(searchParams);
            toggleIsSelectAnotherUser();
          }}
        >
          <ArrowIcon />
          <Label label={selectedGroup.name} />
        </button>
      )}
      <div className={DashboardRegularStyles.SelectionContainer}>
        {!isSelectAnotherUser && (
          <SelectionCard
            title={t('dashboard.group_selection')}
            items={groupItems}
            isLoading={isLoadingGroups}
            selectedValue={groupId}
            onSelect={(value: string) => onSelect(DashboardSearchParams.GROUP_1, value)}
            variant={SelectorVariant.Group}
          />
        )}

        {isSelectAnotherUser && (
          <SelectionCard
            title={t('dashboard.user_selection')}
            items={userItems}
            isLoading={isLoadingGroups}
            selectedValue={userId}
            onSelect={(value: string) => onSelect(DashboardSearchParams.USER_1, value)}
            variant={SelectorVariant.User}
          />
        )}
      </div>
    </div>
  );
};

export default GroupSelector;
