import { useInView } from 'react-intersection-observer';

import classNames from 'classnames';

import { roundPercentage } from 'helpers/dashboard';

import { Size } from 'types/enums';

import styles from './DoughnutChart.module.scss';

const RING_PATH =
  'M18 2C22.2435 2 26.3131 3.68571 29.3137 6.68629C32.3143 9.68687 34 13.7565 34 18C34 22.2435 32.3143 26.3131 29.3137 29.3137C26.3131 32.3143 22.2435 34 18 34C13.7565 34 9.68687 32.3143 6.68629 29.3137C3.68571 26.3131 2 22.2435 2 18C2 13.7565 3.68571 9.68687 6.68629 6.68629C9.68687 3.68571 13.7565 2 18 2Z';

type Props = {
  value: number;
  color: string;
  size: Size;
};

const DoughnutChart = ({ value, color, size }: Props) => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  const CHART_CIRCUMFERENCE = 101;
  const strokeDasharrayValue = Math.floor(value * CHART_CIRCUMFERENCE);
  const roundedValue = roundPercentage(value * 100);

  return (
    <div ref={ref} className={classNames(styles.Container, styles[size])}>
      <svg width="100%" height="100%" viewBox="0 0 36 36">
        <path d={RING_PATH} className={styles.Ring} />

        <path
          d={RING_PATH}
          className={classNames(styles.Segment, { [styles.Animate]: inView })}
          style={{ stroke: color }}
          strokeDasharray={`${strokeDasharrayValue} ${CHART_CIRCUMFERENCE}`}
          strokeDashoffset={strokeDasharrayValue}
        />

        <text x="18" y="20" className={styles.Value}>
          {roundedValue}%
        </text>
      </svg>
    </div>
  );
};

export default DoughnutChart;
