import classNames from 'classnames';

import { Tag } from 'components/Tag/Tag';

import { useAppSelector } from 'hooks/redux';

import { IVisualisationFormMarkup } from 'types/createAssessmentTypes';

import styles from './VisualisationItem.module.scss';

type Props = {
  type: 'large' | 'medium';
  visualisation: IVisualisationFormMarkup;
};

export const VisualisationItem = ({ type, visualisation }: Props) => {
  const mappings = useAppSelector((state) => state.createAssessment.mappings);
  const allSections = useAppSelector((state) => state.createAssessment.allSections);

  const size = {
    large: [styles.Large],
    medium: [styles.Medium],
  }[type];

  return (
    <div className={classNames(styles.Item, size)}>
      <div className={styles.Name}>{visualisation.label}</div>
      <div className={styles.Sections}>
        {mappings[visualisation.id]?.map((section) => (
          <Tag key={section}>{allSections.find((sectionOption) => section === sectionOption.value)?.label}</Tag>
        ))}
      </div>
    </div>
  );
};
