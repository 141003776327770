import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';

import DeleteButton from 'components/Buttons/DeleteButton/DeleteButton';

import { useElementTranslate } from '../../../hooks';

import { BaseOptionElement, ChoiceType, ColumnType } from 'types/createAssessmentTypes';
import { Size } from 'types/enums';
import { Translations } from 'types/translationTypes';

import BoardInput from '../BoardInput/BoardInput';

import styles from '../BoardElements.module.scss';

interface ChoiceOptionProps {
  option: BaseOptionElement;
  columnType: ColumnType;
  isDeleteShown: boolean;
  choiceType: ChoiceType;
  onChange: (field: keyof Translations | 'score', value: string) => void;
  onDelete: () => void;
}

const ChoiceOption = ({ option, isDeleteShown, choiceType, onChange, onDelete }: ChoiceOptionProps) => {
  const { t } = useTranslation();
  const { nameValues } = useElementTranslate();

  return (
    <div className={styles.ChoiceColumnItem}>
      <div className={styles.ChoiceWrapper}>
        {choiceType === ChoiceType.Single ? <div className={styles.Rounded} /> : <div className={styles.Checkbox} />}

        <TextareaAutosize
          maxLength={280}
          value={option[nameValues]}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(nameValues, e.target.value)}
          className={classNames(styles.SmallGreyText, !option[nameValues] && styles.BorderError)}
        />
      </div>

      <BoardInput
        value={option.score}
        onChange={(e) => onChange('score', e.target.value)}
        placeholder={t('assessment.score')}
      />

      {isDeleteShown && <DeleteButton clear={onDelete} size={Size.Sm} />}
    </div>
  );
};

export default ChoiceOption;
