import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface GroupHierarchySlice {
  search: string;
  sort: 'name' | '-name';
}

const initialState: GroupHierarchySlice = {
  search: '',
  sort: 'name',
};

const groupHierarchySlice = createSlice({
  name: 'groupHierarchy',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },

    setSort(state, action: PayloadAction<'name' | '-name'>) {
      state.sort = action.payload;
    },
  },
});

export const { setSort, setSearch } = groupHierarchySlice.actions;

export default groupHierarchySlice.reducer;
