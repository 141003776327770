import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import ROUTES from 'router/routes';

import { ORANIZATION_ID_STORAGE_KEY } from '../../constants';

import { GroupsHierarchyNavigationResponse } from 'types/groupHierarchyTypes';
import { IGroupNavigation, INavigationState } from 'types/navigationTypes';

const initialState: INavigationState = {
  manageGroupId: '',
  groupNavigation: [],
  manageGroupNavigation: [],
  hierarchy: [],
  isLoading: false,
  dashboard: ROUTES.DASHBOARD_REGULAR,
  selectedGroupId: '',
  selectedOrganizationId: '',
  isAdminGroup: false,
  isMobileMenuOpen: false,
};

const navigationSlice = createSlice({
  name: 'Navigation',
  initialState,
  reducers: {
    setHierarchy(state, action: PayloadAction<GroupsHierarchyNavigationResponse>) {
      state.hierarchy = action.payload.items;
      state.isLoading = false;
    },

    setHierarchyLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    setSecondGroupId(state, action: PayloadAction<string>) {
      state.manageGroupId = action.payload;
    },

    setGroupNavigation(state, action: PayloadAction<IGroupNavigation[]>) {
      state.groupNavigation = action.payload;
    },

    setManageGroupNavigation(state, action: PayloadAction<IGroupNavigation[]>) {
      state.manageGroupNavigation = action.payload;
    },

    setDashboard(state, action: PayloadAction<string>) {
      state.dashboard = action.payload;
    },

    removeSecondGroup(state) {
      state.manageGroupId = '';
      state.manageGroupNavigation = [];
    },

    selectOrganization: (state, action) => {
      localStorage.setItem(ORANIZATION_ID_STORAGE_KEY, action.payload);
      state.selectedOrganizationId = action.payload;
    },

    selectGroup: (state, action) => {
      state.selectedGroupId = action.payload;
    },

    resetGroups: (state) => {
      state.selectedGroupId = '';
      state.selectedOrganizationId = '';
    },

    setIsAdminGroup: (state, action) => {
      state.isAdminGroup = action.payload;
    },

    toggleIsMobileMenuOpen: (state) => {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    },
  },
});

export const {
  setHierarchy,
  setGroupNavigation,
  setSecondGroupId,
  setManageGroupNavigation,
  setDashboard,
  setHierarchyLoading,
  removeSecondGroup,
  selectOrganization,
  selectGroup,
  resetGroups,
  setIsAdminGroup,
  toggleIsMobileMenuOpen,
} = navigationSlice.actions;

export default navigationSlice.reducer;
