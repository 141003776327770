import { LoaderProps, Loader as RsuiteLoader } from 'rsuite';

import styles from './Loader.module.scss';

type Props = {
  flexCenter?: boolean;
};

const Loader = ({ flexCenter, ...props }: LoaderProps & Props) =>
  flexCenter ? (
    <div className={styles.Loader}>
      <RsuiteLoader {...props} />
    </div>
  ) : (
    <RsuiteLoader {...props} />
  );

export default Loader;
