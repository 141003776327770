import { FormState } from 'react-hook-form';

import { ReactComponent as ValidFail } from 'assets/images/validation_fail.svg';
import { ReactComponent as ValidPass } from 'assets/images/validation_pass.svg';

type Props = {
  type: string;
  formState: FormState<{ password: string }>;
};

const PasswordValidationCheckbox = ({ type, formState }: Props) => {
  const errorTypes = formState.errors.password?.types?.matches?.toString();

  if (errorTypes?.includes(type) || !formState.dirtyFields?.password) return <ValidFail />;

  return <ValidPass />;
};

export default PasswordValidationCheckbox;
