import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DeleteButton from 'components/Buttons/DeleteButton/DeleteButton';

import { useElementTranslate } from '../../../hooks';

import { ThankBoardElement } from 'types/boardElements';
import { TitleFieldsChange } from 'types/createAssessmentTypes';
import { ButtonFill, ButtonSize, Size } from 'types/enums';

import thankyou from 'assets/images/thankyou.png';

import styles from '../BoardElements.module.scss';

type Props = {
  buttonHandler?: () => void;
  onElementDelete: () => void;
  element: ThankBoardElement;
  onTitleFieldsChange: TitleFieldsChange;
};

const ThankYouPage = ({ onElementDelete, element, onTitleFieldsChange, buttonHandler }: Props) => {
  const { t } = useTranslation();
  const { descriptionValues, nameValues } = useElementTranslate();

  return (
    <div className={classNames(styles.WelcomePage, styles.CardContainer)}>
      <div className={styles.WelcomeDelete}>
        <DeleteButton clear={onElementDelete} size={Size.Lg} />
      </div>

      <div className={styles.AssessmentImage}>
        <img draggable={false} src={thankyou} alt="" />
      </div>

      <div className={styles.WelcomeText}>
        <input
          maxLength={32}
          style={{ border: !element[nameValues] ? '1px solid red' : '' }}
          value={element[nameValues]}
          onChange={(e) => onTitleFieldsChange(element.id, nameValues, e.target.value)}
          className={styles.Name}
          type="text"
        />

        <textarea
          maxLength={140}
          value={element[descriptionValues]}
          onChange={(e) => onTitleFieldsChange(element.id, descriptionValues, e.target.value)}
          className={styles.Description}
        />
      </div>

      <div className={styles.CardBottomLine}>
        <div />
        <div style={{ width: '107px' }}>
          <ButtonStyled onClick={buttonHandler} fill={ButtonFill.Contained} fullWidth size={ButtonSize.Small}>
            {t('general.submit')}
          </ButtonStyled>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
