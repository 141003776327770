import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useCloseOnResize = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [open]);

  return [open, setOpen] as [boolean, Dispatch<SetStateAction<boolean>>];
};
