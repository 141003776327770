import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Eula from 'components/Eula/Eula';
import PasswordInput from 'components/Inputs/Password/Password';
import TextInput from 'components/Inputs/Text/Text';
import ShadowBlock from 'components/PageLayout/components/ShadowBlock/ShadowBlock';
import Title from 'components/Title/Title';

import PasswordValidationCheckbox from '../components/PasswordValidationCheckbox/PasswordValidationCheckbox';

import { useAppDispatch } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import {
  useActivateUserMutation,
  useReceiveActivationEmailMutation,
  useSignInMutation,
} from 'store/api/authApi/authApi';
import { useGetMyProfileQuery } from 'store/api/profileApi/profileApi';
import { setAuthError, setShouldBeRedirected } from 'store/features/authSlice';

import ROUTES from 'router/routes';

import { SignUpResolver } from 'helpers/validations';

import { ButtonFill } from 'types/enums';

import styles from './SignUp.module.scss';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [sessionWarningMessage, setSessionWarningMessage] = useState<string>('');

  let [searchParams] = useSearchParams();
  const uid = searchParams.get('uid') || '';
  const token = searchParams.get('token') || '';
  const { data: user } = useGetMyProfileQuery();

  const [activateUser, { isLoading: activating }] = useActivateUserMutation();
  const [signIn, { isLoading: logging }] = useSignInMutation();
  const [receiveActivationEmail] = useReceiveActivationEmailMutation();

  const { register, getFieldState, setValue, handleSubmit, formState } = useForm<FormValues>({
    resolver: yupResolver(SignUpResolver),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) =>
    activateUser({ uid, token, password: data.password })
      .unwrap()
      .then(() => {
        signIn({ email: data.email, password: data.password })
          .unwrap()
          .then(() => {
            dispatch(setShouldBeRedirected(false));
            navigate(ROUTES.ONBOARDING_PROFILE);
          });
      })
      .catch((error) => {
        dispatch(setAuthError());
        setSessionWarningMessage(error.data?.detail?.error || 'general.error');
      });

  useEffect(() => {
    if (user !== undefined && token) {
      setSessionWarningMessage('sign_up.error_logged_in');
    } else {
      receiveActivationEmail({ token, uid })
        .unwrap()
        .then((data) => {
          data?.email && setValue('email', data?.email);
        })
        .catch((error: { data?: { detail: string | { error: string } }; status: string | number }) => {
          if (error.data?.detail === 'User already activated.') {
            navigate(ROUTES.LOGIN);
            return;
          }
          setSessionWarningMessage(
            {
              400: 'sign_up.error_user_not_exist',
              404: (error.data?.detail as { error: string }).error,
              FETCH_ERROR: 'sign_up.error_token',
            }[error.status] || '',
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  useDocumentTitle([t('page_titles.sign_up')]);

  return (
    <div className={styles.Container}>
      {sessionWarningMessage ? (
        <div className={styles.SessionWarningContainer}>
          <Title title={t('general.warning')} description={t(sessionWarningMessage)} />
        </div>
      ) : (
        <ShadowBlock>
          <div className={styles.SignUp}>
            <Title title={t('general.sign_up')} description={t('general.sign_up_description')} />

            <form className={styles.SignUpForm} onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                disabled
                label={t('inputs.email')}
                placeholder={t('inputs.email_placeholder')}
                register={register('email')}
                error={getFieldState('email', formState)}
                error_message={t(formState?.errors.email?.message || '')}
              />

              <PasswordInput
                label={t('inputs.password')}
                placeholder={t('inputs.password_placeholder')}
                register={register('password')}
                error={getFieldState('password', formState)}
                error_message={t(formState.errors.password?.message ? t('sign_up.stronger_message') : '')}
              />

              <div className={styles.SignUpValidation}>
                <div className={styles.ValidationTitle}>{t('sign_up.validation_title')}</div>
                <div className={styles.ValidationBlock}>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.length" formState={formState} />
                    {t('sign_up.password_length')}
                  </div>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.capital" formState={formState} />
                    {t('sign_up.password_capital')}
                  </div>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.number" formState={formState} />
                    {t('sign_up.password_number')}
                  </div>
                  <div className={styles.ValidationEl}>
                    <PasswordValidationCheckbox type="validation.symbol" formState={formState} />
                    {t('sign_up.password_symbol')}
                  </div>
                </div>
              </div>

              <PasswordInput
                label={t('inputs.confirm_password')}
                placeholder={t('inputs.confirm_password')}
                register={register('confirmPassword')}
                error={getFieldState('confirmPassword', formState)}
                error_message={t(formState?.errors.confirmPassword?.message || '')}
              />

              <Eula i18nKey="sign_up.eula">
                By creating an account, you agree to Disruption Advisors
                <a
                  href="https://thedisruptionadvisors.com/wp-content/uploads/S-Curve-Insight-Tool-End-User-License-Agreement_231109.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  End Users Licensing Agreement
                </a>
                (EULA) and{' '}
                <a
                  href="https://thedisruptionadvisors.com/wp-content/uploads/DA-Privacy-Policy-231109.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </Eula>

              <ButtonStyled
                fullWidth
                clickType="submit"
                fill={ButtonFill.Contained}
                loading={activating || logging}
                disabled={!formState.isDirty || !formState.isValid}
              >
                {t('general.sign_up')}
              </ButtonStyled>
            </form>
          </div>
        </ShadowBlock>
      )}
    </div>
  );
};

export default SignUp;
