import { useEffect } from 'react';

import { useAppSelector } from './redux';

export const useHideScroll = () => {
  const isMobileMenuOpen = useAppSelector((state) => state.navigation.isMobileMenuOpen);

  useEffect(() => {
    if (isMobileMenuOpen) {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
      }
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.touchAction = 'unset';
    }
  }, [isMobileMenuOpen]);
};
