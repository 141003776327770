import { useAppSelector } from 'hooks/redux';

import { useGetCompletionByIdQuery } from 'store/api/assessmentApi/assessmentApi';
import { useGetGroupByIdQuery } from 'store/api/groupsApi/groupsApi';
import { useGetUserByIdQuery } from 'store/api/usersApi/usersApi';

import { ComparisonType } from 'types/assessmentTypes';
import { DashboardType } from 'types/dashboardTypes';

type ComparisonInfoProps = {
  dashboardType: DashboardType;
  groupIdLeft: string;
  groupIdRight: string;
  userIdLeft: string;
  userIdRight: string;
  completionIdLeft: string;
  completionIdRight: string;
};

export const useComparisonInfo = ({
  dashboardType,
  groupIdLeft,
  groupIdRight,
  userIdLeft,
  userIdRight,
  completionIdLeft,
  completionIdRight,
}: ComparisonInfoProps) => {
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);

  const isOwnDashboard = comparisonType === ComparisonType.Own;

  const {
    data: groupLeft,
    isLoading: isLoadingGroupLeft,
    isFetching: isFetchingGroupLeft,
  } = useGetGroupByIdQuery(groupIdLeft, { skip: isOwnDashboard || !groupIdLeft });

  const {
    data: groupRight,
    isLoading: isLoadingGroupRight,
    isFetching: isFetchingGroupRight,
  } = useGetGroupByIdQuery(groupIdRight, { skip: isOwnDashboard || !groupIdRight });

  const {
    data: userLeft,
    isLoading: isLoadingUserLeft,
    isFetching: isFetchingUserLeft,
  } = useGetUserByIdQuery(userIdLeft || '', {
    skip: !userIdLeft,
  });

  const {
    data: userRight,
    isLoading: isLoadingUserRight,
    isFetching: isFetchingUserRight,
  } = useGetUserByIdQuery(userIdRight || '', {
    skip: !userIdRight,
  });

  const {
    data: completionLeft,
    isLoading: isLoadingCompletionLeft,
    isFetching: isFetchingCompletionLeft,
  } = useGetCompletionByIdQuery({
    id: completionIdLeft,
    groupId: dashboardType === DashboardType.GROUP ? groupIdLeft : selectedGroupId,
  });

  const {
    data: completionRight,
    isLoading: isLoadingCompletionRight,
    isFetching: isFetchingCompletionRight,
  } = useGetCompletionByIdQuery({
    id: completionIdRight,
    groupId: dashboardType === DashboardType.GROUP ? groupIdRight : selectedGroupId,
  });

  const isLoadingGroups = isLoadingGroupLeft || isFetchingGroupLeft || isLoadingGroupRight || isFetchingGroupRight;
  const isLoadingUsers = isLoadingUserLeft || isFetchingUserLeft || isLoadingUserRight || isFetchingUserRight;
  const isLoadingCompletions =
    isLoadingCompletionLeft || isFetchingCompletionLeft || isLoadingCompletionRight || isFetchingCompletionRight;

  const isSameGroup = groupIdLeft === groupIdRight;
  const isSameUser = userIdLeft === userIdRight;
  const isSameAssessment = completionLeft?.assessment_id === completionRight?.assessment_id;

  return {
    isLoading: isLoadingGroups || isLoadingUsers || isLoadingCompletions,

    groupLeft,
    groupRight,
    isSameGroup,

    userLeft,
    userRight,
    isSameUser,

    completionLeft,
    completionRight,
    isSameAssessment,
  };
};
