import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';

import { assessmentApi } from './api/assessmentApi/assessmentApi';
import { authApi } from './api/authApi/authApi';
import { groupsApi } from './api/groupsApi/groupsApi';
import { mappingApi } from './api/mappingsApi/mappingsApi';
import { permissionsApi } from './api/permissionsApi/permissionsApi';
import { profileApi } from './api/profileApi/profileApi';
import { rolesApi } from './api/rolesApi/rolesApi';
import { unitsApi } from './api/unitsApi/unitsApi';
import { usersApi } from './api/usersApi/usersApi';
import { visualisationsApi } from './api/visualisationsApi/visualisationsApi';

import { rtkQueryErrorLogger } from './helpers';

import authSlice from './features/authSlice';
import createAssessmentSlice from './features/createAssessmentSlice';
import groupHierarchySlice from './features/groupHierarchySlice';
import navigationSlice from './features/navigationSlice';
import userPermissionsSlice from './features/userPermissionsSlice';
import usersSlice from './features/usersSlice';

const sentryReduxEnhancer = createReduxEnhancer();

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [assessmentApi.reducerPath]: assessmentApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
    [visualisationsApi.reducerPath]: visualisationsApi.reducer,
    [mappingApi.reducerPath]: mappingApi.reducer,
    auth: authSlice,
    groupHierarchy: groupHierarchySlice,
    users: usersSlice,
    createAssessment: createAssessmentSlice,
    permissions: userPermissionsSlice,
    navigation: navigationSlice,
  },
  devTools: process.env.NODE_ENV === 'development',
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkQueryErrorLogger,
      authApi.middleware,
      profileApi.middleware,
      rolesApi.middleware,
      usersApi.middleware,
      groupsApi.middleware,
      permissionsApi.middleware,
      assessmentApi.middleware,
      unitsApi.middleware,
      visualisationsApi.middleware,
      mappingApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
