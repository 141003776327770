import { useTranslation } from 'react-i18next';

import { Radio, RadioGroup } from 'rsuite';
import { ValueType } from 'rsuite/esm/Radio';

import { LikertTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

import styles from '../../AssessmentCards.module.scss';

type Props = {
  element: LikertTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  onChangeAnswer: (value: ValueType) => void;
};

const Likert = ({ element, loading, onNext, onPrev, onChangeAnswer }: Props) => {
  const { i18n } = useTranslation();

  const sortedOptions = [...element.options].sort((a, b) => a.order - b.order);

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{ loading, onNext, onPrev, disabled: !element.answer }}
    >
      <RadioGroup name="radioList" className={styles.CheckboxWrapper} value={element.answer} onChange={onChangeAnswer}>
        {sortedOptions.map((el) => (
          <Radio value={el.id} className={styles.CheckboxAssessment} key={el.id}>
            {el[`text_${i18n.language}` as keyof Translations]}
          </Radio>
        ))}
      </RadioGroup>
    </ElementWrapper>
  );
};

export default Likert;
