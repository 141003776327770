import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './MobileBottomConfirmation.module.scss';

type Props = {
  active: boolean;
  children: ReactNode | ReactNode[];
};

const MobileBottomConfirmation = ({ active, children }: Props) => {
  return <div className={classNames(styles.SelectedContainer, active && styles.Active)}>{children}</div>;
};

export default MobileBottomConfirmation;
