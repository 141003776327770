import { IGroupHierarchyItem, SelectGroupHierarchy } from 'types/groupHierarchyTypes';

export const mapToSelectGroupHierarchy = (data: IGroupHierarchyItem): SelectGroupHierarchy => {
  const obj: SelectGroupHierarchy = {
    value: data.id,
    label: data.name,
    children: undefined,
  };

  if (data?.children && data?.children.length > 0) {
    obj.children = data?.children.map((item) => mapToSelectGroupHierarchy(item));
  }

  return obj;
};
