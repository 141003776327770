import { ForwardedRef, ReactElement, forwardRef } from 'react';

import classNames from 'classnames/bind';
import { Popover, Whisper } from 'rsuite';
import { OverlayTriggerHandle } from 'rsuite/Picker';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { OverlayTriggerType } from 'rsuite/esm/Overlay/OverlayTrigger';

import styles from './Tooltip.module.scss';

const cx = classNames.bind(styles);

type TooltipProps = {
  content: ReactElement;
  width?: number;
  children: ReactElement;
  placement?: TypeAttributes.Placement;
  trigger?: OverlayTriggerType;
  open?: boolean;
  enterable?: boolean;
};

const Tooltip = forwardRef(
  (
    { content, width = 260, children, placement, trigger = 'click', open, enterable }: TooltipProps,
    ref: ForwardedRef<OverlayTriggerHandle>,
  ) => {
    const wrapperClasses = cx({
      Wrapper: true,
      Empty: children.type === 'span' && !children.props.children,
    });

    return (
      <Whisper
        speaker={
          <Popover arrow={false}>
            <div className={styles.Content} style={{ width }}>
              {content}
            </div>
          </Popover>
        }
        placement={placement}
        trigger={trigger}
        open={open}
        enterable={enterable}
        ref={ref}
      >
        <button className={wrapperClasses}>{children}</button>
      </Whisper>
    );
  },
);

export default Tooltip;
