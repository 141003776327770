import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { assessmentApi } from '../api/assessmentApi/assessmentApi';

import { findChildElements } from 'helpers/assessments/elements';

import { Element } from 'types/boardElements';
import {
  AssessmentSettings,
  BoardLanguage,
  IMultipleSelectOption,
  ISectionData,
  ISelectOption,
} from 'types/createAssessmentTypes';

interface CreateAssessment {
  selectedLanguage: BoardLanguage;
  assessmentSettings: AssessmentSettings | null;
  boardElements: Element[];
  draggedElements: string[];
  sectionsOptions: IMultipleSelectOption[];
  allSections: ISelectOption[];
  mappings: Record<string, string[]>;
  isResultsMappingsVerified: boolean;
  questionMappings: Record<
    string,
    {
      visualisationId: string;
      questionName?: string;
    }
  >;
  removedQuestionVisualisations: string[];
  isSaveEnabled: boolean;
}

const initialState: CreateAssessment = {
  selectedLanguage: BoardLanguage.En,
  assessmentSettings: null,
  boardElements: [],
  draggedElements: [],
  sectionsOptions: [],
  allSections: [],
  mappings: {},
  isResultsMappingsVerified: false,
  questionMappings: {},
  removedQuestionVisualisations: [],
  isSaveEnabled: false,
};

const getReorderedElements = (elements: Element[], parentId: string): Element[] => {
  let order = 1;

  return elements.map((element) => {
    if (element.parentId === parentId) {
      const updatedElement = {
        ...element,
        order,
      };

      order++;

      return updatedElement;
    }

    return element;
  });
};

const getReleveledElements = (elements: Element[], parentLevel: number): Element[] => {
  const minChildLevel = Math.min(...elements.map((element) => element.level));
  const levelDelta = minChildLevel - (parentLevel + 1);

  return elements.map((childElement) => ({
    ...childElement,
    level: childElement.level - levelDelta,
  }));
};

const getElementsAfterAdding = (elementToAdd: Element, elements: Element[]): Element[] => {
  const index = elements.findIndex(
    (element) => element.parentId === elementToAdd.parentId && element.order === elementToAdd.order,
  );

  if (index < 0) {
    return [...elements, elementToAdd];
  }

  const updatedElements: Element[] = [...elements.slice(0, index), elementToAdd, ...elements.slice(index)];

  return getReorderedElements(updatedElements, elementToAdd.parentId);
};

const getElementsAfterRemoving = (elementToRemove: Element, elements: Element[]): Element[] => {
  const updatedElements = elements.filter((element) => element.id !== elementToRemove.id);

  return getReorderedElements(updatedElements, elementToRemove.parentId);
};

const createAssessmentSlice = createSlice({
  name: 'createAssessment',
  initialState,
  reducers: {
    changeBoardLanguage(state, action: PayloadAction<BoardLanguage>) {
      state.selectedLanguage = action.payload;
    },

    setAssessmentSettings(state, action: PayloadAction<AssessmentSettings | null>) {
      state.assessmentSettings = action.payload;
    },

    addBoardElement(state, action: PayloadAction<Element>) {
      const elementToAdd = action.payload;

      state.boardElements = getElementsAfterAdding(elementToAdd, state.boardElements);
    },

    removeBoardElement(state, action: PayloadAction<{ elementId: string; isRecursive?: boolean }>) {
      const { elementId, isRecursive = false } = action.payload;
      const elementToRemove = state.boardElements.find((element) => element.id === elementId);

      if (!elementToRemove) return;

      state.boardElements = getElementsAfterRemoving(elementToRemove, state.boardElements);

      if (isRecursive) {
        const childElementsToRemove = findChildElements(state.boardElements, elementToRemove.id).map(({ id }) => id);

        state.boardElements = state.boardElements.filter((element) => !childElementsToRemove.includes(element.id));
      }
    },

    moveBoardElement(state, action: PayloadAction<Element>) {
      const elementToMove = action.payload;
      const children = findChildElements(state.boardElements, elementToMove.id);

      createAssessmentSlice.caseReducers.removeBoardElement(state, {
        type: 'removeBoardElement',
        payload: { elementId: elementToMove.id },
      });

      createAssessmentSlice.caseReducers.addBoardElement(state, {
        type: 'addBoardElement',
        payload: elementToMove,
      });

      getReleveledElements(children, elementToMove.level).forEach((childElement) => {
        createAssessmentSlice.caseReducers.updateBoardElement(state, {
          type: 'updateBoardElement',
          payload: {
            elementId: childElement.id,
            updatedElement: childElement,
          },
        });
      });
    },

    updateBoardElement(state, action: PayloadAction<{ elementId: string; updatedElement: Element }>) {
      const { elementId, updatedElement } = action.payload;

      state.boardElements = state.boardElements.map((element) => {
        if (element.id === elementId) {
          return updatedElement;
        }

        return element;
      });
    },

    setBoardElements(state, action: PayloadAction<Element[]>) {
      state.boardElements = action.payload;
    },

    setDraggedElements(state, action: PayloadAction<string[]>) {
      state.draggedElements = action.payload;
    },

    setSectionsData(state, action: PayloadAction<ISectionData>) {
      const { allSections, sectionsOptions } = action.payload;

      state.allSections = allSections;
      state.sectionsOptions = sectionsOptions;
    },

    addMappings(state, action: PayloadAction<Record<string, string[]>>) {
      state.mappings = { ...state.mappings, ...action.payload };
    },

    clearMappings(state) {
      if (Object.keys(state.mappings).length > 0) {
        state.mappings = {};
      }
    },

    setResultsMappingVerificationStatus(state, action: PayloadAction<boolean>) {
      state.isResultsMappingsVerified = action.payload;
    },

    addQuestionMapping(
      state,
      action: PayloadAction<{
        questionId: string;
        visualisationId: string;
        questionName?: string;
      }>,
    ) {
      const { questionId, visualisationId, questionName } = action.payload;

      state.questionMappings[questionId] = { visualisationId, questionName };
    },

    deleteQuestionMapping(
      state,
      {
        payload: { questionId },
      }: PayloadAction<{
        questionId: string;
      }>,
    ) {
      delete state.questionMappings[questionId];
    },

    clearQuestionMappings(state) {
      if (Object.keys(state.questionMappings).length > 0) {
        state.questionMappings = {};
      }
    },

    enableSaveButton(state, action: PayloadAction<boolean>) {
      state.isSaveEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(assessmentApi.endpoints.createAssessment.matchFulfilled, (state) => {
      state.mappings = {};
      state.questionMappings = {};
    });

    builder.addMatcher(assessmentApi.endpoints.updateAssessment.matchFulfilled, (state) => {
      state.mappings = {};
      state.questionMappings = {};
    });
  },
});

export default createAssessmentSlice.reducer;
export const {
  setAssessmentSettings,
  changeBoardLanguage,
  addBoardElement,
  removeBoardElement,
  moveBoardElement,
  updateBoardElement,
  setBoardElements,
  setDraggedElements,
  setSectionsData,
  addMappings,
  clearMappings,
  setResultsMappingVerificationStatus,
  addQuestionMapping,
  deleteQuestionMapping,
  clearQuestionMappings,
  enableSaveButton,
} = createAssessmentSlice.actions;
