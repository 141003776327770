import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import { Checkbox, CheckboxGroup } from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';

import { MOBILE_BREAKPOINT } from 'constants/';

import { MultipleChoiceTakeElement, Option } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

import styles from '../../AssessmentCards.module.scss';

type Props = {
  element: MultipleChoiceTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  onChangeAnswer: (value: ValueType[]) => void;
};

const MultipleChoice = ({ element, loading, onNext, onPrev, onChangeAnswer }: Props) => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const sortedLeftColumn = [...element.leftColumn].sort((a, b) => a.order - b.order);
  const sortedRightColumn = [...element.rightColumn].sort((a, b) => a.order - b.order);

  const sortedRow = sortedLeftColumn.reduce((acc, item, index) => {
    acc.push(item);
    if (sortedRightColumn[index]) {
      acc.push(sortedRightColumn[index]);
    }
    return acc;
  }, [] as Option[]);

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{
        loading,
        onNext,
        onPrev,
        disabled: !element.answer || !element.answer.length,
      }}
    >
      <CheckboxGroup value={element.answer} onChange={onChangeAnswer} className={styles.CheckboxWrapper}>
        {isMobile
          ? [
              <div key={sortedRow[0].id} className={styles.ChoiceColumn}>
                {sortedRow.map((el) => (
                  <Checkbox
                    value={el.id}
                    className={classNames(styles.CheckboxAssessment, 'take-checkbox')}
                    key={el.id}
                  >
                    {el[`text_${i18n.language}` as keyof Translations]}
                  </Checkbox>
                ))}
              </div>,
            ]
          : [
              <div key={sortedLeftColumn[0].id} className={styles.ChoiceColumn}>
                {sortedLeftColumn.map((el) => (
                  <Checkbox
                    value={el.id}
                    className={classNames(styles.CheckboxAssessment, 'take-checkbox')}
                    key={el.id}
                  >
                    {el[`text_${i18n.language}` as keyof Translations]}
                  </Checkbox>
                ))}
              </div>,
              <div key={sortedRightColumn[0].id} className={styles.ChoiceColumn}>
                {sortedRightColumn.map((el) => (
                  <Checkbox
                    value={el.id}
                    className={classNames(styles.CheckboxAssessment, 'take-checkbox')}
                    key={el.id}
                  >
                    {el[`text_${i18n.language}` as keyof Translations]}
                  </Checkbox>
                ))}
              </div>,
            ]}
      </CheckboxGroup>
    </ElementWrapper>
  );
};

export default MultipleChoice;
