import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { useAppSelector } from 'hooks/redux';

import { useDragAndDrop } from '../../hooks';

import { selectThankYouPageAdded, selectWelcomePageAdded } from 'store/selectors/assessment';

import { findSiblingElements } from 'helpers/assessments/elements';

import { Element } from 'types/boardElements';
import { CARD_TYPES, CardTypes } from 'types/createAssessmentTypes';

import styles from './DropZone.module.scss';

type Props = {
  parentNode?: Element;
  dropIndex: number;
  accept: CardTypes[];
};

const DropZone = ({ parentNode, dropIndex, accept }: Props) => {
  const { t } = useTranslation();
  const { handleDrop } = useDragAndDrop();

  const boardElements = useAppSelector((store) => store.createAssessment.boardElements);
  const isWelcomePageAdded = useSelector(selectWelcomePageAdded);
  const isThankYouPageAdded = useSelector(selectThankYouPageAdded);

  const parentElements = findSiblingElements(boardElements, parentNode?.id || 'root');
  const hasElements = parentElements.length > 0;
  const isRootElement = !parentNode;
  const isFirstDropzone = dropIndex === 0;
  const isLastDropzone = dropIndex === parentElements.length;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (element) => handleDrop({ element, parentNode, dropIndex }),

    canDrop: (element: Element) => {
      switch (element.type) {
        case CARD_TYPES.welcome_page:
          return isRootElement && isFirstDropzone;

        case CARD_TYPES.thankyou_page:
          return isRootElement && isLastDropzone;

        case CARD_TYPES.section:
          if (!isRootElement && parentNode.type !== CARD_TYPES.section) {
            return false;
          }

          if (isRootElement && isWelcomePageAdded && isFirstDropzone) {
            return false;
          }

          if (isRootElement && isThankYouPageAdded && isLastDropzone) {
            return false;
          }

          return true;

        default:
          return true;
      }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      className={classNames(styles.Dropzone, {
        [styles.CanDrop]: canDrop,
        [styles.Last]: isLastDropzone,
        [styles.LastNoEmpty]: isLastDropzone && hasElements,
        [styles.Active]: isOver && canDrop,
      })}
    >
      {!hasElements && (
        <div className={styles.EmptyBox}>
          <p>{t('assessment.empty_module')}</p>
          <p>{t('assessment.drag_question')}</p>
        </div>
      )}
    </div>
  );
};

export default DropZone;
