import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import BreadcrumbsOutlet from 'components/Breadcrumb/BreadcrumbsOutlet';
import MobileDropdownControls from 'components/PageLayout/components/Header/components/MobileDropdownControls/MobileDropdownControls';

import DashboardSwitch from './components/DashboardSwitch/DashboardSwitch';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';
import HeaderNavigation from './components/HeaderNavigation/HeaderNavigation';
import Languages from './components/Languages/Languages';
import MobileDropdown from './components/MobileDropdown/MobileDropdown';
import UserState from './components/UserState/UserState';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';

import { toggleIsMobileMenuOpen } from 'store/features/navigationSlice';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT_HEADER } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import ButtonStyled from '../../../Buttons/ButtonStyled/ButtonStyled';

import styles from './Header.module.scss';

const disabledLinks = [
  ROUTES.ROLE_ADD,
  ROUTES.USER_ADD,
  ROUTES.ROLE_EDIT(''),
  ROUTES.USER_EDIT(''),
  ROUTES.GROUP_HIERARCHY,
  ROUTES.GROUP_ADD,
  ROUTES.GROUP_MANAGE(''),
  ROUTES.GROUP_EDIT(''),
  ROUTES.ONBOARDING_PROFILE,
  ROUTES.PERSONAL_PROFILE_SETTINGS,
];

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT_HEADER,
  });
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const isSignUp = useMatch(ROUTES.SIGNUP);

  const auth = useAuth();
  const navigate = useNavigate();
  const { activity_report } = useAppSelector((state) => state.permissions.permissions);

  const isDenyRender = disabledLinks.includes(pathname);

  const signUp = (
    <div className={styles.HeaderBtnGroup}>
      {isMobile ? (
        isSignUp ? (
          <ButtonStyled fill={ButtonFill.Outlined} size={ButtonSize.Small} onClick={() => navigate(ROUTES.LOGIN)}>
            {t('general.sign_in')}
          </ButtonStyled>
        ) : (
          <ButtonStyled
            fill={ButtonFill.Contained}
            size={ButtonSize.Small}
            onClick={() => navigate(ROUTES.SIGNUP)}
            disabled
          >
            {t('general.sign_up')}
          </ButtonStyled>
        )
      ) : (
        <>
          <ButtonStyled
            fill={ButtonFill.Contained}
            size={ButtonSize.Small}
            onClick={() => navigate(ROUTES.SIGNUP)}
            disabled
          >
            {t('general.sign_up')}
          </ButtonStyled>

          <ButtonStyled fill={ButtonFill.Outlined} size={ButtonSize.Small} onClick={() => navigate(ROUTES.LOGIN)}>
            {t('general.sign_in')}
          </ButtonStyled>
        </>
      )}
    </div>
  );

  return (
    <header id="main-header" className={styles.Header}>
      <div className={styles.FullWidthContainer}>
        <div className={classNames(styles.Container, styles.HeaderContainer)}>
          <div className={styles.HeaderLeft}>
            {auth.user && (
              <div className={styles.HamburgerMenuContainer}>
                <HamburgerMenu onClick={() => dispatch(toggleIsMobileMenuOpen())} />
              </div>
            )}
            <Link to="/">
              <Logo className={styles.Logo} />
            </Link>
            <div className={styles.OrganizationSelector}>{auth.user && !isDenyRender && <HeaderNavigation />}</div>
          </div>

          <div className={styles.HeaderControls}>
            {auth.user && checkPermission(activity_report, [PermissionsAction.READ]) && (
              <div className={styles.DashboardSwitchContainer}>
                <DashboardSwitch />
              </div>
            )}
            <Languages />
            {auth.user ? <UserState /> : signUp}
            {auth.user && <MobileDropdown openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />}
          </div>
        </div>
      </div>
      {auth.user && (
        <MobileDropdownControls
          openMobileMenu={openMobileMenu}
          showDashboardSwitch={checkPermission(activity_report, [PermissionsAction.READ])}
          showHeaderNavigation={!isDenyRender || openMobileMenu}
        />
      )}

      <div className={styles.Container}>
        <BreadcrumbsOutlet />
      </div>
    </header>
  );
};

export default Header;
