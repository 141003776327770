import { Dispatch, SetStateAction } from 'react';

import MobileCardsHierarchy from './components/MobileCardsHierarchy/MobileCardsHierarchy';

import { IGroupHierarchyItem, IGroupsHierarchyUser } from 'types/groupHierarchyTypes';

import styles from './MobileGroupHierarchy.module.scss';

type Props = {
  data: IGroupHierarchyItem[];
  mobileGroups: IGroupHierarchyItem[] | null;
  setMobileGroups: Dispatch<SetStateAction<IGroupHierarchyItem[] | null>>;
};

const MobileGroupHierarchy = ({ data, mobileGroups, setMobileGroups }: Props) => {
  const mobileOrganizations = data.reduce<{
    children: IGroupHierarchyItem[];
    users: IGroupsHierarchyUser[];
    path: {
      path: string;
    };
  }>(
    (acc, item, index) => {
      acc.children = [...acc.children, item];
      acc.path = { path: (index + 1).toString() };
      return acc;
    },
    { children: [], users: [], path: { path: '0' } },
  );

  return (
    <div className={styles.MobileContainer}>
      {data.length && (
        <MobileCardsHierarchy
          data={mobileGroups ? mobileGroups.at(-1)! : mobileOrganizations}
          setMobileGroups={setMobileGroups}
          mobileGroups={mobileGroups}
        />
      )}
    </div>
  );
};

export default MobileGroupHierarchy;
