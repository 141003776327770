import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { IDashboardTooltipComparison, SelectedGroups } from 'types/dashboardTypes';
import { ButtonFill } from 'types/enums';

import TooltipBody from '../TooltipBody/TooltipBody';

import styles from './ComparisonTooltip.module.scss';

type DashboardTooltipContentProps = {
  tooltipData: [IDashboardTooltipComparison, IDashboardTooltipComparison];
  selectedGroups: SelectedGroups;
  handleDetails: () => void;
};

export const ComparisonTooltip = ({ tooltipData, selectedGroups, handleDetails }: DashboardTooltipContentProps) => {
  const { t } = useTranslation();

  const [left, right] = tooltipData;
  const [leftGroup, rightGroup] = selectedGroups;

  return (
    <div className={styles.TooltipContent}>
      <div className={classNames(styles.Container, styles.Blue)}>
        {leftGroup && <span>{leftGroup.name}</span>}
        <TooltipBody tooltipData={left} />
      </div>
      <div className={classNames(styles.Container, styles.Yellow)}>
        {rightGroup && <span>{rightGroup.name}</span>}
        <TooltipBody tooltipData={right} />
      </div>
      <div className={classNames(styles.ButtonContainer)}>
        <ButtonStyled fill={ButtonFill.Outlined} onClick={handleDetails}>
          {t('general.details')}
        </ButtonStyled>
      </div>
    </div>
  );
};

export default ComparisonTooltip;
