import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Tag as TagComponent } from 'rsuite';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DeleteFromGroup from 'components/Modals/DeleteFromGroup/DeleteFromGroup';
import OrganizationSelect from 'components/Modals/OrganizationSelect/OrganizationSelect';
import ViewAllAccesses from 'components/Modals/ViewAllAccesses/ViewAllAccesses';

import { ButtonFill, ButtonSize } from 'types/enums';
import { SelectGroupHierarchy } from 'types/groupHierarchyTypes';
import { Tag, Tags } from 'types/userTypes';

import { ReactComponent as EditIcon } from 'assets/images/pencil-edit.svg';
import { ReactComponent as AddIcon } from 'assets/images/plus.svg';

import styles from './SecondaryGroupsTags.module.scss';

type Props = {
  openSelect: boolean;
  openView: boolean;
  openDelete: boolean;
  isManageGroup?: boolean;
  tag: Tags | null;
  secondaryGroups: Tag[];
  groups: SelectGroupHierarchy[];
  toggleSelect: () => void;
  toggleView: () => void;
  toggleDelete: () => void;
  changeSecondaryGroups: Dispatch<SetStateAction<Tag[]>>;
  selectTag: Dispatch<SetStateAction<Tags | null>>;
};

const SecondaryGroupsTags = ({
  openSelect,
  openView,
  openDelete,
  toggleSelect,
  toggleView,
  toggleDelete,
  secondaryGroups,
  changeSecondaryGroups,
  tag,
  selectTag,
  groups,
  isManageGroup,
}: Props) => {
  const { t } = useTranslation();

  const onSelectTag = (tag: Tag, index: number) => {
    selectTag({ tag, index });
    toggleSelect();
  };

  const onCloseViewAllAccesses = () => {
    toggleView();
    selectTag(null);
  };

  const onCloseOrganizationSelect = () => {
    toggleSelect();
    selectTag(null);
  };

  const onOpenSecondModal = () => {
    toggleSelect();
    toggleDelete();
  };

  const onDeleteGroup = () => {
    const tagArray = [...secondaryGroups];
    if (tag) {
      tagArray.splice(tag.index, 1);
      changeSecondaryGroups(tagArray);
    }

    selectTag(null);
    toggleDelete();
  };

  const tagsRender = () => {
    if (secondaryGroups.length <= 2) {
      return secondaryGroups.map((el, i) => (
        <div key={el.group.value} className={classNames('rs-tag-re', styles.Tags)}>
          <TagComponent onClick={() => onSelectTag(el, i)}>
            {el?.group?.label} | {el.role.label}
            <EditIcon />
          </TagComponent>
        </div>
      ));
    } else
      return (
        <div>
          <div className={classNames('rs-tag-re', styles.Tags)}>
            <TagComponent onClick={() => onSelectTag(secondaryGroups[0], 0)}>
              {secondaryGroups[0]?.group?.label} | {secondaryGroups[0]?.role.label}
              <EditIcon />
            </TagComponent>
          </div>

          <div className={classNames('rs-tag-re', styles.Tags)}>
            <TagComponent onClick={() => onSelectTag(secondaryGroups[1], 1)}>
              {secondaryGroups[1]?.group?.label} | {secondaryGroups[1]?.role.label}
              <EditIcon />
            </TagComponent>

            <div className={styles.More}>
              +{secondaryGroups.length - 2} {t('general.more').toLowerCase()}
            </div>
          </div>

          <div className={styles.ViewAllButtonContainer}>
            <ButtonStyled size={ButtonSize.Small} fill={ButtonFill.Contained} onClick={toggleView}>
              {t('general.view_all')}
            </ButtonStyled>
          </div>
        </div>
      );
  };

  const selectTagFromView = (tag: Tag, index: number) => {
    selectTag({ tag, index });
    toggleSelect();
    toggleView();
  };

  const onSelectSecondGroups = (group: Tag) => {
    const selected_groups = [...secondaryGroups];

    if (tag) {
      const updatedGroups = [...selected_groups.slice(0, tag.index), group, ...selected_groups.slice(tag.index + 1)];
      changeSecondaryGroups(updatedGroups);
    } else {
      selected_groups.push(group);
      changeSecondaryGroups(selected_groups);
    }

    onCloseOrganizationSelect();
  };

  return (
    <>
      <DeleteFromGroup open={openDelete} close={onOpenSecondModal} handleDelete={onDeleteGroup} />

      <OrganizationSelect
        tag={tag}
        open={openSelect}
        openDelete={onOpenSecondModal}
        close={onCloseOrganizationSelect}
        select={onSelectSecondGroups}
        groups={groups}
        isManageGroup={isManageGroup}
      />

      <ViewAllAccesses
        open={openView}
        tags={secondaryGroups}
        close={onCloseViewAllAccesses}
        onSelectTag={selectTagFromView}
      />

      <div className={styles.SecondaryOrganizations}>
        <div className={styles.Label}>{t('users.secondary_organization')}</div>
        <div>{tagsRender()}</div>
        <div className={styles.AddOrganizationButtonContainer}>
          <ButtonStyled fill={ButtonFill.Transparent} onClick={toggleSelect} icon={<AddIcon />}>
            {t('users.add_organization')}
          </ButtonStyled>
        </div>
      </div>
    </>
  );
};

export default SecondaryGroupsTags;
