import i18n from 'i18next';

import { DashboardResult, IDashboardResultPoint } from 'types/dashboardTypes';
import { IEcosystem } from 'types/ecosystemTypes';
import { Translations } from 'types/translationTypes';
import { roundPercentage } from './dashboard';

export const getEcosystemColor = (points: IDashboardResultPoint[], value: number): string => {
  const [point1, point2, point3] = points;

  if (point1.cutoff_value <= value && value < point2.cutoff_value) {
    return point1.color || '';
  }

  if (point2.cutoff_value <= value && value < point3.cutoff_value) {
    return point2.color || '';
  }

  if (point3.cutoff_value <= value) {
    return point3.color || '';
  }

  return point1.color || '';
};

export const mapEcosystem = (ecosystem: DashboardResult): IEcosystem => {
  const lang = `text_${i18n.language}` as keyof Translations;
  const { sub_graphs, sections, value, name, description, id } = ecosystem;

  return {
    id: id,
    title: name[lang],
    value: value,
    color: getEcosystemColor(sections[0].points, roundPercentage(value * 100) / 100),
    tooltip: {
      id: id,
      title: name[lang],
      content: description[lang],
    },
    subGraphs: sub_graphs?.map((item) => ({
      id: item.id,
      title: item.name[lang],
      value: item.value,
      color: getEcosystemColor(item.sections[0].points, roundPercentage(item.value * 100) / 100),
      tooltip: {
        id: item.id,
        title: item.name[lang],
        content: item.description[lang],
      },
    })),
  };
};
