import { Fragment } from 'react';

import { useAppSelector } from 'hooks/redux';

import { useCardManipulate } from '../../hooks';

import { findSiblingElements } from 'helpers/assessments/elements';

import { CARD_TYPES } from 'types/createAssessmentTypes';

import DropZone from '../DropZone/DropZone';

import styles from './AssessmentBuilder.module.scss';

const AssessmentBuilder = () => {
  const { renderCards } = useCardManipulate();

  const boardElements = useAppSelector((store) => store.createAssessment.boardElements);

  const rootElements = findSiblingElements(boardElements, 'root');

  return (
    <div className={styles.AssessmentBuilder}>
      {rootElements.map((element, index) => (
        <Fragment key={element.id}>
          <DropZone
            dropIndex={index}
            accept={[CARD_TYPES.welcome_page, CARD_TYPES.thankyou_page, CARD_TYPES.section]}
          />

          <div>{renderCards(element)}</div>
        </Fragment>
      ))}

      <DropZone
        dropIndex={rootElements.length}
        accept={[CARD_TYPES.welcome_page, CARD_TYPES.thankyou_page, CARD_TYPES.section]}
      />
    </div>
  );
};

export default AssessmentBuilder;
