import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { Permissions, PermissionsAction } from 'types/permissionsTypes';

type Props = {
  entities?: string[];
  permissions?: PermissionsAction[];
  component: JSX.Element;
};

export const PrivateRoute = ({ entities, permissions, component }: Props) => {
  const location = useLocation();
  const { isAuth } = useAppSelector((state) => state.auth);
  const userPermission = useAppSelector((state) => state.permissions.permissions);

  const isAllowed =
    entities && permissions
      ? entities.some((item) => {
          return checkPermission(userPermission[item as keyof Permissions], permissions);
        })
      : true;

  if (!isAuth) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} />;
  }

  if (!isAllowed) {
    return <Navigate to="/" />;
  }

  return component;
};
