import React from 'react';

import { Stack } from '@mui/material';

import { formatDateDetailed } from 'helpers/date';

import { ComparisonList, ComparisonListGroup, ComparisonListUser } from 'types/assessmentTypes';
import { GroupDashboard } from 'types/groupTypes';

import styles from './DashboardComparisonModal.module.scss';

export const getGroupOptions = (groups: GroupDashboard[]) => {
  return groups.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

export const getUserOptions = (users: ComparisonListUser[]) => {
  return users.map((item) => {
    const fullName = `${item.first_name} ${item.last_name}`;

    return {
      label: (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          {item.photo && <img className={styles.Avatar} src={item.photo} alt={`${fullName} Avatar`} />}
          <span>{fullName}</span>
        </Stack>
      ),
      value: item.id,
    };
  });
};

export const getAssessmentOptions = (assessments: ComparisonList[]) => {
  return assessments.map((item) => ({
    label: `${item.assessment_name} ${formatDateDetailed(item.attempt_date)}`,
    value: item.completed_assessment_id,
  }));
};

export const getAssessmentOptionsGroup = (assessments: ComparisonListGroup[]) => {
  return assessments.map((item) => ({
    label: item.assessment_name,
    value: item.completed_assessment_id,
  }));
};
