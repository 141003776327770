import { useTranslation } from 'react-i18next';

import { ModalProps } from 'types/modalTypes';

import ModalWrapper from '../ModalWrapper';

const CloneDiagnosticModal = (props: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      title={t('assessment.clone_diagnostic_modal.title')}
      subtitle={t('assessment.clone_diagnostic_modal.description')}
      actionTitle={t('general.clone')}
      cancelTitle={t('general.cancel')}
    />
  );
};

export default CloneDiagnosticModal;
