import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import classNames from 'classnames';

import Loader from 'components/Loader/Loader';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';

import ContextItem from './components/ContextItem/ContextItem';
import ContextModal from './components/ContextModal/ContextModal';

import { useLazyGetContextsQuery } from 'store/api/mappingsApi/mappingsApi';

import { IContextItem } from 'types/resultsContext';

import pageStyles from '../../Dashboard.module.scss';
import styles from './Context.module.scss';

type ContextProps = {
  completionId: string;
};

const Context = ({ completionId }: ContextProps) => {
  const { t } = useTranslation();

  const [detailsOpen, toggleDetails] = useState<boolean>(false);
  const [selectedContext, selectContext] = useState<IContextItem | null>(null);

  const getContextsAbortRef = useRef(() => {});

  const [getContexts, { data, isLoading, isFetching }] = useLazyGetContextsQuery();

  const handleOpenModal = (context: IContextItem): void => {
    toggleDetails(true);
    selectContext(context);
  };

  const handleCloseModal = (): void => {
    toggleDetails(false);
  };

  const handleClearData = (): void => {
    selectContext(null);
  };

  useEffect(() => {
    getContextsAbortRef.current();

    if (completionId) {
      const { abort } = getContexts({ completionId });
      getContextsAbortRef.current = abort;
    }
  }, [completionId, getContexts]);

  if (isLoading || isFetching) {
    return (
      <div className={pageStyles.Section}>
        <h3 className={pageStyles.SectionTitle}>{t('dashboard.context.title')}</h3>

        <Box m={4}>
          <Loader flexCenter size="md" />
        </Box>
      </div>
    );
  }

  return (
    <div className={classNames(pageStyles.Section, styles.Context)}>
      <ContextModal
        overflow
        open={detailsOpen}
        question={selectedContext}
        close={handleCloseModal}
        cleanAfterExited={handleClearData}
      />

      <h3 className={classNames(pageStyles.SectionTitle, styles.ContextTitle)}>
        {t('dashboard.context.title')}
        <div className={styles.ContextCounter}>{data?.questions_answers.length || 0}</div>
      </h3>

      <div className={pageStyles.SectionContent}>
        <div className={styles.ContextContentWrapper}>
          {data?.questions_answers.length ? (
            data.questions_answers.map((question: IContextItem) => (
              <ContextItem key={question.id} question={question} openDetails={handleOpenModal} />
            ))
          ) : (
            <NothingFoundText text={t('assessment.nothing_found')} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Context;
