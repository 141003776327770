import { ReactNode, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { CardVariants } from 'types/assessmentTypes';

import styles from './Scrollable.module.scss';

type BaseProps = {
  children: ReactNode;
  variant?: CardVariants;
  paddingX?: number;
  paddingY?: number;
  visible?: boolean;
};

type PropsHeight = BaseProps & {
  maxHeight?: never;
  height?: string | number;
};

type PropsMaxHeight = BaseProps & {
  maxHeight?: number;
  height?: never;
};

const Scrollable = ({
  children,
  variant,
  height,
  visible,
  paddingX = 0,
  paddingY = 0,
  maxHeight = 200,
}: PropsHeight | PropsMaxHeight) => {
  const divElement = useRef<HTMLDivElement>(null);
  const [isDivScrollable, setIsDivScrollable] = useState<boolean | null>(false);

  useEffect(() => {
    setIsDivScrollable(divElement.current && divElement.current.scrollHeight > divElement.current?.clientHeight);
  }, [children]);

  return (
    <div
      className={classNames(styles.Container, {
        [styles.Scrollable]: isDivScrollable,
      })}
      style={{ padding: `${paddingY}px ${paddingX}px` }}
    >
      <div
        className={classNames(styles.Wrapper, {
          [styles.Scrollable]: isDivScrollable,
          [styles.Yellow]: variant === CardVariants.Yellow,
          [styles.Visible]: visible,
        })}
        style={
          height ? { height: typeof height === 'string' ? height : `${height}px` } : { maxHeight: `${maxHeight}px` }
        }
        ref={divElement}
      >
        {children}
      </div>
    </div>
  );
};

export default Scrollable;
