import { ReactNode, useEffect, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';
import { Loader, PickerHandle, SelectPicker, TreePicker } from 'rsuite';
import { ItemDataType } from 'rsuite/esm/@types/common';

import { useCloseOnResize } from 'hooks/useCloseOnResize';

import { hasParentWithClass } from 'helpers/dom';

import InputLabel from '../InputLabel/InputLabel';

import styles from '../Inputs.module.scss';
import './Select.scss';

type Placement =
  | 'bottomStart'
  | 'bottomEnd'
  | 'topStart'
  | 'topEnd'
  | 'leftStart'
  | 'leftEnd'
  | 'rightStart'
  | 'rightEnd'
  | 'auto'
  | 'autoVerticalStart'
  | 'autoVerticalEnd'
  | 'autoHorizontalStart'
  | 'autoHorizontalEnd';

type Props = {
  data: ItemDataType<string | number>[] | undefined;
  disabledItemValues?: string[];
  placement?: Placement;
  value?: string | number;
  isLoading?: boolean;
  label?: string;
  size?: 'sm';
  cascade?: boolean;
  placeholder?: string;
  asyncData?: boolean;
  required?: boolean;
  disabled?: boolean;
  onSelect: (value: string) => void;
  error?: {
    invalid: boolean;
    isDirty?: boolean;
    isTouched?: boolean;
    error?: FieldError | undefined;
  };
  error_message?: string;
  labelClassNames?: {
    InputLabels?: string;
    InputLabel?: string;
  };
  selectClassName?: string;
  selectMenuClassName?: string;
};

const Select = ({
  data,
  disabledItemValues,
  value,
  label,
  size,
  cascade,
  placement,
  placeholder,
  disabled,
  required,
  isLoading,
  onSelect,
  error,
  error_message,
  labelClassNames,
  selectClassName,
  selectMenuClassName,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useCloseOnResize();
  const treePickerRef = useRef<PickerHandle>(null);
  const selectPickerRef = useRef<PickerHandle>(null);
  const treePickerWidth = treePickerRef?.current?.target?.offsetWidth;
  const selectPickerWidth = selectPickerRef?.current?.target?.offsetWidth;
  const MENU_MAX_HEIGHT = 212;

  const renderMenu = (menu: ReactNode) =>
    isLoading ? (
      <div className={styles.LoaderContainer}>
        <Loader size="sm" content="Loading..." />
      </div>
    ) : (
      menu
    );

  useEffect(() => {
    const handleClose = (event: KeyboardEvent) => {
      const shouldOpen = event.key === 'Enter' || event.key === ' ';

      if (event.key === 'Escape') {
        setOpen(false);
      }

      if (cascade && treePickerRef.current?.target === document.activeElement && shouldOpen) {
        setOpen((prev) => !prev);
        return;
      }

      if (selectPickerRef.current?.target === document.activeElement && shouldOpen) {
        setOpen((prev) => !prev);
      }
    };

    window.addEventListener('keyup', handleClose);

    return () => window.removeEventListener('keyup', handleClose);
  }, [cascade, setOpen]);

  return (
    <>
      {data && (
        <div className={styles.CustomInput}>
          <InputLabel
            errorMessage={error_message}
            label={label}
            required={required}
            labelClassNames={labelClassNames}
          />

          <ClickAwayListener
            onClickAway={(event) => {
              if (open) {
                if (hasParentWithClass(event.target as HTMLElement, 'rs-tree')) {
                  return;
                }
                setOpen(false);
              }
            }}
          >
            {cascade ? (
              <TreePicker
                data={data}
                value={value}
                className={classNames('custom-single-select-tree', { error: error?.invalid })}
                menuClassName="custom-single-select-menu-tree"
                placeholder={placeholder || t('general.select')}
                disabled={disabled}
                searchable={false}
                cleanable={false}
                onSelect={(data) => {
                  onSelect(String(data.value));
                  setOpen(false);
                }}
                renderMenu={renderMenu}
                menuMaxHeight={MENU_MAX_HEIGHT}
                open={open}
                ref={treePickerRef}
                onClick={() => setOpen((prev) => !prev)}
                menuStyle={{ maxWidth: treePickerWidth, minWidth: treePickerWidth }}
              />
            ) : (
              <div onClick={() => !disabled && setOpen((prev) => !prev)}>
                <SelectPicker
                  placement={placement ? placement : 'bottomStart'}
                  disabled={disabled}
                  value={value}
                  data={data}
                  disabledItemValues={disabledItemValues}
                  className={classNames(selectClassName, 'custom-single-select', {
                    sm: size === 'sm',
                    error: error?.invalid,
                  })}
                  menuClassName={classNames(selectMenuClassName, 'custom-single-select-menu')}
                  placeholder={placeholder || t('general.select')}
                  searchable={false}
                  cleanable={false}
                  renderMenu={renderMenu}
                  onSelect={onSelect}
                  menuMaxHeight={MENU_MAX_HEIGHT}
                  open={open}
                  ref={selectPickerRef}
                  menuStyle={{ maxWidth: selectPickerWidth, minWidth: selectPickerWidth }}
                />
              </div>
            )}
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export default Select;
