import styles from './Title.module.scss';

type Props = {
  title?: string;
  description?: string;
};

const Title = ({ title, description }: Props) => {
  return (
    <>
      {title && <h2 className={styles.Title}>{title}</h2>}
      {description && <p className={styles.Description}>{description}</p>}
    </>
  );
};

export default Title;
