import { IGroup, IUser, TagType } from 'types/assessmentTypes';

export const mapToTag = (data: IGroup[] | IUser[] | undefined, type: TagType) => {
  const groupTypes = [TagType.Group, TagType.Organization, TagType.SubGroup];

  if (data && groupTypes.includes(type)) {
    return (data as IGroup[]).map(({ id, name }) => ({ id, title: name, type }));
  }

  if (data && type === TagType.Name) {
    return (data as IUser[]).map(({ id, first_name, last_name }) => ({
      id,
      title: `${first_name} ${last_name}`,
      type,
    }));
  }

  return [];
};
