import { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withScrolling from 'react-dnd-scrolling';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Loader from 'components/Loader/Loader';
import CloneDiagnosticModal from 'components/Modals/CloneDiagnosticModal/CloneDiagnosticModal';
import Tabs from 'components/Tabs/Tabs';

import Aside from './components/Aside/Aside';
import AssessmentBuilder from './components/AssessmentBuilder/AssessmentBuilder';
import AssessmentSettingTab from './components/AssessmentSettingTab/AssessmentSettingTab';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useSectionsBuilder, useValidateAssessmentMappings } from './hooks';

import {
  useCreateAssessmentMutation,
  useGetAssessmentHierarchyQuery,
  useUpdateAssessmentMutation,
} from 'store/api/assessmentApi/assessmentApi';
import { useLazyGetVisualisationsQuery } from 'store/api/visualisationsApi/visualisationsApi';
import {
  addMappings,
  addQuestionMapping,
  clearMappings,
  clearQuestionMappings,
  enableSaveButton,
  setAssessmentSettings,
  setBoardElements,
  setResultsMappingVerificationStatus,
} from 'store/features/createAssessmentSlice';

import ROUTES from 'router/routes';

import { convertHierarchyIntoFlat } from 'helpers/assessments/elements';
import { getAssessmentElementErrors } from 'helpers/assessments/validation';
import { checkPermission } from 'helpers/checkUserPermission';
import { generateKey } from 'helpers/generateKey';

import { AssessmentTab, getBreadcrumbs } from './constants';

import { IResultsMapping } from 'types/assessmentTypes';
import { AssessmentSettings } from 'types/createAssessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import styles from './AssessmentCreate.module.scss';

const ScrollingComponent = withScrolling('div');

const CreateAssessment = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { buildSectionsData } = useSectionsBuilder();
  const { isQuestionsMappingsNumberValid, isQuestionsMappingsUnique } = useValidateAssessmentMappings();

  const { current: buttonKeys } = useRef([generateKey(), generateKey()]);
  const [activeTab, setActiveTab] = useState(AssessmentTab.Build);
  const [openCloneDiagnosticModal, setOpenCloneDiagnosticModal] = useState(false);
  const { cloneAssessmentId } = state || {};

  const boardElements = useAppSelector((store) => store.createAssessment.boardElements);
  const isSaveEnabled = useAppSelector((store) => store.createAssessment.isSaveEnabled);
  const mappings = useAppSelector((store) => store.createAssessment.mappings);
  const { assessment: assessmentPermission } = useAppSelector((store) => store.permissions.permissions);

  const { data: assessment, isFetching } = useGetAssessmentHierarchyQuery((id || cloneAssessmentId) ?? skipToken);
  const [createAssessment, { isLoading: creating }] = useCreateAssessmentMutation();
  const [updateAssessment, { isLoading: updating }] = useUpdateAssessmentMutation();
  const [triggerGetVisualisations] = useLazyGetVisualisationsQuery();

  const showCloneButton = checkPermission(assessmentPermission, [PermissionsAction.CREATE]) && !cloneAssessmentId && id;

  const validateAssessmentElements = () => {
    const assessmentErrors = getAssessmentElementErrors(boardElements);

    if (assessmentErrors.length) {
      for (const error of assessmentErrors) {
        toast.error(t(error));
      }
      return false;
    }

    if (!isQuestionsMappingsNumberValid()) {
      toast.error(t('assessment.validation.all_questions_mapped'));
      return false;
    }

    if (!isQuestionsMappingsUnique()) {
      toast.error(t('assessment.validation.questions_graphs_unique'));
      return false;
    }

    buildSectionsData(boardElements);
    return true;
  };

  const onSetSettingsTab = () => {
    if (activeTab === AssessmentTab.Build) {
      if (validateAssessmentElements()) {
        setActiveTab(AssessmentTab.Settings);
      }
    }
  };

  const handleCloneDiagnosticModalAction = () => {
    if (id) {
      setOpenCloneDiagnosticModal(false);
      setActiveTab(AssessmentTab.Build);
      navigate(ROUTES.DIAGNOSTIC_CREATE, { state: { cloneAssessmentId: id } });
    }
  };

  const handleCloneDiagnosticModalClose = () => {
    setOpenCloneDiagnosticModal(false);
  };

  useEffect(() => {
    dispatch(enableSaveButton(boardElements.length > 0));
  }, [boardElements.length, dispatch]);

  useEffect(() => {
    if (assessment) {
      dispatch(
        setBoardElements(
          convertHierarchyIntoFlat(assessment.children).map((element) => ({
            ...element,
            key: generateKey(),
          })),
        ),
      );
    }
  }, [assessment, dispatch]);

  useEffect(() => {
    if (assessment) {
      const settingsDto: AssessmentSettings = {
        assessment_name: cloneAssessmentId
          ? `${assessment.assessment_name} (${t('general.cloned')})`
          : assessment.assessment_name,
        assessment_description: assessment.assessment_description,
        assessment_image: assessment.assessment_image,
        is_default: assessment.id === assessment.default_assessment.id,
        show_context: assessment.show_context,
        unit_ids: assessment.units.map((el) => el.id),
        group_ids: assessment.groups.map((el) => el.id),
        user_ids: assessment.users.map((el) => el.id),
      };

      dispatch(setAssessmentSettings(settingsDto));
    }
  }, [assessment, cloneAssessmentId, dispatch, t]);

  useEffect(() => {
    if (assessment) {
      const resultMappings = assessment.mappings.reduce(
        (mappings: Record<string, string[]>, { visualisation_id, section_definition_ids }: IResultsMapping) => {
          mappings[visualisation_id] = section_definition_ids;
          return mappings;
        },
        {},
      );

      dispatch(addMappings(resultMappings));
    } else {
      dispatch(clearMappings());
    }
  }, [assessment, dispatch]);

  useEffect(() => {
    if (mappings && (id || cloneAssessmentId)) {
      const isAllResultMappingsMapped = Object.keys(mappings).every((key) => mappings[key].length);
      dispatch(setResultsMappingVerificationStatus(isAllResultMappingsMapped));
    }
  }, [mappings, id, dispatch, cloneAssessmentId]);

  useEffect(() => {
    if (assessment) {
      assessment.responsibility_mappings.forEach((mapping) => {
        dispatch(
          addQuestionMapping({
            questionId: mapping.question_id,
            visualisationId: mapping.visualisation_id,
          }),
        );
      });
    } else {
      dispatch(clearQuestionMappings());
    }
  }, [assessment, dispatch]);

  useEffect(() => {
    if (id) {
      triggerGetVisualisations();
    }
  }, [id, triggerGetVisualisations]);

  useEffect(() => {
    return () => {
      dispatch(setAssessmentSettings(null));
      dispatch(clearMappings());
      dispatch(setResultsMappingVerificationStatus(false));
      dispatch(setBoardElements([]));
    };
  }, [dispatch]);

  useDocumentTitle(id ? [t('page_titles.diagnostic_edit')] : [t('page_titles.diagnostic_create')]);

  return (
    <div style={{ width: '100%' }}>
      <CloneDiagnosticModal
        open={openCloneDiagnosticModal}
        close={handleCloneDiagnosticModalClose}
        action={handleCloneDiagnosticModalAction}
      />

      <Breadcrumb items={getBreadcrumbs(t, Boolean(id))} />

      <Tabs
        tabs={[
          { title: id ? t('assessment.edit_diagnostic') : t('assessment.create_diagnostic') },
          {
            title: t('assessment.settings'),
            shouldChangeTab: () => validateAssessmentElements(),
          },
        ]}
        activeTab={activeTab}
        changeTab={setActiveTab}
        spaceBetweenChildren
        fontLarge
      >
        <ButtonStyled
          onClick={() => navigate(ROUTES.DIAGNOSTIC_LIST)}
          size={ButtonSize.Small}
          fill={ButtonFill.Outlined}
        >
          {t('general.cancel')}
        </ButtonStyled>
        {showCloneButton && (
          <ButtonStyled
            onClick={() => setOpenCloneDiagnosticModal(true)}
            size={ButtonSize.Small}
            fill={ButtonFill.Contained}
            clickType="button"
          >
            {t('general.clone')}
          </ButtonStyled>
        )}
        {activeTab === AssessmentTab.Build ? (
          <ButtonStyled
            key={buttonKeys[0]}
            onClick={onSetSettingsTab}
            disabled={!isSaveEnabled}
            size={ButtonSize.Small}
            fill={ButtonFill.Contained}
            clickType="button"
          >
            {t('general.next')}
          </ButtonStyled>
        ) : (
          <ButtonStyled
            key={buttonKeys[1]}
            disabled={!isSaveEnabled || creating || updating}
            loading={creating || updating}
            size={ButtonSize.Small}
            fill={ButtonFill.Contained}
            form="hook-form"
            clickType="submit"
          >
            {t('general.save')}
          </ButtonStyled>
        )}
      </Tabs>

      <div
        className={classNames(styles.CreateAssessmentWrapper, { [styles.Hidden]: activeTab !== AssessmentTab.Build })}
      >
        {isFetching && id ? (
          <Loader size="lg" center />
        ) : (
          <>
            <LanguageSelector />

            <div>
              <DndProvider backend={HTML5Backend}>
                <ScrollingComponent className={styles.BuilderWrapper}>
                  <Aside />
                  <AssessmentBuilder />
                </ScrollingComponent>
              </DndProvider>
            </div>
          </>
        )}
      </div>

      <div className={classNames(styles.SettingsContainer, { [styles.Hidden]: activeTab !== AssessmentTab.Settings })}>
        <AssessmentSettingTab
          cloneAssessmentId={cloneAssessmentId}
          isRendered={activeTab === AssessmentTab.Settings}
          createAssessment={createAssessment}
          updateAssessment={updateAssessment}
        />
      </div>
    </div>
  );
};

export default CreateAssessment;
