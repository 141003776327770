import { ReactNode } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import { ReactComponent as Caret } from 'assets/images/caret.svg';

const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<Caret />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  color: 'var(--matte-black)',

  '.Mui-expanded': {
    color: 'var(--refined-teal)',

    '&.MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(360deg)',
    },
  },

  '.MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',

    'svg path': {
      fill: 'var(--matte-black)',
    },

    '&.Mui-expanded': {
      'svg path': {
        fill: 'var(--refined-teal)',
      },
    },
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

type AccordionProps = {
  accordionSummaryChildren: ReactNode;
  accordionContent: ReactNode;
};

const AccordionCustom = ({ accordionContent, accordionSummaryChildren, ...props }: AccordionProps) => (
  <Accordion disableGutters sx={accordionSx} elevation={0} {...props}>
    <AccordionSummaryStyled
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={accordionSummarySx}
    >
      {accordionSummaryChildren}
    </AccordionSummaryStyled>
    <AccordionDetails sx={accordionDetailsSx}>{accordionContent}</AccordionDetails>
  </Accordion>
);

const accordionSx = {
  background: 'none',

  '&:before': {
    display: 'none',
  },
};

const accordionSummarySx = {
  padding: 0,

  '&:before': {
    display: 'none',
  },

  '.MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    marginLeft: '8px',
    minWidth: '0',
  },
};

const accordionDetailsSx = {
  padding: 0,
};

export default AccordionCustom;
