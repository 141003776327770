import { useEffect } from 'react';

import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';

import DeleteButton from 'components/Buttons/DeleteButton/DeleteButton';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';

import { useElementTranslate } from '../../hooks';

import { addQuestionMapping } from 'store/features/createAssessmentSlice';

import { QuestionElement } from 'types/boardElements';
import { CARD_TYPES, OnElementDelete, TitleFieldsChange } from 'types/createAssessmentTypes';
import { Size } from 'types/enums';

import styles from '../BoardElements/BoardElements.module.scss';

type Props = {
  id?: string;
  onDelete: OnElementDelete;
  element: QuestionElement;
  onTitleChange: TitleFieldsChange;
  descriptionRequired?: boolean;
  titleMaxLength?: number;
  descriptionMaxLength?: number;
};

const CardTitleBlock = ({
  id,
  element,
  onTitleChange,
  onDelete,
  descriptionRequired,
  titleMaxLength = 400,
  descriptionMaxLength = 400,
}: Props) => {
  const { nameValues, descriptionValues } = useElementTranslate();
  const dispatch = useAppDispatch();
  const questionsMappings = useAppSelector((state) => state.createAssessment.questionMappings);

  const hasMappedVisualisation = id && questionsMappings[id]?.visualisationId;
  const debouncedQuestionName = useDebounce(element[nameValues], 1000);

  useEffect(() => {
    if (element.type === CARD_TYPES.short_text && hasMappedVisualisation) {
      dispatch(
        addQuestionMapping({
          questionId: id,
          visualisationId: questionsMappings[id].visualisationId,
          questionName: debouncedQuestionName,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuestionName, hasMappedVisualisation]);

  return (
    <div className={styles.Title}>
      <div className={styles.CardNameDescriptionBlock}>
        <div className={styles.InputBlock}>
          <small className={styles.Fz_18}>*</small>

          <TextareaAutosize
            maxLength={titleMaxLength}
            value={element[nameValues]}
            onChange={(e) => onTitleChange(element.id, nameValues, e.target.value)}
            className={classNames(styles.Name, !element[nameValues] && styles.BorderError)}
          />
        </div>

        <div className={styles.InputBlock}>
          {descriptionRequired && <small className={styles.Fz_14}>*</small>}

          <TextareaAutosize
            maxLength={descriptionMaxLength}
            value={element[descriptionValues]}
            onChange={(e) => onTitleChange(element.id, descriptionValues, e.target.value)}
            className={classNames(
              styles.Description,
              descriptionRequired && !element[descriptionValues] && styles.BorderError,
            )}
          />
        </div>
      </div>

      <div className={styles.ButtonsContainer}>
        <DeleteButton clear={onDelete} size={Size.Sm} />
      </div>
    </div>
  );
};

export default CardTitleBlock;
