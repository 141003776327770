import { IGroupHierarchyItem } from 'types/groupHierarchyTypes';

export const findElement = (elements: IGroupHierarchyItem[]) => {
  let isElementFounded = false;

  function recurse(elements: IGroupHierarchyItem[]) {
    for (let childrenElement of elements) {
      if (childrenElement.children && childrenElement.children.some((element) => element.matches_search_term)) {
        isElementFounded = true;
        break;
      } else if (childrenElement.children) {
        recurse(childrenElement.children);
        if (isElementFounded) {
          break;
        }
      }
    }
  }

  recurse(elements);
  return isElementFounded;
};

export const getFlatSearchHierarchy = (data: IGroupHierarchyItem[]) => {
  const flatTree = (object: IGroupHierarchyItem): IGroupHierarchyItem[] => [
    { ...object },
    ...object.children.flatMap(flatTree),
  ];
  const find = (item: IGroupHierarchyItem) => item.id === start?.parent_id;

  const flatHierarchy = data.flatMap(flatTree);
  const foundGroup = flatHierarchy.find((item) => item.matches_search_term);
  const results: IGroupHierarchyItem[] = [];
  let start = foundGroup;

  while (start?.parent_id) {
    const item = flatHierarchy.find(find);
    start = item;
    if (item) {
      results.push(item);
    }
  }

  return results;
};
