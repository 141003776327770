import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import PageTitle from 'components/PageTitle/PageTitle';

import PermissionsTable from './components/PermissionsTable/PermissionsTable';

import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import { usePermissionsOverwriteMutation } from 'store/api/permissionsApi/permissionsApi';
import { useGetAllRolesPermissionsQuery } from 'store/api/rolesApi/rolesApi';

import ROUTES from 'router/routes';

import { copyObjectWithoutMethods } from 'helpers/copyObjectWithoutMethods';

import { MIN_SEARCH_LENGTH } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';
import { IServerError } from 'types/errorTypes';
import { Permissions, PermissionsOverwrite, permissionsEntity } from 'types/permissionsTypes';
import { RoleBulkTransformed, RolePermissions as RolePermissionsType } from 'types/roleTypes';

import { ReactComponent as AssessmentPerm } from 'assets/images/assessment.svg';
import { ReactComponent as GroupPerm } from 'assets/images/group-perm.svg';
import { ReactComponent as ModulePerm } from 'assets/images/module.svg';
import { ReactComponent as RolesPerm } from 'assets/images/roles-perm.svg';
import { ReactComponent as SectionPerm } from 'assets/images/section.svg';
import { ReactComponent as UnitPerm } from 'assets/images/unit.svg';
import { ReactComponent as UserPerm } from 'assets/images/user-perm.svg';
import { ReactComponent as ViewPerm } from 'assets/images/view.svg';

import styles from './RolePermissions.module.scss';

export const initialPermissions: Permissions = {
  [permissionsEntity.ROLE]: [],
  [permissionsEntity.GROUP]: [],
  [permissionsEntity.USER]: [],
  [permissionsEntity.ASSESSMENT]: [],
  [permissionsEntity.MODULE]: [],
  [permissionsEntity.SECTION]: [],
  [permissionsEntity.UNIT]: [],
  [permissionsEntity.GROUP_DASHBOARD_COMPARISON]: [],
  [permissionsEntity.OTHER_USERS_DASHBOARD_COMPARISON]: [],
  [permissionsEntity.OWN_COMPARISON]: [],
  [permissionsEntity.OWN_RESULTS]: [],
  [permissionsEntity.ADDITIONAL_FIELD]: [],
  [permissionsEntity.GROUPS_USERS_REPORT]: [],
  [permissionsEntity.ACTIVITY_REPORT]: [],
  [permissionsEntity.INVISIBLE_ROLE]: [],
};

const RolePermissions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sort, search, onSearching, onSorting } = useTableControl('name');
  const debouncedSearch = useDebounce(search, 500);

  const [roles, setRoles] = useState<RoleBulkTransformed[]>([]);

  const { data, isLoading, isFetching, isSuccess, isError } = useGetAllRolesPermissionsQuery({
    order_by: sort.sortBy,
    search: debouncedSearch.length < MIN_SEARCH_LENGTH ? '' : debouncedSearch,
  });

  const [permissionsOverwrite, { isLoading: updating }] = usePermissionsOverwriteMutation();

  useEffect(() => {
    if (isSuccess) {
      setRoles((prevState) => {
        return data.map((role: RolePermissionsType) => {
          const permissions = copyObjectWithoutMethods(initialPermissions);

          role?.permissions.forEach((rolePermission) => {
            permissions[rolePermission.entity as keyof Permissions].push(rolePermission.action);
          });

          return { ...role, permissions };
        });
      });
    }
  }, [data, isSuccess]);

  const updateRolePermissions = (roleId: string, permissions: Permissions): void => {
    setRoles((prevState) => {
      return prevState.map((prevRole) => (prevRole.role_id === roleId ? { ...prevRole, permissions } : prevRole));
    });
  };

  const cancelUpdateRolePermissions = () => navigate(ROUTES.ROLE_LIST);

  const saveRolePermissions = async () => {
    const isDisabled = roles.some((role) =>
      Object.keys(role.permissions).every((entity) => role.permissions[entity as keyof Permissions].length === 0),
    );

    if (isDisabled) {
      return toast.error<string>(t('roles.no_selected_permissions'));
    }

    const preparedData = roles
      .map((role) => {
        return Object.keys(role.permissions).map((entity) => ({
          role_id: role.role_id,
          entity: entity as keyof Permissions,
          actions: role.permissions[entity as keyof Permissions],
        }));
      })
      .flat(Infinity);

    try {
      await permissionsOverwrite(preparedData as PermissionsOverwrite[]).unwrap();
      navigate(ROUTES.ROLE_LIST);
    } catch (error: unknown) {
      toast.error((error as IServerError).data?.detail);
    }
  };

  useDocumentTitle([t('page_titles.role_permissions')]);

  return (
    <div className={styles.PermissionPage}>
      <Breadcrumb
        items={[
          { name: t('roles.role_list'), href: ROUTES.ROLE_LIST },
          {
            name: t('roles.edit_permissions'),
            href: ROUTES.ROLE_PERMISSIONS,
            active: true,
          },
        ]}
      />

      <PageTitle
        controls
        search={search}
        sort={sort.sortType}
        onSort={onSorting}
        onSearch={onSearching}
        title={t('roles.edit_permissions')}
      >
        <ButtonStyled size={ButtonSize.Small} fill={ButtonFill.Outlined} onClick={cancelUpdateRolePermissions}>
          {t('general.cancel')}
        </ButtonStyled>
        <ButtonStyled
          size={ButtonSize.Small}
          fill={ButtonFill.Contained}
          onClick={saveRolePermissions}
          loading={updating}
        >
          {t('general.save')}
        </ButtonStyled>
      </PageTitle>

      <div className={styles.PermissionsWrapper}>
        <div className={styles.Titles}>
          <div className={styles.Header}>
            <div className={styles.Title}>{t('roles.entity_type')}</div>
          </div>

          <div className={styles.TitleElement}>
            <RolesPerm />
            {t('general.roles')}
          </div>
          <div className={styles.TitleElement}>
            <GroupPerm />
            {t('general.groups')}
          </div>
          <div className={styles.TitleElement}>
            <UserPerm />
            {t('general.users')}
          </div>

          <div className={styles.TitleElement}>
            <AssessmentPerm />
            {t('general.diagnostic')}
          </div>

          <div className={styles.TitleElement}>
            <SectionPerm />
            {t('general.section')}
          </div>

          <div className={styles.TitleElement}>
            <ModulePerm />
            {t('general.module')}
          </div>

          <div className={styles.TitleElement}>
            <UnitPerm />
            {t('general.unit')}
          </div>

          <div className={classNames(styles.TitleElement, styles.Checkboxes)}>
            <ViewPerm />
            {t('general.view')}
          </div>
        </div>

        <PermissionsTable
          roles={roles}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          onUpdateRolePermissions={updateRolePermissions}
        />
      </div>
    </div>
  );
};

export default RolePermissions;
