import { useEffect, useState } from 'react';

import { useAppDispatch } from 'hooks/redux';

import { enableSaveButton } from 'store/features/createAssessmentSlice';

import { ResultsMappingDisplayMode, ResultsMappingStep } from 'types/enums';

export const useVisualisationDisplayMode = () => {
  const dispatch = useAppDispatch();

  const defaultState = {
    [ResultsMappingStep.Scurve]: ResultsMappingDisplayMode.View,
    [ResultsMappingStep.Accelerants]: ResultsMappingDisplayMode.View,
    [ResultsMappingStep.Ecosystem]: ResultsMappingDisplayMode.View,
  };

  const [visualisationDisplayMode, setVisualisationDisplayMode] = useState(defaultState);
  const isEditing = Object.values(visualisationDisplayMode).includes(ResultsMappingDisplayMode.Edit);

  useEffect(() => {
    dispatch(enableSaveButton(!isEditing));
  }, [dispatch, isEditing, visualisationDisplayMode]);

  const handleEditVisualisation = (visualisation: ResultsMappingStep) => {
    setVisualisationDisplayMode({
      ...visualisationDisplayMode,
      [visualisation]: ResultsMappingDisplayMode.Edit,
    });
  };

  const resetAllDisplayModes = () => {
    setVisualisationDisplayMode(defaultState);
  };

  return {
    visualisationDisplayMode,
    handleEditVisualisation,
    resetAllDisplayModes,
    isEditing,
  };
};
