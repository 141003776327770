import { ChangeEvent, Fragment, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import classNames from 'classnames';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Accelerants from 'components/Dashboard/components/Accelerants/Accelerants';
import Context from 'components/Dashboard/components/Context/Context';
import Ecosystem from 'components/Dashboard/components/Ecosystem/Ecosystem';
import SCurve from 'components/Dashboard/components/SCurve/SCurve';
import Loader from 'components/Loader/Loader';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';
import Tabs from 'components/Tabs/Tabs';

import SelectInGroupCard from './components/SelectInGroupCard/SelectInGroupCard';
import SelectManyCard from './components/SelectManyCard/SelectManyCard';
import SelectOwnCard from './components/SelectOwnCard/SelectOwnCard';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useGetCompletionByIdQuery } from 'store/api/assessmentApi/assessmentApi';
import { useGetContextsQuery } from 'store/api/mappingsApi/mappingsApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { BlockNames, CardVariants, ComparisonType, IMenuOption, MenuOptions } from 'types/assessmentTypes';
import { DashboardType } from 'types/dashboardTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Caret } from 'assets/images/caret.svg';
import { ReactComponent as Compare } from 'assets/images/compare.svg';

import AssessmentInfo from '../AssessmentInfo/AssessmentInfo';

import styles from './AssessmentCompletion.module.scss';

const initialRadioValues = {
  user: {
    user1: {
      value: '',
      title: '',
    },
    user2: {
      value: '',
      title: '',
    },
  },
  group: {
    group1: {
      value: '',
      title: '',
    },
    group2: {
      value: '',
      title: '',
    },
  },
  assessmentTake: {
    assessmentTake1: {
      value: '',
      title: '',
    },
    assessmentTake2: {
      value: '',
      title: '',
    },
  },
};

enum Tab {
  SCurve,
  Accelerants,
  Ecosystem,
  Context,
}

const AssessmentCompletion = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const navigate = useNavigate();
  const authUserId = useAppSelector((state) => state.auth.user?.id);
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);
  const { other_users_dashboard_comparison, own_results } = useAppSelector((state) => state.permissions.permissions);
  const { id = '' } = useParams();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { data, isLoading } = useGetCompletionByIdQuery({ id, groupId: selectedGroupId });
  const isOwnCompletion = data?.user_id === authUserId;
  const hasOwnResultsPermission = checkPermission(own_results, [PermissionsAction.READ]);
  const hasOtherUsersDashboardComparisonPermission = checkPermission(other_users_dashboard_comparison, [
    PermissionsAction.READ,
  ]);
  const hasPermission =
    (hasOtherUsersDashboardComparisonPermission && !isOwnCompletion) || (hasOwnResultsPermission && isOwnCompletion);

  const { data: contextData, isLoading: isLoadingContext } = useGetContextsQuery(
    { completionId: id },
    { skip: !id || !hasPermission },
  );
  const { assessment_name } = data || {};
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSelectedValue, setMenuSelectedValue] = useState<MenuOptions | null>(null);
  const [isSelectAnotherUserChecked, setIsSelectAnotherUserChecked] = useState(false);
  const [radioValues, setRadioValues] = useState(initialRadioValues);
  const AssessmentTake1Value =
    radioValues[BlockNames.AssessmentTake][
      (BlockNames.AssessmentTake + 1) as keyof (typeof radioValues)[BlockNames.AssessmentTake]
    ].value;
  const AssessmentTake2Value =
    radioValues[BlockNames.AssessmentTake][
      (BlockNames.AssessmentTake + 2) as keyof (typeof radioValues)[BlockNames.AssessmentTake]
    ].value;
  const AssessmentGroup1Value =
    radioValues[BlockNames.Group][(BlockNames.Group + 1) as keyof (typeof radioValues)[BlockNames.Group]].value;
  const AssessmentUser1Value =
    radioValues[BlockNames.User][(BlockNames.User + 1) as keyof (typeof radioValues)[BlockNames.User]].value;

  const isOwn = comparisonType === ComparisonType.Own;
  const isOpenMenu = Boolean(menuAnchorEl);
  const { group_dashboard_comparison, own_comparison } = useAppSelector((state) => state.permissions.permissions);

  const mobileTabs =
    isMobile && contextData
      ? [
          { title: t('assessment.steps.scurve.title') },
          { title: t('dashboard.accelerants.title') },
          { title: t('dashboard.ecosystem.title') },
          ...(contextData.show_context ? [{ title: t('dashboard.context.title') }] : []),
        ]
      : null;
  const [activeMobileTab, setActiveMobileTab] = useState(Tab.SCurve);

  const menuOptions: IMenuOption[] = [];

  if (checkPermission(own_comparison, [PermissionsAction.READ])) {
    menuOptions.push({ value: MenuOptions.MyResults, label: t('assessment.my_results') });
  }

  if (checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ])) {
    menuOptions.push({ value: MenuOptions.Users, label: t('assessment.users') });
  }

  if (checkPermission(group_dashboard_comparison, [PermissionsAction.READ])) {
    menuOptions.push({ value: MenuOptions.Groups, label: t('assessment.groups') });
  }

  const handleChangeSelectAnotherUser = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSelectAnotherUserChecked(event.target.checked);
  };

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (option: IMenuOption) => {
    handleCloseMenu();
    setRadioValues(initialRadioValues);
    setMenuSelectedValue(option.value);
  };

  const handleChangeRadioValue = (
    event: ChangeEvent<HTMLInputElement>,
    name: string,
    radioTitle: string,
    order: number,
  ) => {
    const { name: radioName, value } = event.target;

    setRadioValues((prevState) => {
      let clearName;

      if (name === BlockNames.Group) {
        clearName = BlockNames.User;
      }

      if (name === BlockNames.User) {
        clearName = BlockNames.AssessmentTake;
      }

      return {
        ...prevState,
        ...(clearName && {
          [clearName]: {
            ...prevState[clearName as keyof typeof prevState],
            [clearName + order]: { value: '', title: '' },
          },
        }),
        [name]: { ...prevState[name as keyof typeof prevState], [radioName]: { value: value, title: radioTitle } },
      };
    });
  };

  useEffect(() => {
    if (AssessmentTake1Value && AssessmentTake2Value) {
      navigate(ROUTES.DIAGNOSTIC_COMPARE(AssessmentTake1Value, AssessmentTake2Value));
    }
  }, [navigate, AssessmentTake1Value, AssessmentTake2Value]);

  useEffect(() => {
    if (!isSelectAnotherUserChecked) {
      setRadioValues((prevState) => ({
        ...prevState,
        user: { ...prevState.user, user2: { ...prevState.user.user1 } },
      }));
    }
  }, [isSelectAnotherUserChecked, AssessmentTake1Value, AssessmentUser1Value]);

  useDocumentTitle([t('page_titles.diagnostic_completion')]);

  const renderComparison = useMemo(() => {
    const renderComparisonContent = () => {
      if (menuSelectedValue === MenuOptions.MyResults) {
        return (
          <Fragment>
            <SelectOwnCard
              order={1}
              radioValues={radioValues}
              handleChangeRadioValue={handleChangeRadioValue}
              menuOption={MenuOptions.MyResults}
            />
            <Compare className={styles.CompareIcon} />
            <SelectOwnCard
              order={2}
              radioValues={radioValues}
              handleChangeRadioValue={handleChangeRadioValue}
              variant={CardVariants.Yellow}
              hidden={!AssessmentTake1Value}
              menuOption={MenuOptions.MyResults}
            />
          </Fragment>
        );
      }

      if (menuSelectedValue === MenuOptions.Groups) {
        return (
          <Fragment>
            <SelectInGroupCard
              order={1}
              radioValues={radioValues}
              handleChangeRadioValue={handleChangeRadioValue}
              menuOption={MenuOptions.Groups}
            />
            <Compare className={styles.CompareIcon} />
            <SelectInGroupCard
              order={2}
              radioValues={radioValues}
              handleChangeRadioValue={handleChangeRadioValue}
              isSelectAnotherUser={!isSelectAnotherUserChecked}
              variant={CardVariants.Yellow}
              hidden={!(AssessmentGroup1Value && AssessmentUser1Value && AssessmentTake1Value)}
              menuOption={MenuOptions.Groups}
            />
          </Fragment>
        );
      }

      if (menuSelectedValue === MenuOptions.Users) {
        return (
          <Fragment>
            <SelectManyCard
              order={1}
              radioValues={radioValues}
              handleChangeRadioValue={handleChangeRadioValue}
              menuOption={MenuOptions.Users}
            />
            <Compare className={styles.CompareIcon} />
            <SelectManyCard
              order={2}
              isSelectAnotherUser={!isSelectAnotherUserChecked}
              radioValues={radioValues}
              handleChangeRadioValue={handleChangeRadioValue}
              variant={CardVariants.Yellow}
              hidden={!(AssessmentUser1Value && AssessmentTake1Value)}
              menuOption={MenuOptions.Users}
            />
          </Fragment>
        );
      }
    };

    if (menuSelectedValue === null) {
      const dashboardType = DashboardType.USER;
      const requestId1 = id;

      return (
        <div>
          <SCurve dashboardType={dashboardType} requestId1={requestId1} />
          <Accelerants dashboardType={dashboardType} requestId1={requestId1} />
          <Ecosystem dashboardType={dashboardType} requestId1={requestId1} />
          {contextData?.show_context && <Context completionId={requestId1} />}
        </div>
      );
    }

    return (
      <div className={styles.Compare}>
        <div className={styles.CompareHeader}>
          <h3>{t('assessment.comparison')}</h3>
          {!isOwn && !(menuSelectedValue === MenuOptions.MyResults) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch sx={SwitchSx} checked={isSelectAnotherUserChecked} onChange={handleChangeSelectAnotherUser} />
                }
                label={t('assessment.select_another_user')}
                sx={FormControlLabelSx}
              />
            </FormGroup>
          )}
        </div>
        <div className={styles.Blocks}> {renderComparisonContent()} </div>
      </div>
    );
  }, [
    menuSelectedValue,
    t,
    isOwn,
    isSelectAnotherUserChecked,
    radioValues,
    AssessmentTake1Value,
    AssessmentGroup1Value,
    AssessmentUser1Value,
    id,
    contextData?.show_context,
  ]);

  if (isLoading || isLoadingContext) {
    return (
      <div className={styles.AssessmentPage}>
        <Loader flexCenter size="md" />
      </div>
    );
  }

  if (!hasPermission) {
    return (
      <div className={styles.AssessmentPage}>
        <NothingFoundText text={t('general.no_permission')} />
      </div>
    );
  }

  return (
    <div className={styles.AssessmentPage}>
      <Breadcrumb
        items={[
          { name: t('assessment.diagnostic_results'), href: ROUTES.DIAGNOSTIC_RESULTS },
          {
            name: assessment_name || '',
            href: ROUTES.DIAGNOSTIC_COMPLETION(id),
            active: true,
          },
        ]}
      />

      <PageTitle title={assessment_name} searchPlaceholder={t('assessment.search_placeholder')}>
        {!isMobile && menuOptions.length ? (
          <div>
            {!!comparisonType && (
              <Button onClick={handleOpenMenu} sx={ButtonSx}>
                <Compare />
                <span className={styles.ButtonText}>{t('general.compare')}</span>
                <Caret className={classNames(styles.Caret, { [styles.Active]: isOpenMenu })} />
              </Button>
            )}
            <Menu anchorEl={menuAnchorEl} open={isOpenMenu} onClose={handleCloseMenu} sx={MenuSx}>
              {menuOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  sx={MenuItemSx}
                  selected={option.value === menuSelectedValue}
                  onClick={() => handleMenuItemClick(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : null}
      </PageTitle>

      {data && <AssessmentInfo completionLeft={data} />}

      {isMobile && mobileTabs ? (
        <>
          <Tabs tabs={mobileTabs} activeTab={activeMobileTab} changeTab={setActiveMobileTab} underline spaceBetween />
          {
            [
              <SCurve key={Tab.SCurve} dashboardType={DashboardType.USER} requestId1={id} />,
              <Accelerants key={Tab.Accelerants} dashboardType={DashboardType.USER} requestId1={id} />,
              <Ecosystem key={Tab.Ecosystem} dashboardType={DashboardType.USER} requestId1={id} />,
              ...(contextData?.show_context ? [<Context key={Tab.Context} completionId={id} />] : []),
            ][activeMobileTab]
          }
        </>
      ) : (
        renderComparison
      )}
    </div>
  );
};

const ButtonSx = { color: 'var(--refined-teal)', display: 'flex', alignItems: 'center' };

const SwitchSx = {
  '.MuiSwitch-thumb': {
    width: '12px',
    height: '12px',
  },

  '.MuiSwitch-track': {
    width: '36px',
    height: '20px',
    borderRadius: '1000px',
    backgroundColor: 'var(--dark-grey)',
    opacity: 1,
  },

  '& .MuiSwitch-switchBase': {
    transform: 'translateX(8px) translateY(7px)',
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(20px) translateY(7px)',
      color: 'var(--white)',

      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--refined-teal)',
        opacity: 1,
      },
    },
  },
};

const FormControlLabelSx = {
  '.MuiFormControlLabel-label': {
    fontFamily: 'Arimo',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '140%',
    color: 'var(--matte-black)',
    transform: 'translateY(3px)',
  },
};

const MenuSx = {
  '.MuiMenu-root': {
    border: '1px solid var(--cool-grey)',
    borderRadius: '8px',
    overflow: 'hidden',
  },

  '.MuiMenu-paper': {
    border: '1px solid var(--cool-grey)',
    boxShadow: 'none',
  },

  '.Mui-selected': {
    backgroundColor: 'var(--light-teal) !important',
  },

  '.MuiMenu-list': {
    backgroundColor: 'var(--white)',
    width: '154px',
    padding: 0,
  },
};

const MenuItemSx = {
  fontSize: '14px',
  padding: '12px',
};

export default AssessmentCompletion;
