import { ReactNode } from 'react';

import styles from './Steps.module.scss';

type Props = {
  children: ReactNode;
};

export const Steps = ({ children }: Props) => {
  return <ul className={styles.Container}>{children}</ul>;
};
