import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Switch from '@mui/material/Switch';
import classNames from 'classnames';

import { useAppDispatch } from 'hooks/redux';

import { setDashboard } from 'store/features/navigationSlice';

import ROUTES from 'router/routes';

import styles from './DashboardSwitch.module.scss';

const DashboardSwitch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isChecked, setChecked] = useState(false);

  const changeDashboard = (isNavigational: boolean) => {
    dispatch(setDashboard(isNavigational ? ROUTES.DASHBOARD_NAVIGATIONAL : ROUTES.DASHBOARD_REGULAR));

    if (pathname === ROUTES.DASHBOARD_NAVIGATIONAL && !isNavigational) {
      navigate(ROUTES.DASHBOARD_REGULAR);
    }

    if (pathname === ROUTES.DASHBOARD_REGULAR && isNavigational) {
      navigate(ROUTES.DASHBOARD_NAVIGATIONAL);
    }

    setChecked(isNavigational);
  };

  return (
    <div className={styles.Container}>
      <button
        className={classNames(styles.LeftLabel, { [styles.Active]: !isChecked })}
        onClick={() => changeDashboard(false)}
      >
        {t('general.regular')}
      </button>

      <Switch sx={SwitchSx} checked={isChecked} onChange={() => changeDashboard(!isChecked)} />

      <button
        className={classNames(styles.RightLabel, { [styles.Active]: isChecked })}
        onClick={() => changeDashboard(true)}
      >
        {t('general.navigational')}
      </button>
    </div>
  );
};

const SwitchSx = {
  height: 'auto',

  ':has(input:focus-visible)': {
    '.MuiSwitch-track': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },

  '.MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    backgroundColor: 'var(--refined-teal)',
    boxShadow: 'none',
  },

  '.MuiSwitch-track': {
    width: '36px',
    height: '20px',
    borderRadius: '1000px',
    backgroundColor: 'var(--lines)',
    opacity: 1,
  },

  '& .MuiSwitch-switchBase': {
    transform: 'translateX(6px) translateY(5px)',
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(18px) translateY(5px)',
      color: 'var(--white)',

      '.MuiSwitch-thumb': {
        backgroundColor: 'var(--chartreuse)',
      },

      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--corn-field)',
        opacity: 1,
      },
    },
  },
};

export default DashboardSwitch;
