import { ReactNode } from 'react';

import Loader from 'components/Loader/Loader';

import CardFooter, { FooterProps } from '../../../CardFooter/CardFooter';
import CardHeader from '../../../CardHeader/CardHeader';

import styles from './IntroWrapper.module.scss';

type Props = {
  title: string;
  subtitle: string;
  isAssessmentLoading?: boolean;
  image?: string;
  icon: ReactNode;
  footerProps: FooterProps;
};

const IntroWrapper = ({ isAssessmentLoading, image, icon, title, subtitle, footerProps }: Props) => {
  return (
    <div className={styles.Container}>
      <div className={styles.ContentContainer}>
        <div className={styles.ImageBox}>
          {isAssessmentLoading ? <Loader flexCenter /> : image ? <img src={image} alt="intro" /> : icon}
        </div>

        <CardHeader intro title={title} subtitle={subtitle} />
      </div>

      <CardFooter {...footerProps} />
    </div>
  );
};

export default IntroWrapper;
