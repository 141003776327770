import React from 'react';

const MediumDeleteSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 0.75C11.733 0.75 13.1492 2.10645 13.2449 3.81558L13.25 4H18.5C18.9142 4 19.25 4.33579 19.25 4.75C19.25 5.1297 18.9678 5.44349 18.6018 5.49315L18.5 5.5H17.704L16.4239 18.5192C16.2912 19.8683 15.1984 20.91 13.8626 20.9945L13.6871 21H6.31293C4.95734 21 3.81365 20.0145 3.59883 18.6934L3.57614 18.5192L2.295 5.5H1.5C1.1203 5.5 0.806509 5.21785 0.756847 4.85177L0.75 4.75C0.75 4.3703 1.03215 4.05651 1.39823 4.00685L1.5 4H6.75C6.75 2.20507 8.20507 0.75 10 0.75ZM16.197 5.5H3.802L5.06893 18.3724C5.12768 18.9696 5.60033 19.4343 6.18585 19.4936L6.31293 19.5H13.6871C14.2872 19.5 14.7959 19.0751 14.9123 18.4982L14.9311 18.3724L16.197 5.5ZM11.75 8.25C12.1297 8.25 12.4435 8.53215 12.4932 8.89823L12.5 9V16C12.5 16.4142 12.1642 16.75 11.75 16.75C11.3703 16.75 11.0565 16.4678 11.0068 16.1018L11 16V9C11 8.58579 11.3358 8.25 11.75 8.25ZM8.25 8.25C8.6297 8.25 8.94349 8.53215 8.99315 8.89823L9 9V16C9 16.4142 8.66421 16.75 8.25 16.75C7.8703 16.75 7.55651 16.4678 7.50685 16.1018L7.5 16V9C7.5 8.58579 7.83579 8.25 8.25 8.25ZM10 2.25C9.08183 2.25 8.32881 2.95711 8.2558 3.85647L8.25 4H11.75C11.75 3.0335 10.9665 2.25 10 2.25Z"
      fill="#DC001C"
    />
  </svg>
);

export default MediumDeleteSVG;
