import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';

import ImagePreview from 'components/ImagePreview/ImagePreview';
import Loader from 'components/Loader/Loader';

import { useUploadImageMutation } from 'store/api/assessmentApi/assessmentApi';

import { toBase64 } from 'helpers/file';

import { MAX_UPLOAD_FILE_SIZE } from 'constants/index';

import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';

import styles from './FileUploadBuilder.module.scss';

type Props = {
  imageURL: string;
  setImageURL: Dispatch<SetStateAction<string>>;
};

const FileUploadBuilder = ({ setImageURL, imageURL }: Props) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<FileType[]>([]);
  const [imageError, setImageError] = useState<null | string>(null);

  const [uploadImage, { isLoading: isLoadingUploadImage }] = useUploadImageMutation();

  const uploader = useRef();
  const reader = useMemo(() => new FileReader(), []);

  useEffect(() => {
    if (image[0] && image[0].blobFile) {
      toBase64(image[0].blobFile, reader).then((base64) => {
        uploadImage({ image_base64: base64 as string })
          .unwrap()
          .then(({ image_url }) => setImageURL(image_url));
      });
    }
  }, [image, setImageURL, reader, uploadImage]);

  const onImageUpload = (file: FileType[]) => {
    if (file[0].blobFile) {
      const fileSize = file[0].blobFile.size;

      if (fileSize <= MAX_UPLOAD_FILE_SIZE) {
        setImage(file);
      } else {
        setImage([]);
        setImageError(t('validation.max_file_size'));
      }
    }
  };

  const handleEditPreview = () => {
    setImage([]);
    setImageURL('');
  };

  return (
    <div className={styles.FileUploader}>
      {imageURL ? (
        <ImagePreview imageURL={imageURL} onEdit={handleEditPreview} />
      ) : (
        <Uploader
          onChange={(fileList) => onImageUpload(fileList)}
          action=""
          ref={uploader}
          accept=".png, .jpg, .jpeg"
          fileListVisible={false}
          className={classNames(styles.DndBlock, false && styles.DndError)}
          autoUpload={false}
          multiple={false}
          draggable
          fileList={image}
        >
          <div>
            {isLoadingUploadImage ? (
              <Loader flexCenter />
            ) : (
              <>
                <small className={styles.ErrorText}>{imageError}</small>
                <UploadIcon className={styles.DragImage} />
                <p className={styles.DragText}>{t('csv.drag_title')}</p>
                <div className={styles.DragSelectText}>
                  <p className={styles.ActiveText}>{t('csv.drag_select_text_1')} </p> {t('csv.drag_select_text_2')}
                </div>
              </>
            )}
          </div>
        </Uploader>
      )}
    </div>
  );
};

export default FileUploadBuilder;
