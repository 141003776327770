import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  useLazyGetMappingsGroupAccelerantsQuery,
  useLazyGetMappingsGroupEcosystemQuery,
  useLazyGetMappingsGroupScurveQuery,
  useLazyGetMappingsQuery,
} from 'store/api/mappingsApi/mappingsApi';

import {
  DashboardResult,
  DashboardResultGroupAccelerants,
  DashboardResultGroupScurve,
  DashboardSearchParams,
  DashboardType,
  VisualisationTypes,
} from 'types/dashboardTypes';

function returnedResult<T1, T2>(dashboardType: DashboardType, userResult: T1, groupResult: T2) {
  return {
    [DashboardType.USER]: userResult,
    [DashboardType.GROUP]: groupResult,
  }[dashboardType];
}

function useGetMappingPerSection(
  requestId1: string,
  requestId2: string,
  dashboardType: DashboardType,
  type: VisualisationTypes,
) {
  const getMappingsUserCompletion1AbortRef = useRef(() => {});
  const getMappingsUserCompletion2AbortRef = useRef(() => {});
  const getMappingsGroupCompletionScurve1AbortRef = useRef(() => {});
  const getMappingsGroupCompletionAccelerants1AbortRef = useRef(() => {});
  const getMappingsGroupCompletionEcosystem1AbortRef = useRef(() => {});
  const getMappingsGroupCompletionScurve2AbortRef = useRef(() => {});
  const getMappingsGroupCompletionAccelerants2AbortRef = useRef(() => {});
  const getMappingsGroupCompletionEcosystem2AbortRef = useRef(() => {});

  const [searchParams] = useSearchParams();
  const assessmentId1 = searchParams.get(DashboardSearchParams.ASSESSMENT_1);
  const assessmentId2 = searchParams.get(DashboardSearchParams.ASSESSMENT_2);
  const dateFrom = searchParams.get(DashboardSearchParams.DATE_FROM);
  const dateTo = searchParams.get(DashboardSearchParams.DATE_TO);

  const [
    getMappingsUserCompletion1,
    { data: mappingsUserCompletion = [], isLoading: isLoadingUserCompletion, isFetching: isFetchingUserCompletion },
  ] = useLazyGetMappingsQuery();

  const [
    getMappingsUserCompletion2,
    { data: mappingsUserCompletion2 = [], isLoading: isLoadingUserCompletion2, isFetching: isFetchingUserCompletion2 },
  ] = useLazyGetMappingsQuery();

  const [
    getMappingsGroupCompletionScurve1,
    {
      data: mappingsGroupCompletionScurve1 = [],
      isLoading: isLoadingGroupCompletionScurve1,
      isFetching: isFetchingGroupCompletionScurve1,
    },
  ] = useLazyGetMappingsGroupScurveQuery();

  const [
    getMappingsGroupCompletionAccelerants1,
    {
      data: mappingsGroupCompletionAccelerants1 = [],
      isLoading: isLoadingGroupCompletionAccelerants1,
      isFetching: isFetchingGroupCompletionAccelerants1,
    },
  ] = useLazyGetMappingsGroupAccelerantsQuery();

  const [
    getMappingsGroupCompletionEcosystem1,
    {
      data: mappingsGroupCompletionEcosystem1 = [],
      isLoading: isLoadingGroupCompletionEcosystem1,
      isFetching: isFetchingGroupCompletionEcosystem1,
    },
  ] = useLazyGetMappingsGroupEcosystemQuery();

  const [
    getMappingsGroupCompletionScurve2,
    {
      data: mappingsGroupCompletionScurve2 = [],
      isLoading: isLoadingGroupCompletionScurve2,
      isFetching: isFetchingGroupCompletionScurve2,
    },
  ] = useLazyGetMappingsGroupScurveQuery();

  const [
    getMappingsGroupCompletionAccelerants2,
    {
      data: mappingsGroupCompletionAccelerants2 = [],
      isLoading: isLoadingGroupCompletionAccelerants2,
      isFetching: isFetchingGroupCompletionAccelerants2,
    },
  ] = useLazyGetMappingsGroupAccelerantsQuery();

  const [
    getMappingsGroupCompletionEcosystem2,
    {
      data: mappingsGroupCompletionEcosystem2 = [],
      isLoading: isLoadingGroupCompletionEcosystem2,
      isFetching: isFetchingGroupCompletionEcosystem2,
    },
  ] = useLazyGetMappingsGroupEcosystemQuery();

  useEffect(() => {
    getMappingsUserCompletion1AbortRef.current();
    getMappingsUserCompletion2AbortRef.current();
    getMappingsGroupCompletionScurve1AbortRef.current();
    getMappingsGroupCompletionAccelerants1AbortRef.current();
    getMappingsGroupCompletionEcosystem1AbortRef.current();
    getMappingsGroupCompletionScurve2AbortRef.current();
    getMappingsGroupCompletionAccelerants2AbortRef.current();
    getMappingsGroupCompletionEcosystem2AbortRef.current();

    const dateRangeValue = dateFrom && dateTo ? { dateFrom, dateTo } : null;

    if (dashboardType === DashboardType.USER) {
      if (requestId1) {
        const { abort } = getMappingsUserCompletion1(
          {
            completionId: requestId1,
            visualisationType: type,
          },
          true,
        );

        getMappingsUserCompletion1AbortRef.current = abort;
      }

      if (requestId2) {
        const { abort } = getMappingsUserCompletion2(
          {
            completionId: requestId2,
            visualisationType: type,
          },
          true,
        );

        getMappingsUserCompletion2AbortRef.current = abort;
      }
    }

    if (dashboardType === DashboardType.GROUP) {
      if (requestId1) {
        if (type === VisualisationTypes.SCurve) {
          const { abort } = getMappingsGroupCompletionScurve1(
            {
              groupId: requestId1,
              assessmentId: assessmentId1,
              visualisationType: type,
              ...dateRangeValue,
            },
            true,
          );

          getMappingsGroupCompletionScurve1AbortRef.current = abort;
        }

        if (type === VisualisationTypes.Accelerants) {
          const { abort } = getMappingsGroupCompletionAccelerants1(
            {
              groupId: requestId1,
              assessmentId: assessmentId1,
              visualisationType: type,
              ...dateRangeValue,
            },
            true,
          );

          getMappingsGroupCompletionAccelerants1AbortRef.current = abort;
        }

        if (type === VisualisationTypes.Ecosystem) {
          const { abort } = getMappingsGroupCompletionEcosystem1(
            {
              groupId: requestId1,
              assessmentId: assessmentId1,
              visualisationType: type,
              ...dateRangeValue,
            },
            true,
          );

          getMappingsGroupCompletionEcosystem1AbortRef.current = abort;
        }
      }

      if (requestId2) {
        if (type === VisualisationTypes.SCurve) {
          const { abort } = getMappingsGroupCompletionScurve2(
            {
              groupId: requestId2,
              assessmentId: assessmentId2,
              visualisationType: type,
              ...dateRangeValue,
            },
            true,
          );

          getMappingsGroupCompletionScurve2AbortRef.current = abort;
        }

        if (type === VisualisationTypes.Accelerants) {
          const { abort } = getMappingsGroupCompletionAccelerants2(
            {
              groupId: requestId2,
              assessmentId: assessmentId2,
              visualisationType: type,
              ...dateRangeValue,
            },
            true,
          );

          getMappingsGroupCompletionAccelerants2AbortRef.current = abort;
        }

        if (type === VisualisationTypes.Ecosystem) {
          const { abort } = getMappingsGroupCompletionEcosystem2(
            {
              groupId: requestId2,
              assessmentId: assessmentId2,
              visualisationType: type,
              ...dateRangeValue,
            },
            true,
          );

          getMappingsGroupCompletionEcosystem2AbortRef.current = abort;
        }
      }
    }
  }, [
    dashboardType,
    type,
    requestId1,
    getMappingsUserCompletion1,
    requestId2,
    getMappingsUserCompletion2,
    assessmentId1,
    getMappingsGroupCompletionScurve1,
    getMappingsGroupCompletionAccelerants1,
    getMappingsGroupCompletionEcosystem1,
    assessmentId2,
    getMappingsGroupCompletionScurve2,
    getMappingsGroupCompletionAccelerants2,
    getMappingsGroupCompletionEcosystem2,
    dateFrom,
    dateTo,
  ]);

  const mappingsGroupCompletion1 = {
    [VisualisationTypes.SCurve]: {
      mappingsGroupCompletion: mappingsGroupCompletionScurve1,
      isLoadingGroupCompletion: isLoadingGroupCompletionScurve1,
      isFetchingGroupCompletion: isFetchingGroupCompletionScurve1,
    },
    [VisualisationTypes.Accelerants]: {
      mappingsGroupCompletion: mappingsGroupCompletionAccelerants1,
      isLoadingGroupCompletion: isLoadingGroupCompletionAccelerants1,
      isFetchingGroupCompletion: isFetchingGroupCompletionAccelerants1,
    },
    [VisualisationTypes.Ecosystem]: {
      mappingsGroupCompletion: mappingsGroupCompletionEcosystem1,
      isLoadingGroupCompletion: isLoadingGroupCompletionEcosystem1,
      isFetchingGroupCompletion: isFetchingGroupCompletionEcosystem1,
    },
  };

  const mappingsGroupCompletion2 = {
    [VisualisationTypes.SCurve]: {
      mappingsGroupCompletion: mappingsGroupCompletionScurve2,
      isLoadingGroupCompletion: isLoadingGroupCompletionScurve2,
      isFetchingGroupCompletion: isFetchingGroupCompletionScurve2,
    },
    [VisualisationTypes.Accelerants]: {
      mappingsGroupCompletion: mappingsGroupCompletionAccelerants2,
      isLoadingGroupCompletion: isLoadingGroupCompletionAccelerants2,
      isFetchingGroupCompletion: isFetchingGroupCompletionAccelerants2,
    },
    [VisualisationTypes.Ecosystem]: {
      mappingsGroupCompletion: mappingsGroupCompletionEcosystem2,
      isLoadingGroupCompletion: isLoadingGroupCompletionEcosystem2,
      isFetchingGroupCompletion: isFetchingGroupCompletionEcosystem2,
    },
  };

  return {
    mappingsCompletion: returnedResult<
      DashboardResult[] | null,
      DashboardResultGroupScurve[] | DashboardResultGroupAccelerants[] | DashboardResult[] | null
    >(dashboardType, mappingsUserCompletion, mappingsGroupCompletion1[type].mappingsGroupCompletion),
    mappingsCompletion2: returnedResult<
      DashboardResult[] | null,
      DashboardResultGroupScurve[] | DashboardResultGroupAccelerants[] | DashboardResult[] | null
    >(dashboardType, mappingsUserCompletion2, mappingsGroupCompletion2[type].mappingsGroupCompletion),

    isLoadingCompletion: returnedResult<boolean, boolean>(
      dashboardType,
      isLoadingUserCompletion,
      mappingsGroupCompletion1[type].isLoadingGroupCompletion,
    ),
    isLoadingCompletion2: returnedResult<boolean, boolean>(
      dashboardType,
      isLoadingUserCompletion2,
      mappingsGroupCompletion2[type].isLoadingGroupCompletion,
    ),

    isFetchingCompletion: returnedResult<boolean, boolean>(
      dashboardType,
      isFetchingUserCompletion,
      mappingsGroupCompletion1[type].isFetchingGroupCompletion,
    ),
    isFetchingCompletion2: returnedResult<boolean, boolean>(
      dashboardType,
      isFetchingUserCompletion2,
      mappingsGroupCompletion2[type].isFetchingGroupCompletion,
    ),
  };
}

export default useGetMappingPerSection;
