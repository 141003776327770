import { ChangeEventHandler } from 'react';
import { NumericFormat } from 'react-number-format';

import classNames from 'classnames';

import boardStyles from '../BoardElements.module.scss';

interface Props {
  value: string | number;
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const BoardInput = ({ onChange, value, placeholder }: Props) => {
  return (
    <NumericFormat
      value={value}
      className={classNames(boardStyles.WeightInput, (!value || value === '.') && boardStyles.BorderError)}
      placeholder={placeholder}
      onChange={onChange}
      allowNegative={false}
    />
  );
};

export default BoardInput;
