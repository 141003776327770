import { BulkAction } from './enums';

export interface Accesses {
  group_id: string;
  role_id: string;
}

export interface NamedAccesses extends Accesses {
  group_name: string;
  role_name: string;
}

export interface BaseUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  photo?: null | string;
  phone_number: null | string;
}

export interface User extends BaseUser {
  role: string;
  group_accesses: {
    organization: string;
    unit?: string;
  }[];
}

export interface CSVUser extends User {
  fieldId: number;
  itemId: number;
}

export interface ParsedUser {
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  accesses: NamedAccesses[];
  errors: ParsedUserError[];
}

export interface ParsedUserError {
  error?: string;
  name?: ErrorName;
  items: { [key: string]: string }[];
}

export interface ExtendedParsedUser extends ParsedUser {
  itemId: number;
  fieldId: number;
  id: string;
}

export interface ParsedCSVUsers {
  items: ExtendedParsedUser[];
  role_id?: string;
  group_ids?: string[];
  itemId?: number;
}

export interface PdfUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface PdfUserPayload {
  users: PdfUser[];
}

export interface Avatar {
  preview: string;
  file?: string | null;
  files?: FileList | File | null;
}

interface UserGroup {
  group_id: string;
  group_name: string;
  role_id: string;
  role_name: string;
  role_level: string;
}

export interface CurrentUser {
  id: string;
  group_id: string;
  email: string;
  first_name: string;
  groups: UserGroup[];
  photo: string | null;
  is_active: boolean;
  last_name: string;
  phone_number: string | null;
}

interface Role {
  value: string;
  label: string;
}

export interface Group {
  value: string;
  label: string;
  children?: Group[];
  visible?: boolean;
  refKey?: string;
}

export interface Tag {
  role: Role;
  group: Group;
}

export interface Tags {
  tag: Tag;
  index: number;
}

export interface GetUsersResponse {
  items: User[];
  total: number;
  page: number;
  size: number;
}

export type AuthState = {
  user: CurrentUser | null;
  isAuth: boolean;
  loading: boolean;
  selected_group: string;
  selected_org: string;
  shouldBeRedirected: boolean;
  isRedirectLogin: boolean;
};

export enum ErrorName {
  Email = 'email',
  Group = 'group',
  Role = 'role',
  FirstName = 'first_name',
  LastName = 'last_name',
}

export type ActionType = BulkAction.Delete | BulkAction.Role | BulkAction.Deactivate | '';
