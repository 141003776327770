import { ChangeEventHandler } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import classNames from 'classnames';

import InputLabel from '../InputLabel/InputLabel';

import styles from '../Inputs.module.scss';

type Props = {
  label?: string;
  type?: string;
  register?: UseFormRegisterReturn;
  value?: string;
  classes?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  error_message?: string;
  error?: {
    invalid: boolean;
    isDirty?: boolean;
    isTouched?: boolean;
    error?: FieldError | undefined;
  } | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  maxLength?: number;
};

const Text = ({
  label,
  type,
  value,
  disabled,
  classes,
  register,
  placeholder,
  required,
  error,
  error_message,
  onChange,
  maxLength,
}: Props) => {
  const inputStyles = classNames(
    styles.Input,
    error?.invalid && styles.Invalid,
    error?.isDirty && !error.invalid && styles.Valid,
  );

  return (
    <div className={classNames(styles.CustomInput, classes)}>
      <InputLabel errorMessage={error_message} label={label} required={required} />

      <input
        type={type ?? 'text'}
        disabled={disabled}
        value={value}
        className={inputStyles}
        onChange={onChange}
        maxLength={maxLength}
        {...register}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Text;
