export const getFieldId = ({
  page,
  size,
  index,
  total,
}: {
  page: number;
  size: number;
  index: number;
  total: number;
}) => {
  if (total > size) {
    return index + 1;
  }

  return page * size - (size - (index + 1));
};
