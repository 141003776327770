import classNames from 'classnames';

import styles from './CardHeader.module.scss';

type Props = {
  title: string;
  subtitle: string;
  intro?: boolean;
};

const CardHeader = ({ title, subtitle, intro }: Props) => {
  return (
    <div className={classNames(styles.CardHeader, intro && styles.Intro)}>
      <h2 className={styles.Title}>{title}</h2>
      <p className={styles.Subtitle}>{subtitle}</p>
    </div>
  );
};

export default CardHeader;
