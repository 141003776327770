import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';

import Select from 'components/Inputs/Select/Select';
import ModalWrapper from 'components/Modals/ModalWrapper';

import { useAppSelector } from 'hooks/redux';

import { useGetComparisonListQuery } from 'store/api/assessmentApi/assessmentApi';

import { compareOwnAssessmentModalResolver } from 'helpers/validations';

import { getAssessmentOptions } from '../../helpers';

import { CardVariants, DashboardSearchParams } from 'types/dashboardTypes';
import { ModalProps } from 'types/modalTypes';

import OptionCard from '../../../OptionCard/OptionCard';

interface Props extends ModalProps {}

type FormValues = {
  option1: {
    assessment: string;
  };
  option2: {
    assessment: string;
  };
};

const initialState = {
  option1: {
    assessment: '',
  },
  option2: {
    assessment: '',
  },
};

const OwnComparisonModal = ({ open, close }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);

  const { user } = useAppSelector((state) => state.auth);

  const { handleSubmit, formState, control, reset, resetField, watch, setValue } = useForm<FormValues>({
    resolver: yupResolver(compareOwnAssessmentModalResolver),
    defaultValues: initialState,
    mode: 'all',
  });

  const currentUserId = user?.id || '';
  const assessment1Value = watch('option1.assessment');

  const {
    data: assessments1 = [],
    isFetching: isFetchingAssessments1,
    isSuccess: isSuccessAssessments1,
  } = useGetComparisonListQuery({
    comparisonType,
    userId: currentUserId,
  });

  const { data: assessments2 = [], isFetching: isFetchingAssessments2 } = useGetComparisonListQuery(
    {
      comparisonType,
      userId: currentUserId,
      comparedAssessmentId: assessment1Value,
    },
    { skip: !assessment1Value },
  );

  const onClose = () => {
    close?.();
    reset(initialState);
  };

  const onSubmit: SubmitHandler<FormValues> = ({ option1, option2 }) => {
    searchParams.set(DashboardSearchParams.COMPLETION_1, option1.assessment);
    searchParams.set(DashboardSearchParams.COMPLETION_2, option2.assessment);
    setSearchParams(searchParams);
    onClose();
  };

  useEffect(() => {
    if (isSuccessAssessments1 && assessments1.length) {
      setValue('option1.assessment', assessments1[assessments1.length - 1].completed_assessment_id);
    }
  }, [assessments1, isSuccessAssessments1, setValue]);

  useEffect(() => {
    resetField('option2.assessment');
  }, [assessment1Value, resetField]);

  return (
    <ModalWrapper
      title={t('assessment.comparison')}
      subtitle={t('dashboard.comparison_modal.description')}
      open={open}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.compare')}
      actionDisabled={!formState.isValid}
      close={onClose}
      cancelTitle={t('general.cancel')}
      large
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row">
          <OptionCard variant={CardVariants.Blue} title={t('dashboard.comparison_modal.option_1_title')}>
            <Box mt={1.5} mb={-2}>
              <Controller
                control={control}
                name="option1.assessment"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    data={getAssessmentOptions(assessments1)}
                    label={t('inputs.diagnostic')}
                    placeholder={t('inputs.select_diagnostic')}
                    onSelect={onChange}
                    isLoading={isFetchingAssessments1}
                  />
                )}
              />
            </Box>
          </OptionCard>

          <OptionCard variant={CardVariants.Yellow} title={t('dashboard.comparison_modal.option_2_title')}>
            <Box mt={1.5} mb={-2}>
              <Controller
                control={control}
                name="option2.assessment"
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    data={getAssessmentOptions(assessments2)}
                    label={t('inputs.diagnostic')}
                    placeholder={t('inputs.select_diagnostic')}
                    onSelect={onChange}
                    isLoading={isFetchingAssessments2}
                    disabled={!assessment1Value}
                  />
                )}
              />
            </Box>
          </OptionCard>
        </Stack>
      </form>
    </ModalWrapper>
  );
};

export default OwnComparisonModal;
