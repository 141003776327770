import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { getCurrentPermissions } from 'store/features/userPermissionsSlice';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';

import { Permissions, PermissionsAction, permissionsEntity } from 'types/permissionsTypes';

import { ReactComponent as Group } from 'assets/images/group-editor.svg';
import { ReactComponent as NoteList } from 'assets/images/note-list.svg';
import { ReactComponent as User } from 'assets/images/person.svg';
import { ReactComponent as Role } from 'assets/images/role-editor.svg';

import styles from './DashboardNavigational.module.scss';

const DashboardNavigational = () => {
  const { t } = useTranslation();
  const permissions = useAppSelector(getCurrentPermissions);

  const links = [
    {
      href: ROUTES.ROLE_LIST,
      title: t('general.role_editor'),
      icon: <Role className={styles.Icon} />,
      entity: permissionsEntity.ROLE,
      permissions: [PermissionsAction.READ],
    },
    {
      href: ROUTES.GROUP_LIST,
      title: t('general.group_editor'),
      icon: <Group className={styles.Icon} />,
      entity: permissionsEntity.GROUP,
      permissions: [PermissionsAction.READ],
    },
    {
      href: ROUTES.USER_LIST,
      title: t('general.user_editor'),
      icon: <User className={styles.Icon} />,
      entity: permissionsEntity.USER,
      permissions: [PermissionsAction.READ],
    },
    {
      href: ROUTES.DIAGNOSTIC_LIST,
      title: t('assessment.diagnostic'),
      icon: <NoteList className={styles.Icon} />,
      entity: permissionsEntity.ASSESSMENT,
      permissions: [PermissionsAction.READ],
    },
  ];

  useDocumentTitle([t('page_titles.dashboard_navigational')]);

  return (
    <div className={styles.Dashboard}>
      <h3 className={styles.PageTitle}>{t('dashboard.page_title')}</h3>

      <div className={styles.DashboardContent}>
        {links
          .filter((el) => checkPermission(permissions[el.entity as keyof Permissions], el.permissions))
          .map((el) => (
            <Link key={el.href} to={el.href} className={styles.Element}>
              {el.icon}
              <div className={styles.Title}>{el.title}</div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default DashboardNavigational;
