export const findNestedObj = (
  entireObj: object | undefined,
  keyToFind: string,
  valToFind: string | null,
): { id: string; name: string } | undefined => {
  let foundObj;

  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) foundObj = nestedValue;
    return nestedValue;
  });
  return foundObj;
};
