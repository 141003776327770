import classNames from 'classnames';

import LevelGroups from './components/LevelGroups/LevelGroups';
import LevelHeader from './components/LevelHeader/LevelHeader';
import LevelUsers from './components/LevelUsers/LevelUsers';

import { useToggle } from 'hooks/useToggle';

import { GetRolesResponse } from 'store/api/rolesApi/types';

import { findElement } from 'helpers/groupHierarchy';

import { Size } from 'types/enums';
import { IGroupHierarchyItem } from 'types/groupHierarchyTypes';

import styles from './Level.module.scss';

type Props = { data: IGroupHierarchyItem; roles: GetRolesResponse | undefined; index: number };

const Level = ({ roles, index, data }: Props) => {
  const [open, toggle] = useToggle(findElement([data]));
  const size = [Size.Lg, Size.Md, Size.Sm][index] || Size.Sm;

  return (
    <li>
      <LevelHeader size={size} data={data} roles={roles} open={open} toggle={toggle} />
      <ul
        className={classNames(styles.Tree, styles.ListLevel, size && styles[size], {
          [styles.Active]: open,
        })}
      >
        <LevelUsers data={data.users} size={size} />
        <LevelGroups data={data.children} roles={roles} index={index} />
      </ul>
    </li>
  );
};

export default Level;
