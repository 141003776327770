import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@mui/material';
import { TagPicker } from 'rsuite';

import { useCloseOnResize } from 'hooks/useCloseOnResize';

import { hasParentWithClass } from 'helpers/dom';

import { Size } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import DeleteButton from '../../Buttons/DeleteButton/DeleteButton';

import './SelectTags.scss';

type Props = {
  value: string[];
  onChange: ((value: string[], event?: SyntheticEvent<Element, Event>) => void) | undefined;
  isEditPermissions?: boolean;
  isAssessmentPermission?: boolean;
};

const SelectTags = ({ value, onChange, isEditPermissions, isAssessmentPermission }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useCloseOnResize();

  const FULL_PERMISSIONS = [
    { label: t('general.list'), value: PermissionsAction.READ },
    { label: t('general.edit'), value: PermissionsAction.UPDATE },
    { label: t('general.create'), value: PermissionsAction.CREATE },
    { label: t('general.delete'), value: PermissionsAction.DELETE },
  ];

  const EDIT_PERMISSIONS = [
    { label: t('general.create'), value: PermissionsAction.CREATE },
    { label: t('general.edit'), value: PermissionsAction.UPDATE },
  ];

  const ASSESSMENT_PERMISSIONS = [
    { label: t('general.list'), value: PermissionsAction.READ },
    { label: t('general.assign'), value: PermissionsAction.ASSIGN },
    { label: t('general.edit'), value: PermissionsAction.UPDATE },
    { label: t('general.create'), value: PermissionsAction.CREATE },
    { label: t('general.delete'), value: PermissionsAction.DELETE },
  ];

  let permissions = FULL_PERMISSIONS;

  if (isEditPermissions) {
    permissions = EDIT_PERMISSIONS;
  }

  if (isAssessmentPermission) {
    permissions = ASSESSMENT_PERMISSIONS;
  }

  return (
    <div className="select-tags-wrapper">
      <div className="select-tags-header">
        <span>{t('general.possible_actions')}</span>

        {value && value.length > 0 && (
          <DeleteButton
            title={t('general.clear')}
            clear={() => {
              if (onChange) {
                onChange([]);
              }
            }}
            size={Size.Sm}
          />
        )}
      </div>

      <ClickAwayListener
        onClickAway={(event) => {
          if (open) {
            if (hasParentWithClass(event.target as HTMLElement, 'rs-picker-check-menu')) {
              return;
            }
            setOpen(false);
          }
        }}
      >
        <TagPicker
          value={value}
          onChange={onChange}
          data={permissions}
          cleanable={false}
          searchable={false}
          open={open}
          onClick={() => setOpen((prev) => !prev)}
          placeholder={t('general.none')}
          className="custom-tags-select"
          menuClassName="custom-tags-select-menu"
        />
      </ClickAwayListener>
    </div>
  );
};

export default SelectTags;
