import { Dispatch, SetStateAction } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import CustomCheckbox from 'components/DataTable/components/CustomCheckbox/CustomCheckbox';

import { ISection, ISectionItems } from 'types/addAssigneeModalTypes';

import Scrollable from '../../../../Scrollable/Scrollable';

import styles from '../../AddAssigneeModal.module.scss';

type Props = {
  section: ISection;
  setData: Dispatch<SetStateAction<ISection[] | null | undefined>>;
};

const Section = ({ section, setData }: Props) => {
  const stateChecked = Object.keys(section.items).reduce((accumulator: { [key: string]: boolean }, key) => {
    section.items[key].value !== false && (accumulator[key] = section.items[key].value);
    return accumulator;
  }, {});

  const checkedCount = Object.keys(stateChecked).length;
  const textColor = section.value && checkedCount ? 'var(--refined-teal)' : 'var(--dark-grey)';

  const AccordionSummarySx = {
    padding: 0,

    span: {
      color: textColor,
    },

    '.MuiAccordionSummary-content': {
      alignItems: 'center',
      display: 'flex',
      margin: 0,
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
      'svg path': {
        fill: textColor,
      },
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => {
      if (prevState) {
        const index = prevState.findIndex((item) => item.type === section.type);
        const result = [...prevState];

        result[index] = {
          ...prevState[index],
          items: {
            ...prevState[index].items,
            [event.target.name]: { ...prevState[index].items[event.target.name], value: event.target.checked },
          },
        };

        result[index] = {
          ...result[index],
          value: Object.keys(result[index].items).some((item) => result[index].items[item].value),
        };

        return result;
      }
    });
  };

  const handleChangeSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => {
      if (prevState) {
        const index = prevState.findIndex((item) => item.type === section.type);
        const result = [...prevState];

        result[index] = {
          ...prevState[index],
          value: event.target.checked,
          items: Object.keys(result[index].items).reduce((acc: ISectionItems, item) => {
            acc[item as keyof typeof acc] = {
              ...result[index].items[item],
              value: event.target.checked ? true : false,
            };
            return acc;
          }, {}),
        };

        return result;
      }
    });
  };

  return (
    <Accordion sx={AccordionSx} disableGutters elevation={0}>
      <AccordionSummary
        sx={AccordionSummarySx}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CustomCheckbox
          checked={section.value && Boolean(checkedCount)}
          onChange={(event) => handleChangeSection(event)}
          onClick={(event) => event.stopPropagation()}
        />
        <span className={styles.Title}>{section.title}</span>
        {checkedCount ? <span className={styles.Count}>{checkedCount}</span> : null}
      </AccordionSummary>
      <AccordionDetails sx={AccordionDetailsSx}>
        <Scrollable>
          <FormGroup>
            {Object.keys(section.items).map((item, index) => (
              <FormControlLabel
                key={index}
                sx={FormControlLabelSx}
                control={
                  <CustomCheckbox
                    checked={section.items[item as keyof typeof section.items].value}
                    onChange={handleChange}
                    name={item}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </Scrollable>
      </AccordionDetails>
    </Accordion>
  );
};

const AccordionSx = {
  overflow: 'hidden',

  '&:before': {
    display: 'none',
  },
};

const AccordionDetailsSx = {
  padding: 0,
  paddingBottom: '16px',
};

const FormControlLabelSx = {
  margin: '0',
  padding: '6px 2px',
  border: '1px solid var(--cool-grey)',
  borderRadius: '6px',
  width: '100%',

  '&:not(:last-child)': {
    marginBottom: '4px',
  },

  '.MuiFormControlLabel-label': {
    fontSize: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  ':has(span.Mui-checked)': {
    border: '1px solid var(--refined-teal)',
    background: 'var(--light-teal)',
    color: 'var(--refined-teal)',
  },
};

export default Section;
