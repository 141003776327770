import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { PickerHandle, SelectPicker } from 'rsuite';

import { GroupHierarchyType } from 'types/groupHierarchyTypes';
import { IGroupNavigation } from 'types/navigationTypes';

import { ReactComponent as ArrowIcon } from 'assets/images/header-arrow.svg';

import styles from './Level.module.scss';

type Props = {
  levelIndex: number;
  level: IGroupNavigation;
  onSelectLevel: (value: string, levelIndex: number) => void;
  showArrow?: boolean;
  disabled?: boolean;
  nested?: boolean;
};

const Level = ({ levelIndex, level, onSelectLevel, showArrow, disabled, nested }: Props) => {
  const { t } = useTranslation();

  const selectPickerRef = useRef<PickerHandle>(null);
  const [selectPickerWidth, setSelectPickerWidth] = useState<number>(0);

  useLayoutEffect(() => {
    const width = selectPickerRef?.current?.target?.offsetWidth;
    if (width) {
      setSelectPickerWidth(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPickerRef.current]);

  return (
    <>
      <SelectPicker
        ref={selectPickerRef}
        cleanable={false}
        searchable={false}
        disabled={disabled}
        placeholder={t('general.select')}
        value={level.selectedValue}
        data={level.data}
        onSelect={(value) => onSelectLevel(value, levelIndex)}
        className={classNames(!nested && styles.SelectPickerContainer)}
        menuClassName={styles.MenuContainer}
        renderMenuItem={(label, item) => {
          return (
            <div
              className={classNames(styles.OptionContainer, {
                [styles.Group]: item.type === GroupHierarchyType.Group || item.type === GroupHierarchyType.Subgroup,
              })}
            >
              <span className={styles.Label}>{label}</span>
            </div>
          );
        }}
        menuStyle={{ maxWidth: selectPickerWidth, minWidth: selectPickerWidth }}
      />
      {showArrow && <ArrowIcon />}
    </>
  );
};

export default Level;
