import React from 'react';

const ArrowSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.2845 5.09489C12.2849 5.2896 12.2171 5.47829 12.0929 5.62822C12.0229 5.7126 11.937 5.78235 11.84 5.83348C11.7431 5.88461 11.637 5.9161 11.5278 5.92617C11.4187 5.93623 11.3086 5.92466 11.204 5.89213C11.0993 5.85959 11.0021 5.80672 10.9179 5.73655L6.4512 2.00322L1.9762 5.60322C1.89096 5.67244 1.79288 5.72414 1.6876 5.75533C1.58232 5.78652 1.47191 5.7966 1.36272 5.78498C1.25353 5.77337 1.14771 5.74029 1.05135 5.68764C0.954982 5.635 0.869973 5.56383 0.801204 5.47822C0.72534 5.392 0.668117 5.29103 0.633126 5.18164C0.598135 5.07226 0.586132 4.95682 0.59787 4.84258C0.609607 4.72833 0.644832 4.61775 0.701333 4.51776C0.757835 4.41778 0.834393 4.33055 0.926204 4.26155L5.9262 0.236554C6.07531 0.113986 6.26235 0.0469806 6.45537 0.0469806C6.64839 0.0469806 6.83543 0.113986 6.98454 0.236554L11.9845 4.40322C12.0854 4.48682 12.1651 4.59301 12.2172 4.71319C12.2694 4.83336 12.2924 4.96413 12.2845 5.09489Z"
      fill="#292D32"
    />
    <path
      d="M12.2845 5.09489C12.2849 5.2896 12.2171 5.47829 12.0929 5.62822C12.0229 5.7126 11.937 5.78235 11.84 5.83348C11.7431 5.88461 11.637 5.9161 11.5278 5.92617C11.4187 5.93623 11.3086 5.92466 11.204 5.89213C11.0993 5.85959 11.0021 5.80672 10.9179 5.73655L6.4512 2.00322L1.9762 5.60322C1.89096 5.67244 1.79288 5.72414 1.6876 5.75533C1.58232 5.78652 1.47191 5.7966 1.36272 5.78498C1.25353 5.77337 1.14771 5.74029 1.05135 5.68764C0.954982 5.635 0.869973 5.56383 0.801204 5.47822C0.72534 5.392 0.668117 5.29103 0.633126 5.18164C0.598135 5.07226 0.586132 4.95682 0.59787 4.84258C0.609607 4.72833 0.644832 4.61775 0.701333 4.51776C0.757835 4.41778 0.834393 4.33055 0.926204 4.26155L5.9262 0.236554C6.07531 0.113986 6.26235 0.0469806 6.45537 0.0469806C6.64839 0.0469806 6.83543 0.113986 6.98454 0.236554L11.9845 4.40322C12.0854 4.48682 12.1651 4.59301 12.2172 4.71319C12.2694 4.83336 12.2924 4.96413 12.2845 5.09489Z"
      fill="#1899A2"
    />
  </svg>
);
export default ArrowSVG;
