import LevelUser from './components/LevelUser/LevelUser';

import { Size } from 'types/enums';
import { IGroupsHierarchyUser } from 'types/groupHierarchyTypes';

type Props = {
  data: IGroupsHierarchyUser[];
  size?: Size;
};

const LevelUsers = ({ data, size = Size.Lg }: Props) => {
  return <>{data && data.length > 0 && data.map((user) => <LevelUser key={user.id} data={user} size={size} />)}</>;
};

export default LevelUsers;
