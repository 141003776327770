import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputLabel from 'components/Inputs/InputLabel/InputLabel';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

import { serializeToChars } from 'helpers/slate';

import { MAX_LONG_TEXT_ENTRY_LENGTH } from 'constants/';

import { LongTextEntryTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

type Props = {
  element: LongTextEntryTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  onChangeAnswer: Dispatch<SetStateAction<string>>;
};

const LongTextEntry = ({ element, loading, onNext, onPrev, onChangeAnswer }: Props) => {
  const { i18n, t } = useTranslation();

  const [showError, setShowError] = useState(false);
  const editorCharsLength = element.answer && serializeToChars(JSON.parse(element.answer)).length;

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{
        loading,
        onNext,
        onPrev,
        disabled: !element.answer || !editorCharsLength || editorCharsLength > MAX_LONG_TEXT_ENTRY_LENGTH,
      }}
    >
      {(showError || Boolean(editorCharsLength && editorCharsLength > MAX_LONG_TEXT_ENTRY_LENGTH)) && (
        <InputLabel errorMessage={t('validation.long_text_max_input')} />
      )}
      <RichTextEditor
        setValue={onChangeAnswer}
        value={element.answer || ''}
        maxLength={MAX_LONG_TEXT_ENTRY_LENGTH}
        showError={showError}
        setShowError={setShowError}
      />
    </ElementWrapper>
  );
};

export default LongTextEntry;
