import { MouseEventHandler, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import CustomCheckbox from 'components/DataTable/components/CustomCheckbox/CustomCheckbox';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';

import ButtonStyled from '../ButtonStyled/ButtonStyled';

import styles from './CheckboxButton.module.scss';

type Props = {
  checked: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
};

const CheckboxButton = ({ checked, onClick, children }: Props) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  return (
    <div className={styles.CheckBoxButton}>
      <ButtonStyled
        onClick={onClick}
        fill={ButtonFill.Transparent}
        size={isMobile ? ButtonSize.Link : ButtonSize.Small}
        icon={<CustomCheckbox checked={checked} />}
      >
        {children}
      </ButtonStyled>
    </div>
  );
};

export default CheckboxButton;
