import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Form, Radio, RadioGroup } from 'rsuite';

import { useDeleteUserMutation, useUpdateUserMutation } from 'store/api/usersApi/usersApi';

import { DeleteType } from 'types/enums';
import { ModalProps } from 'types/modalTypes';

import ModalWrapper from '../ModalWrapper';

import styles from './DeleteUserModal.module.scss';

interface Props extends ModalProps {
  change: Dispatch<SetStateAction<DeleteType>>;
  deleteType: DeleteType;
  selectedUserId?: string;
}

const DeleteUserModal = ({ deleteType, selectedUserId, change, ...props }: Props) => {
  const { t } = useTranslation();
  const [deleteUser, { isLoading: deleting }] = useDeleteUserMutation();
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();

  const deleteActions: {
    [key in DeleteType]: (selectedUserId: string) => void;
  } = {
    hard: (selectedUserId: string) => {
      deleteUser(selectedUserId)
        .unwrap()
        .then(() => toast.success(t('modals.user_removed')))
        .catch(() => toast.error(t('general.error')));
    },
    soft: (selectedUserId: string) => {
      updateUser({
        id: selectedUserId,
        user: { is_active: false },
      })
        .unwrap()
        .then(() => toast.success(t('modals.user_deactivated')))
        .catch(() => toast.error(t('general.error')));
    },
  };

  const handleAction = () => {
    if (selectedUserId) {
      deleteActions[deleteType](selectedUserId);
    }
    if (props.close) {
      props.close();
    }
  };

  return (
    <ModalWrapper
      status={deleting || updating}
      action={handleAction}
      title={t('users.modal.title')}
      subtitle={t('users.modal.subtitle')}
      {...props}
    >
      <Form.Group controlId="radioList">
        <RadioGroup name="radioList" value={deleteType} onChange={(value) => change(value as DeleteType)}>
          <Radio value={DeleteType.Soft} className={styles.Checkbox}>
            {t('users.modal.soft')}
          </Radio>
          <Radio value={DeleteType.Hard} className={styles.Checkbox}>
            {t('users.modal.hard')}
          </Radio>
        </RadioGroup>
      </Form.Group>
    </ModalWrapper>
  );
};

export default DeleteUserModal;
