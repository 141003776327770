import { useTranslation } from 'react-i18next';

import { WelcomePageTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import { ReactComponent as WelcomeIcon } from 'assets/images/welcomepage.svg';

import IntroWrapper from '../IntroWrapper/IntroWrapper';

type Props = {
  element: WelcomePageTakeElement;
  isStartAssessmentLoading: boolean;
  isAssessmentLoading: boolean;
  image: string | undefined;
  onNext: () => void;
};

const WelcomePage = ({ element, image, isStartAssessmentLoading, isAssessmentLoading, onNext }: Props) => {
  const { i18n } = useTranslation();

  return (
    <IntroWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      image={image}
      icon={<WelcomeIcon />}
      isAssessmentLoading={isAssessmentLoading}
      footerProps={{ onNext, loading: isStartAssessmentLoading, welcome: true }}
    />
  );
};

export default WelcomePage;
