import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { AuthState, CurrentUser } from 'types/userTypes';

const initialState = {
  user: null,
  isAuth: false,
  loading: false,
  shouldBeRedirected: false,
  isRedirectLogin: false,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoadingUser: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setAuth: (state, action: PayloadAction<CurrentUser>) => {
      state.isAuth = true;
      state.loading = false;
      state.user = action.payload;
      state.isRedirectLogin = false;
    },

    setAuthError: (state) => {
      state.isAuth = false;
      state.loading = false;
    },

    signOut: (state) => {
      state.user = null;
      state.isAuth = false;
      state.loading = false;
      state.isRedirectLogin = true;
    },

    setShouldBeRedirected: (state, action: PayloadAction<boolean>) => {
      state.shouldBeRedirected = action.payload;
    },
  },
});

export const { signOut, setAuth, setAuthError, setLoadingUser, setShouldBeRedirected } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
