import { useState } from 'react';

import { SortTypes } from 'types/enums';

const useTableControl = (sortValue: string) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ sortBy: sortValue, sortType: SortTypes.ASC });

  const handleChangePage = (page: number) => setPage(page);

  const handleChangeSize = (size: number) => setSize(size);

  const handleSearch = (data: string) => {
    setSearch((prev) => {
      if (prev.trim() === '' && page !== 1) {
        handleChangePage(1);
      }
      return data;
    });
  };

  const handleSort = () => {
    if (sort.sortType === SortTypes.ASC) {
      handleChangePage(1);
      setSort({ sortType: SortTypes.DESC, sortBy: `-${sortValue}` });
    } else if (sort.sortType === SortTypes.DESC) {
      handleChangePage(1);
      setSort({ sortType: SortTypes.ASC, sortBy: sortValue });
    }
  };

  return {
    page,
    size,
    sort,
    search,
    onPageChange: handleChangePage,
    onSizeChange: handleChangeSize,
    onSearching: handleSearch,
    onSorting: handleSort,
  } as const;
};

export default useTableControl;
