import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

import { ButtonFill, ButtonSize } from 'types/enums';
import { IContextItem } from 'types/resultsContext';
import { Translations } from 'types/translationTypes';

import styles from '../../Context.module.scss';

type Props = {
  question: IContextItem | null;
  inModal?: boolean;
  openDetails?: (context: IContextItem) => void;
};

const ContextItem = ({ question, inModal, openDetails }: Props) => {
  const { t, i18n } = useTranslation();

  const handleOpenDetails = (): void => {
    openDetails && openDetails(question!);
  };

  let parsedAnswer;

  try {
    parsedAnswer = JSON.parse(question?.answer || '');
  } catch (e) {
    parsedAnswer = question?.answer || '';
  }

  const isRichText = typeof parsedAnswer === 'object';

  return (
    <div className={styles.ContextItemWrapper}>
      <span className={styles.Title}>{t('dashboard.context.question')}</span>
      <span className={styles.Question}>{question?.question[`text_${i18n.language}` as keyof Translations]}</span>
      <h3 className={styles.Title}>{t('dashboard.context.answer')}</h3>
      <div className={classNames(styles.Answer, { [styles.Short]: !inModal })}>
        {isRichText ? <RichTextEditor value={question?.answer || ''} renderOnly /> : question?.answer}
      </div>

      {!inModal && (
        <div className={styles.ItemButton}>
          <ButtonStyled fullWidth size={ButtonSize.Small} fill={ButtonFill.Outlined} onClick={handleOpenDetails}>
            {t('general.details')}
          </ButtonStyled>
        </div>
      )}
    </div>
  );
};

export default ContextItem;
