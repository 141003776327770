import { TFunction } from 'i18next';

import ROUTES from 'router/routes';

import { BaseOptionElement, CARD_TYPES } from 'types/createAssessmentTypes';

import {
  DropdownSVG,
  InfoSVG,
  LikertSVG,
  LongTextSVG,
  MultipleChoiceSVG,
  QuestionSlider,
  ShortTextSVG,
  SingleChoiceSVG,
  ThankYouPageSVG,
  WelcomePageSVG,
} from 'assets/svg/create-assesment/sidebar';

export const getBreadcrumbs = (t: TFunction, edit: boolean) => [
  {
    name: t('assessment.diagnostic'),
    href: ROUTES.DIAGNOSTIC_LIST,
  },
  {
    name: edit ? t('assessment.edit_diagnostic') : t('assessment.create_diagnostic'),
    href: '',
    active: true,
  },
];

export enum AssessmentTab {
  Build,
  Settings,
}

export const getMenu = (t: TFunction) => [
  {
    title: t('assessment.card_names.welcome_page'),
    type: CARD_TYPES.welcome_page,
    icon: <WelcomePageSVG />,
  },
  {
    title: t('assessment.card_names.thank_you_page'),
    type: CARD_TYPES.thankyou_page,
    icon: <ThankYouPageSVG />,
  },
  {
    title: t('assessment.card_names.information'),
    type: CARD_TYPES.information,
    icon: <InfoSVG />,
  },
  {
    title: t('assessment.card_names.short_text_entry'),
    type: CARD_TYPES.short_text,
    icon: <ShortTextSVG />,
  },
  {
    title: t('assessment.card_names.long_text_entry'),
    type: CARD_TYPES.long_text,
    icon: <LongTextSVG />,
  },
  {
    title: t('assessment.card_names.dropdown'),
    type: CARD_TYPES.dropdown,
    icon: <DropdownSVG />,
  },
  {
    title: t('assessment.card_names.single_choice'),
    type: CARD_TYPES.single_choice,
    icon: <SingleChoiceSVG />,
  },
  {
    title: t('assessment.card_names.multiple_choice'),
    type: CARD_TYPES.multiple_choice,
    icon: <MultipleChoiceSVG />,
  },
  {
    title: t('assessment.card_names.question_slider'),
    type: CARD_TYPES.question_slider,
    icon: <QuestionSlider />,
  },
  {
    title: t('assessment.card_names.likert'),
    type: CARD_TYPES.likert,
    icon: <LikertSVG />,
  },
];

export interface IDefaultCardValues {
  [key: string]: {
    weight?: string;
    children?: [];
    calculation_type?: string;
    text_en?: string;
    description_en?: string;
    text_es?: string;
    description_es?: string;
    min_value?: number;
    max_value?: number;
    options?: BaseOptionElement[];
    leftColumn?: BaseOptionElement[];
    rightColumn?: BaseOptionElement[];
  };
}

export const defaultCardValues: IDefaultCardValues = {
  [CARD_TYPES.module]: {
    weight: '1.0',
    children: [],
    calculation_type: 'average',
  },

  [CARD_TYPES.section]: {
    weight: '1.0',
    children: [],
    calculation_type: 'average',
  },

  [CARD_TYPES.dropdown]: {
    weight: '1.0',
    options: [
      {
        text_en: 'Option 1',
        text_es: 'Opción 1',
        score: '1.0',
        order: 1,
      },
      {
        text_en: 'Option 2',
        text_es: 'Opción 2',
        score: '1.0',
        order: 2,
      },
    ],
  },

  [CARD_TYPES.likert]: {
    weight: '1.0',
    options: [
      {
        text_en: 'Strongly disagree',
        text_es: 'Totalmente en desacuerdo',
        score: '1.0',
        order: 1,
      },
      {
        text_en: 'Disagree',
        text_es: 'En desacuerdo2',
        score: '1.0',
        order: 2,
      },
      {
        text_en: 'Somewhat disagree',
        text_es: 'Algo en desacuerdo',
        score: '1.0',
        order: 3,
      },
      {
        text_en: 'Neither agree nor disagree',
        text_es: 'Ni de acuerdo ni en desacuerdo',
        score: '1.0',
        order: 4,
      },
      {
        text_en: 'Somewhat agree',
        text_es: 'Algo de acuerdo',
        score: '1.0',
        order: 5,
      },
      {
        text_en: 'Agree',
        text_es: 'De acuerdo',
        score: '1.0',
        order: 6,
      },
      {
        text_en: 'Strongly agree',
        text_es: 'Totalmente de acuerdo',
        score: '1.0',
        order: 7,
      },
    ],
  },

  [CARD_TYPES.short_text]: {},

  [CARD_TYPES.information]: {},

  [CARD_TYPES.long_text]: {},

  [CARD_TYPES.thankyou_page]: {
    text_en: 'Add thank you text',
    description_en: 'Add thank you description',
    text_es: 'Añadir texto de agradecimiento',
    description_es: 'Agregar descripción de agradecimiento',
  },

  [CARD_TYPES.welcome_page]: {
    text_en: 'Add welcome text',
    description_en: 'Add welcome description',
    text_es: 'Agregar texto de bienvenida',
    description_es: 'Agregar descripción de bienvenida',
  },

  [CARD_TYPES.question_slider]: {
    weight: '1.0',
    min_value: 0,
    max_value: 0,
  },

  [CARD_TYPES.multiple_choice]: {
    weight: '1.0',

    leftColumn: [
      {
        text_en: 'Option 1',
        text_es: 'Opción 1',
        score: '1.0',
        order: 1,
      },
    ],
    rightColumn: [
      {
        text_en: 'Option 2',
        text_es: 'Opción 2',
        score: '1.0',
        order: 1,
      },
    ],
  },

  [CARD_TYPES.single_choice]: {
    weight: '1.0',
    leftColumn: [
      {
        text_en: 'Option 1',
        text_es: 'Opción 1',
        score: '1.0',
        order: 1,
      },
    ],
    rightColumn: [
      {
        text_en: 'Option 2',
        text_es: 'Opción 2',
        score: '1.0',
        order: 1,
      },
    ],
  },
};

export const cardTypesAcceptedByModule = [
  CARD_TYPES.dropdown,
  CARD_TYPES.likert,
  CARD_TYPES.long_text,
  CARD_TYPES.multiple_choice,
  CARD_TYPES.question_slider,
  CARD_TYPES.short_text,
  CARD_TYPES.single_choice,
  CARD_TYPES.information,
];

export const cardTypesAcceptedBySection = [CARD_TYPES.section, CARD_TYPES.module];
