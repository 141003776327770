import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { addQuestionMapping, deleteQuestionMapping } from 'store/features/createAssessmentSlice';

import { MAX_QUESTION_MAPPING_NUMBER } from 'constants/';

export const useQuestionsMapping = (questionId: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const questionMappings = useAppSelector((state) => state.createAssessment.questionMappings);
  const [errorMessage, setErrorMessage] = useState('');

  const selectedVisualisation = questionMappings?.[questionId]?.visualisationId;

  const handleSelectVisualisation = (visualisation: string) => {
    if (Object.keys(questionMappings).length === MAX_QUESTION_MAPPING_NUMBER && !questionMappings[questionId]) {
      setErrorMessage(t('assessment.validation.max_questions_mapped'));
      return;
    }

    if (visualisation) {
      dispatch(addQuestionMapping({ questionId, visualisationId: visualisation }));
    } else {
      dispatch(deleteQuestionMapping({ questionId }));
    }
    setErrorMessage('');
  };

  return {
    selectedVisualisation,
    handleSelectVisualisation,
    errorMessage,
  };
};
