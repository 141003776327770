import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseAuthQueryWithReauth } from '../../helpers';

import {
  CreateRolePayload,
  CreateRoleResponse,
  DeleteRolePayload,
  DeleteRoleResponse,
  GetAllRolesPermissionsPayload,
  GetAllRolesPermissionsResponse,
  GetRolesPayload,
  GetRolesResponse,
  UpdateRolePayload,
  UpdateRoleResponse,
} from './types';

export const rolesApi = createApi({
  tagTypes: ['Roles', 'RolePermissions'],
  reducerPath: 'rolesApi',
  baseQuery: baseAuthQueryWithReauth,
  endpoints: (builder) => ({
    getRoles: builder.query<GetRolesResponse, GetRolesPayload>({
      query: (payload) => {
        const { groupId, ...params } = payload || {};
        return {
          url: '/roles/',
          params: { ...params },
          headers: {
            group_id: groupId,
          },
        };
      },
      transformResponse: (response: GetRolesResponse) => ({
        ...response,
        items: response.items.map((item, i) => ({ ...item, fieldId: i + 1 })),
      }),
      providesTags: () => ['Roles'],
    }),

    getRoleById: builder.query({
      query: (id) => `/roles/${id}/`,
    }),

    getAllRolesPermissions: builder.query<GetAllRolesPermissionsResponse, GetAllRolesPermissionsPayload>({
      query: (params) => ({ url: '/roles/permissions/', params }),
      providesTags: () => ['RolePermissions'],
    }),

    createRole: builder.mutation<CreateRoleResponse, CreateRolePayload>({
      query: (role) => ({
        url: '/roles/',
        method: 'POST',
        body: role,
      }),
      invalidatesTags: ['Roles'],
    }),

    updateRole: builder.mutation<UpdateRoleResponse, UpdateRolePayload>({
      query: ({ id, role }) => ({
        url: `/roles/${id}/`,
        method: 'PUT',
        body: role,
      }),
      invalidatesTags: ['Roles'],
    }),

    deleteRole: builder.mutation<DeleteRoleResponse, DeleteRolePayload>({
      query: (id) => ({
        url: `/roles/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles'],
    }),
  }),
});

export const { useGetRolesQuery, useGetAllRolesPermissionsQuery, useGetRoleByIdQuery } = rolesApi;

export const { useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation } = rolesApi;
