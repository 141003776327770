import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Scrollable from 'components/Scrollable/Scrollable';

import { PointUser } from 'types/dashboardTypes';
import { ButtonFill } from 'types/enums';

import styles from './PointUsers.module.scss';

type Props = {
  users: PointUser[];
  onCancel: () => void;
};

const PointUsers = ({ users, onCancel }: Props) => {
  const { t } = useTranslation();

  const [scrollableHeight, setScrollableHeight] = useState(0);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollableContainerRef.current) {
      setScrollableHeight(scrollableContainerRef.current.clientHeight);
    }
  }, [users]);

  return (
    <div className={styles.Container}>
      <p className={styles.Title}>{`${users.length} ${t(users.length === 1 ? 'general.person' : 'general.people')}`}</p>
      <div ref={scrollableContainerRef} className={styles.ScrollableContainer}>
        <Scrollable paddingX={16} maxHeight={scrollableHeight}>
          {users.map(({ id, firstName, lastName }) => (
            <div key={id} className={styles.User}>{`${firstName} ${lastName}`}</div>
          ))}
        </Scrollable>
      </div>
      <div className={styles.ButtonContainer}>
        <ButtonStyled fill={ButtonFill.Outlined} onClick={onCancel} fullWidth>
          {t('general.cancel')}
        </ButtonStyled>
      </div>
    </div>
  );
};

export default PointUsers;
