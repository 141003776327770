import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateSectionMutation, useUpdateSectionMutation } from 'store/api/assessmentApi/assessmentApi';

import { ContainerType } from 'types/createAssessmentTypes';

import Text from '../../Inputs/Text/Text';
import ModalWrapper from '../ModalWrapper';

type FormValue = {
  name: string;
};

type Props = {
  open: boolean;
  type: ContainerType;
  handleClose: () => void;
  edit?: {
    name: string;
    id: string;
  };
};
const CreateOrEditSectionModal = ({ open, handleClose, edit, type }: Props) => {
  const { t } = useTranslation();
  const [createModule, { isLoading: isCreateLoading, reset: createReset }] = useCreateSectionMutation();
  const [updateModule, { isLoading: isEditLoading, reset: editReset }] = useUpdateSectionMutation();
  const { register, handleSubmit, setValue, formState, getFieldState } = useForm<FormValue>({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = ({ name }: FormValue) => {
    if (edit) {
      updateModule({ title: name, section_type: type, id: edit.id })
        .unwrap()
        .then(() => {
          handleClose();
          editReset();
        });
    } else {
      createModule({ title: name, section_type: type })
        .unwrap()
        .then(() => {
          handleClose();
          createReset();
        });
    }
  };

  const labelNameByType = useMemo(
    () =>
      ({
        [ContainerType.Module]: t('assessment.module_name'),
        [ContainerType.Section]: t('assessment.section_name'),
      })[type],
    [t, type],
  );

  const modalName = useMemo(
    () =>
      ({
        [ContainerType.Module]: edit ? t('assessment.edit_module') : t('assessment.create_new_module'),
        [ContainerType.Section]: edit ? t('assessment.edit_section') : t('assessment.create_new_section'),
      })[type],
    [t, type, edit],
  );
  const modalDescription = useMemo(
    () =>
      type === 'module'
        ? edit
          ? t('assessment.edit_module_description')
          : t('assessment.create_new_module_description')
        : type === 'section'
        ? edit
          ? t('assessment.edit_section_description')
          : t('assessment.create_new_section_description')
        : '',
    [edit, t, type],
  );

  useEffect(() => {
    if (edit) {
      setValue('name', edit.name);
    }
  }, [edit, setValue]);

  return (
    <ModalWrapper
      open={open}
      close={handleClose}
      cancelTitle={t('general.cancel')}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.save')}
      title={modalName}
      subtitle={modalDescription}
      status={isCreateLoading || isEditLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          label={labelNameByType}
          placeholder={labelNameByType}
          error_message={formState.errors?.name?.message}
          error={getFieldState('name', formState)}
          register={register('name', {
            required: { value: true, message: t('assessment.module_validation_message') },
            minLength: { value: 3, message: t('assessment.module_validation_message') },
            maxLength: { value: 140, message: t('assessment.module_validation_message') },
          })}
        />
      </form>
    </ModalWrapper>
  );
};

export default CreateOrEditSectionModal;
