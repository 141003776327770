import classNames from 'classnames';

import styles from '../Inputs.module.scss';

type Props = {
  label?: string;
  required?: boolean;
  errorMessage?: string;
  labelClassNames?: {
    InputLabels?: string;
    InputLabel?: string;
  };
};

const InputLabel = ({ errorMessage, label, required, labelClassNames }: Props) => {
  return (
    <>
      {(errorMessage || label) && (
        <div className={styles.LabelContainer}>
          {label && (
            <div className={classNames(styles.InputLabels, [labelClassNames?.InputLabels])}>
              <label className={classNames(styles.InputLabel, labelClassNames?.InputLabel)}>
                {label} {required && <span className={styles.Required}>*</span>}
              </label>
            </div>
          )}
          {errorMessage && (
            <div className={styles.ErrorMessage}>
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InputLabel;
