import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import SelectTags from 'components/Inputs/SelectTags/SelectTags';

import ROUTES from 'router/routes';

import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';
import { Permissions, permissionsEntity } from 'types/permissionsTypes';
import { RoleBulkTransformed } from 'types/roleTypes';

import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from './RoleElement.module.scss';

type RoleElementProps = {
  role: RoleBulkTransformed;
  onUpdateRolePermissions: (roleId: string, permissions: Permissions) => void;
};

const RoleElement = ({ role, onUpdateRolePermissions }: RoleElementProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const updateRolePermission = (permissions: string[], entity: keyof Permissions): void => {
    onUpdateRolePermissions(role.role_id, { ...role.permissions, [entity]: permissions });
  };

  return (
    <>
      <div className={styles.Title}>
        {role.role_name}

        <ButtonStyled
          onClick={() => navigate(ROUTES.ROLE_EDIT(role.role_id), { state: { id: role.role_id } })}
          fill={ButtonFill.Transparent}
          size={ButtonSize.Small}
          icon={<Edit />}
        >
          {t('general.edit')}
        </ButtonStyled>
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.role}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.ROLE)}
        />
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.group}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.GROUP)}
        />
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.user}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.USER)}
        />
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.assessment}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.ASSESSMENT)}
          isAssessmentPermission
        />
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.section}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.SECTION)}
          isEditPermissions
        />
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.module}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.MODULE)}
          isEditPermissions
        />
      </div>

      <div className={styles.Permissions}>
        <SelectTags
          value={role.permissions.unit}
          onChange={(value: string[]) => updateRolePermission(value, permissionsEntity.UNIT)}
          isEditPermissions
        />
      </div>

      <div className={classNames(styles.Permissions, styles.Checkboxes)}>
        <Checkbox
          checked={role.permissions.own_results[0] === PermissionsAction.READ}
          onChange={() =>
            updateRolePermission(
              role.permissions.own_results[0] ? [] : [PermissionsAction.READ],
              permissionsEntity.OWN_RESULTS,
            )
          }
        >
          {t('roles.own_results')}
        </Checkbox>

        <Checkbox
          checked={role.permissions.own_comparison[0] === PermissionsAction.READ}
          onChange={() =>
            updateRolePermission(
              role.permissions.own_comparison[0] ? [] : [PermissionsAction.READ],
              permissionsEntity.OWN_COMPARISON,
            )
          }
        >
          {t('roles.own_comparison')}
        </Checkbox>

        <Checkbox
          checked={role.permissions.other_users_dashboard_comparison[0] === PermissionsAction.READ}
          onChange={() =>
            updateRolePermission(
              role.permissions.other_users_dashboard_comparison[0] ? [] : [PermissionsAction.READ],
              permissionsEntity.OTHER_USERS_DASHBOARD_COMPARISON,
            )
          }
        >
          {t('roles.other_users_dashboard_comparison')}
        </Checkbox>

        <Checkbox
          checked={role.permissions.group_dashboard_comparison[0] === PermissionsAction.READ}
          onChange={() =>
            updateRolePermission(
              role.permissions.group_dashboard_comparison[0] ? [] : [PermissionsAction.READ],
              permissionsEntity.GROUP_DASHBOARD_COMPARISON,
            )
          }
        >
          {t('roles.group_dashboard_comparison')}
        </Checkbox>

        <Checkbox
          checked={role.permissions.additional_field[0] === PermissionsAction.UPDATE}
          onChange={() =>
            updateRolePermission(
              role.permissions.additional_field[0] ? [] : [PermissionsAction.UPDATE],
              permissionsEntity.ADDITIONAL_FIELD,
            )
          }
        >
          {t('roles.additional_field')}
        </Checkbox>

        <Checkbox
          checked={role.permissions.groups_users_report[0] === PermissionsAction.READ}
          onChange={() =>
            updateRolePermission(
              role.permissions.groups_users_report[0] ? [] : [PermissionsAction.READ],
              permissionsEntity.GROUPS_USERS_REPORT,
            )
          }
        >
          {t('roles.groups_users_report')}
        </Checkbox>

        <Checkbox
          checked={role.permissions.activity_report[0] === PermissionsAction.READ}
          onChange={() =>
            updateRolePermission(
              role.permissions.activity_report[0] ? [] : [PermissionsAction.READ],
              permissionsEntity.ACTIVITY_REPORT,
            )
          }
        >
          {t('roles.activity_report')}
        </Checkbox>
      </div>
    </>
  );
};

export default RoleElement;
