import React from 'react';

const PlusSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.5 0.75C6.5 0.335786 6.16421 0 5.75 0C5.33579 0 5 0.335786 5 0.75V5H0.75C0.335786 5 0 5.33579 0 5.75C0 6.16421 0.335786 6.5 0.75 6.5H5V10.75C5 11.1642 5.33579 11.5 5.75 11.5C6.16421 11.5 6.5 11.1642 6.5 10.75V6.5H10.75C11.1642 6.5 11.5 6.16421 11.5 5.75C11.5 5.33579 11.1642 5 10.75 5H6.5V0.75Z"
        fill="#1899A2"
      />
    </svg>
  );
};

export default PlusSvg;
