import classNames from 'classnames';

import { LikertBoardElement } from 'types/boardElements';
import {
  ChoiceType,
  ColumnType,
  OnElementDelete,
  SingleColumnOptionAdd,
  SingleColumnOptionChange,
  SingleColumnOptionDelete,
  TitleFieldsChange,
} from 'types/createAssessmentTypes';

import AddOption from '../../AddOption/AddOption';
import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';
import ChoiceOption from '../ChoiceOption/ChoiceOption';

import styles from '../BoardElements.module.scss';

type LikertProps = {
  element: LikertBoardElement;
  onTitleFieldsChange: TitleFieldsChange;
  onDropdownOptionAdd: SingleColumnOptionAdd;
  onDropdownOptionChange: SingleColumnOptionChange;
  onDropdownOptionDelete: SingleColumnOptionDelete;
  onElementDelete: OnElementDelete;
};
const Likert = ({
  element,
  onTitleFieldsChange,
  onDropdownOptionAdd,
  onDropdownOptionChange,
  onDropdownOptionDelete,
  onElementDelete,
}: LikertProps) => {
  const isLastOption = element.options.length === 1;

  return (
    <div className={classNames(styles.CardContainer, styles.ShortTextEntry)}>
      <div className={styles.InnerBody}>
        <CardTitleBlock onDelete={onElementDelete} element={element} onTitleChange={onTitleFieldsChange} />

        <div className={styles.ChoiceBlock}>
          <div>
            <div>
              {element.options.map((option, index) => (
                <ChoiceOption
                  key={index}
                  option={option}
                  columnType={ColumnType.Left}
                  isDeleteShown={!isLastOption}
                  choiceType={ChoiceType.Single}
                  onChange={(field, value) => onDropdownOptionChange(element.id, index, field, value)}
                  onDelete={() => onDropdownOptionDelete(element.id, index)}
                />
              ))}
            </div>

            <div className={styles.AddOptionBlock}>
              <AddOption onClick={() => onDropdownOptionAdd(element.id)} />
            </div>
          </div>
        </div>
      </div>

      <BottomCardNavPanel
        weight={element.weight}
        onChange={(weight) => onTitleFieldsChange(element.id, 'weight', weight)}
      />
    </div>
  );
};

export default Likert;
