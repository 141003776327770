import styles from './ChartTooltip.module.scss';

export type Props = {
  id?: string;
};

const ChartTooltipOutlet = ({ id = 'default-chart-tooltip' }: Props) => {
  return <div className={styles.OutletContainer} aria-label="chart" id={id} />;
};

export default ChartTooltipOutlet;
