import isEqual from 'lodash/isEqual';

import { Permissions, PermissionsAction, permissionsEntity } from 'types/permissionsTypes';

export const checkPermission = (entity: string[], values: PermissionsAction[]): boolean =>
  values.some((item) => entity.includes(item));

export const checkPermissionsStrictEquality = (
  allPermissions: Permissions,
  necessaryPermissions: Partial<Permissions>,
) =>
  Object.keys(allPermissions).every((permission) =>
    isEqual(
      [...(necessaryPermissions[permission as permissionsEntity] || [])].sort(),
      [...allPermissions[permission as permissionsEntity]].sort(),
    ),
  );

export const getIsIndividualUser = (permissions: Permissions) => {
  return checkPermissionsStrictEquality(permissions, {
    [permissionsEntity.ASSESSMENT]: [PermissionsAction.READ],
    [permissionsEntity.OWN_COMPARISON]: [PermissionsAction.READ],
    [permissionsEntity.OWN_RESULTS]: [PermissionsAction.READ],
  });
};
