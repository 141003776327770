import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { BlockNames, CardVariants, HandleChangeRadioValueType, IRadioValues, MenuOptions } from 'types/assessmentTypes';

import Block from '../Block/Block';

import styles from '../../AssessmentCompletion.module.scss';

type Props = {
  order: number;
  radioValues: IRadioValues;
  hidden?: boolean;
  variant?: CardVariants;
  handleChangeRadioValue: HandleChangeRadioValueType;
  menuOption?: MenuOptions;
};

const SelectOwnCard = ({ order, hidden, variant, radioValues, handleChangeRadioValue, menuOption }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.Container, {
        [styles.Hidden]: hidden,
      })}
    >
      <Block
        hideSearch
        title={t('assessment.date')}
        order={order}
        name={BlockNames.AssessmentTake}
        hidden={hidden}
        variant={variant}
        radioValues={radioValues}
        handleChangeRadioValue={handleChangeRadioValue}
        menuOption={menuOption}
      />
    </div>
  );
};

export default SelectOwnCard;
