import { useAppDispatch } from 'hooks/redux';

import { setSectionsData } from 'store/features/createAssessmentSlice';

import { Element, SectionBoardElement } from 'types/boardElements';
import { CARD_TYPES, IMultipleSelectOption, ISelectOption } from 'types/createAssessmentTypes';

export const useSectionsBuilder = () => {
  const dispatch = useAppDispatch();

  const createSectionOptions = (elements: Element[]) => {
    const buildSectionTree = (parentId: string): IMultipleSelectOption[] => {
      const sections = elements.filter((item) => item.parentId === parentId && item.type === CARD_TYPES.section);

      return (sections as SectionBoardElement[]).map((section) => {
        const { id, name } = section;
        const children = buildSectionTree(id);

        return {
          value: id,
          label: name,
          children: children.length > 0 ? children : [],
        } as IMultipleSelectOption;
      });
    };

    const rootSections = elements.filter((item) => item.parentId === 'root' && item.type === CARD_TYPES.section);

    return (rootSections as SectionBoardElement[]).map((rootSection) => {
      const { id, name } = rootSection;
      const children = buildSectionTree(id);

      return {
        value: id,
        label: name,
        children: children.length > 0 ? children : [],
      } as IMultipleSelectOption;
    });
  };

  const buildSectionsData = (elements: Element[]) => {
    const allSections: ISelectOption[] = elements
      .filter((element): element is SectionBoardElement => element.type === CARD_TYPES.section)
      .map((section) => ({
        value: section.id,
        label: section.name,
      }));
    const sectionsOptions: IMultipleSelectOption[] = createSectionOptions(elements);

    dispatch(setSectionsData({ allSections, sectionsOptions }));
  };

  return { buildSectionsData };
};
