import { useTranslation } from 'react-i18next';

import PlusSVG from 'assets/svg/create-assesment/PlusSVG';

import styles from '../BoardElements/BoardElements.module.scss';

const AddOption = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className={styles.AddOption}>
      <PlusSVG />
      <p>{t('general.add_option')}</p>
    </div>
  );
};
export default AddOption;
