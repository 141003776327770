import classNames from 'classnames';

import { useHideScroll } from 'hooks/useHideScroll';

import DashboardSwitch from '../DashboardSwitch/DashboardSwitch';
import HeaderNavigation from '../HeaderNavigation/HeaderNavigation';

import styles from './MobileDropdownControls.module.scss';

type Props = {
  openMobileMenu: boolean;
  showDashboardSwitch: boolean;
  showHeaderNavigation: boolean;
};

const MobileDropdownControls = ({ openMobileMenu, showHeaderNavigation, showDashboardSwitch }: Props) => {
  useHideScroll();

  return (
    <div
      className={classNames(styles.MobileControls, {
        [styles.Active]: openMobileMenu,
      })}
    >
      <div className={styles.OrganizationSelector}>{showHeaderNavigation && <HeaderNavigation />}</div>

      {showDashboardSwitch && (
        <div className={styles.DashboardSwitchContainer}>
          <DashboardSwitch />
        </div>
      )}
    </div>
  );
};

export default MobileDropdownControls;
