import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { ReactComponent as ValidPass } from 'assets/images/validation_pass.svg';

import styles from './Step.module.scss';

type Props = {
  title: string;
  isActive?: boolean;
  isPathCompleted?: boolean;
  isStepCompleted?: boolean;
};

export const Step = ({ title, isActive, isPathCompleted, isStepCompleted }: Props) => {
  const { t } = useTranslation();
  return (
    <li
      className={classNames(styles.Step, {
        [styles.Active]: isActive,
        [styles.PathCompleted]: isPathCompleted,
        [styles.StepCompleted]: isStepCompleted,
      })}
    >
      <div className={styles.TextBox}>
        {isStepCompleted && <ValidPass />}
        <h4 className={styles.Title}>{t(`assessment.steps.${title}.title`)}</h4>
      </div>
      <span className={styles.Point} />
    </li>
  );
};
