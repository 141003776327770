import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Box, Grid } from '@mui/material';

import Label from 'components/Label/Label';
import Loader from 'components/Loader/Loader';

import ComparisonHeaderInfo from '../HeaderInfo/components/ComparisonHeaderInfo/ComparisonHeaderInfo';
import { EcosystemCard } from './components/EcosystemCard/EcosystemCard';

import useGetMappingPerSection from 'hooks/useGetMappingPerSection';

import { MOBILE_BREAKPOINT } from 'constants/';

import { DashboardResult, DashboardType, SelectedGroups, VisualisationTypes } from 'types/dashboardTypes';

import pageStyles from '../../Dashboard.module.scss';
import styles from './Ecosystem.module.scss';

type ComponentWrapperProps = {
  children: ReactNode;
  dashboardType?: DashboardType;
  isComparisonMode?: boolean;
  selectedGroups?: SelectedGroups;
};

const ComponentWrapper = ({ children, dashboardType, isComparisonMode, selectedGroups }: ComponentWrapperProps) => {
  const { t } = useTranslation();

  return (
    <div className={pageStyles.Section}>
      <h3 className={pageStyles.SectionTitle}>{t('dashboard.ecosystem.title')}</h3>

      {(dashboardType === DashboardType.GROUP || (dashboardType === DashboardType.USER && isComparisonMode)) && (
        <div className={pageStyles.SectionLabel}>
          <span>
            {isComparisonMode
              ? t('dashboard.accelerants.comparison_average_responses_for')
              : t('dashboard.accelerants.average_responses_for')}
          </span>
          {isComparisonMode ? (
            <ComparisonHeaderInfo dashboardType={dashboardType} isHideButton />
          ) : (
            selectedGroups?.[0] && <Label label={selectedGroups[0].name} />
          )}
        </div>
      )}

      <div className={pageStyles.SectionContent}>{children}</div>
    </div>
  );
};

type EcosystemProps = {
  requestId1: string;
  requestId2?: string;
  dashboardType?: DashboardType;
  selectedGroups?: SelectedGroups;
};

const Ecosystem = ({
  requestId1,
  requestId2 = '',
  dashboardType = DashboardType.USER,
  selectedGroups,
}: EcosystemProps) => {
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const {
    mappingsCompletion,
    mappingsCompletion2,
    isLoadingCompletion,
    isLoadingCompletion2,
    isFetchingCompletion,
    isFetchingCompletion2,
  } = useGetMappingPerSection(requestId1, requestId2, dashboardType, VisualisationTypes.Ecosystem);

  const [ecosystemLeft, ecosystemRight] = [mappingsCompletion, mappingsCompletion2] as [
    DashboardResult[] | null | undefined,
    DashboardResult[] | null | undefined,
  ];

  const isLoading = isLoadingCompletion || isLoadingCompletion2 || isFetchingCompletion || isFetchingCompletion2;
  const isComparisonMode = !!requestId2;

  if (isLoading) {
    return (
      <ComponentWrapper>
        <Box m={4}>
          <Loader flexCenter size="md" />
        </Box>
      </ComponentWrapper>
    );
  }

  if (!ecosystemLeft) return null;

  if (isComparisonMode) {
    if (!ecosystemRight) return null;

    return (
      <ComponentWrapper
        dashboardType={dashboardType}
        isComparisonMode={isComparisonMode}
        selectedGroups={selectedGroups}
      >
        <Grid container spacing={2}>
          {ecosystemLeft.map((item, index) => (
            <Grid key={item.id} item xs={12}>
              <div className={styles.Card}>
                <EcosystemCard ecosystems={[ecosystemLeft[index], ecosystemRight[index]]} />
              </div>
            </Grid>
          ))}
        </Grid>
      </ComponentWrapper>
    );
  }

  return (
    <ComponentWrapper dashboardType={dashboardType} isComparisonMode={isComparisonMode} selectedGroups={selectedGroups}>
      <Grid container spacing={2}>
        {ecosystemLeft.map((item) => (
          <Grid key={item.id} item xs={isMobile ? 12 : 6}>
            <div className={styles.Card}>
              <EcosystemCard ecosystems={[item]} />
            </div>
          </Grid>
        ))}
      </Grid>
    </ComponentWrapper>
  );
};

export default Ecosystem;
