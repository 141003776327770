import { toast } from 'react-toastify';

import i18n from 'i18next';

export const handleNoPermissionError = (errorStatus: number) => {
  if (errorStatus === 403) {
    toast.error(i18n.t('server.no_permission'));
    return;
  }
};
