import classNames from 'classnames';

import { ActionsItem, MobileCardData, MobileCardType, MobileCardVariant } from 'types/mobileTypes';

import styles from './MobileCard.module.scss';

type Props = {
  data: MobileCardData;
  variant?: MobileCardVariant;
  clickAction?: (() => void) | undefined;
};

const MobileCard = ({ data, variant, clickAction }: Props) => {
  const variantStyles =
    variant &&
    {
      [MobileCardVariant.User]: styles.VariantUser,
      [MobileCardVariant.Organization]: styles.VariantOrganization,
      [MobileCardVariant.Group]: styles.VariantGroup,
      [MobileCardVariant.Subgroup]: styles.VariantSubgroup,
      [MobileCardVariant.Clear]: styles.VariantClear,
      [MobileCardVariant.Selected]: styles.VariantSelected,
      [MobileCardVariant.FullSelected]: styles.FullSelected,
      [MobileCardVariant.Error]: styles.VariantError,
    }[variant];

  return (
    <div
      className={classNames(styles.MobileCard, variantStyles)}
      onClick={clickAction}
      style={clickAction && { cursor: 'pointer' }}
    >
      <div className={styles.Container}>
        {data.map((row) => {
          const isHideActionsRow =
            (row.columns.find((item) => item.type === MobileCardType.Actions) as ActionsItem)?.actions?.length === 0;

          if (isHideActionsRow) {
            return null;
          }

          return (
            <div key={row.key} className={styles.Row}>
              {row.columns.map((column) => {
                if (column.type === MobileCardType.Error) {
                  return (
                    <div key={column.key} className={styles.ErrorContainer}>
                      <span className={styles.Value}>{column.value || '-'}</span>
                    </div>
                  );
                }
                if (column.type === MobileCardType.Info) {
                  return (
                    <div key={column.key} className={styles.InfoContainer}>
                      <span className={styles.Label}>{column.label}: </span>
                      <span className={styles.Value}>{column.value || '-'}</span>
                    </div>
                  );
                }
                if (column.type === MobileCardType.Ordinal) {
                  return (
                    <div key={column.key} className={styles.OrdinalContainer}>
                      <span className={styles.Label}>{column.label || '-'}</span>
                      <span className={styles.Value}>{column.value || '-'}</span>
                    </div>
                  );
                }
                if (column.type === MobileCardType.Actions) {
                  return (
                    <div key={column.key} className={styles.ActionsContainer}>
                      {column.label && <span className={styles.Label}>{column.label}:</span>}
                      <div className={styles.Actions}>{column.actions}</div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileCard;
