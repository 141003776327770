import { createSelector } from 'reselect';

import { RootState } from '../store';

import { Element } from 'types/boardElements';
import { CARD_TYPES } from 'types/createAssessmentTypes';

export const selectWelcomePageAdded = createSelector(
  (state: RootState) => state.createAssessment.boardElements,
  (boardElements: Element[]) => {
    return Boolean(boardElements.find((element) => element.type === CARD_TYPES.welcome_page));
  },
);

export const selectThankYouPageAdded = createSelector(
  (state: RootState) => state.createAssessment.boardElements,
  (boardElements: Element[]) => {
    return Boolean(boardElements.find((element) => element.type === CARD_TYPES.thankyou_page));
  },
);
