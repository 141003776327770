import { createSelector } from 'reselect';

import { RootState } from './../store';

import { IGroupsHierarchyNavigationItem, NormalizedGroupsHierarchy } from 'types/groupHierarchyTypes';

function mapHierarchy(hierarchyItem: IGroupsHierarchyNavigationItem) {
  return {
    children: hierarchyItem.children,
    id: hierarchyItem.id,
    parentId: hierarchyItem.parent_id,
    name: hierarchyItem.name,
    type: hierarchyItem.type,
    path: hierarchyItem.path,
    hasAccess: hierarchyItem.has_access,
  };
}

export const selectorHierarchyNormalized = createSelector(
  (state: RootState) => state.navigation.hierarchy,
  (hierarchy: IGroupsHierarchyNavigationItem[]) => {
    const toNormalizedGroupHierarchy: [
      (normalized: Record<string, NormalizedGroupsHierarchy>, hierarchyItem: IGroupsHierarchyNavigationItem) => {},
      Record<string, NormalizedGroupsHierarchy>,
    ] = [
      (normalized, hierarchyItem) => {
        if (hierarchyItem.children.length) {
          const nestedHierarchy = hierarchyItem.children.reduce(...toNormalizedGroupHierarchy);
          return {
            [hierarchyItem.id]: mapHierarchy(hierarchyItem),
            ...nestedHierarchy,
            ...normalized,
          };
        }
        return {
          [hierarchyItem.id]: mapHierarchy(hierarchyItem),
          ...normalized,
        };
      },
      {},
    ];
    return hierarchy?.reduce(...toNormalizedGroupHierarchy);
  },
);
