import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Loader from 'components/Loader/Loader';
import AddAssigneeModal from 'components/Modals/AddAssigneeModal/AddAssigneeModal';
import AllAssigneesModal from 'components/Modals/AllAssigneesModal/AllAssigneesModal';
import DemoModal from 'components/Modals/DemoModal/DemoModal';
import ModalWrapper from 'components/Modals/ModalWrapper';
import PageTitle from 'components/PageTitle/PageTitle';
import Tags from 'components/Tags/Tags';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useDeleteAssessmentMutation, useGetAssessmentByIdQuery } from 'store/api/assessmentApi/assessmentApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { mapToTag } from 'helpers/mappers/mapToTag';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ITag } from 'types/addAssigneeModalTypes';
import { TagType } from 'types/assessmentTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { GroupHierarchyType } from 'types/groupHierarchyTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Add } from 'assets/images/add.svg';
import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';
import { ReactComponent as NoteList } from 'assets/images/note-list.svg';

import styles from './AssessmentDetails.module.scss';

const AssessmentDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id = '' } = useParams();

  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { assessment } = useAppSelector((state) => state.permissions.permissions);
  const { data, isLoading, isFetching } = useGetAssessmentByIdQuery({ id, groupId: selectedGroupId });
  const { title = '', description = '', creator_full_name = t('general.not_specified') } = data || {};
  const [openDeleteModal, toggleDeleteModal] = useState(false);
  const [openAddAssigneeModal, toggleAddAssigneeModal] = useState(false);
  const [openAllAssigneesModal, toggleAllAssigneesModal] = useState(false);
  const [openDemoModal, toggleDemoModal] = useState(false);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const handleCloseDeleteModal = () => toggleDeleteModal(false);
  const handleCloseAddAssigneeModal = () => toggleAddAssigneeModal(false);
  const handleCloseAllAssigneesModal = () => toggleAllAssigneesModal(false);
  const handleCloseDemoModal = () => toggleDemoModal(false);

  const [deleteAssessment] = useDeleteAssessmentMutation();

  const handleDelete = () => {
    if (id) {
      deleteAssessment(id)
        .unwrap()
        .then(() => {
          handleCloseDeleteModal();
          navigate(ROUTES.DIAGNOSTIC_LIST);
        });
    }
  };
  const handleViewAll = () => toggleAllAssigneesModal(true);

  const unitTags = data?.units.map(({ id, title }) => ({ id, title, type: TagType.Organization }));

  const selectedSections = {
    organizations: data?.groups.filter((item) => item.type === GroupHierarchyType.Organization),
    groups: data?.groups.filter((item) => item.type === GroupHierarchyType.Group),
    subgroups: data?.groups.filter((item) => item.type === GroupHierarchyType.Subgroup),
    users: data?.users,
  };

  const organizationTags = mapToTag(selectedSections.organizations, TagType.Organization);
  const groupTags = mapToTag(selectedSections.groups, TagType.Group);
  const subGroupTags = mapToTag(selectedSections.subgroups, TagType.SubGroup);
  const userTags = mapToTag(selectedSections.users, TagType.Name);

  const tags: ITag[] = [...organizationTags, ...groupTags, ...subGroupTags, ...userTags];

  const handleTryDemoClick = () => {
    toggleDemoModal(true);
  };

  const handleOpenDemo = () => {
    navigate(ROUTES.DIAGNOSTIC_TAKE(id), { state: { isDemo: true } });
  };

  useDocumentTitle([t('page_titles.diagnostic_details')]);

  const getPageTitleAction = () => {
    const actions = [];

    if (!isMobile && checkPermission(assessment, [PermissionsAction.UPDATE])) {
      actions.push(
        <ButtonStyled
          key="edit"
          onClick={() => {
            navigate(ROUTES.DIAGNOSTIC_EDIT(id));
          }}
          fill={ButtonFill.Transparent}
          size={ButtonSize.Link}
          icon={<Edit />}
        >
          {t('general.edit')}
        </ButtonStyled>,
      );
    }

    if (checkPermission(assessment, [PermissionsAction.DELETE])) {
      actions.push(
        <ButtonStyled
          key="delete"
          fill={ButtonFill.TransparentRed}
          size={ButtonSize.Link}
          onClick={() => toggleDeleteModal(true)}
          icon={<Delete />}
          justifyStart={isMobile}
        >
          {t('general.delete')}
        </ButtonStyled>,
      );
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  return (
    <div className={styles.AssessmentDetailsPage}>
      {isLoading ? (
        <Loader flexCenter size="md" />
      ) : (
        <>
          <ModalWrapper
            open={openDeleteModal}
            title={t('delete_diagnostic.delete_diagnostic_title')}
            subtitle={t('delete_diagnostic.delete_diagnostic_subtitle')}
            close={handleCloseDeleteModal}
            action={handleDelete}
          />
          <AddAssigneeModal
            open={openAddAssigneeModal}
            handleClose={handleCloseAddAssigneeModal}
            selectedSections={selectedSections}
          />
          <AllAssigneesModal
            tags={tags}
            open={openAllAssigneesModal}
            handleClose={handleCloseAllAssigneesModal}
            selectedSections={selectedSections}
          />
          <DemoModal open={openDemoModal} handleClose={handleCloseDemoModal} handleOpenDemo={handleOpenDemo} />

          <Breadcrumb
            items={[
              { name: t('assessment.diagnostic'), href: ROUTES.DIAGNOSTIC_LIST },
              {
                name: title,
                href: ROUTES.DIAGNOSTIC_DETAILS(id),
                active: true,
              },
            ]}
          />

          <PageTitle
            title={
              <div className={styles.PageTitle}>
                <h3>{title}</h3>
                <ButtonStyled size={ButtonSize.Small} fill={ButtonFill.Contained} onClick={handleTryDemoClick}>
                  {t('assessment.try_demo')}
                </ButtonStyled>
              </div>
            }
            titleTopColumn={isMobile}
          >
            {pageTitleActions.length ? pageTitleActions : null}
          </PageTitle>

          <div className={styles.IconContainer}>
            <NoteList />
          </div>

          <div className={styles.AssessmentInfoContainer}>
            <div className={styles.AssessmentInfo}>
              <span>{t('assessment.description')}</span>
              <p>{description}</p>
            </div>

            <div className={styles.AssessmentInfo}>
              <span>{t('assessment.table.creator')}</span>
              <p>{creator_full_name || t('general.not_specified')}</p>
            </div>
          </div>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('assessment.units')}:</h3>
            </div>
            <div className={styles.Tags}>
              {unitTags?.length ? <Tags tags={unitTags} /> : <span>{t('general.not_assigned')}</span>}
            </div>
          </div>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('assessment.table.assigned_to')}:</h3>
              {checkPermission(assessment, [PermissionsAction.ASSIGN]) && (
                <ButtonStyled
                  onClick={() => toggleAddAssigneeModal(true)}
                  fill={ButtonFill.Transparent}
                  size={ButtonSize.Small}
                  icon={<Add />}
                >
                  {t('assessment.add_assignee')}
                </ButtonStyled>
              )}
            </div>

            {isFetching ? (
              <Loader size="sm" />
            ) : (
              <div className={styles.Tags}>
                {tags.length ? (
                  <Tags tags={tags} handleViewAll={handleViewAll} />
                ) : (
                  <span>{t('general.not_assigned')}</span>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AssessmentDetails;
