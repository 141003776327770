import classNames from 'classnames';

import { CardVariants } from 'types/dashboardTypes';

import styles from './ComparisonChartInfoItem.module.scss';

type Props = {
  title: string;
  subTitle: string[];
  color: CardVariants;
};

const ComparisonChartInfoItem = ({ title, subTitle, color }: Props) => {
  const backgroundColor = {
    Blue: styles.Blue,
    Yellow: styles.Yellow,
  }[color];

  return (
    <div className={classNames(styles.ChartInfo, backgroundColor)}>
      <h2>{title}</h2>
      <div className={styles.ChartInfoLabel}>
        {subTitle.map((item, index) => (
          <span key={item + index}>{item}</span>
        ))}
      </div>
    </div>
  );
};

export default ComparisonChartInfoItem;
