import { useTranslation } from 'react-i18next';

import ImagePreview from 'components/ImagePreview/ImagePreview';

import { InformationTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import ElementWrapper from '../ElementWrapper/ElementWrapper';

type Props = {
  element: InformationTakeElement;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
};

const Information = ({ element, loading, onNext, onPrev }: Props) => {
  const { i18n } = useTranslation();

  return (
    <ElementWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      footerProps={{ loading, onNext, onPrev }}
    >
      {element.image ? <ImagePreview imageURL={element.image} /> : null}
    </ElementWrapper>
  );
};

export default Information;
