import { useDrag } from 'react-dnd';

import classNames from 'classnames';

import { DragSource } from 'types/createAssessmentTypes';

import styles from '../../AssessmentCreate.module.scss';

type Props = {
  icon: JSX.Element;
  type: string;
  name: string;
  component: { type: string; source: DragSource };
  disabled?: boolean;
};

const AsideComponent = ({ icon, type, name, component, disabled }: Props) => {
  const [, drag] = useDrag(() => ({
    type,
    item: component,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  }));

  return (
    <div ref={drag} className={classNames(styles.AsideElement, { [styles.Disabled]: disabled })}>
      <div className={styles.Icon}>{icon}</div>
      {name}
    </div>
  );
};

export default AsideComponent;
