import { useTranslation } from 'react-i18next';

import ModalWrapper from '../ModalWrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
};

const DeleteUnitModal = ({ open, handleClose, handleDelete, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      {...props}
      open={open}
      close={handleClose}
      action={handleDelete}
      actionTitle={t('general.confirm')}
      subtitle={t('assessment.delete_unit_description')}
      cancelTitle={t('general.back')}
      attention
    />
  );
};

export default DeleteUnitModal;
