import { useState } from 'react';

import { DeleteModalState, EditModalState } from 'types/createAssessmentTypes';

export const useModulesSidebar = () => {
  const [addModule, showAddModule] = useState(false);
  const [deleteModuleModal, showDeleteModuleModal] = useState<DeleteModalState>({
    open: false,
    id: '',
  });
  const [editModule, showEditModule] = useState<EditModalState>({
    open: false,
    id: null,
    name: '',
  });
  const toggleAddModal = (state: boolean) => {
    showAddModule(state);
  };
  const toggleEditModule = (state: EditModalState) => {
    showEditModule(state);
  };
  const toggleModuleDelete = (state: { open: boolean; id: string }) => {
    showDeleteModuleModal(state);
  };

  return {
    addModule,
    editModule,
    toggleAddModal,
    toggleEditModule,
    deleteModuleModal,
    toggleModuleDelete,
  };
};
