import React, { ChangeEventHandler } from 'react';

import classNames from 'classnames';

import styles from '../../../Inputs.module.scss';

type InputFieldProps = {
  disabled?: boolean;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  type?: string;
  className?: string;
};

const InputField = ({ placeholder, value, disabled, onChange, className, ...props }: InputFieldProps) => {
  return (
    <div className={styles.CustomInput}>
      <input
        onChange={onChange}
        disabled={disabled}
        value={value}
        className={classNames(styles.Input, className)}
        type="text"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default InputField;
