import React, { ForwardedRef, forwardRef } from 'react';

import { Checkbox, CheckboxProps } from '@mui/material';

import { ReactComponent as CheckboxUncheck } from 'assets/images/checkbox.svg';
import { ReactComponent as CheckboxCheck } from 'assets/images/checkboxCheck.svg';

const CustomCheckbox = forwardRef((props: CheckboxProps, ref: ForwardedRef<HTMLButtonElement | null>) => (
  <Checkbox
    ref={ref}
    icon={<CheckboxUncheck />}
    checkedIcon={<CheckboxCheck />}
    indeterminateIcon={<CheckboxCheck />}
    {...props}
  />
));

export default CustomCheckbox;
