import { ReactNode, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useFocused, useSlateStatic } from 'slate-react';

import { LinkType } from 'types/richTextEditorTypes';

import Icon from '../../common/Icon';
import { removeLink } from '../../utils/link';

import styles from './Link.module.scss';

type LinkProps = {
  attributes: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  children: ReactNode;
  element: LinkType;
};

const LinkElement = ({ attributes, element, children }: LinkProps) => {
  const editor = useSlateStatic();
  const focused = useFocused();
  const [isOpen, setIsOpen] = useState(false);
  const iconLinkOff = 'link_off';

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.link}>
        <a
          onClick={() => setIsOpen((state) => !state)}
          href={element.href}
          {...attributes}
          {...element.attr}
          target={element.target}
        >
          {children}
        </a>
        {focused && isOpen && (
          <div className={styles.linkPopup} contentEditable={false}>
            <a href={element.href} target={element.target}>
              {element.href}
            </a>
            <button onClick={() => removeLink(editor)} className={styles.button}>
              <Icon>{iconLinkOff}</Icon>
            </button>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default LinkElement;
