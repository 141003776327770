import { useState } from 'react';

import classNames from 'classnames';

import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

import { LongTextBoardElement } from 'types/boardElements';
import { OnElementDelete, TitleFieldsChange } from 'types/createAssessmentTypes';

import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';

import styles from '../BoardElements.module.scss';

type Props = {
  onElementDelete: OnElementDelete;
  element: LongTextBoardElement;
  onTitleFieldsChange: TitleFieldsChange;
};

const LongTextEntry = ({ onElementDelete, onTitleFieldsChange, element }: Props) => {
  const [value, setValue] = useState('');

  return (
    <div className={classNames(styles.CardContainer, styles.ShortTextEntry)}>
      <div className={styles.InnerBody}>
        <CardTitleBlock
          descriptionRequired
          onDelete={onElementDelete}
          element={element}
          onTitleChange={onTitleFieldsChange}
        />

        <div className={styles.Input}>
          <RichTextEditor disabled value={value} setValue={setValue} />
        </div>
      </div>

      <BottomCardNavPanel />
    </div>
  );
};

export default LongTextEntry;
