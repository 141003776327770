import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Search from 'components/Inputs/Search/Search';
import SortButton from 'components/SortButton/SortButton';

import { SortTypes } from 'types/enums';

import styles from './SearchBar.module.scss';

type Props = {
  search: string;
  onSearch: Dispatch<SetStateAction<string>>;
  sort?: SortTypes;
  onSort?: Dispatch<SetStateAction<SortTypes>>;
};

const SearchBar = ({ search, onSearch, sort, onSort }: Props) => {
  const { t } = useTranslation();

  const handleSort = () => onSort && (sort === SortTypes.ASC ? onSort(SortTypes.DESC) : onSort(SortTypes.ASC));

  return (
    <div className={styles.SearchInputContainer}>
      <Search onSearch={onSearch} value={search} placeholder={t('general.search_placeholder')} />

      {sort && (
        <div className={styles.ListFilter}>
          <SortButton onSort={handleSort} sortType={sort} />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
