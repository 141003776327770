import classNames from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import CardInfo from 'components/CardInfo/CardInfo';

import { GetGroupByIdResponse } from 'store/api/groupsApi/types';
import { GetUserByIdResponse } from 'store/api/usersApi/types';

import { CardColor } from 'types/enums';

import styles from './DashboardCardInfo.module.scss';

type Props = {
  group?: GetGroupByIdResponse;
  groupColor?: CardColor;
  user?: GetUserByIdResponse;
  userColor?: CardColor;
  assessmentName?: string;
  assessmentNameColor?: CardColor;
  hideUserName?: boolean;
};

const DashboardCardInfo = ({
  group,
  user,
  assessmentName,
  groupColor,
  userColor,
  assessmentNameColor,
  hideUserName,
}: Props) => {
  const fullName = (user && `${user.first_name} ${user.last_name}`) || '';

  return (
    <div className={styles.Container}>
      {group && (
        <CardInfo color={groupColor}>
          <div className={styles.CardInfoContainer}>
            <span className={styles.Content}>{group.name}</span>
          </div>
        </CardInfo>
      )}

      {user && (
        <CardInfo color={userColor}>
          <div className={styles.CardInfoContainer}>
            <Avatar src={user.photo}>{fullName}</Avatar>
            {!hideUserName && <span className={classNames(styles.Content, styles.UserName)}>{fullName}</span>}
          </div>
        </CardInfo>
      )}

      {assessmentName && (
        <CardInfo color={assessmentNameColor}>
          <div className={styles.CardInfoContainer}>
            <span className={styles.Content}>{assessmentName}</span>
          </div>
        </CardInfo>
      )}
    </div>
  );
};

export default DashboardCardInfo;
