import { useTranslation } from 'react-i18next';

import { roundPercentage } from 'helpers/dashboard';

import { IDashboardTooltipComparison } from 'types/dashboardTypes';

import styles from './TooltipBody.module.scss';

type DashboardTooltipContentProps = {
  tooltipData: IDashboardTooltipComparison;
};

export const TooltipBody = ({ tooltipData }: DashboardTooltipContentProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <h2>{`${tooltipData.amount} ${t(
        tooltipData.amount === 1 ? 'general.person' : 'general.people',
      ).toLowerCase()}`}</h2>
      <div className={styles.Content}>
        <span>{t('dashboard.team_distribution')}</span>
        <span>{`${roundPercentage(tooltipData.percentage * 100)}%`}</span>
      </div>
    </div>
  );
};

export default TooltipBody;
