import { useAppSelector } from 'hooks/redux';

import { ACCEPTED_MAPPINGS_NUMBERS } from 'constants/';

export const useValidateAssessmentMappings = () => {
  const questionsMappings = useAppSelector((state) => state.createAssessment.questionMappings);

  const isQuestionsMappingsNumberValid = () => {
    const numberOfVisualisations = Object.values(questionsMappings).map((mapping) => mapping.visualisationId).length;

    return ACCEPTED_MAPPINGS_NUMBERS.includes(numberOfVisualisations);
  };

  const isQuestionsMappingsUnique = () => {
    const visualisations = Object.values(questionsMappings).map((mapping) => mapping.visualisationId);
    const numberOfUniqueMappedVisualisations = new Set(visualisations).size;

    return numberOfUniqueMappedVisualisations === visualisations.length;
  };

  return {
    isQuestionsMappingsNumberValid,
    isQuestionsMappingsUnique,
  };
};
