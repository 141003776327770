import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.Footer}>
    <p>
      © {new Date().getFullYear()} Disruption Advisors | All Rights Reserved |{' '}
      <a
        href="https://thedisruptionadvisors.com/wp-content/uploads/S-Curve-Insight-Tool-End-User-License-Agreement_231109.pdf"
        target="_blank"
        rel="noreferrer"
      >
        EULA
      </a>
    </p>
  </footer>
);

export default Footer;
