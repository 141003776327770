import { useTranslation } from 'react-i18next';

import { OutlinedInput } from '@mui/material';
import { InputAdornment } from '@mui/material';

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg';

import styles from './Search.module.scss';

type Props = {
  value?: string;
  onSearch: (arg0: string) => void;
  placeholder?: string;
};

const Search = ({ placeholder, value, onSearch }: Props) => {
  const { t } = useTranslation();

  return (
    <OutlinedInput
      className={styles.Container}
      placeholder={placeholder || t('general.search_placeholder')}
      sx={{ border: '1px solid var(--cool-grey)', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
      value={value}
      inputProps={{ maxLength: 160 }}
      onChange={(e) => onSearch(e.target.value)}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon className={styles.SearchIcon} />
        </InputAdornment>
      }
    />
  );
};

export default Search;
