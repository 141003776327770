import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ExtendedParsedUser, ParsedCSVUsers } from '../../types/userTypes';

type UsersState = {
  usersFromCsv: ParsedCSVUsers;
  userToEdit: ExtendedParsedUser | null;
  loaded: boolean;
};

const initialState: UsersState = {
  usersFromCsv: { items: [] },
  userToEdit: null,
  loaded: false,
};

const usersSlice = createSlice({
  name: 'users_slice',
  initialState,
  reducers: {
    setCSVUsers(state, action) {
      state.usersFromCsv = action.payload;
      state.loaded = true;
    },

    updateCSVUsers(state, action: PayloadAction<ParsedCSVUsers>) {
      state.usersFromCsv = { items: action.payload.items.map((item, index) => ({ ...item, itemId: index + 1 })) };
    },

    setUserToEdit(state, action: PayloadAction<ExtendedParsedUser>) {
      state.userToEdit = action.payload;
    },

    clearUsersCsv(state) {
      state.usersFromCsv.items = [];
      state.userToEdit = null;
      state.loaded = false;
    },

    editCSVUser(state, action: PayloadAction<ExtendedParsedUser>) {
      if (state.usersFromCsv) {
        state.usersFromCsv.items = state.usersFromCsv.items.map((el) =>
          el.itemId === action.payload.itemId ? action.payload : el,
        );
      }
    },
  },
});

export const { setCSVUsers, updateCSVUsers, setUserToEdit, editCSVUser, clearUsersCsv } = usersSlice.actions;

export default usersSlice.reducer;
