import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import CustomNoRowsOverlay from 'components/DataTable/components/CustomNoRowsOverlay/CustomNoRowsOverlay';
import Loader from 'components/Loader/Loader';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { useGetAssessmentsQuery } from 'store/api/assessmentApi/assessmentApi';

import { mapAssessmentsByUnits } from 'helpers/mappers/mapAssessmentsByUnits';
import { mapAssessmentsSearch } from 'helpers/mappers/mapAssessmentsSearch';

import { MIN_SEARCH_LENGTH } from 'constants/';

import { IAssessment, SearchAssessments, Unit } from 'types/assessmentTypes';
import { AssessmentListTab, OutletProps } from 'types/assessmentTypes';

import AssessmentListItem from '../../../AssessmentListItem/AssessmentListItem';

type Result = { [key: string]: { items: IAssessment[]; id: string } };

const AssessmentListOutlet = () => {
  const { t } = useTranslation();
  const { tableProps, changeTab } = useOutletContext<OutletProps>();
  const { page, size, sort, search, onPageChange } = tableProps;
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);

  useEffect(() => {
    changeTab(AssessmentListTab.LIST);
  }, [changeTab]);

  let params = null;
  let result: IAssessment[] | Result | null = null;

  const isValidMinSearchLength = search.length >= MIN_SEARCH_LENGTH;

  if (isValidMinSearchLength) {
    params = { page, size, search, order_by: sort };
  }

  const { data, refetch, isFetching, isLoading } = useGetAssessmentsQuery(params || undefined);

  useEffect(() => {
    refetch();
  }, [refetch, selectedGroupId]);

  if (data) {
    if (isValidMinSearchLength) {
      result = mapAssessmentsSearch(data as SearchAssessments);
    } else {
      const groupByKey = 'title';
      const mappedAssessments = mapAssessmentsByUnits(data as Unit[], groupByKey);
      result = mappedAssessments;
    }
  }

  const resultKeys = result ? Object.keys(result as Result) : null;

  const tablePropsWithStatus = {
    data,
    page,
    size,
    sort,
    search: isValidMinSearchLength ? search : '',
    onPageChange,
    isFetching,
    isLoading,
  };

  useDocumentTitle([t('page_titles.diagnostic')]);

  const renderList = () => {
    if (!result || !resultKeys) {
      return <Loader flexCenter size="md" />;
    }

    if (isValidMinSearchLength) {
      return (
        <AssessmentListItem
          assessments={result as IAssessment[]}
          tableProps={tablePropsWithStatus}
          total={(data as SearchAssessments).total}
        />
      );
    }

    if (!resultKeys.length) {
      return <CustomNoRowsOverlay text={t('assessment.no_diagnostics')} />;
    }

    return resultKeys.map((item) => (
      <AssessmentListItem
        key={(result as Result)[item].id}
        unitTitle={item}
        unitId={(result as Result)[item].id}
        assessments={(result as Result)[item].items}
        tableProps={tablePropsWithStatus}
      />
    ));
  };

  return <>{renderList()}</>;
};

export default AssessmentListOutlet;
