import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Select from 'components/Inputs/Select/Select';
import InputField from 'components/Inputs/Text/components/InputField/InputField';

import { useQuestionsMapping, useVisualisationsOptions } from 'pages/Assessments/AssessmentCreate/hooks';

import { ShortTextBoardElement } from 'types/boardElements';
import { OnElementDelete, TitleFieldsChange } from 'types/createAssessmentTypes';

import BottomCardNavPanel from '../../BottomCardNavPanel/BottomCardNavPanel';
import CardTitleBlock from '../../CardTitleBlock/CardTitleBlock';

import styles from '../BoardElements.module.scss';

type Props = {
  element: ShortTextBoardElement;
  onTitleFieldsChange: TitleFieldsChange;
  onElementDelete: OnElementDelete;
};
const ShortTextEntry = ({ onElementDelete, onTitleFieldsChange, element }: Props) => {
  const { t } = useTranslation();
  const { selectedVisualisation, handleSelectVisualisation, errorMessage } = useQuestionsMapping(element.id);
  const { visualisationsOptions, isLoading } = useVisualisationsOptions();
  const options = [...[{ label: 'None' }], ...visualisationsOptions];

  const [value, setValue] = useState('');

  return (
    <div className={classNames(styles.CardContainer, styles.ShortTextEntry)}>
      <div className={styles.InnerBody}>
        <CardTitleBlock
          id={element.id}
          descriptionRequired
          onDelete={onElementDelete}
          element={element}
          onTitleChange={onTitleFieldsChange}
        />

        <div className={styles.Input}>
          <InputField disabled value={value} onChange={(e: any) => setValue(e.target.value)} />
        </div>

        <Select
          value={selectedVisualisation || ''}
          isLoading={isLoading}
          data={options}
          placeholder={t('inputs.select_placeholder')}
          onSelect={handleSelectVisualisation}
          selectClassName="custom-select-visualisations"
          selectMenuClassName="custom-select-menu-visualisations"
          error={{ invalid: !!errorMessage }}
          error_message={errorMessage}
        />
      </div>

      <BottomCardNavPanel />
    </div>
  );
};

export default ShortTextEntry;
