import classNames from 'classnames';

import LevelHeader from './components/LevelHeader/LevelHeader';
import LevelUsers from './components/LevelUsers/LevelUsers';

import { useToggle } from 'hooks/useToggle';

import { Size } from 'types/enums';
import { IGroupHierarchyItem, IGroupsHierarchyList } from 'types/groupHierarchyTypes';

import styles from './Level.module.scss';

type Props = {
  data: IGroupHierarchyItem;
  list: IGroupsHierarchyList;
  onSelectEntity: (value: string, entity: string) => void;
  index: number;
};

const Level = ({ data, list, onSelectEntity, index }: Props) => {
  const [open, toggle] = useToggle(false);
  const size = [Size.Lg, Size.Md, Size.Sm][index] || Size.Sm;

  return (
    <li>
      <LevelHeader size={size} data={data} list={list} onSelectEntity={onSelectEntity} open={open} toggle={toggle} />

      <ul
        className={classNames(styles.ListLevel, size && styles[size], {
          [styles.Active]: open,
        })}
      >
        {data.children.map((element, key) => (
          <Level key={key} data={element} list={list} onSelectEntity={onSelectEntity} index={index + 1} />
        ))}

        {data.users.map((element, index) => (
          <LevelUsers key={index} data={element} list={list} onSelectEntity={onSelectEntity} size={size} />
        ))}
      </ul>
    </li>
  );
};

export default Level;
