import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import ModalWrapper from 'components/Modals/ModalWrapper';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import PageTitle from 'components/PageTitle/PageTitle';

import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import { useDeleteGroupMutation, useGetGroupsQuery } from 'store/api/groupsApi/groupsApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { getFieldId } from 'helpers/table';

import { MIN_SEARCH_LENGTH, MOBILE_BREAKPOINT } from 'constants/';

import { ButtonFill, ButtonSize } from 'types/enums';
import { MobileCardType, MobileCardsData } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import styles from './GroupList.module.scss';

const GroupList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const { group } = useAppSelector((state) => state.permissions.permissions);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const { page, size, sort, search, onPageChange, onSearching, onSorting } = useTableControl('name');
  const debouncedSearch = useDebounce(search, 500);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const { data, isFetching, isLoading, refetch } = useGetGroupsQuery({
    page,
    size,
    order_by: sort.sortBy,
    search: debouncedSearch.length < MIN_SEARCH_LENGTH ? '' : debouncedSearch,
    groupId: selectedGroupId,
  });

  const [deleteGroup, { isLoading: isDeleting }] = useDeleteGroupMutation();

  useEffect(() => {
    refetch();
  }, [refetch, selectedGroupId]);

  const handleClickOpen = (id: string): void => {
    setOpen(true);
    setSelected(id);
  };

  const handleClose = (): void => {
    setOpen(false);
    setSelected('');
  };

  const handleDeleteGroup = () => {
    deleteGroup(selected)
      .unwrap()
      .then((resp) => handleClose());
  };

  const getActions = useCallback(
    (id: string) => {
      const actions: ReactNode[] = [];

      if (!isMobile && checkPermission(group, [PermissionsAction.UPDATE])) {
        actions.push(
          <ButtonStyled
            key="edit"
            onClick={() => navigate(ROUTES.GROUP_EDIT(id))}
            fill={ButtonFill.Transparent}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            icon={<Edit />}
          >
            {t('general.edit')}
          </ButtonStyled>,
        );
      }

      if (checkPermission(group, [PermissionsAction.DELETE])) {
        actions.push(
          <ButtonStyled
            key="delete"
            fill={ButtonFill.TransparentRed}
            size={isMobile ? ButtonSize.Link : ButtonSize.Small}
            onClick={() => handleClickOpen(id)}
            icon={<Delete />}
          >
            {t('general.delete')}
          </ButtonStyled>,
        );
      }

      return actions;
    },
    [group, isMobile, navigate, t],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'fieldId', headerName: '#', sortable: false, width: 30 },
      {
        field: 'name',
        headerName: t('groups.table.name'),
        width: 150,
        sortable: false,
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('groups.table.description'),
        width: 150,
        sortable: false,
        flex: 1,
        valueFormatter: (data) => data.value || '-',
      },
      {
        field: 'creator',
        headerName: t('groups.table.creator'),
        width: 150,
        sortable: false,
        flex: 1,
        valueFormatter: (data) => (data.value ? `${data.value.first_name} ${data.value.last_name}` : '-'),
      },
      {
        headerName: t('general.actions'),
        field: 'actions',
        headerAlign: 'left',
        width: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => getActions(params.row.id),
      },
    ],
    [t, getActions],
  );

  const mobileCardsData = useMemo<MobileCardsData>(
    () =>
      isMobile && data?.items
        ? data.items.map((item, index) => ({
            row: [
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('groups.table.name'),
                    value: item.name,
                    key: item.id + 1,
                  },
                  {
                    type: MobileCardType.Ordinal,
                    label: '#',
                    value: getFieldId({ size: size, page, index, total: data?.items.length }),
                    key: item.id + 2,
                  },
                ],
                key: item.id + 1,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('groups.table.creator'),
                    value: `${item.creator?.first_name || ''} ${item.creator?.last_name || ''}`.trim(),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 2,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Info,
                    label: t('groups.table.description'),
                    value: item.description || '',
                    key: item.id + 1,
                  },
                ],
                key: item.id + 3,
              },
              {
                columns: [
                  {
                    type: MobileCardType.Actions,
                    actions: getActions(item.id),
                    key: item.id + 1,
                  },
                ],
                key: item.id + 4,
              },
            ],
            key: item.id,
          }))
        : [],
    [data?.items, getActions, isMobile, page, size, t],
  );

  const getPageTitleAction = () => {
    const actions = [];

    if (checkPermission(group, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="group_hierarchy"
          size={ButtonSize.Small}
          fill={ButtonFill.Outlined}
          onClick={() => navigate(ROUTES.GROUP_HIERARCHY)}
        >
          {t('groups.group_hierarchy')}
        </ButtonStyled>,
      );
    }

    if (!isMobile && checkPermission(group, [PermissionsAction.CREATE])) {
      actions.push(
        <ButtonStyled
          key="add_group"
          size={ButtonSize.Small}
          fill={ButtonFill.Contained}
          onClick={() => navigate(ROUTES.GROUP_ADD)}
          icon={<AddIcon />}
        >
          {t('groups.add_group')}
        </ButtonStyled>,
      );
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  useDocumentTitle([t('page_titles.group_list')]);

  return (
    <div className={styles.GroupList}>
      <ModalWrapper
        open={open}
        close={handleClose}
        action={handleDeleteGroup}
        status={isDeleting}
        title={t('groups.modal.title')}
        subtitle={t('groups.modal.subtitle')}
      />

      <PageTitle
        controls
        title={t('groups.group_list')}
        searchPlaceholder={t('groups.search_placeholder')}
        search={search}
        sort={sort.sortType}
        onSort={onSorting}
        onSearch={onSearching}
        titleTopColumn={isMobile}
      >
        {pageTitleActions.length ? pageTitleActions : null}
      </PageTitle>

      {isMobile ? (
        <div className={styles.MobileContainer}>
          {isLoading || isFetching ? (
            <Loader flexCenter size="md" />
          ) : (
            <>
              {mobileCardsData?.length ? (
                <MobileCards data={mobileCardsData} />
              ) : (
                <NothingFoundText text={t('groups.table.empty_result')} />
              )}
            </>
          )}
          {data?.items.length ? (
            <div className={styles.PaginationContainer}>
              <Pagination
                color="primary"
                shape="rounded"
                page={page}
                count={Math.ceil((data?.total || 0) / (data?.size || 0))}
                onChange={(_, value) => onPageChange(value)}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <DataTable
          page={page}
          data={data}
          columns={columns}
          isLoading={isLoading || isFetching}
          emptyResultText={t('groups.table.empty_result')}
          changePage={onPageChange}
        />
      )}
    </div>
  );
};

export default GroupList;
