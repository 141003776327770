import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Toggle } from 'rsuite';

import { Element, WeightedElement } from 'types/boardElements';
import { CalculationType, CalculationTypeChange, TitleFieldsChange } from 'types/createAssessmentTypes';

import BoardInput from '../BoardInput/BoardInput';

import styles from './WeightBlock.module.scss';

interface Props {
  element: Element;
  onWeightChange: TitleFieldsChange;
  calculationType: CalculationType;
  calculationTypeChange: CalculationTypeChange;
}

const WeightBlock = ({ onWeightChange, calculationType, element, calculationTypeChange }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.WeightBlock}>
      <div>
        <p className={classNames({ [styles.toggled]: calculationType === CalculationType.Average })}>
          {t('general.average')}
        </p>

        <Toggle
          checked={calculationType === CalculationType.Summary}
          onChange={() =>
            calculationTypeChange(
              element.id,
              calculationType === CalculationType.Average ? CalculationType.Summary : CalculationType.Average,
            )
          }
        />

        <p className={classNames({ [styles.toggled]: calculationType === CalculationType.Summary })}>
          {t('general.summary')}
        </p>
      </div>

      <BoardInput
        value={(element as WeightedElement).weight}
        placeholder={t('general.weight')}
        onChange={(e) => {
          onWeightChange(element.id, 'weight', e.target.value);
        }}
      />
    </div>
  );
};

export default WeightBlock;
