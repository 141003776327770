import { ChangeEvent, Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getBase64 } from 'helpers/getBase64';

import { MAX_UPLOAD_FILE_SIZE } from 'constants/index';

import { Avatar } from 'types/userTypes';

import { ReactComponent as User } from 'assets/images/user-avatar.svg';

import ModalWrapper from '../ModalWrapper';

import styles from './AvatarSelectModal.module.scss';

type Props = {
  open: boolean;
  avatar: Avatar;
  loading?: boolean;
  deleteAvatar: () => void;
  setAvatar: Dispatch<{ preview: string; file: string }>;
  handleClose: () => void;
};

const AvatarSelectModal = ({ open, avatar, loading, deleteAvatar, handleClose, setAvatar }: Props) => {
  const { t } = useTranslation();
  const [avatarTemp, setAvatarTemp] = useState<Avatar>(avatar ?? ({} as Avatar));

  const onDeleteAvatar = () => {
    deleteAvatar();
    setAvatarTemp({ preview: '', files: null });
  };

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const reader = new FileReader();

      reader.readAsDataURL(files[0]);
      reader.onload = function (e) {
        const image = new Image();

        if (typeof e.target?.result !== 'string') return false;

        image.src = e.target.result;

        image.onload = function () {
          const height = image.height;
          const width = image.width;

          if (height < 50 || height > 300 || width < 50 || width > 300) {
            toast.error(t('validation.image_dimensions'));
            event.target.value = '';
            return false;
          }

          if (files[0].size > MAX_UPLOAD_FILE_SIZE) {
            toast.error(t('validation.max_file_size'));
            event.target.value = '';
            return false;
          }

          setAvatarTemp({ preview: URL.createObjectURL(files[0]), files: files[0] });
        };
      };
    }
  };

  const onSaveAvatar = () => {
    if (avatarTemp?.files)
      getBase64(avatarTemp?.files as Blob).then((resp) => {
        setAvatar({ ...avatarTemp, file: resp as string });
      });

    handleClose();
  };

  return (
    <ModalWrapper
      open={open}
      close={handleClose}
      cancelTitle={t('general.cancel')}
      action={onSaveAvatar}
      actionTitle={t('general.save')}
      title={t('avatar_modal.title')}
      subtitle={t('avatar_modal.description')}
      footerDeleteAction={onDeleteAvatar}
      footerDeleteActionTitle={t('avatar_modal.remove')}
    >
      <div className={styles.Avatar}>
        <div className={styles.CustomAvatar}>
          <input id="avatar" type="file" accept=".png, .jpg, .jpeg" className={styles.Input} onChange={onChangeImage} />
          <label htmlFor="avatar" className={styles.AvatarLabel}>
            {avatarTemp?.preview ? <img src={avatarTemp.preview} alt="avatar" className={styles.Image} /> : <User />}
          </label>
        </div>
      </div>

      <div className={styles.InfoBlock}>
        <div className={styles.Element}>
          <div className={styles.Title}>{t('avatar_modal.max_avatar')}</div>
          <div className={styles.Info}>300x300 px</div>
        </div>

        <div className={styles.Element}>
          <div className={styles.Title}>{t('avatar_modal.min_avatar')}</div>
          <div className={styles.Info}>50x50 px</div>
        </div>

        <div className={styles.Element}>
          <div className={styles.Title}>{t('avatar_modal.format')}</div>
          <div className={styles.Info}>png, jpg, jpeg</div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AvatarSelectModal;
