import { Fragment, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import { useAppSelector } from 'hooks/redux';

import { useGetComparisonListQuery, useGetComparisonUsersAllQuery } from 'store/api/assessmentApi/assessmentApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { formatDateDetailed } from 'helpers/date';
import { compareIndividualUsersAssessmentModalResolver, compareOwnAssessmentModalResolver } from 'helpers/validations';

import { ComparisonList, ComparisonListUser, ICompletionsAssessment } from 'types/assessmentTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Compare } from 'assets/images/compare.svg';

import Select from '../../Inputs/Select/Select';
import ModalWrapper from '../ModalWrapper';

import styles from './CompareAssessmentModal.module.scss';

type Props = {
  open: boolean;
  handleClose: () => void;
  completionAssessment: ICompletionsAssessment | null;
};

type FormValues = {
  option1: {
    user: string;
    assessment: string;
  };
  option2: {
    user: string;
    assessment: string;
  };
};

const initialState = {
  option1: {
    user: '',
    assessment: '',
  },
  option2: {
    user: '',
    assessment: '',
  },
};

const prepareUserOptions = (users: ComparisonListUser[] | undefined) => {
  return users
    ?.map((item) => ({ label: `${item.first_name} ${item.last_name}`, value: item.id }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

const prepareAssessmentOptions = (assessments: ComparisonList[] | undefined) => {
  return assessments?.map((item) => ({
    label: `${item.assessment_name} ${formatDateDetailed(item.attempt_date)}`,
    value: item.completed_assessment_id,
  }));
};

const CompareAssessmentModal = ({ open, handleClose, completionAssessment }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const comparisonType = useAppSelector((state) => state.permissions.comparisonType);
  const { other_users_dashboard_comparison, own_comparison } = useAppSelector((state) => state.permissions.permissions);
  const { user } = useAppSelector((state) => state.auth);
  const isUserComparison = checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ]);
  const isOwnComparison = !isUserComparison && checkPermission(own_comparison, [PermissionsAction.READ]);

  const { handleSubmit, formState, resetField, setValue, control, reset, watch } = useForm<FormValues>({
    resolver: isOwnComparison
      ? yupResolver(compareOwnAssessmentModalResolver)
      : yupResolver(compareIndividualUsersAssessmentModalResolver),
    defaultValues: initialState,
    mode: 'all',
  });
  const currentUserId = user?.id || '';
  const user1Value = watch('option1.user');
  const assessment1Value = watch('option1.assessment');
  const user2Value = watch('option2.user');

  const { data: users1, isFetching: isFetchingUsers1 } = useGetComparisonUsersAllQuery(
    {},
    { skip: isOwnComparison || !open },
  );

  const { data: assessments1, isFetching: isFetchingAssessments1 } = useGetComparisonListQuery(
    { comparisonType, userId: isOwnComparison ? currentUserId : user1Value },
    { skip: (!user1Value && !isOwnComparison) || !open },
  );

  const { data: users2, isFetching: isFetchingUsers2 } = useGetComparisonUsersAllQuery(
    { assessmentTakeId: assessment1Value },
    { skip: !user1Value || !assessments1 || !open },
  );

  const { data: assessments2, isFetching: isFetchingAssessments2 } = useGetComparisonListQuery(
    {
      comparisonType,
      userId: isOwnComparison ? currentUserId : user2Value,
      comparedAssessmentId: assessment1Value,
    },
    { skip: (!user2Value && !isOwnComparison) || !open },
  );

  const user1Values = prepareUserOptions(users1);
  const assessments1Values = prepareAssessmentOptions(assessments1);
  const user2Values = prepareUserOptions(users2);
  const assessments2Values = prepareAssessmentOptions(assessments2);

  useEffect(() => {
    if (open) {
      reset({
        option1: {
          user: '',
          assessment: completionAssessment?.last_assessment_take_id,
        },
        option2: {
          user: '',
          assessment: '',
        },
      });
    }
  }, [completionAssessment?.last_assessment_take_id, isOwnComparison, open, reset]);

  useEffect(() => {
    resetField('option1.assessment', { defaultValue: '' });
  }, [assessments1, resetField]);

  useEffect(() => {
    resetField('option2.assessment');
  }, [assessments2, resetField]);

  useEffect(() => {
    if (assessments1?.length) {
      setValue('option1.assessment', assessments1[assessments1.length - 1].completed_assessment_id);
    }
  }, [assessments1, setValue]);

  useEffect(() => {
    setValue('option2.user', user1Value);
  }, [user1Value, setValue]);

  const onSubmit: SubmitHandler<FormValues> = ({ option1, option2 }) => {
    navigate(ROUTES.DIAGNOSTIC_COMPARE(option1.assessment, option2.assessment));
  };

  const handleCancel = () => {
    handleClose();
    reset(initialState);
  };

  const renderForm = useMemo(() => {
    if (isUserComparison) {
      return (
        <Fragment>
          <Controller
            control={control}
            name="option1.user"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                value={value}
                data={user1Values}
                label={t('inputs.user')}
                placeholder={t('inputs.user')}
                onSelect={onChange}
                isLoading={isFetchingUsers1}
              />
            )}
          />

          <Controller
            control={control}
            name="option1.assessment"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                value={value}
                data={assessments1Values}
                label={t('inputs.diagnostic_name')}
                placeholder={t('inputs.select_diagnostic')}
                onSelect={onChange}
                isLoading={isFetchingAssessments1}
              />
            )}
          />

          <div className={styles.Compare}>
            <Compare className={styles.CompareIcon} />
          </div>

          <Controller
            control={control}
            name="option2.user"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                value={value}
                data={user2Values}
                label={t('inputs.user')}
                placeholder={t('inputs.user')}
                onSelect={onChange}
                isLoading={isFetchingUsers2}
              />
            )}
          />

          <Controller
            control={control}
            name="option2.assessment"
            render={({ field: { onChange, value } }) => (
              <Select
                required
                value={value}
                data={assessments2Values}
                label={t('inputs.diagnostic_name')}
                placeholder={t('inputs.select_diagnostic')}
                onSelect={onChange}
                isLoading={isFetchingAssessments2}
              />
            )}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Controller
          control={control}
          name="option1.assessment"
          render={({ field: { onChange, value } }) => (
            <Select
              required
              value={value}
              data={assessments1Values}
              label={t('inputs.diagnostic_name')}
              placeholder={t('inputs.select_diagnostic')}
              onSelect={onChange}
              isLoading={isFetchingAssessments1}
            />
          )}
        />

        <div className={styles.Compare}>
          <Compare className={styles.CompareIcon} />
        </div>

        <Controller
          control={control}
          name="option2.assessment"
          render={({ field: { onChange, value } }) => (
            <Select
              required
              value={value}
              data={assessments2Values}
              label={t('inputs.diagnostic_name')}
              placeholder={t('inputs.select_diagnostic')}
              onSelect={onChange}
              isLoading={isFetchingAssessments2}
            />
          )}
        />
      </Fragment>
    );
  }, [
    t,
    control,
    user1Values,
    user2Values,
    assessments1Values,
    assessments2Values,
    isUserComparison,
    isFetchingUsers1,
    isFetchingUsers2,
    isFetchingAssessments1,
    isFetchingAssessments2,
  ]);

  return (
    <ModalWrapper
      open={open}
      close={handleCancel}
      title={t('assessment.comparison')}
      subtitle={isOwnComparison ? t('assessment.own_comparison_description') : t('assessment.comparison_description')}
      action={handleSubmit(onSubmit)}
      actionTitle={t('general.compare')}
      actionDisabled={!formState.isValid}
      cancelTitle={t('general.cancel')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>{renderForm}</form>
    </ModalWrapper>
  );
};

export default CompareAssessmentModal;
