import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Tag as TagComponent } from 'rsuite';

import { Tag } from 'types/userTypes';

import { ReactComponent as EditIcon } from 'assets/images/pencil-edit.svg';

import ModalWrapper from '../ModalWrapper';

import styles from './ViewAllAccesses.module.scss';

type Props = {
  open: boolean;
  tags: Tag[];
  close: () => void;
  onSelectTag: (tag: Tag, index: number) => void;
};

const ViewAllAccesses = ({ open, tags, close, onSelectTag }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      open={open}
      title={t('users.all_secondary_organizations')}
      subtitle={t('users.add_user_to_another_organization_description')}
      action={close}
      actionTitle={t('general.back')}
      close={close}
    >
      <div className={styles.TagList}>
        {tags.map((el, i) => (
          <div className={classNames('rs-tag-re', styles.Tags)} key={i}>
            <TagComponent onClick={() => onSelectTag(el, i)}>
              {el?.group?.label} | {el.role.label}
              <EditIcon />
            </TagComponent>
          </div>
        ))}
      </div>
    </ModalWrapper>
  );
};

export default ViewAllAccesses;
