import { Dispatch, KeyboardEvent, MouseEvent, SetStateAction, useCallback, useMemo } from 'react';

import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import { Descendant, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, RenderElementProps, RenderLeafProps, Slate, useSlate, withReact } from 'slate-react';

import { Button, Toolbar } from './common/components';
import ColorPicker from './components/ColorPicker/ColorPicker';
import LinkElement from './components/Link/Link';
import LinkButton from './components/Link/components/LinkButton/LinkButton';

import { serializeToChars } from 'helpers/slate';

import { MAX_LONG_TEXT_ENTRY_LENGTH_ALLOWED_KEYS } from 'constants/';

import { CustomEditor, CustomElement, LinkType, TextAlign } from 'types/richTextEditorTypes';

import Icon from './common/Icon';
import withLinks from './plugins/withLinks';
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from './utils/SlateUtilityFunctions';
import { TEXT_ALIGN_TYPES } from './utils/SlateUtilityFunctions';

import styles from './RichTextEditor.module.scss';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+shift+x': 'delete',
};

type Props = {
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  renderOnly?: boolean;
  maxLength?: number;
  setShowError?: Dispatch<SetStateAction<boolean>>;
  showError?: boolean;
};

const RichTextEditor = ({
  value,
  disabled = false,
  renderOnly = false,
  setValue,
  maxLength,
  showError,
  setShowError,
}: Props) => {
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))) as CustomEditor, []);

  const initialValue = useMemo<Descendant[]>(
    () =>
      value
        ? JSON.parse(value)
        : [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ],
    [value],
  );

  const onChange = (data: Descendant[]) => {
    const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);

    if (isAstChange && setValue) {
      const content = JSON.stringify(data);
      setValue(content);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (isHotkey('mod+a', event)) {
      return true;
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
        toggleMark(editor, mark);
      }
    }

    const chars = value ? serializeToChars(JSON.parse(value)) : [];

    if (maxLength && !MAX_LONG_TEXT_ENTRY_LENGTH_ALLOWED_KEYS.includes(event.code) && chars.length >= maxLength) {
      if (!showError && setShowError) {
        setShowError(true);
      }

      event.preventDefault();
      return false;
    }

    if (showError && setShowError && maxLength && chars.length - 1 <= maxLength) {
      setShowError(false);
    }
  };

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
      <Editable
        className={classNames(styles.Editable, { [styles.Disabled]: disabled, [styles.RenderOnly]: renderOnly })}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        readOnly={disabled || renderOnly}
        spellCheck
        autoFocus
      />

      {!renderOnly && (
        <Toolbar disabled={disabled}>
          <MarkButton format="bold" />
          <MarkButton format="italic" />
          <MarkButton format="underline" />
          <MarkButton format="delete" />
          <LinkToolbarButton />
          <ColorToolbarButton />
          <BlockButton format="left" />
          <BlockButton format="center" />
          <BlockButton format="right" />
          <BlockButton format="bulleted-list" />
        </Toolbar>
      )}
    </Slate>
  );
};

const Element = ({ attributes, children, element }: RenderElementProps & { element: CustomElement }) => {
  const style = { textAlign: element.align as TextAlign };
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul style={{ ...style, listStylePosition: 'inside' }} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'link':
      return (
        <LinkElement attributes={attributes} element={element as LinkType}>
          {children}
        </LinkElement>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.delete) {
    children = <del>{children}</del>;
  }

  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

type BlockButtonProps = { format: string };

const BlockButton = ({ format }: BlockButtonProps) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{format}</Icon>
    </Button>
  );
};

const MarkButton = ({ format }: BlockButtonProps) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{format}</Icon>
    </Button>
  );
};

const LinkToolbarButton = () => {
  const editor = useSlate();
  return <LinkButton editor={editor} />;
};

const ColorToolbarButton = () => {
  const editor = useSlate();
  return <ColorPicker editor={editor} />;
};

export default RichTextEditor;
